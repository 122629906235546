import React, { ReactElement } from 'react';
import { RESOURCE_CERT_TEMPLATE } from '../../../constants';
import GatingArrayField from './GatingArrayField';

interface GatingCertTemplatesFieldProps {
  label?: string;
}

const GatingCertTemplatesField: React.FC<
  GatingCertTemplatesFieldProps
> = (): ReactElement => (
  <GatingArrayField
    source="tblCertGatingPredCerts"
    resource={RESOURCE_CERT_TEMPLATE}
    recordName="predCertTemplate"
    itemNameResource="predCertTemplate.certName"
  />
);

GatingCertTemplatesField.defaultProps = { label: 'Certificates' };

export default GatingCertTemplatesField;
