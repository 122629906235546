import React, { ReactElement } from 'react';
import { maxLength, required } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import CustomDateInput from '../common/CustomDateInput';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { TestType } from './types';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';

const TestTypeEdit: React.FC<EditPropsWithLocation<TestType>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Test Type"
      id="test_type_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="testType"
          label="Test Type"
          validate={[maxLength(30), required()]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(120)}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="updatedAt"
          label="Updated at"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="updatedBy"
          label="Updated by"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="updatedByName"
          label="Updated by name"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </LazyFormTab>
    <HistoryTab tableName="tbl_test_type" />
  </TabbedEditForm>
);

export default TestTypeEdit;
