import { ListProps, TextField, useStore } from 'react-admin';
import React, { ReactElement } from 'react';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import CustomBooleanField from '../common/CustomBooleanField';
import SubSystemSideFilters from './SubSystemSideFilters';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import BulkActionButtons from './BulkActionButtons';
import { RESOURCE_SUBSYSTEM } from '../constants';
import ListActions from '../common/ListActions';
import SystemField from '../common/SystemField';
import SubSystemEdit from './SubSystemEdit';
import SubSystemShow from './SubSystemShow';

const SubSystemList: React.FC<ListProps> = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_SUBSYSTEM);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      aside={
        showSideFilter ? (
          <SubSystemSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'subsystem', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<SubSystemShow withoutActions />}
        EditComponent={<SubSystemEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'subsystem',
          'systemId',
          'phase',
          'description',
          'sequence',
          'updatedAt',
        ]}
        sx={{
          '& .column-actions': { textAlign: 'center' },
        }}
      >
        <TextField source="id" label="ID" />
        <TextField source="subsystem" label="SubSystem" />
        <SystemField source="systemId" />
        <TextField source="phase" label="Phase" />
        <TextField source="description" label="Description" />
        <CustomBooleanField source="isScoped" label="Scoped" />
        <CustomBooleanField source="isVerified" label="Verified" />
        <TextField source="sequence" label="Sequence" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SubSystemList;
