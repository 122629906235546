import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { GREEN } from '../../../theme/colors';
import ChoicesLegendItem from './ChoicesLegendItem';
import { IChoicesLegendItem } from './types';

interface ChoicesLegendProps {
  title: string;
  items: IChoicesLegendItem[];
  extraInfoTitle?: string;
  extraInfoItems?: string[];
}

const ChoicesLegend: React.FC<ChoicesLegendProps> = ({
  title,
  items,
  extraInfoTitle,
  extraInfoItems,
}): ReactElement => (
  <Box textAlign="justify">
    <Typography component="h6" paragraph color={GREEN} fontWeight="bold">
      {title}
    </Typography>
    {items.map((item) => (
      <ChoicesLegendItem {...item} key={`choices-legend-item-${item.name}`} />
    ))}
    {extraInfoTitle ? (
      <Typography component="h6" paragraph color={GREEN} fontWeight="bold">
        {extraInfoTitle}
      </Typography>
    ) : null}
    {extraInfoItems && extraInfoItems.length ? (
      <ul style={{ fontSize: '.875rem' }}>
        {extraInfoItems.map((extraInfoItem, index) => (
          <li key={`extra-info-item-${index}`}>{extraInfoItem}</li>
        ))}
      </ul>
    ) : null}
  </Box>
);

export default ChoicesLegend;
