export const mobileTestStatuses = [
  { id: 'NONE', name: 'None' },
  { id: 'SUBMITTED', name: 'Submitted' },
  { id: 'APPROVED', name: 'Approved' },
  { id: 'REJECTED', name: 'Rejected' },
  {
    id: 'APPROVED_PROCESSED',
    name: 'Approved Processed',
    disabled: true,
  },
];

export const statuses = [
  { id: 'NONE', name: 'None' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'COMPLETED', name: 'Completed' },
];
