import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_DRAWING_DISCIPLINE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface DrawingDisciplineFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}

const DrawingDisciplineField: React.FC<DrawingDisciplineFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_DRAWING_DISCIPLINE}
  >
    <TextField source="drawingDiscipline.drawingDiscipline" />
  </SmartLinkField>
);

DrawingDisciplineField.defaultProps = {
  label: 'Drawing Discipline',
  source: 'drawingDisciplineId',
  sortBy: '`drawingDiscipline.drawingDiscipline`',
};

export default DrawingDisciplineField;
