import Box from '@mui/material/Box';
import React, { Fragment, ReactElement } from 'react';
import { BooleanInput, maxLength, minValue } from 'react-admin';
import CustomNumberInput from '../../common/CustomNumberInput';
import MaterialTypeInput from '../../common/MaterialTypeInput';
import commonStyles from '../../common/commonStyles';
import TextInput from '../../common/TextInput';
import PriceInput from '../common/PriceInput';
import {
  emptyToZero,
  required,
  integer,
  useDictionaryLabelsWithResource,
} from '../../../utils/UtilityFunctions';

interface MaterialFormProps {
  bulkEditMode?: boolean;
}

const MaterialForm: React.FC<MaterialFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const isRequired = !bulkEditMode;
  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="material"
          name="material"
          label={labels['material'] || 'Material'}
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
          validate={[maxLength(255)]}
        />
        <TextInput
          source="description"
          name="description"
          label={labels['description'] || 'Description'}
          sx={commonStyles.flexBoxLongItem}
          isRequired={isRequired}
          validate={[maxLength(255)]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <MaterialTypeInput sx={commonStyles.flexBoxItem} isRequired />
        <PriceInput
          label={labels['price'] || 'Price'}
          sx={commonStyles.flexBoxItem}
          format={emptyToZero}
          parse={emptyToZero}
        />
        <BooleanInput
          source="spare"
          name="spare"
          label={labels['spare'] || 'Spare'}
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="orderPoint"
          name="orderPoint"
          label={labels['orderPoint'] || 'Order Point'}
          sx={commonStyles.flexBoxItem}
          validate={[required(), minValue(0), integer()]}
        />
        <CustomNumberInput
          source="defReorderQty"
          name="defReorderQty"
          label={labels['defReorderQty'] || 'Def Reorder Qty'}
          sx={commonStyles.flexBoxItem}
          validate={[minValue(0), integer()]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="minLevel"
          name="minLevel"
          label={labels['minLevel'] || 'Min Level'}
          sx={commonStyles.flexBoxItem}
          validate={[minValue(0), integer()]}
        />
        <CustomNumberInput
          source="maxLevel"
          name="maxLevel"
          label={labels['maxLevel'] || 'Max Level'}
          sx={commonStyles.flexBoxItem}
          validate={[minValue(0), integer()]}
        />
        <CustomNumberInput
          source="totalQty"
          name="totalQty"
          label={labels['totalQty'] || 'Total Qty on Hand'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </Fragment>
  );
};

export default MaterialForm;
