import React, { Fragment, ReactElement } from 'react';
import { DateField, Labeled, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import YesNoField from '../../common/YesNoField';

const ElectricalShowTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box>
      <Labeled>
        <YesNoField
          label="Cable Pulled"
          sx={commonStyles.flexBoxItem}
          source="electrical.cablePulled"
        />
      </Labeled>
      <Labeled label="Cable Pulled Date" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.cablePulledDate" />
      </Labeled>
      <Labeled>
        <YesNoField
          label="Cable Glanded A"
          sx={commonStyles.flexBoxItem}
          source="electrical.cableGlandedA"
        />
      </Labeled>
      <Labeled>
        <YesNoField
          label="Cable Glanded B"
          sx={commonStyles.flexBoxItem}
          source="electrical.cableGlandedB"
        />
      </Labeled>
      <Labeled>
        <YesNoField
          label="Cable Tested"
          sx={commonStyles.flexBoxItem}
          source="electrical.cableTested"
        />
      </Labeled>
    </Box>
    <Box>
      <Labeled>
        <YesNoField
          label="Cable Term From"
          sx={commonStyles.flexBoxItem}
          source="electrical.cableTermFrom"
        />
      </Labeled>
      <Labeled label="Cable Term From Date" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.cableTermFromDate" />
      </Labeled>
      <Labeled>
        <YesNoField
          label="Cable Term To"
          sx={commonStyles.flexBoxItem}
          source="electrical.cableTermTo"
        />
      </Labeled>
      <Labeled label="Cable Term To Date" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.cableTermToDate" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Size" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.size" />
      </Labeled>
      <Labeled label="Motor RTG" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.motorRtg" />
      </Labeled>
      <Labeled label="Rating Units" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.ratingUnits" />
      </Labeled>
      <Labeled label="RTD Voltage" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.rtdVoltage" />
      </Labeled>
      <Labeled label="Skid No" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.skidNo" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Current Previous" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.currentPrevious" />
      </Labeled>
      <Labeled label="Record Status" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.recordStatus" />
      </Labeled>
      <Labeled label="From Plan Dwg" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.fromPlanDwg" />
      </Labeled>
      <Labeled label="To Plan Dwg" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.toPlanDwg" />
      </Labeled>
      <Labeled label="Connection Diagram" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.connectionDiagram" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="From Description" sx={commonStyles.flexBoxLongItem}>
        <TextField source="electrical.fromDescription" />
      </Labeled>
      <Labeled label="To Description" sx={commonStyles.flexBoxLongItem}>
        <TextField source="electrical.toDescription" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Cable Description" sx={commonStyles.flexBoxLongItem}>
        <TextField source="electrical.cableDesc" />
      </Labeled>
      <Labeled label="Cable Type" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.cableType" />
      </Labeled>
      <Labeled label="Route" sx={commonStyles.flexBoxLongItem}>
        <TextField source="electrical.route" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Updated At" sx={commonStyles.flexBoxItem}>
        <DateField source="electrical.updatedAt" />
      </Labeled>
      <Labeled label="Updated By" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.updatedBy" />
      </Labeled>
      <Labeled label="Updated By Name" sx={commonStyles.flexBoxItem}>
        <TextField source="electrical.updatedByName" />
      </Labeled>
    </Box>
  </Fragment>
);

export default ElectricalShowTab;
