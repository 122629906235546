import {
  RESOURCE_CERT_DETAIL,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_COMPONENT,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_DRAWING,
  RESOURCE_MOC,
  RESOURCE_PUNCH,
  RESOURCE_PROJECT,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
  RESOURCE_TEST,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PENDING_PUNCH,
  RESOURCE_BOOK,
  RESOURCE_PUNCH_TEMPLATE,
} from '../../../constants';

export const HARD_DELETE_CONFIRMATION_MESSAGE =
  'This file will be permanently deleted. It is not recoverable. Are you sure?';

export const RESOURCE_CHOICES = [
  { id: 'subsystemId', name: 'SubSystem', filter: 'project' },
  { id: 'systemId', name: 'System', filter: 'project' },
  { id: 'drawingId', name: 'Drawing', filter: 'project' },
  { id: 'componentId', name: 'Component', filter: 'project' },
  { id: 'componentTestId', name: 'Component Test', filter: 'project' },
  { id: 'testId', name: 'Test', filter: 'division' },
  { id: 'punchId', name: 'Punch', filter: 'project' },
  { id: 'mocId', name: 'MoC', filter: 'project' },
  { id: 'refProjectId', name: 'Project' },
  { id: 'certTemplateId', name: 'Certificate Template', filter: 'division' },
  { id: 'certDetailId', name: 'Certificate Detail', filter: 'project' },
  {
    id: 'preservationItemActivityId',
    name: 'Preservation Item Activity',
    filter: 'project',
  },
  { id: 'pendingPunchId', name: 'Smart Punch Review', filter: 'project' },
  { id: 'bookId', name: 'Book', filter: 'project' },
  { id: 'punchTemplateId', name: 'Punch Template', filter: 'division' },
];

export const RESOURCE_NAMES = {
  subsystemId: RESOURCE_SUBSYSTEM,
  systemId: RESOURCE_SYSTEM,
  drawingId: RESOURCE_DRAWING,
  componentId: RESOURCE_COMPONENT,
  componentTestId: RESOURCE_COMPONENT_TEST,
  testId: RESOURCE_TEST,
  punchId: RESOURCE_PUNCH,
  mocId: RESOURCE_MOC,
  refProjectId: RESOURCE_PROJECT,
  certTemplateId: RESOURCE_CERT_TEMPLATE,
  certDetailId: RESOURCE_CERT_DETAIL,
  preservationItemActivityId: RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  pendingPunchId: RESOURCE_PENDING_PUNCH,
  bookId: RESOURCE_BOOK,
  punchTemplateId: RESOURCE_PUNCH_TEMPLATE,
};

export const ATTACHMENT_TYPE_CHOICES = [
  { id: 'GENERAL', name: 'General' },
  { id: 'LOGO', name: 'Logo' },
  { id: 'IMPORT', name: 'Import' },
];
