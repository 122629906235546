import { useCallback } from 'react';
import { useResourceContext } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';

const useInvalidateManyReferenceQueryCache = (resourceName?: string) => {
  const resourceContext = useResourceContext();
  const resource = resourceName ? resourceName : resourceContext;

  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [resource, 'getManyReference'],
    });
  }, [queryClient, resource]);
};

export default useInvalidateManyReferenceQueryCache;
