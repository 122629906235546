import React, { ReactElement, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { RESOURCE_ATTACHMENT } from '../../../constants';

export const RefreshAttachmentList: React.FC = (): ReactElement => {
  const queryClient = useQueryClient();

  const refreshAttachmentList = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [RESOURCE_ATTACHMENT] });
  }, [queryClient]);

  return (
    <IconButton title="Refresh" color="primary" onClick={refreshAttachmentList}>
      <RefreshIcon />
    </IconButton>
  );
};

export default RefreshAttachmentList;
