import { CreateDialog } from '@react-admin/ra-form-layout';
import React, { ReactElement, useCallback } from 'react';
import {
  SimpleForm,
  useListContext,
  useNotify,
  useRedirect,
  useResourceContext,
  useUnselectAll,
} from 'react-admin';
import { getContext, UserContext } from '../../../../provider/userContext';
import useCanAccess from '../../../common/hooks/useCanAccess';

interface CreateForSelectedMaterialsDialogProps {
  createResource: string;
  title: string;
  redirectTab: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
}

const CreateForSelectedMaterialsDialog: React.FC<
  CreateForSelectedMaterialsDialogProps
> = ({
  createResource,
  title,
  redirectTab,
  isOpen,
  onClose,
  children,
}): ReactElement => {
  const canAccess = useCanAccess(createResource);
  const unselectAll = useUnselectAll(useResourceContext());
  const { selectedIds } = useListContext();
  const ctx: UserContext = getContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = useCallback(
    ({ id }) => {
      redirect(`/${createResource}/${id}/${redirectTab}`);
      notify('Element created');
      unselectAll();
    },
    [notify, redirect, unselectAll, redirectTab, createResource]
  );

  if (!canAccess.create) return;

  return (
    <CreateDialog
      resource={createResource}
      fullWidth
      maxWidth="md"
      isOpen={isOpen}
      title={`Create ${title} for ${selectedIds.length} selected materials. `}
      close={onClose}
      mutationOptions={{
        onSuccess,
      }}
    >
      <SimpleForm
        defaultValues={{ projectId: ctx?.projectId, materialIds: selectedIds }}
      >
        {children}
      </SimpleForm>
    </CreateDialog>
  );
};

export default CreateForSelectedMaterialsDialog;
