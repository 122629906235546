import React, { ReactElement } from 'react';
import { RESOURCE_COMPONENT } from '../../../constants';
import RelatedArrayField from './RelatedArrayField';

interface RelatedComponentsFieldProps {
  label?: string;
}

const RelatedComponentsField: React.FC<
  RelatedComponentsFieldProps
> = (): ReactElement => (
  <RelatedArrayField
    source="tblMocComponents"
    resource={RESOURCE_COMPONENT}
    recordName="component"
    itemNameResource="component.tag"
  />
);

RelatedComponentsField.defaultProps = { label: 'Components' };

export default RelatedComponentsField;
