import React, { ReactElement } from 'react';
import { Link } from 'react-admin';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface DownloadLinkProps {
  downloadUrl: string;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({
  downloadUrl,
  title,
  onClick,
}): ReactElement => {
  return (
    <Link
      to={downloadUrl}
      download
      title={title}
      className="downloadButton"
      target={'_blank'}
    >
      <Button size="small" color="primary" onClick={onClick}>
        <FileDownloadIcon />
        download
      </Button>
    </Link>
  );
};
