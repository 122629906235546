import React, { ReactElement, useCallback } from 'react';
import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import commonStyles from '../../../common/commonStyles';
import useExcludeSelectedFilter from '../../../common/hooks/useExcludeSelectedFilter';
import MaterialStorageInput from '../../../common/MaterialStorageInput';

const MRMaterialStorageInput: React.FC = (): ReactElement => {
  const { setValue } = useFormContext();
  const { data: listRecords } = useListContext();

  const excludeFilter = useExcludeSelectedFilter(
    listRecords,
    'materialStorageId'
  );

  const onMaterialSelect = useCallback(
    (id, item) => {
      setValue('materialId', item.materialId);
      setValue('storageId', item.storageId);
      setValue('qtyOnHand', item.qtyOnHand);
    },
    [setValue]
  );

  return (
    <MaterialStorageInput
      sx={commonStyles.flexBoxLongItem}
      additionalFilter={excludeFilter}
      onChange={onMaterialSelect}
      isRequired
    />
  );
};

export default MRMaterialStorageInput;
