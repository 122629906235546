import React, { ReactElement, useEffect } from 'react';
import { BooleanInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';
import commonStyles from '../../common/commonStyles';

const VerifiedInput: React.FC<{ sx?: SxProps<Theme> }> = ({
  sx,
}): ReactElement => {
  const { setValue, formState } = useFormContext();

  const verifiedBy = useWatch({ name: 'verifiedBy' });
  const verifiedDate = useWatch({ name: 'verifiedDate' });

  useEffect(() => {
    if (!!verifiedBy && !!verifiedDate && !!formState?.isDirty) {
      setValue('verified', true, { shouldDirty: true });
    }
  }, [verifiedBy, verifiedDate, setValue, formState?.isDirty]);

  return (
    <BooleanInput
      source="verified"
      label="Verified"
      defaultValue={false}
      sx={sx || commonStyles.rowFormInput}
    />
  );
};

export default VerifiedInput;
