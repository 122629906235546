import React, { ReactElement } from 'react';
import { getContext, UserContext } from '../../../../../provider/userContext';
import commonStyles from '../../../../common/commonStyles';
import SubsystemInput from '../../../../common/SubsystemInput';
import { RESOURCE_MOC_SUBSYSTEM } from '../../../../constants';
import { FormRow } from '../../../types';
import RelatedFormRow from '../RelatedFormRow';

const SubSystemFormRow: React.FC<FormRow> = ({ recordId }): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <RelatedFormRow
      recordId={recordId}
      projectId={ctx?.projectId}
      reference={RESOURCE_MOC_SUBSYSTEM}
    >
      <SubsystemInput sx={commonStyles.rowFormInput} isRequired />
    </RelatedFormRow>
  );
};

export default SubSystemFormRow;
