import React, { ReactElement } from 'react';
import { ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import DeleteWithConfirmIconButton from '../../../common/DeleteWithConfirmIconButton';
import ContactField from '../../../common/ContactField';
import commonStyles from '../../../common/commonStyles';
import { RESOURCE_GROUP_CONTACT } from '../../../constants';
import ContactRowForm from './ContactRowForm';

const ContactFormTab: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <ReferenceManyField target="group_id" reference={RESOURCE_GROUP_CONTACT}>
      <EditableDatagrid
        disableAutofocus
        bulkActionButtons={false}
        mutationMode="pessimistic"
        createForm={<ContactRowForm recordId={record.id} />}
        editForm={<ContactRowForm recordId={record.id} />}
        sx={commonStyles.formTabDatagrid}
        actions={<DeleteWithConfirmIconButton />}
      >
        <ContactField />
        <TextField
          label="First name"
          source="contact.firstName"
          sortBy="contact.first_name"
        />
        <TextField
          label="Last name"
          source="contact.lastName"
          sortBy="contact.last_name"
        />
        <TextField label="Phone" source="contact.phone" />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default ContactFormTab;
