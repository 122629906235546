import { Datagrid, ReferenceManyField } from 'react-admin';
import React, { ReactElement } from 'react';
import { RESOURCE_MATERIAL_COMPONENT } from '../constants';
import MaterialComponentDatagridColumns from './MaterialComponentDatagridColumns';

const MaterialComponentTab: React.FC = (): ReactElement => (
  <ReferenceManyField
    target="componentId"
    reference={RESOURCE_MATERIAL_COMPONENT}
  >
    <Datagrid bulkActionButtons={false}>
      {MaterialComponentDatagridColumns()}
    </Datagrid>
  </ReferenceManyField>
);

export default MaterialComponentTab;
