import React, { ReactElement, useMemo } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import { RESOURCE_STORAGE } from '../../constants';
import AutocompleteReferenceInput from '../AutocompleteReferenceInput';
import commonStyles from '../commonStyles';
import sideFilterStyles from '../sideFilterStyles';
import StorageCreateDialog from './StorageCreateDialog';

interface VendorInputProps {
  alwaysOn?: boolean;
  multiple?: boolean;
  source?: string;
  label?: string;
  defaultValue?: string;
  sx?: SxProps<Theme>;
  canCreate?: boolean;
}

const optionRenderer = (choice) =>
  `${choice.storage} :: ${choice.storageLocation}`;

const StorageInput: React.FC<VendorInputProps> = ({
  alwaysOn,
  multiple,
  sx,
  canCreate,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'storage', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      optionText={optionRenderer}
      {...rest}
      resource={RESOURCE_STORAGE}
      requestParams={requestParams}
      create={canCreate && <StorageCreateDialog />}
      sx={
        sx ||
        (alwaysOn
          ? sideFilterStyles.sideFiltersSelectInput
          : commonStyles.flexBoxItem)
      }
      multiple={multiple}
      validate={[required()]}
      includeFields={['id', 'storage', 'storageLocation']}
    />
  );
};

StorageInput.defaultProps = {
  source: 'storageId',
  label: 'Storage',
  canCreate: false,
};

export default StorageInput;
