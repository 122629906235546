import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../common/FreeTextSearchInput';
import ResourceInput from './ResourceInput';

const DictionaryFilters: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput source="q" label="Search" name="Search" alwaysOn />
      <ResourceInput sx={{ marginBottom: '5px' }} alwaysOn />
    </Filter>
  );
};

export default DictionaryFilters;
