import React, { ReactElement } from 'react';
import {
  createDefaultValue,
  JsonSchema,
  ArrayTranslations,
} from '@jsonforms/core';
import {
  IconButton,
  Box,
  Tooltip,
  Grid,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

export interface ArrayToolbarProps {
  label: string;
  description: string;
  path: string;
  schema: JsonSchema;
  rootSchema: JsonSchema;
  enabled: boolean;
  translations: ArrayTranslations;
  addItem(path: string, value: any): () => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const ArrayToolbar = React.memo(function ArrayToolbar({
  label,
  description,
  path,
  addItem,
  schema,
  enabled,
  translations,
  rootSchema,
}: ArrayToolbarProps): ReactElement {
  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        marginBottom={2}
      >
        <Grid item>
          <Typography variant="h6">{label}</Typography>
          {description && <FormHelperText>{description}</FormHelperText>}
        </Grid>
        {enabled && (
          <Tooltip
            id="tooltip-add"
            title={translations.addTooltip}
            placement="bottom"
          >
            <IconButton
              aria-label={translations.addAriaLabel}
              onClick={addItem(path, createDefaultValue(schema, rootSchema))}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Box>
  );
});

export default ArrayToolbar;
