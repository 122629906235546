import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_CONTACT } from '../constants';
import SmartLinkField from './SmartLinkField';

interface ContactFieldProps {
  label?: string;
  source?: string;
  textFieldSource?: string;
  sortBy?: string;
}
const ContactField: React.FC<ContactFieldProps> = ({
  label,
  source,
  textFieldSource,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_CONTACT}>
    <TextField source={textFieldSource} />
  </SmartLinkField>
);

ContactField.defaultProps = {
  label: 'Contacts',
  source: 'contactId',
  textFieldSource: 'contact.nickname',
  sortBy: '`contact.nickname`',
};

export default ContactField;
