import { Tab, Tabs } from '@mui/material';
import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import CustomTabPanel from './CustomTabPanel';
import commonStyles from './commonStyles';

export interface SimpleTabsProps {
  tabs: Array<{
    label: string;
    component: ReactElement;
    id?: string;
    visible?: boolean;
    style?: CSSProperties;
  }>;
}

export const SimpleTabbedLayout: React.FC<SimpleTabsProps> = ({
  tabs,
}): ReactElement => {
  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = useCallback(
    (event: React.SyntheticEvent, newTab: number) => {
      setCurrentTab(newTab);
    },
    [setCurrentTab]
  );

  const visibleTabs = useMemo(
    () => tabs.filter((t) => t.visible == undefined || t.visible),
    [tabs]
  );

  return (
    <>
      <Tabs value={currentTab} onChange={changeTab}>
        {visibleTabs.map((tab, index) => {
          return (
            <Tab
              label={tab.label}
              key={index}
              sx={{ ...commonStyles.formTabHeader, ...tab.style }}
              id={tab.id}
            />
          );
        })}
      </Tabs>
      {visibleTabs.map((t, index) => {
        return (
          <CustomTabPanel value={currentTab} index={index} key={index}>
            {t.component}
          </CustomTabPanel>
        );
      })}
    </>
  );
};
