import React, { ReactElement, useEffect } from 'react';
import {
  FunctionField,
  Labeled,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  useResourceContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import { get } from 'lodash';
import { getContext, UserContext } from '../../provider/userContext';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import LazyFormTab from '../common/LazyFormTab';
import CustomBooleanField from '../common/CustomBooleanField';
import SmartReferenceField from '../common/SmartReferenceField';
import commonStyles from '../common/commonStyles';
import { RESOURCE_DIVISION } from '../constants';
import ProjectTabField from './components/ProjectTabField';
import PermissionsTab from './components/PermissionsTab';
import { getEffectivePermissionWithProjectId } from './helpers';

const UserShow: React.FC<ShowComponentProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const resource = useResourceContext();
  const queryClient = useQueryClient();
  const { id } = useParams();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [resource, 'getOne', { id: String(id) }],
    });
  }, [queryClient, id, resource]);

  return (
    <ShowComponent {...props}>
      <SimpleShowLayout sx={commonStyles.noPadding}>
        <TabbedForm
          toolbar={null}
          style={{ width: '100%' }}
          sx={commonStyles.tabbedFormBoldHeader}
          syncWithLocation={false}
        >
          <LazyFormTab
            label="User"
            id="user_tab"
            sx={commonStyles.formTabHeader}
          >
            <Box>
              <Labeled>
                <TextField
                  source="id"
                  label="Email"
                  sx={commonStyles.flexBoxItem}
                />
              </Labeled>
              <Labeled>
                <TextField
                  source="firstName"
                  label="First Name"
                  sx={commonStyles.flexBoxItem}
                />
              </Labeled>
              <Labeled>
                <TextField
                  source="lastName"
                  label="Last Name"
                  sx={commonStyles.flexBoxItem}
                />
              </Labeled>
              <Labeled>
                <TextField
                  source="jobTitle"
                  label="Job Title"
                  sx={commonStyles.flexBoxItem}
                />
              </Labeled>
              <Labeled>
                <TextField
                  source="externalCompany"
                  label="Company"
                  sx={commonStyles.flexBoxItem}
                />
              </Labeled>
            </Box>
            <Box>
              <Labeled>
                <SmartReferenceField
                  source={`companies.${ctx?.company}.defaultDivisionId`}
                  label="Division"
                  reference={RESOURCE_DIVISION}
                  sx={commonStyles.flexBoxItem}
                  textSource="divisionName"
                />
              </Labeled>
              <Labeled>
                <CustomBooleanField
                  source={`companies.${ctx?.company}.isPreferred`}
                  label="Preferred"
                />
              </Labeled>
            </Box>
          </LazyFormTab>
          <LazyFormTab
            label="Permissions"
            id="permissions_tab"
            sx={commonStyles.formTabHeader}
          >
            <FunctionField
              sx={commonStyles.fullWidth}
              render={(user) => {
                const effectivePermissions =
                  getEffectivePermissionWithProjectId(
                    get(user, `companies.${ctx?.company}.projects`, []),
                    ctx?.projectId
                  );
                return (
                  <PermissionsTab
                    effectivePermissions={effectivePermissions}
                    userId={user.id}
                    isShowPage
                  />
                );
              }}
            />
          </LazyFormTab>
          <LazyFormTab
            label="Projects"
            id="projects_tab"
            sx={commonStyles.formTabHeader}
          >
            <FunctionField
              sx={commonStyles.fullWidth}
              render={(user) => {
                const projects = get(
                  user,
                  `companies.${ctx?.company}.projects`,
                  {}
                );
                return <ProjectTabField projects={projects} />;
              }}
            />
          </LazyFormTab>
        </TabbedForm>
      </SimpleShowLayout>
    </ShowComponent>
  );
};

export default UserShow;
