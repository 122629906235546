import React, { ReactElement } from 'react';
import { BooleanField } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

type CustomBooleanFieldProps = {
  source: string;
  label?: string;
  sx?: SxProps<Theme>;
};

const CustomBooleanField: React.FC<CustomBooleanFieldProps> = ({
  source,
  label,
  sx,
}): ReactElement => (
  <BooleanField source={source} label={label} FalseIcon={null} sx={sx} />
);

export default CustomBooleanField;
