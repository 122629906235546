enum TicketType {
  bug = 'bug',
  featureRequest = 'featureRequest',
  modification = 'modification',
  suggestion = 'suggestion',
  needHelp = 'needHelp',
}

enum Priority {
  critical = 'critical',
  high = 'high',
  medium = 'medium',
  low = 'low',
}

interface TicketTypeOption {
  value: TicketType;
  label: string;
}

interface TicketPriorityOption {
  value: Priority;
  label: string;
}

export const ticketTypeOptions: TicketTypeOption[] = [
  {
    label: 'Bug',
    value: TicketType.bug,
  },
  {
    label: 'Feature Request',
    value: TicketType.featureRequest,
  },
  {
    label: 'Modification',
    value: TicketType.modification,
  },
  {
    label: 'Suggestion',
    value: TicketType.suggestion,
  },
  {
    label: 'Need Help',
    value: TicketType.needHelp,
  },
];

export const ticketPriorityOptions: TicketPriorityOption[] = [
  {
    label: 'Critical',
    value: Priority.critical,
  },
  {
    label: 'High',
    value: Priority.high,
  },
  {
    label: 'Medium',
    value: Priority.medium,
  },
  {
    label: 'Low',
    value: Priority.low,
  },
];
