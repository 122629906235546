import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NotFound } from 'react-admin';
import './styles.css';
import { RESOURCE_COMPANY_SUBSCRIPTION } from '../constants';
import useCanAccess from '../common/hooks/useCanAccess';
import DataProviderErrorsWrapper from '../DataProviderErrors/DataProviderErrorsWrapper';
import useDataProvider from '../../provider/dataProvider/useDataProvider';

const SubscriptionPortal: React.FC = (): ReactElement => {
  const canAccess = useCanAccess(RESOURCE_COMPANY_SUBSCRIPTION);
  const [portalLink, setPortalLink] = useState();
  const iframeRef = useRef<HTMLIFrameElement>();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUrl = async () => {
      try {
        const result = await dataProvider.getCompanySubscriptionPortal(
          RESOURCE_COMPANY_SUBSCRIPTION
        );
        setPortalLink(result.data);
        setIsLoading(false);
      } catch (error) {}
    };
    getUrl().then();
  }, [dataProvider]);

  const onIframeLoad = useCallback(() => {
    setIsLoading(isLoading ? false : false);
  }, [setIsLoading, isLoading]);

  if (!canAccess.list) {
    return <NotFound />;
  }

  return (
    <DataProviderErrorsWrapper
      resource={RESOURCE_COMPANY_SUBSCRIPTION}
      action={'getCompanySubscriptionPortal'}
    >
      <div className="subscription-portal--container">
        {!isLoading && !portalLink ? (
          <div className="subscription-portal--no-subscription">
            No subscriptions
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            name="subscription-portal"
            src={portalLink}
            onLoad={onIframeLoad}
          />
        )}
      </div>
    </DataProviderErrorsWrapper>
  );
};

export default SubscriptionPortal;
