import React, { Fragment, ReactElement } from 'react';
import {
  maxLength,
  BooleanInput,
  useRecordContext,
  minValue,
} from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import VendorInput from '../common/VendorInput/VendorInput';
import DisciplineTestInput from '../common/DisciplineTestInput';
import CustomNumberInput from '../common/CustomNumberInput';
import TestTypeInput from '../common/TestTypeInput';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import RevisionInput from './components/RevisionInput';

interface TestFormProps {
  bulkEditMode?: boolean;
}

const TestForm: React.FC<TestFormProps> = ({ bulkEditMode }): ReactElement => {
  const record = useRecordContext();
  const requiredValidation = bulkEditMode ? [] : [required()];
  const disabled = Boolean(
    record?.tblComponentTests?.length || record?.tblPreservationItems?.length
  );

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="testFormNo"
          label="Test Form No"
          validate={[...requiredValidation, maxLength(50)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
          disabled={bulkEditMode}
        />
        <TextInput
          source="testName"
          label="Test Name"
          validate={[...requiredValidation, maxLength(75)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
        <TextInput
          source="testResp"
          label="Test Responsible"
          validate={[maxLength(50)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TestTypeInput sx={commonStyles.flexBoxItem} disabled={disabled} />
        <DisciplineTestInput sx={commonStyles.flexBoxItem} />
        {record && <VendorInput />}
      </Box>
      <Box sx={commonStyles.flexBox}>
        <RevisionInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="multiTag"
          label="Multi Tag"
          defaultValue={false}
        />
        <BooleanInput
          source="isPreservation"
          label="Preservation"
          defaultValue={false}
          disabled={disabled}
        />
        <BooleanInput source="loop" label="Loop" defaultValue={false} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="duration"
          label="Duration"
          validate={[minValue(0, "Duration can't be negative.")]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="class"
          label="Class"
          validate={[maxLength(4)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
        <TextInput
          source="externalFormId"
          label="External Form ID"
          validate={[maxLength(50)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
    </Fragment>
  );
};

export default TestForm;
