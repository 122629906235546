import React, { useCallback, useState } from 'react';
import { Button } from 'react-admin';
import useDataProvider from '../../provider/dataProvider/useDataProvider';
import { RESOURCE_COMPANY_SUBSCRIPTION } from '../constants';
import './styles.css';

const SendEmailButton: React.FC = () => {
  const [isSent, setIsSent] = useState(false);
  const dataProvider = useDataProvider();

  const handleSendEmail = useCallback(() => {
    const sendEmail = async () => {
      try {
        await dataProvider.sendSubscriptionAdminEmail(
          RESOURCE_COMPANY_SUBSCRIPTION
        );
        setIsSent(true);
      } catch (e) {}
    };
    sendEmail().then();
  }, [dataProvider]);

  return (
    <div className="send-email-button">
      {isSent ? (
        <span className="send-email-button__sent">Email was sent</span>
      ) : (
        <Button label={'Send email'} onClick={handleSendEmail} />
      )}
    </div>
  );
};

export default SendEmailButton;
