import React, { ReactElement } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import './styles.css';

interface CustomDialogProps {
  title: string;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  children,
  open,
  onClose,
}): ReactElement => {
  return (
    open && (
      <Window
        title={title}
        onClose={onClose}
        modal={true}
        doubleClickStageChange={false}
        minimizeButton={() => null}
        maximizeButton={() => null}
        initialWidth={850}
        initialHeight={750}
      >
        {children}
      </Window>
    )
  );
};

export default CustomDialog;
