import { Types } from 'ably';
import Ably from 'ably/callbacks';

export interface NotificationContext {
  ablyClient: Types.RealtimePromise;
  userChannel?: Types.RealtimeChannelPromise;
}

let cacheContext: NotificationContext = undefined;

export const getNotificationContext = (
  ablyToken: string,
  companyName: string,
  clientId: string
): NotificationContext | undefined => {
  if (!cacheContext) {
    initNotificationContext(ablyToken, companyName, clientId);
  }
  return cacheContext;
};

export const setNotificationContext = (ctx: NotificationContext) => {
  cacheContext = ctx;
};

export const initNotificationContext = (
  ablyToken: string,
  companyName: string,
  clientId: string
) => {
  if (ablyToken) {
    const client = new Ably.Realtime.Promise({
      token: ablyToken,
      clientId: clientId,
    });
    const channel = client.channels.get(`${companyName}/${clientId}`);
    channel.presence.enterClient(clientId);

    setNotificationContext({
      ablyClient: client,
      userChannel: channel,
    });
  }
};

export const releaseNotificationContext = (clientId: string) => {
  if (cacheContext && cacheContext.userChannel.state !== 'detaching') {
    cacheContext.userChannel.presence.leaveClient(clientId);
  }
};

export const clearNotificationContext = async () => {
  if (cacheContext && cacheContext.userChannel.state !== 'detaching') {
    try {
      const channelName = cacheContext.userChannel.name;
      cacheContext.userChannel.unsubscribe();
      await cacheContext.userChannel.detach();
      cacheContext.ablyClient.channels.release(channelName);
      setNotificationContext(undefined);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        'clearNotificationContext: Error during detach chanel',
        error
      );
    }
  }
};
