import { RESOURCE_EQUIPMENT_TYPE_WITH_TEST } from '../constants';

export const equipmentTypeChoices = ['Hard', 'Soft'];

export const EQUIPMENT_TYPE_TEST_EXPORT_BUTTON_INFO = [
  {
    label: 'Export Eq. Type Tests',
    fileName: 'equipment_type_tests_and_locations',
    resource: RESOURCE_EQUIPMENT_TYPE_WITH_TEST,
    columns: [
      { source: 'equipType', label: 'Type' },
      { source: 'test', label: 'Test' },
      { source: 'location', label: 'Location' },
    ],
    singleButton: true,
  },
];
