import { createFilterOptions } from '@mui/material';
import { OptionType } from '../common/Assigned/types';

export const getContactItem = (contact, isNew = false) => ({
  group: isNew ? 'New Contact' : 'Contacts',
  option: {
    assignedType: 'assignedContactId',
    title: contact.nickname,
    value: contact.id,
  },
  id: `assignedContactId_${contact.id}`,
  isNew,
});

export const getUserItem = (user, isNew = false) => ({
  group: 'Users',
  option: {
    assignedType: 'assignedEmail',
    title: `${user.firstName} ${user.lastName} ${user.id}`,
    value: user.id,
  },
  id: `assignedEmail_${user.id}`,
  isNew,
});

// export const getGroupItem = (group, isNew = false) => ({
//   group: 'Groups',
//   option: {
//     assignedType: 'assignedGroupId',
//     title: group.groupName,
//     value: group.id,
//   },
//   id: `assignedGroupId_${group.id}`,
//   isNew,
// });

export const getUserGroupItem = (userGroup, isNew = false) => ({
  group: 'User Groups',
  option: {
    assignedType: 'assignedUserGroupId',
    title: userGroup.userGroup,
    value: userGroup.id,
  },
  id: `assignedUserGroupId_${userGroup.id}`,
  isNew,
});

export const getInitialValue = (record) => {
  const {
    assignedContact,
    assignedUserGroup,
    // assignedGroup,
    assignedEmail,
  } = record;

  if (assignedContact) {
    return getContactItem(assignedContact);
  }

  // if (assignedGroup) {
  //   return getGroupItem(assignedGroup);
  // }

  if (assignedUserGroup) {
    return getUserGroupItem(assignedUserGroup);
  }

  if (assignedEmail) {
    return getUserItem(assignedEmail);
  }

  return null;
};

export const filterOptions =
  (createMode: boolean = false) =>
  (options, params) => {
    const filtered = createFilterOptions<OptionType>()(options, params);

    if (createMode && params.inputValue !== '') {
      const newContact = {
        nickname: `Create Contact: ${params.inputValue}`,
        id: 'new',
      };

      filtered.push(getContactItem(newContact, true));
    }
    return filtered;
  };

export const getDialogTitle = (title) => {
  const newTitle = title.replace('Create Contact: ', '');

  return newTitle;
};
