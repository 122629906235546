import React from 'react';
import ContactSupportLink from '../../common/ContactSupportLink';

const Error500: React.FC = () => {
  return (
    <div>
      <p>
        We have some problems please contact with support <ContactSupportLink />
      </p>
    </div>
  );
};

export default Error500;
