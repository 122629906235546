import { useCallback, ReactElement } from 'react';
import {
  useRecordContext,
  Button,
  useNotify,
  useDataProvider,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import { RESOURCE_ATTACHMENT_REVISION } from '../constants';

const AttachmentRevisionTest = ({ outerResource }): ReactElement => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: generateTestPdf, isPending: isGeneratingPdf } = useMutation({
    mutationFn: () =>
      dataProvider.generateTestPdf(RESOURCE_ATTACHMENT_REVISION, {
        id: record?.id,
        type: outerResource,
      }),
    onError: async (error: AxiosError) => {
      let errorMessage = error.message;
      if (error?.response?.data instanceof Blob) {
        errorMessage = await error?.response?.data.text();
        if (error?.response?.data.type === 'application/json') {
          errorMessage = JSON.parse(errorMessage);
        }
      }

      notify(getErrorMessage(errorMessage, 'PDF file generation failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  const onClick = useCallback(() => {
    generateTestPdf();
  }, [generateTestPdf]);

  return (
    <Box sx={commonStyles.columnActionsBox}>
      <Button
        label="test"
        onClick={onClick}
        disabled={isGeneratingPdf}
        className="pdfTestButton"
      />
    </Box>
  );
};

export default AttachmentRevisionTest;
