import get from 'lodash/get';
import { useMemo } from 'react';

const useExcludeSelectedFilter = (
  selectedRecords: object[],
  idPropName: string
): { id_neq_any?: Array<string | number> } => {
  return useMemo(() => {
    const selectedIds = selectedRecords
      .map((item) => get(item, idPropName))
      .filter(Boolean);

    return !!selectedIds.length ? { id_neq_any: selectedIds } : {};
  }, [selectedRecords, idPropName]);
};

export default useExcludeSelectedFilter;
