import React, { ReactElement } from 'react';
import { ArrayField, ChipField } from 'react-admin';
import SmartSingleFieldList from '../common/SmartSingleFieldList';

interface SecurityRoleFieldProps {
  label?: string;
}

const SecurityRoleField: React.FC<SecurityRoleFieldProps> = ({
  label,
}): ReactElement => (
  <ArrayField source="groupRoles" label={label}>
    <SmartSingleFieldList linkType={false}>
      <ChipField source="role.role" clickable={false} />
    </SmartSingleFieldList>
  </ArrayField>
);

export default SecurityRoleField;
