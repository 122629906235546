import React, { ReactElement, useCallback } from 'react';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import AttachmentFormTab from '../common/AttachmentFormTab';
import { EditPropsWithLocation } from '../common/types';
import TabbedEditForm from '../common/TabbedEditForm';
import { RESOURCE_CERT_DETAIL } from '../constants';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import CertDetailsForm from './CertDetailsForm';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';

const CertDetailsEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_CERT_DETAIL,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Certificate"
        id="cert_detail_tab"
        sx={commonStyles.formTabHeader}
      >
        <CertDetailsForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="certDetailId"
          onUpload={clearCache}
          onDelete={clearCache}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_cert_detail" />
    </TabbedEditForm>
  );
};

export default CertDetailsEdit;
