import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecordContext } from 'react-admin';
import MetaDataInputs from '../../common/MetaDataInputs';
import LazyFormTab from '../../common/LazyFormTab';
import MechanicalForm from './MechanicalForm';
import commonStyles from '../../common/commonStyles';

const MechanicalFormTab: React.FC = (props): ReactElement => {
  const record = useRecordContext();
  const hasMechanical = useWatch({ name: 'hasMechanical' });

  return (
    <LazyFormTab
      {...props}
      label="Mechanical"
      id="mechanical_tab"
      sx={hasMechanical ? commonStyles.formTabHeader : commonStyles.displayNone}
    >
      <MechanicalForm />
      {record && <MetaDataInputs source="mechanical" />}
    </LazyFormTab>
  );
};

export default MechanicalFormTab;
