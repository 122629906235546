import React, { ReactElement } from 'react';
import { CreateProps, SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import CreateWithTitle from '../../common/CreateWithTitle';
import FooterToolbar from '../../common/FooterToolbar';
import MaterialReleasedForm from './components/MaterialReleasedForm';

const MaterialReleasedCreate: React.FC<CreateProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <CreateWithTitle {...props} redirect="edit">
      <SimpleForm
        toolbar={<FooterToolbar />}
        defaultValues={{ projectId: ctx?.projectId }}
      >
        <MaterialReleasedForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default MaterialReleasedCreate;
