import React, { Fragment } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as GearIcon } from '../../../assets/icons/functionalIcons/Gear.svg';
import { FILTER_TYPES } from './constants';
import FilterTypeSelectionMenu from './FilterTypeSelectionMenu';

interface AdditionalSearchFilterButtonProps {
  isInputFocused: boolean;
  menuOpen: boolean;
  handleMenuOpen: () => void;
  index: string;
  handleMenuClose: (event: Event) => void;
  filterType: (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];
  handleChangeFilterType: (
    value: AdditionalSearchFilterButtonProps['filterType']
  ) => void;
}

const AdditionalSearchFilterButton = React.forwardRef<
  HTMLButtonElement,
  AdditionalSearchFilterButtonProps
>((props, ref: React.RefObject<HTMLButtonElement>) => {
  const {
    isInputFocused,
    menuOpen,
    handleMenuOpen,
    index,
    handleMenuClose,
    filterType,
    handleChangeFilterType,
  } = props;

  return (
    <Fragment>
      <FilterTypeSelectionMenu
        menuOpen={menuOpen}
        menuRef={ref}
        handleMenuClose={handleMenuClose}
        filterType={filterType}
        handleChangeFilterType={handleChangeFilterType}
      />
      <IconButton
        sx={{
          visibility: isInputFocused || menuOpen ? 'visible' : 'hidden',
          width: '24px',
          height: '24px',
        }}
        ref={ref}
        onClick={handleMenuOpen}
        key={index}
      >
        <GearIcon />
      </IconButton>
    </Fragment>
  );
});

export default AdditionalSearchFilterButton;
