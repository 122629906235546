import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import TextInput from '../common/TextInput';
import { EditPropsWithLocation } from '../common/types';
import commonStyles from '../common/commonStyles';
import SecurityRoleInput from './SecurityRoleInput';
import { SecurityGroupEditProps } from './types';

const SecurityGroupEdit: React.FC<
  EditPropsWithLocation<SecurityGroupEditProps>
> = (props): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Group"
      id="security_group_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="groupName"
            label="Group Name"
            validate={[required(), maxLength(35)]}
            sx={commonStyles.flexBoxItem}
          />
          <SecurityRoleInput sx={commonStyles.flexBoxLongItem} />
        </Box>
        <MetaDataInputs />
      </Box>
    </LazyFormTab>
    <HistoryTab tableName="tbl_security_group" />
  </TabbedEditForm>
);

export default SecurityGroupEdit;
