import React, { ReactElement, useEffect } from 'react';
import { NumberInput, NumberInputProps } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatFromCents } from '../../common/helpers';

export interface MultipliedNumberInputProps
  extends Omit<NumberInputProps, 'source'> {
  source?: string;
  qtySource?: string;
  priceSource?: string;
}

const SubTotalInput: React.FC<MultipliedNumberInputProps> = ({
  source,
  qtySource,
  priceSource,
  ...rest
}): ReactElement => {
  const { setValue } = useFormContext();
  const qtyValue = useWatch({ name: qtySource });
  const priceValue = useWatch({ name: priceSource });

  useEffect(() => {
    const value =
      qtyValue != null && priceValue != null ? qtyValue * priceValue : null;
    setValue(source, value);
  }, [priceValue, qtyValue, setValue, source]);

  return (
    <NumberInput {...rest} source={source} format={formatFromCents} readOnly />
  );
};

SubTotalInput.defaultProps = {
  source: 'subTotal',
  label: 'SubTotal',
  qtySource: 'qtyOrdered',
  priceSource: 'price',
};

export default SubTotalInput;
