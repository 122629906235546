import React, { ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { RESOURCE_COMPONENT_DRAWING } from '../../../constants';

const ComponentFormShow: React.FC = (): ReactElement => (
  <ReferenceManyField
    target="drawing_id"
    reference={RESOURCE_COMPONENT_DRAWING}
  >
    <Datagrid bulkActionButtons={false} rowClick={false} sx={{ width: '50%' }}>
      <TextField label="Tag" source="component.tag" />
      <TextField label="Description" source="component.tag_description" />
    </Datagrid>
  </ReferenceManyField>
);

export default ComponentFormShow;
