import React, { ReactElement } from 'react';
import { Button, Identifier, useDataProvider, useNotify } from 'react-admin';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';

type PullAlphaFormButtonProps = {
  id: Identifier;
  externalFormId: string;
};

const PullAlphaFormButton: React.FC<PullAlphaFormButtonProps> = ({
  id,
  externalFormId,
}): ReactElement => {
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: pullAlphaForm, isPending: pullInProgress } = useMutation({
    mutationFn: async () => {
      return await dataProvider.pullAlphaForm(RESOURCE_ATTACHMENT_REVISION, {
        id,
        externalFormId,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [RESOURCE_ATTACHMENT_REVISION],
      });
      notify('Successfully Pulled Alpha Form Data.', {
        type: 'success',
        undoable: false,
      });
    },
    onError: (error) => {
      notify('Pull Alpha Form Data Error: ' + getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  if (!externalFormId) return null;

  return (
    <Button
      label={pullInProgress ? 'Pulling...' : 'Pull Alpha Form'}
      disabled={pullInProgress}
      onClick={() => {
        pullAlphaForm();
      }}
      size="medium"
      variant="contained"
      sx={{ marginTop: '10px' }}
    />
  );
};

export default PullAlphaFormButton;
