import React, { Fragment, ReactElement } from 'react';
import { maxLength, minValue, BooleanInput } from 'react-admin';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { ContactCategories } from '../common/contactInput/constants';
import AssignedWithCreateInput from '../common/Assigned/AssignedWithCreateInput';
import CustomNumberInput from '../common/CustomNumberInput';
import CustomDateInput from '../common/CustomDateInput';
import ComponentInput from '../common/ComponentInput';
import LocationInput from '../common/LocationInput';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import PreservationTestInput from './components/PreservationTestInput';
import { validateEndDate, validatePlusDate } from './validators';

export enum PreservationFormMode {
  create = 'create',
  edit = 'edit',
}

interface PreservationFormProps {
  mode: PreservationFormMode;
  bulkEditMode?: boolean;
}

const PreservationForm: React.FC<PreservationFormProps> = ({
  bulkEditMode,
  mode,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const isRequired = !bulkEditMode;
  const getMinDate = (): Dayjs => {
    return dayjs().subtract(1, 'month');
  };
  const startDateValidation =
    mode === PreservationFormMode.create ? getMinDate() : null;

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput
          sx={commonStyles.flexBoxItem}
          label={labels['componentId']}
          isRequired={isRequired}
        />
        <PreservationTestInput isRequired={isRequired} />
        <TextInput
          source="sequence"
          label={labels['sequence'] || 'Sequence'}
          sx={commonStyles.flexBoxItem}
          validate={[maxLength(20)]}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <LocationInput
          sx={commonStyles.flexBoxItem}
          label={labels['locationId']}
        />
        <AssignedWithCreateInput
          label={labels['assigned'] || 'Responsible'}
          sx={commonStyles.flexBoxLongItem}
          category={ContactCategories.preservationEmpWorkGrp}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="frequency"
          label={labels['frequency'] || 'Frequency'}
          sx={commonStyles.flexBoxItem}
          validate={[minValue(1)]}
        />
        <CustomNumberInput
          source="estimatedManHours"
          label={labels['estimatedManHours'] || 'Estimated Man Hours'}
          sx={commonStyles.flexBoxItem}
          validate={[minValue(0)]}
        />
        <BooleanInput
          source="isMobile"
          label={labels['isMobile'] || 'Mobile'}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="startDate"
          label={labels['startDate'] || 'Start Date'}
          sx={commonStyles.flexBoxItem}
          minDate={startDateValidation}
        />
        <CustomDateInput
          source="endDate"
          label={labels['endDate'] || 'End Date'}
          sx={commonStyles.flexBoxItem}
          validate={[validateEndDate]}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="onSiteDate"
          label={labels['onSiteDate'] || 'On Site Date'}
          sx={commonStyles.flexBoxItem}
        />
        <CustomNumberInput
          source="plusDate"
          label={labels['plusDate'] || 'Plus Date'}
          sx={commonStyles.flexBoxItem}
          validate={[minValue(1), validatePlusDate]}
        />
      </Box>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="storagePreservation"
          label={labels['storagePreservation'] || 'Storage Preservation'}
          fullWidth
          multiline
        />
      </Box>
    </Fragment>
  );
};

export default PreservationForm;
