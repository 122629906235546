import React, { ReactElement, useCallback } from 'react';
import { TextInputProps } from 'ra-ui-materialui';
import { useFormContext } from 'react-hook-form';
import { getContext, UserContext } from '../../../provider/userContext';
import TextInput from '../../common/TextInput';

const PrintNameInput: React.FC<TextInputProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const { setValue } = useFormContext();

  const handleOnClick = useCallback(
    () => setValue(props.source, ctx?.fullName),
    [props, ctx, setValue]
  );

  return <TextInput {...props} onClick={handleOnClick} />;
};

export default PrintNameInput;
