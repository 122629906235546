import React, { ReactElement } from 'react';
import { SelectField } from 'react-admin';
import { ACTIVITY_CONSTRAINT_OPTIONS } from './constants';
import { SelectFieldPropsWithOptionalSource } from './types';

const ActivityConstraintField: React.FC<SelectFieldPropsWithOptionalSource> = ({
  label,
  source,
  ...rest
}): ReactElement => (
  <SelectField
    {...rest}
    label={label}
    source={source}
    choices={ACTIVITY_CONSTRAINT_OPTIONS}
  />
);

ActivityConstraintField.defaultProps = {
  label: 'Constraint',
  source: 'activityConstraint',
};

export default ActivityConstraintField;
