import { useState } from 'react';
import { useRecordBrowserActivity } from './useRecordBrowserActivity';

export const useSupportModal = () => {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const {
    recordingInProgress,
    withRecording,
    lastRecordTimeRange,
    recordLink,
    stopRecording,
    setWithRecording,
    startRecording,
  } = useRecordBrowserActivity();

  const onClose = (_e, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;
    setSupportModalOpen(false);
  };

  const onOpen = () => {
    setWithRecording(false);
    setSupportModalOpen(true);
  };

  const onStopRecordingAndOpen = () => {
    stopRecording();
    setSupportModalOpen(true);
  };

  const onStartRecording = () => {
    startRecording();
  };

  return {
    onClose,
    onOpen,
    onStopRecordingAndOpen,
    onStartRecording,
    recordingInProgress,
    withRecording,
    lastRecordTimeRange,
    recordLink,
    supportModalOpen,
  };
};
