import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import ActivityConstraintField from '../common/ActivityConstraintField';
import ActivityDurationUnitField from '../common/ActivityDurationUnitField';
import ActivityPhaseField from '../common/ActivityPhaseField';
import ActivityTypeField from '../common/ActivityTypeField';
import CertDetailsShowTab from '../certDetails/CertDetailsShowTab';
import CertTemplateField from '../common/CertTemplateField';
import commonStyles from '../common/commonStyles';
import DisciplineField from '../common/DisciplineField';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import ComponentField from '../common/ComponentField';
import AuditListTab from '../audit/AuditListTab';

const ComponentActivityShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Component Activity">
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="componentActivity" label="Activity" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ComponentField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="sequence" label="Sequence" />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityPhaseField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DisciplineField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTypeField />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="description" label="Description" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityConstraintField />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="duration" label="Duration" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="actualDuration" label="Actual Duration" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityDurationUnitField />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="plannedDate" label="Planned Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="actualDate" label="Actual Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CertTemplateField />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="certGatingStatus.itrStatus" label="ITR Status" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certGatingStatus.punchStatus"
              label="Punch Status"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certGatingStatus.certificateStatus"
              label="Predecessor Certificate Status"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certGatingStatus.certStatus"
              label="Cert Gating Status"
            />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          {renderShowMetaDataFields()}
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Certificates" id="certificates_tab">
        <CertDetailsShowTab target="componentActivityId">
          <ComponentField />
        </CertDetailsShowTab>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_component_activity" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ComponentActivityShow;
