import { useCallback, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { RecordWithAnyData } from '../types';
import { useUploadFile } from './useUploadFile';

const useUpdateRevisionFile = () => {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const { uploadFile } = useUploadFile();
  const dataProvider = useDataProvider();

  const updateFile = useCallback(
    async (resource: string, data: RecordWithAnyData, file: File) => {
      const result = await uploadFile(resource, file, data);

      return dataProvider.updateRevisionFile(resource, {
        id: data.id,
        file_key: result.key,
        size: result.size,
      });
    },
    [dataProvider, uploadFile]
  );

  const updateRevisionFile = useCallback(
    async (resource: string, data) => {
      try {
        setUpdateInProgress(true);
        const { file, ...formValues } = data;
        return await updateFile(resource, formValues, file);
      } catch (err) {
        throw err;
      } finally {
        setUpdateInProgress(false);
      }
    },
    [updateFile]
  );

  return { updateInProgress, updateRevisionFile };
};

export default useUpdateRevisionFile;
