import React, {
  useCallback,
  useState,
  Fragment,
  ReactElement,
  useMemo,
} from 'react';
import {
  Confirm,
  useNotify,
  Button,
  Identifier,
  useRecordContext,
} from 'react-admin';
import CircularProgress from '../../../../common/CircularProgress';
import ActionDelete from '@mui/icons-material/Delete';
import { useMutation } from '@tanstack/react-query';
import { getErrorMessage } from '../../../../../utils/UtilityFunctions';
import useInvalidateResourceQueryCache from '../../../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_QR_SCANNER } from '../../../../constants';
import { styles } from '../../../styles';
import { QrScanItem } from '../../../types';
import useDataProvider from '../../../../../provider/dataProvider/useDataProvider';

interface DeleteButtonProps {
  data: QrScanItem[];
  className?: string;
  confirmTitle?: string;
  confirmContent?: string;
  resource?: string;
}

const DeleteQrItemButton: React.FC<DeleteButtonProps> = ({
  data,
}): ReactElement => {
  const notify = useNotify();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const record = useRecordContext();
  const invalidateAttachmentQueries =
    useInvalidateResourceQueryCache(RESOURCE_QR_SCANNER);
  const dataProvider = useDataProvider();

  const qrScanItem = useMemo(
    () => data.find((item) => item.recordId === record.id),
    [data, record.id]
  );
  const confirmationMessage = useMemo(
    () =>
      `File "${qrScanItem?.fileName}" will be permanently deleted. Are you sure?`,
    [qrScanItem?.fileName]
  );

  const { mutateAsync: deleteScanItem, isPending: isPending } = useMutation({
    mutationFn: async (id: Identifier) => {
      return await dataProvider.deleteScanItem(RESOURCE_QR_SCANNER, id);
    },
    onSuccess: async () => {
      await invalidateAttachmentQueries();
      notify(`File "${qrScanItem?.fileName}" was deleted`, {
        type: 'success',
      });
    },
    onError: (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
    onSettled: () => setIsOpenDialog(false),
  });

  const handleDelete = useCallback(async () => {
    await deleteScanItem(qrScanItem?.id);
  }, [deleteScanItem, qrScanItem?.id]);

  const handleDialogOpen = useCallback((event) => {
    event.stopPropagation();

    setIsOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => setIsOpenDialog(false), []);

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        key="button"
        label={' '}
        sx={styles.deleteButton}
      >
        <ActionDelete />
      </Button>
      <Confirm
        isOpen={isOpenDialog}
        loading={isPending}
        title={'Delete file'}
        content={isPending ? <CircularProgress /> : confirmationMessage}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
        fullWidth
      />
    </Fragment>
  );
};

export default DeleteQrItemButton;
