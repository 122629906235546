import React, { ReactElement, useCallback } from 'react';
import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import useExcludeSelectedFilter from '../../../common/hooks/useExcludeSelectedFilter';
import MaterialInput from '../../../common/MaterialInput';
import { MaterialType } from '../../materialPage/types';

export interface POMaterialsMaterialInputProps {
  disabled?: boolean;
  onMaterialSelect?: (id, material: MaterialType) => void;
}

const POMaterialsMaterialInput: React.FC<POMaterialsMaterialInputProps> = ({
  disabled,
  onMaterialSelect,
}): ReactElement => {
  const { setValue } = useFormContext();
  const { data: listRecords } = useListContext();

  const onChange = useCallback(
    (id, material: MaterialType) => {
      setValue('materialDescription', material?.description);
      setValue('materialType', material?.materialType?.type);
      onMaterialSelect(id, material);
    },
    [setValue, onMaterialSelect]
  );

  const materialsFilter = useExcludeSelectedFilter(listRecords, 'materialId');

  return (
    <MaterialInput
      onChange={onChange}
      disabled={disabled}
      isRequired
      additionalFilter={materialsFilter}
      label="Material"
    />
  );
};

export default POMaterialsMaterialInput;
