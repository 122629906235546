import React, { ReactElement } from 'react';
import { RESOURCE_TEST_TYPE } from '../../../constants';
import GatingArrayField from './GatingArrayField';

interface GatingTestTypesFieldProps {
  label?: string;
}

const GatingTestTypesField: React.FC<
  GatingTestTypesFieldProps
> = (): ReactElement => (
  <GatingArrayField
    source="tblCertGatingTests"
    resource={RESOURCE_TEST_TYPE}
    recordName="testType"
    itemNameResource="testType.testType"
  />
);

GatingTestTypesField.defaultProps = { label: 'Test Types' };

export default GatingTestTypesField;
