import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Identifier, TextField } from 'react-admin';
import ContactInput from './ContactInput';
import commonStyles from '../../../common/commonStyles';

interface ContactRowFormProps {
  recordId: Identifier;
}

const ContactRowForm: React.FC<ContactRowFormProps> = ({
  recordId,
}): ReactElement => (
  <RowForm
    transform={(data) => ({
      ...data,
      groupId: recordId,
    })}
  >
    <ContactInput sx={commonStyles.rowFormInput} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
    <TextField source="" sx={commonStyles.flexBoxLongItem} />
  </RowForm>
);

export default ContactRowForm;
