import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_SYSTEM } from '../constants';
import SmartLinkField from './SmartLinkField';

interface SystemFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: object;
}
const SystemField: React.FC<SystemFieldProps> = ({
  label,
  source,
  sx,
  textSource,
}): ReactElement => {
  return (
    <SmartLinkField label={label} source={source} resource={RESOURCE_SYSTEM}>
      <TextField source={textSource} sx={sx} />
    </SmartLinkField>
  );
};

SystemField.defaultProps = {
  label: 'System',
  source: 'systemId',
  textSource: 'system.systemName',
  sortBy: '`system.systemName`',
};

export default SystemField;
