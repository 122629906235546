import { PreservationItemActivityType } from './types';

export const getValidateRequiredFunc = (
  valueFieldName: string,
  valueNameForMessage: string
) => {
  return (
    value: unknown,
    allValues: PreservationItemActivityType
  ): string | null => {
    if (value && !allValues[valueFieldName]) {
      return `Cannot be filled out without ${valueNameForMessage}`;
    }

    return null;
  };
};

export const getValidateRequiredFuncByValue = (
  valueFieldName: string,
  targetValue: string
) => {
  return (
    value: unknown,
    allValues: PreservationItemActivityType
  ): string | null => {
    if (
      !value &&
      allValues[valueFieldName] &&
      allValues[valueFieldName] === targetValue
    ) {
      return `Required`;
    }

    return null;
  };
};
