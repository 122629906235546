import React, { ReactElement } from 'react';
import EditTitle from '../common/EditTitle';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import ComponentShowLayout from './components/ComponentShowLayout';

const ComponentShow: React.FC<ShowComponentProps> = (props): ReactElement => {
  return (
    <ShowComponent {...props} title={<EditTitle />}>
      <ComponentShowLayout />
    </ShowComponent>
  );
};

export default ComponentShow;
