export enum CONFIGURATION_TYPE {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  FILE = 'FILE',
}

export const CONFIGURATION_CHOICES = [
  { id: 'COMPANY_NAME', name: 'Company name' },
  { id: 'COMPANY_LOGO', name: 'Company logo' },
  { id: 'BOOK_PAGE_HEADER_ROW3', name: 'Book page header row 3' },
];
