import React, { ReactElement } from 'react';
import { AutocompleteInput, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

export interface ResponsibilityInputProps {
  validate?: Validator[];
  sx: SxProps<Theme>;
}

const ResponsibilityInput: React.FC<ResponsibilityInputProps> = ({
  validate,
  sx,
}): ReactElement => {
  // Hard-coded list
  const choices = [
    { id: 'install', name: 'Install' },
    { id: 'supply', name: 'Supply' },
    { id: 'install/supply', name: 'Install/Supply' },
  ];

  return (
    <AutocompleteInput
      source="responsibility"
      label="Responsibility"
      choices={choices}
      validate={validate ?? []}
      sx={sx}
    />
  );
};

export default ResponsibilityInput;
