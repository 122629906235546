import React, { ReactElement } from 'react';
import { List } from 'react-admin';
import ListTitle from './ListTitle';
import { ListWithPagination } from './ListWithPagination';
import DataProviderErrorsWrapper from '../DataProviderErrors/DataProviderErrorsWrapper';

interface ListWithTitleProps extends React.ComponentProps<typeof List> {
  title?: string | ReactElement;
  filterDefaultValues?: object;
  children: ReactElement[] | ReactElement;
}

const ListWithTitle: React.FC<ListWithTitleProps> = ({
  title,
  filterDefaultValues,
  children,
  ...rest
}): ReactElement => {
  return (
    <DataProviderErrorsWrapper action={'getList'} ignoreCodes={[400, 404, 500]}>
      <ListWithPagination
        {...rest}
        title={title || <ListTitle />}
        filterDefaultValues={filterDefaultValues}
      >
        {children}
      </ListWithPagination>
    </DataProviderErrorsWrapper>
  );
};

export default ListWithTitle;
