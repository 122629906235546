import React, { ReactElement } from 'react';
import {
  Datagrid,
  ListContextProvider,
  ListControllerResult,
  ListView,
  TextField,
} from 'react-admin';
import { Permission } from '../../../provider/userContext';
import ActionField from '../../securityRolePermission/ActionField';
import Empty from '../../common/Empty';

interface PermissionListProps {
  userId: string;
  effectivePermissions: Permission[];
  isShowPage?: boolean;
}

const PermissionsTab: React.FC<PermissionListProps> = ({
  effectivePermissions,
}): ReactElement => {
  const listCtx: ListControllerResult = {
    data: effectivePermissions.sort((permission1, permission2) =>
      permission1.resource.localeCompare(permission2.resource)
    ),
    sort: { field: 'resource', order: 'ASC' },
    perPage: effectivePermissions.length,
    total: effectivePermissions.length,
    page: 0,
    displayedFilters: {},
    filterValues: {},
    selectedIds: [],
    resource: '',
    isFetching: false,
    isPending: false,
    hasNextPage: false,
    hasPreviousPage: false,
    exporter: false,
    error: null,
    onSelect: () => {},
    onToggleItem: () => {},
    onUnselectItems: () => {},
    setPerPage: () => {},
    setPage: () => {},
    setFilters: () => {},
    refetch: () => {},
    hideFilter: () => {},
    showFilter: () => {},
    setSort: () => {},
  };

  return (
    <ListContextProvider value={listCtx}>
      <ListView
        title=" "
        empty={<Empty emptyText="User has no access to the current project." />}
        actions={false}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="resource" label="Resource" sortable={false} />
          <TextField source="type" label="Type" sortable={false} />
          <ActionField label="Role Action" />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

export default PermissionsTab;
