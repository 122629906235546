import { GREEN, RED, YELLOW } from '../../theme/colors';

export const statusChoices = [
  { id: 'RAISED', name: 'Raised' },
  { id: 'CLEARED', name: 'Cleared' },
  { id: 'ACCEPTED', name: 'Accepted' },
];

export const legend = [
  {
    label: 'ACCEPTED',
    color: GREEN,
  },
  {
    label: 'CLEARED',
    color: YELLOW,
  },
  {
    label: 'RAISED',
    color: RED,
  },
];
