import React, { ReactElement, useCallback, useMemo } from 'react';
import { useListContext, useStore } from 'react-admin';
import { Badge, Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { UserContext, getContext } from '../../provider/userContext';

interface FilterSidebarButtonProps {
  sideFilter?: string;
  defaultFilter?: object;
  filters?: object;
}

const FilterSidebarButton: React.FC<FilterSidebarButtonProps> = ({
  sideFilter,
  defaultFilter,
  filters,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const { setFilters, filterValues } = useListContext();
  const [sideFilters, setSideFilters] = useStore(sideFilter, false);

  const appliedFiltersCount = useMemo(() => {
    return Object.keys(filterValues).filter(
      (key) =>
        JSON.stringify(filterValues[key]) !==
        JSON.stringify(defaultFilter?.[key])
    ).length;
  }, [filterValues, defaultFilter]);

  const handleSwitchChange = useCallback(
    (e) => {
      const mergedFilterValues = {
        ...filters,
        ...(filterValues.assignedEmail === ctx?.id && {
          assignedEmail: ctx?.id,
        }),
        ...(filterValues.q && { q: filterValues.q }),
      };

      setFilters(mergedFilterValues, []);
      setSideFilters(e.target.checked);
    },
    [filters, filterValues, ctx?.id, setFilters, setSideFilters]
  );

  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={sideFilters}
          onChange={handleSwitchChange}
        />
      }
      label={
        <Badge badgeContent={appliedFiltersCount} color="secondary">
          <Tooltip
            title={
              appliedFiltersCount > 0
                ? `Filters Enabled (${appliedFiltersCount} applied)`
                : 'Filters Disabled'
            }
          >
            <Box sx={{ marginRight: '10px' }}>Show Filters</Box>
          </Tooltip>
        </Badge>
      }
    />
  );
};

export default FilterSidebarButton;
