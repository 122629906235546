import React, { ReactElement } from 'react';
import { useRecordContext, Validator } from 'react-admin';
import { TextInput as TextInputRA } from 'ra-ui-materialui';
import { SxProps, Theme } from '@mui/material/styles';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { required } from '../../../utils/UtilityFunctions';
import commonStyles from '../../common/commonStyles';

interface ActivityTextInputProps {
  sortBy?: string;
  label?: string;
  isRequired?: boolean;
  sx?: SxProps<Theme>;
  source?: string;
  resource?: string;
  validate?: Validator | Validator[];
  disabled?: boolean;
}

const ActivityTextInput: React.FC<ActivityTextInputProps> = (
  props
): ReactElement => {
  const record = useRecordContext();
  const { disabled, isRequired, sx, source, resource, sortBy, ...rest } = props;
  const activity = record?.systemActivityId
    ? 'systemActivity'
    : record?.subsystemActivityId
      ? 'subsystemActivity'
      : record?.componentActivityId
        ? 'componentActivity'
        : null;

  let validate = props.validate;
  validate = validate ?? [];
  validate = Array.isArray(validate) ? validate : [validate];
  if (isRequired) {
    validate.push(required());
  }

  if (resource) {
    return (
      <AutocompleteReferenceInput
        {...rest}
        resource={resource}
        source={`${activity}.${source}`}
        name={`${sortBy}_inc`}
        optionText={sortBy}
        disabled={disabled}
        sx={sx}
        includeFields={['id', sortBy]}
      />
    );
  }

  return (
    <TextInputRA
      validate={validate}
      source={`${activity}.${source}`}
      {...rest}
      InputProps={{ disabled }}
      sx={{ ...sx, ...(disabled && commonStyles.textInputDisabled) }}
    />
  );
};

export default ActivityTextInput;
