import { useGetList } from 'react-admin';
import { RESOURCE_LUCID_DIAGRAM } from '../../constants';
import { getContext, UserContext } from '../../../provider/userContext';
import { LucidDiagram } from '../../lucidDiagram/type';

const useDiagramForDashboard = (): LucidDiagram | null => {
  const ctx: UserContext = getContext();

  const { data: diagrams } = useGetList(
    RESOURCE_LUCID_DIAGRAM,
    {
      filter: {
        projectId: ctx?.projectId,
        isForDashboard: true,
      },
    },
    {
      enabled: !!ctx?.projectId,
    }
  );

  if (Array.isArray(diagrams) && diagrams.length == 1) {
    return diagrams[0];
  }

  return null;
};

export default useDiagramForDashboard;
