import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  BooleanInput,
  Identifier,
  RadioButtonGroupInput,
  useRecordContext,
  useStore,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { required } from '../../../utils/UtilityFunctions';
import { UserContext, getContext } from '../../../provider/userContext';
import DrawingTypeInput from '../../../components/common/DrawingTypeInput';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_ATTACHMENT_TYPE } from '../../constants';
import { BookSectionCertListEdit } from '../bookSectionCertList/BookSectionCertListEdit';
import { BookSectionEngCodeListEdit } from '../bookSectionEngCodeList/BookSectionEngCodeListEdit';
import { BookSectionTestListEdit } from '../bookSectionTestList/BookSectionTestListEdit';
import {
  SectionType,
  bookSectionIdStore,
  drawingReportTypeChoices,
} from '../constants';
import { BookSection } from '../type';

export const BookSectionTypeComponentEdit: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
    };
  }, [ctx?.projectId]);
  const record = useRecordContext<BookSection>();
  const [, setBookSectionId] = useStore<Identifier>(bookSectionIdStore, null);

  useEffect(() => {
    setBookSectionId(record?.id);
  }, [record, setBookSectionId]);

  const sectionType = useWatch<{ sectionType: SectionType }>({
    name: 'sectionType',
  });

  switch (sectionType) {
    case SectionType.PRESERVATION: {
      return (
        <BooleanInput label="Include attachments" source="includeAttachments" />
      );
    }
    case SectionType.DRAWING: {
      return (
        <Box>
          <DrawingTypeInput
            source="drawingTypes"
            label="Drawing Types"
            multiple
            sx={commonStyles.flexBoxLongItem}
          />
          <BooleanInput label="Include drawings" source="includeDrawings" />
          <RadioButtonGroupInput
            label={false}
            source="drawingReportType"
            validate={required()}
            choices={drawingReportTypeChoices}
          />
        </Box>
      );
    }
    case SectionType.ATTACHMENT: {
      return (
        <AutocompleteReferenceInput
          resource={RESOURCE_ATTACHMENT_TYPE}
          label="Attachment Type"
          source="attachmentType"
          name="subtype_inc"
          optionText="subtype"
          sx={commonStyles.flexBoxLongItem}
          validate={[required()]}
          multiple
          requestParams={requestParams}
          includeFields={['id', 'subtype']}
        />
      );
    }
    case SectionType.CERT: {
      return <BookSectionCertListEdit />;
    }
    case SectionType.TEST: {
      return <BookSectionTestListEdit />;
    }
    case SectionType.ENG_LIST: {
      return <BookSectionEngCodeListEdit />;
    }
    default: {
      return null;
    }
  }
};
