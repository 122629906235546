import React, { ReactElement } from 'react';
import { DateField, TextField, useRecordContext } from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import commonStyles from '../../../../common/commonStyles';
import { RESOURCE_PUNCH } from '../../../../constants';
import { FormTabProps, QrScanList } from '../../../types';
import DataFormTab from '../DataFormTab';
import PunchFormRow from './PunchFormRow';

const PunchFormTab: React.FC<FormTabProps> = ({ editMode }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const record = useRecordContext<QrScanList>();
  const data = record.qrs.punches;

  return (
    <DataFormTab
      data={data}
      attachmentId={record.id}
      resource={RESOURCE_PUNCH}
      editFormRow={<PunchFormRow data={data} />}
      editMode={editMode}
    >
      <TextField source="punchNo" label={labels['punchNo'] || 'Punch No'} />
      <TextField
        source="clearedBy"
        label={labels['clearedBy'] || 'Cleared By'}
        sx={commonStyles.rowFormField}
      />
      <DateField
        source="clearedDate"
        label={labels['clearedDate'] || 'Cleared Date'}
        sx={commonStyles.rowFormField}
      />
      <TextField
        source="acceptedBy"
        label={labels['acceptedBy'] || 'Accepted By'}
        sx={commonStyles.rowFormField}
      />
      <DateField
        source="acceptedDate"
        label={labels['acceptedDate'] || 'Accepted Date'}
        sx={commonStyles.rowFormField}
      />
    </DataFormTab>
  );
};

export default PunchFormTab;
