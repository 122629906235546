import { ReactElement } from 'react';
import { TextInputProps, TextInput as TextInputRA } from 'ra-ui-materialui';
import { required } from '../../utils/UtilityFunctions';
import commonStyles from './commonStyles';

const TextInput = (props: TextInputProps): ReactElement => {
  const { disabled, isRequired, sx, id, source, ...rest } = props;
  let validate = props.validate;
  validate = validate ?? [];
  validate = Array.isArray(validate) ? validate : [validate];
  if (isRequired) {
    validate.push(required());
  }

  return (
    <TextInputRA
      validate={validate}
      {...rest}
      source={source}
      id={id || source}
      InputProps={{ disabled }}
      sx={{ ...sx, ...(disabled && commonStyles.textInputDisabled) }}
    />
  );
};

export default TextInput;
