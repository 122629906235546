import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import ActivityCertGatingItrStatusInput from '../../common/ActivityCertGatingItrStatusInput';
import ActivityCertGatingPredCertStatusInput from '../../common/ActivityCertGatingPredCertStatusInput';
import ActivityCertGatingPunchStatusInput from '../../common/ActivityCertGatingPunchStatusInput';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityCertGatingStatusOverallInput from '../../common/ActivityCertGatingStatusOverallInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import { activityCategory } from '../../common/constants';
import DateRangeFilter from '../../common/DateRangeFilter';
import DisciplineInput from '../../common/DisciplineInput';
import SubsystemInput from '../../common/SubsystemInput';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import sideFilterStyles from '../../common/sideFilterStyles';
import { SideFilter } from '../../common/types';
import { RESOURCE_SUBSYSTEM_ACTIVITY } from '../../constants';
import TextInput from '../../common/TextInput';
import SystemInput from '../../common/SystemInput';

const SubSystemActivitySideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_SUBSYSTEM_ACTIVITY, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="subsystemActivity"
            name="subsystemActivity_inc"
            label="Activity"
            fullWidth
            alwaysOn
          />
          <SubsystemInput multiple fullWidth alwaysOn />
          <TextInput
            source="sequence"
            name="sequence_inc"
            label="Sequence"
            fullWidth
            alwaysOn
          />
          <ActivityPhaseInput
            category={activityCategory.subSystem}
            multiple
            fullWidth
            alwaysOn
          />
          <DisciplineInput withAllOption multiple fullWidth alwaysOn />
          <ActivityTypeInput
            category={activityCategory.subSystem}
            multiple
            fullWidth
            alwaysOn
          />
          <TextInput
            source="description"
            name="description_inc"
            label="Description"
            fullWidth
            alwaysOn
          />
          <ActivityConstraintInput multiple fullWidth alwaysOn />
          <CertTemplateInput
            category={activityCategory.subSystem}
            multiple
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            source="plannedDate"
            label="Planned Date"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="actualDate"
            label="Actual Date"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <ActivityCertGatingItrStatusInput multiple fullWidth alwaysOn />
          <ActivityCertGatingPunchStatusInput multiple fullWidth alwaysOn />
          <ActivityCertGatingPredCertStatusInput multiple fullWidth alwaysOn />
          <ActivityCertGatingStatusOverallInput multiple fullWidth alwaysOn />
          <SystemInput
            source="subsystem->systemId"
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            multiple
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SubSystemActivitySideFilters;
