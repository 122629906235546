import React, { Fragment, ReactElement } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import AssignResponsibleButton from '../../common/AssignResponsibleButton';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import { ContactCategories } from '../../common/contactInput/constants';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_COMPONENT_TEST } from '../../constants';
import InfoButton from './InfoButton';

interface BulkActionButtonsProps {
  onUpdate: () => void;
}

const BulkActionButtons: React.FC<BulkActionButtonsProps> = ({
  onUpdate,
}): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <AssignResponsibleButton category={ContactCategories.itrEmpWorkGrp} />
      {canAccessList.closeout && (
        <Fragment>
          <CustomBulkUpdateButton
            data={{ mobileTestStatus: 'SUBMITTED' }}
            label="Close Out ITR(s)"
            icon={<CheckIcon />}
            resource={RESOURCE_COMPONENT_TEST}
            onUpdate={onUpdate}
          />

          <InfoButton />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BulkActionButtons;
