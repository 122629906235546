import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '../common/CircularProgress';
import { styles } from './styles';

const IframeSection: React.FC<{
  loaded: boolean;
  onLoad: () => void;
  src: string;
  iframeHeight: number;
}> = ({ loaded, onLoad, src, iframeHeight }) => (
  <Box sx={styles.iframeWrapper}>
    {!loaded && (
      <Box sx={styles.iframeLoader}>
        <CircularProgress />
      </Box>
    )}
    <iframe style={{ height: iframeHeight }} onLoad={onLoad} src={src} />
  </Box>
);

export default IframeSection;
