import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

const TopText: React.FC = (): ReactElement => {
  return (
    <Box
      sx={{
        minWidth: 270,
      }}
    >
      <p>
        The
        <strong style={{ color: '#800000' }}>
          &apos;Drawing Classes&apos;
        </strong>
        element is for informational purposes only. The intended use is to
        identify drawing prefixes or classes and give a brief description for
        reference.
        <br />
        Examples: (Example 1) All &apos;968&apos; prefixed drawings are
        elementaries. (Example 2) All &apos;12020&apos; drawings are ISOs.
      </p>
    </Box>
  );
};

export default TopText;
