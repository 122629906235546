import React, { Fragment, ReactElement } from 'react';
import { SelectInput, maxLength, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import { required } from '../../utils/UtilityFunctions';
import TextInput from '../common/TextInput';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import { reportTypeChoices } from './constants';

interface ReportFormProps {
  editMode?: boolean;
}

const ReportForm: React.FC<ReportFormProps> = ({ editMode }): ReactElement => {
  const record = useRecordContext();
  const isEditable =
    record.reportType === reportTypeChoices[0].name || !editMode;

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="reportName"
          label="Report Name"
          sx={commonStyles.flexBoxItem}
          validate={[required(), maxLength(75)]}
          disabled={!isEditable}
        />
        <SelectInput
          source="reportType"
          label="Report Type"
          choices={reportTypeChoices}
          sx={commonStyles.flexBoxItem}
          validate={[required()]}
          disabled={editMode}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          validate={[maxLength(255)]}
          disabled={!isEditable}
        />
      </Box>
      {editMode && <MetaDataInputs />}
    </Fragment>
  );
};

export default ReportForm;
