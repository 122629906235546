import React, { ReactElement, Fragment, useEffect, useState } from 'react';
import { SelectInput, useGetList, useRecordContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomDateInput from '../../common/CustomDateInput';
import {
  RESOURCE_ATTACHMENT_REVISION,
  RESOURCE_ATTACHMENT,
} from '../../constants';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../../utils/UtilityFunctions';

interface RevisionInputProps {
  isRequired?: boolean;
  sx: SxProps<Theme>;
  disabled?: boolean;
}

const RevisionInput: React.FC<RevisionInputProps> = ({
  isRequired,
  sx,
  disabled,
}): ReactElement => {
  const formContext = useFormContext();
  const record = useRecordContext();
  const testId = record ? record.id : undefined;

  const revision = useWatch({ name: 'attachmentRevisionId' });
  const [revisions, setRevisions] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: attachments, isLoading: isAttachmentLoading } = useGetList(
    RESOURCE_ATTACHMENT,
    { filter: { testId, isDeleted: false } },
    { enabled: !!testId }
  );

  const { data: dataRevisions, isLoading: isRevisionLoading } = useGetList(
    RESOURCE_ATTACHMENT_REVISION,
    { filter: { attachmentId: attachments?.[0]?.id } },
    { enabled: !isAttachmentLoading && !!attachments?.length }
  );

  useEffect(() => {
    if (testId && !attachments?.length) {
      setRevisions([]);
      setOptions([]);
    }
  }, [testId, attachments]);

  useEffect(() => {
    setRevisions(dataRevisions || []);
    setOptions(
      (dataRevisions || []).map((item) => ({
        id: item.id,
        name: String(item.revision),
      }))
    );
  }, [dataRevisions]);

  useEffect(() => {
    for (const rev of revisions) {
      if (Number(rev?.['id']) === Number(revision)) {
        formContext.setValue('revDate', rev?.['revisionDate']);
        break;
      }
    }
  }, [formContext, revisions, revision]);

  return (
    <Fragment>
      {options.length > 0 && (
        <Fragment>
          <SelectInput
            name="attachmentRevisionId"
            source="attachmentRevisionId"
            label="Revision"
            validate={isRequired ? [required()] : []}
            sx={sx}
            choices={options}
            isLoading={isAttachmentLoading || isRevisionLoading}
            disabled={disabled}
          />
          <CustomDateInput
            source="revDate"
            label="Revision Date"
            disabled
            sx={sx}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default RevisionInput;
