import axios from 'axios';
import { downloadFile } from '../../../utils/UtilityFunctions';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export const getGeneratePdfDataProvider = () => ({
  generatePdf: (resource: string, params: { id: number; type?: string }) => {
    const { id, type } = params;

    return axios
      .get(
        `${CRUD_API_URL}/${resource}/${id}/generate-pdf${type ? '?type=' + type : ''}`,
        {
          ...getRequestConfig(),
          responseType: 'blob',
        }
      )
      .then((req) => {
        if (req['status'] === 200) {
          downloadFile(resource, req.data, req.headers['content-type'], 'pdf');
        }
        return req;
      });
  },
  bulkGeneratePdf: (resource: string, ids: number[]) => {
    return axios.get(`${CRUD_API_URL}/${resource}/bulk-generate-pdf`, {
      ...getRequestConfig(),
      responseType: 'blob',
      params: { ids },
    });
  },
});
