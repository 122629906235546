import React, { ReactElement } from 'react';
import ChoicesLegend from '../../common/choicesLegend/ChoicesLegend';

const ItrAutomationLegend: React.FC = (): ReactElement => (
  <ChoicesLegend
    title="ITR Test Plan Automation Setting"
    items={[
      {
        name: 'Manual',
        description: 'Tag ITRs can be created by using Tag ITR/Tag ITRs page.',
        isDefault: true,
      },
      {
        name: 'Semi-Auto',
        description:
          'ITRs are assigned automatically by SubSystem. SubSystem population ' +
          'is controlled by the "Populate" toggle button on ' +
          'Tag ITR/Assign ITRs page. Enabling Populate toggle button will cause the auto ' +
          'population routine to run immediately for that SubSystem when in ' +
          'Semi-Auto. ITR populations after that are controlled by the triggers ' +
          'listed below in "All Methods". Disabling the toggle button has no ' +
          'affect other than stopping new populations from occurring.',
        isMarked: true,
      },
      {
        name: 'Auto',
        description:
          'ITRs are assigned automatically based on the ITR Test Plan',
        isMarked: true,
      },
    ]}
    extraInfoTitle="All Methods"
    extraInfoItems={[
      'Regardless of toggle setting, ITRs will not be deleted if they have ' +
        'historical data associated with them. Historical data is defined as ' +
        'completed ITR Attachments, completed Preservation Attachments, or the ' +
        'Tag-ITR has sign off data like Completed By/Date.',
      'All ITR assignments follow the Equipment Types/Test Plan assigned to each Component.',
      'Auto assignment occurs during tag imports, manual tag additions, Equipment Types/Test Plan changes.',
    ]}
  />
);

export default ItrAutomationLegend;
