import React, { ReactElement } from 'react';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import GroupTabsShow from './components/groupContactFormTab/GroupTabsShow';

const GroupShow: React.FC<ShowComponentProps> = (props): ReactElement => (
  <ShowComponent {...props}>
    <GroupTabsShow {...props} />
  </ShowComponent>
);

export default GroupShow;
