export enum ContactCategories {
  component = 'COMPONENT',
  email = 'EMAIL',
  testPack = 'TEST_PACK',
  testUnits = 'TEST_UNITS',
  punchSignOff = 'PUNCH_SIGN_OFF',
  preservationEmpWorkGrp = 'PRESERVATION_EMP_WORK_GRP',
  itrEmpWorkGrp = 'ITR_EMP_WORK_GRP',
  drawingEngineeringGrp = 'DRAWING_ENGINEERING_GRP',
  certSignOff = 'CERT_SIGN_OFF',
}
