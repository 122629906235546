import { Identifier, RaRecord, useGetList, useStore } from 'react-admin';
import { bookSectionIdStore } from './constants';

export const useGetMaxSortOrder = (resource: string) => {
  const [bookSectionId] = useStore<Identifier>(bookSectionIdStore, null);
  const { data } = useGetList<RaRecord & { sortOrder: number }>(resource, {
    filter: {
      bookSectionId,
    },
  });

  const maxSortOrder =
    data?.length && Math.max(...data.map((test) => test.sortOrder));
  return maxSortOrder || 0;
};
