import React, { ReactElement, Fragment } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import commonStyles from '../common/commonStyles';
import TextInput from '../common/TextInput';

interface SystemFormProps {
  bulkEditMode?: boolean;
}

const SystemForm: React.FC<SystemFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];
  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="systemName"
          validate={[...requiredValidation, maxLength(85)]}
          name="systemName"
          label="System"
          sx={commonStyles.flexBoxItem}
          disabled={bulkEditMode}
        />
        <TextInput
          source="description"
          name="description"
          label="Description"
          validate={[maxLength(150), ...requiredValidation]}
          sx={commonStyles.flexBoxLongItem}
        />
        <TextInput
          source="priority"
          name="priority"
          label="Priority"
          validate={[maxLength(30)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="commissioningProcedureNo"
          name="commissioningProcedureNo"
          label="Commissioning Procedure No"
          validate={[maxLength(35)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="commissioningEngineer"
          name="commissioningEngineer"
          label="Commissioning Engineer"
          validate={[maxLength(50)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="clientRepresentative"
          name="clientRepresentative"
          label="Client Representative"
          validate={[maxLength(50)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="sequence"
          name="sequence"
          label="Sequence"
          validate={[maxLength(15)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Fragment>
  );
};

export default SystemForm;
