import React, { useCallback, useState, Fragment, ReactElement } from 'react';
import { Button, CreateProps, useRecordContext } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import Add from '@mui/icons-material/Add';
import AttachmentRevisionCreateForm from './AttachmentRevisionCreateForm';

type AttachmentRevisionCreateInDialogProps = {
  onUpload?: () => void;
} & CreateProps;

const AttachmentRevisionCreateInDialog: React.FC<
  AttachmentRevisionCreateInDialogProps
> = ({ onUpload: propsOnUpload, ...rest }): ReactElement => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onUpload = useCallback(async () => {
    setOpen(false);
    if (propsOnUpload) {
      propsOnUpload();
    }
  }, [propsOnUpload]);

  return (
    <Fragment>
      <Button label="Add revision" onClick={handleOpen}>
        <Add />
      </Button>
      <CreateDialog
        {...rest}
        title={<span>Add Revision</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <AttachmentRevisionCreateForm record={record} onSubmit={onUpload} />
      </CreateDialog>
    </Fragment>
  );
};

export default AttachmentRevisionCreateInDialog;
