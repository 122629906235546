import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import TestForm from './TestForm';

const TestCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data) => {
    return {
      ...data,
      divisionId: ctx?.defaultDivisionId,
      projectId: ctx?.projectId,
    };
  };

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <TestForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default TestCreate;
