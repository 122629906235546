import React, { ReactElement } from 'react';
import QrScannerForm from './QrScannerForm';

interface QrScannerEditProps {
  withoutActions?: boolean;
}

const QrScannerEdit: React.FC<QrScannerEditProps> = ({
  withoutActions,
  ...rest
}): ReactElement => (
  <QrScannerForm {...rest} withoutActions={withoutActions} editMode />
);

export default QrScannerEdit;
