import React, { ReactElement } from 'react';
import useCanAccess from '../common/hooks/useCanAccess';
import BulkEditButton from '../common/BulkEditButton';
import { RESOURCE_SYSTEM } from '../constants';
import SystemForm from './SystemForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <BulkEditButton
      resource={RESOURCE_SYSTEM}
      form={<SystemForm bulkEditMode />}
    />
  );
};

export default BulkActionButtons;
