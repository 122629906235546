import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import { Button, CreateProps, useRecordContext } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import Add from '@mui/icons-material/Add';
import useInvalidateManyReferenceQueryCache from '../common/hooks/useInvalidateManyReferenceQueryCache';
import useAttachmentCanAccess from '../common/hooks/useAttachmentCanAccess';
import AttachmentCreateForm from './components/AttachmentCreateForm';
import { RESOURCE_ATTACHMENT } from '../constants';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import { AxiosResponse } from 'axios';

interface AttachmentCreateInDialogProps extends CreateProps {
  onUpload?: (result: AxiosResponse | AxiosResponse[]) => void;
  targetId: string;
  isImage?: boolean;
  withoutProjectId?: boolean;
  attachmentType?: ATTACHMENT_TYPE_ENUMS;
  onlyShow?: boolean;
  outerResource?: string;
}

const AttachmentCreateInDialog: React.FC<AttachmentCreateInDialogProps> = ({
  title = 'Add Attachment',
  onUpload,
  targetId,
  isImage = false,
  withoutProjectId,
  attachmentType,
  onlyShow,
  outerResource,
  ...rest
}): ReactElement => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const invalidateQueries =
    useInvalidateManyReferenceQueryCache(RESOURCE_ATTACHMENT);
  const onlyShowAccess = useAttachmentCanAccess(onlyShow, outerResource);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnUpload = useCallback(
    async (result) => {
      setOpen(false);

      await invalidateQueries();

      if (onUpload) {
        onUpload(result);
      }
    },
    [invalidateQueries, onUpload]
  );

  return (
    <Fragment>
      {!onlyShowAccess && (
        <Button label={String(title)} onClick={handleOpen}>
          <Add />
        </Button>
      )}
      <CreateDialog
        {...rest}
        title={<span>{title}</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <AttachmentCreateForm
          record={record}
          onUpload={handleOnUpload}
          targetIdFieldName={targetId}
          targetIdFieldValue={record.id}
          isImage={isImage}
          withoutProjectId={withoutProjectId}
          attachmentType={attachmentType}
        />
      </CreateDialog>
    </Fragment>
  );
};

export default AttachmentCreateInDialog;
