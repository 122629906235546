import React, { ReactElement } from 'react';
import { Link } from 'react-admin';
import { Button as ButtonMaterial } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import { RESOURCE_BOOK } from '../../constants';
import commonStyles from '../../common/commonStyles';

export const LinkToBooks: React.FC = (): ReactElement => {
  return (
    <Link to={`/${RESOURCE_BOOK}`} key="back" sx={commonStyles.flexBoxLongItem}>
      <ButtonMaterial startIcon={<BookIcon />} sx={{ flexBasis: 160 }}>
        Back to books
      </ButtonMaterial>
    </Link>
  );
};
