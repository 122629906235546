import { ReactElement, ReactNode } from 'react';
import { ListButton, ListButtonProps, TopToolbar } from 'react-admin';

export interface ShowActionsProps extends ListButtonProps {
  Component?: ReactNode;
}

const ShowActions: React.FC<ShowActionsProps> = ({
  Component,
  ...rest
}): ReactElement => (
  <TopToolbar>
    {Component}
    <ListButton label="Back" icon={null} {...rest} />
  </TopToolbar>
);

export default ShowActions;
