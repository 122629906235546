export const tagCategory = [
  { id: 'IO', name: 'IO' },
  { id: 'CABLE', name: 'CABLE' },
  { id: 'TEST_PACK', name: 'TEST PACK' },
];

export const COMPONENT_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'component',
  },
  {
    label: 'Export with Electrical',
    fileName: 'component_electrical',
    columns: [
      { source: 'electrical.cablePulled', label: 'Cable Pulled' },
      {
        source: 'electrical.cablePulledDate',
        label: 'Cable Pulled Date',
      },
      { source: 'electrical.cableGlandedA', label: 'Cable Glanded A' },
      { source: 'electrical.cableGlandedB', label: 'Cable Glanded B' },
      { source: 'electrical.cableTested', label: 'Cable Tested' },
      { source: 'electrical.cableTermFrom', label: 'Cable Term From' },
      {
        source: 'electrical.cableTermFromDate',
        label: 'Cable Term From Date',
      },
      { source: 'electrical.cableTermTo', label: 'Cable Term To' },
      {
        source: 'electrical.cableTermToDate',
        label: 'Cable Term To Date',
      },
      { source: 'electrical.size', label: 'Size' },
      { source: 'electrical.motorRtg', label: 'Motor RTG' },
      { source: 'electrical.ratingUnits', label: 'Rating Units' },
      { source: 'electrical.rtdVoltage', label: 'RTD Voltage' },
      { source: 'electrical.skidNo', label: 'Skid No' },
      {
        source: 'electrical.currentPrevious',
        label: 'Current Previous',
      },
      { source: 'electrical.recordStatus', label: 'Record Status' },
      { source: 'electrical.fromPlanDwg', label: 'From Plan Dwg' },
      { source: 'electrical.toPlanDwg', label: 'To Plan Dwg' },
      {
        source: 'electrical.fromDescription',
        label: 'From Description',
      },
      { source: 'electrical.toDescription', label: 'To Description' },
      {
        source: 'electrical.connectionDiagram',
        label: 'Connection Diagram',
      },
      { source: 'electrical.cableDesc', label: 'Cable Description' },
      { source: 'electrical.cableType', label: 'Cable Type' },
      { source: 'electrical.route', label: 'Route' },
      {
        source: 'electrical.updatedAt',
        label: 'Electrical Updated At',
      },
      {
        source: 'electrical.updatedBy',
        label: 'Electrical Updated By',
      },
      {
        source: 'electrical.updatedByName',
        label: 'Electrical Updated By Name',
      },
    ],
  },
  {
    label: 'Export with Piping',
    fileName: 'component_piping',
    columns: [
      { source: 'piping.testPressure', label: 'Test Pressure' },
      { source: 'piping.testUnits', label: 'Test Units' },
      { source: 'piping.testMedium', label: 'Test Medium' },
      { source: 'piping.testDurationMinutes', label: 'Test Duration Minutes' },
      { source: 'piping.cleaning', label: 'Cleaning' },
      { source: 'piping.tstPkGrp', label: 'Test Pack Grp' },
      { source: 'piping.lineNo', label: 'Line No' },
      { source: 'piping.unitNo', label: 'Unit No' },
      { source: 'piping.systemCode', label: 'System Code' },
      { source: 'piping.isoNumber', label: 'ISO' },
      { source: 'piping.pipeSpec', label: 'Pipe Specification' },
      { source: 'piping.pipeType', label: 'Pipe Type' },
      { source: 'piping.pidNumber', label: 'Pid Number' },
      { source: 'piping.tempDesign', label: 'Design Temp' },
      { source: 'piping.pressDesign', label: 'Design Press' },
      { source: 'piping.endPrep', label: 'End Prep' },
      { source: 'piping.pressClass', label: 'Press Class' },
      { source: 'piping.drawingGa', label: 'General Arrgmnt Dwg' },
      { source: 'piping.materialType', label: 'Material Type' },
      { source: 'piping.drawingPlanSpec', label: 'Dwg Plan Specification' },
      { source: 'piping.pressFlowrate', label: 'Press Flow Rate' },
      { source: 'piping.typeAddtl', label: 'Type-Additional' },
      { source: 'piping.serviceFluid', label: 'Service Fluid' },
      { source: 'piping.insPurp', label: 'Insulation Purpose' },
      { source: 'piping.insThk', label: 'Insulation Thickness' },
      { source: 'piping.ht', label: 'Heat Trace' },
      { source: 'piping.blockNo', label: 'Block No' },
      { source: 'piping.rev', label: 'Revision' },
      { source: 'piping.designArea', label: 'Design Area' },
      { source: 'piping.hydroTestPressure', label: 'Hydro Test Press' },
      { source: 'piping.coatingType', label: 'Coating Type' },
      { source: 'piping.traceTypeCircuit', label: 'Trace Type Circuit' },
      { source: 'piping.packageTovrToBmcd', label: 'Package Turnover Date' },
      { source: 'piping.mastPlItem', label: 'Master Punch Item' },
      { source: 'piping.designTempFH', label: 'Design Temp - FH' },
      { source: 'piping.designTempFC', label: 'Design Temp - FC' },
      { source: 'piping.designPressPsiH', label: 'Design Press - PSI H' },
      { source: 'piping.designPressPsiC', label: 'Design Press - PSI C' },
      { source: 'piping.fireproofing', label: 'Fire Proofing' },
      { source: 'piping.materialConstruction', label: 'Material Construction' },
      { source: 'piping.specPkg', label: 'Special Package' },
      { source: 'piping.material1', label: 'Material 1' },
      { source: 'piping.schedule1', label: 'Schedule 1' },
      { source: 'piping.material2', label: 'Material 2' },
      { source: 'piping.schedule2', label: 'Schedule 2' },
      {
        source: 'piping.fromDescription',
        label: 'From Description',
      },
      { source: 'piping.fromPlanDwg', label: 'From Plan Dwg' },
      { source: 'piping.toDescription', label: 'To Description' },
      { source: 'piping.toPlanDwg', label: 'To Plan Dwg' },
      {
        source: 'piping.updatedAt',
        label: 'Piping Updated At',
      },
      {
        source: 'piping.updatedBy',
        label: 'Piping Updated By',
      },
      {
        source: 'piping.updatedByName',
        label: 'Piping Updated By Name',
      },
    ],
  },
  {
    label: 'Export with Mechanical',
    fileName: 'component_mechanical',
    columns: [
      { source: 'mechanical.disciplineId', label: 'Discipline' },
      {
        source: 'mechanical.descriptionAddtl',
        label: 'Additional Description',
      },
      { source: 'mechanical.subsystemOther', label: 'Other Subsystem' },
      { source: 'mechanical.weights', label: 'Weights' },
      { source: 'mechanical.capacity', label: 'Capacity' },
      { source: 'mechanical.pwht', label: 'Post Weld Heat Treatment' },
      { source: 'mechanical.insulation', label: 'Insulation' },
      { source: 'mechanical.size', label: 'Size' },
      { source: 'mechanical.skidNo', label: 'Skid No' },
      { source: 'mechanical.currentPrevious', label: 'Current Previous' },
      {
        source: 'mechanical.updatedAt',
        label: 'Mechanical Updated At',
      },
      {
        source: 'mechanical.updatedBy',
        label: 'Mechanical Updated By',
      },
      {
        source: 'mechanical.updatedByName',
        label: 'Mechanical Updated By Name',
      },
    ],
  },
  {
    label: 'Export with Instrumentation',
    fileName: 'component_instrumentation',
    columns: [
      { source: 'instrumentation.stemPosition', label: 'Stem Position' },
      {
        source: 'instrumentation.maxOpenCloseTime',
        label: 'Max Open Close Time',
      },
      { source: 'instrumentation.failOpenClose', label: 'Fail Open Close' },
      { source: 'instrumentation.signalType', label: 'Signal Type' },
      { source: 'instrumentation.mountedOn', label: 'Mounted On' },
      { source: 'instrumentation.instRgMin', label: 'Inst Rg Min' },
      { source: 'instrumentation.instRgMax', label: 'Inst Rg Max' },
      { source: 'instrumentation.instRgUom', label: 'Inst Rg UoM' },
      { source: 'instrumentation.calRgMin', label: 'Cal Rg Min' },
      { source: 'instrumentation.calRgMax', label: 'Cal Rg Max' },
      { source: 'instrumentation.calRgUom', label: 'Cal Rg UoM' },
      { source: 'instrumentation.pressMin', label: 'Min Press' },
      { source: 'instrumentation.pressMax', label: 'Max Press' },
      { source: 'instrumentation.pressUom', label: 'UoM Press' },
      { source: 'instrumentation.tempMin', label: 'Min Temp' },
      { source: 'instrumentation.tempMax', label: 'Max Temp' },
      { source: 'instrumentation.tempUom', label: 'UoM Temp' },
      { source: 'instrumentation.actuatorType', label: 'Actuator Type' },
      { source: 'instrumentation.pwrSrc', label: 'Pwr Src' },
      { source: 'instrumentation.top', label: 'Top' },
      { source: 'instrumentation.vendorIndex', label: 'Vendor Index' },
      { source: 'instrumentation.loopService', label: 'Loop Service' },
      { source: 'instrumentation.equipName', label: 'Equip Name' },
      { source: 'instrumentation.ioTypeName', label: 'IO Type Name' },
      { source: 'instrumentation.systemIoCab', label: 'System IO Cable' },
      { source: 'instrumentation.criticality', label: 'Criticality' },
      { source: 'instrumentation.status', label: 'Status' },
      { source: 'instrumentation.instLocation', label: 'Inst Location' },
      { source: 'instrumentation.locationPlan', label: 'Location Plan' },
      { source: 'instrumentation.heatTrace', label: 'Heat Trace' },
      { source: 'instrumentation.scopeArea', label: 'Scope Area' },
      {
        source: 'instrumentation.processConnDetail',
        label: 'Process Conn Detail',
      },
      {
        source: 'instrumentation.supportConnDetail',
        label: 'Support Conn Detail',
      },
      {
        source: 'instrumentation.electConnDetail',
        label: 'Electrical Conn Detail',
      },
      {
        source: 'instrumentation.instAirConnDetail',
        label: 'Inst Air Conn Detail',
      },
      { source: 'instrumentation.notes', label: 'Notes' },
      { source: 'instrumentation.installed', label: 'Installed' },
      {
        source: 'instrumentation.updatedAt',
        label: 'Instrumentation Updated At',
      },
      {
        source: 'instrumentation.updatedBy',
        label: 'Instrumentation Updated By',
      },
      {
        source: 'instrumentation.updatedByName',
        label: 'Instrumentation Updated By Name',
      },
    ],
  },
  {
    label: 'Export All',
    fileName: 'component_all',
    includeAllColumns: true,
  },
];
