import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import SecurityRoleInput from './SecurityRoleInput';
import ResourceInput from './ResourceInput';
import PermissionTypeInput from './PermissionTypeInput';
import ActionInput from './ActionInput';
import { formatRoleRecord } from './helpers';

const SecurityRolePermissionForm: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <SecurityRoleInput isRequired sx={commonStyles.flexBoxSelectItem} />
      <PermissionTypeInput
        sx={commonStyles.flexBoxSelectItem}
        validate={[required()]}
      />
      <ResourceInput
        sx={commonStyles.flexBoxSelectItem}
        validate={[required(), maxLength(75)]}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <ActionInput label="Role Action" />
      <TextInput
        source="record"
        label="Record"
        format={(record) => formatRoleRecord(record)}
        sx={commonStyles.flexBoxLongItem}
      />
    </Box>
  </Fragment>
);

export default SecurityRolePermissionForm;
