import React, { useCallback, ReactElement } from 'react';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import {
  DateField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import ActivityPhaseField from '../common/ActivityPhaseField';
import commonStyles from '../common/commonStyles';
import { RESOURCE_CERT_DETAIL } from '../constants';
import CertTemplateField from '../common/CertTemplateField';
import CustomBooleanField from '../common/CustomBooleanField';
import DisciplineField from '../common/DisciplineField';
import CertDetailsActions from './CertDetailsActions';
import CertDetailsRowForm from './CertDetailsRowForm';
import { CertificateDetailsEditTabProps } from './types';
import ColumnActions from '../common/ColumnActions';

const CertDetailsEditTab: React.FC<CertificateDetailsEditTabProps> = ({
  target,
  targetReference,
  children,
}): ReactElement => {
  const record = useRecordContext();
  const queryClient = useQueryClient();

  const clearQueryClientCache = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [RESOURCE_CERT_DETAIL] });
    await queryClient.invalidateQueries({ queryKey: [targetReference] });
  }, [queryClient, targetReference]);

  const clearTargetReferenceQueryCache = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [targetReference] });
  }, [queryClient, targetReference]);

  return (
    <ReferenceManyField target={target} reference={RESOURCE_CERT_DETAIL}>
      <EditableDatagrid
        disableAutofocus
        actions={false}
        mutationMode="pessimistic"
        editForm={
          <CertDetailsRowForm
            recordId={record.id}
            onSuccess={clearTargetReferenceQueryCache}
          >
            {children}
          </CertDetailsRowForm>
        }
        expand={
          <AttachmentRevisionFormTab
            targetId="certDetailId"
            onUpload={clearQueryClientCache}
          />
        }
        expandSingle
        bulkActionButtons={false}
        sx={commonStyles.formTabDatagrid}
      >
        <TextField source="id" label="ID" />
        {children}
        <ActivityPhaseField />
        <CertTemplateField />
        <DisciplineField />
        <TextField source="generatedBy" label="Generated By" />
        <DateField
          source="generatedDate"
          label="Generated Date"
          showTime={false}
        />
        <TextField source="acceptedBy" label="Accepted By" />
        <TextField source="acceptedDate" label="Accepted Date" />
        <CustomBooleanField source="isVerified" label="Verified" />
        <ColumnActions
          label="Actions"
          showActions={{ show: false, edit: false, delete: false }}
        >
          <CertDetailsActions />
        </ColumnActions>
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default CertDetailsEditTab;
