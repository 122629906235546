import React, { useCallback, ReactElement } from 'react';
import {
  Title,
  useDataProvider,
  useNotify,
  SimpleForm,
  Toolbar,
  email,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { getErrorMessage, required } from '../../utils/UtilityFunctions';
import { setPageTitle } from '../common/helpers/setPageTitle';
import ProjectInput from '../common/ProjectInput';
import SaveButton from '../common/SaveButton';
import TextInput from '../common/TextInput';
import CustomDateInput from '../common/CustomDateInput';
import commonStyles from '../common/commonStyles';
import { RESOURCE_USER } from '../constants';

export const QRCodeGeneration: React.FC = (): ReactElement => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const title = 'QR Code Generator';
  setPageTitle(title);

  const { mutate: printQRCode } = useMutation({
    mutationFn: (data) => {
      return dataProvider.generateQRCode(RESOURCE_USER, data);
    },
    onSuccess: () => {
      notify('QR Code PDF file was generated!');
    },
    onError: async (error: AxiosError) => {
      let errorMessage = error.message;
      if (error?.response?.data instanceof Blob) {
        errorMessage = await error?.response?.data.text();
        if (error?.response?.data.type === 'application/json') {
          errorMessage = JSON.parse(errorMessage);
        }
      }

      notify(getErrorMessage(errorMessage, 'PDF file generation failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  const handleSubmit = useCallback(
    async (data) => {
      printQRCode(data);
    },
    [printQRCode]
  );

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      toolbar={
        <Toolbar>
          <SaveButton variant="outlined" label="Print QR Code" icon={null} />
        </Toolbar>
      }
    >
      <Title title={title} />
      <Box sx={{ ...commonStyles.flexBox, marginTop: '16px' }}>
        <Stack alignItems="flex-start">
          <Box sx={commonStyles.flexBox}>
            <ProjectInput
              label="Select Project"
              source="projectId"
              isRequired
              requestParams={{ filter: { isActive: true } }}
              sx={commonStyles.flexBoxLongItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              label="Mobile user email"
              source="email"
              validate={[required(), email()]}
              sx={commonStyles.flexBoxItem}
            />
            <TextInput
              label="Mobile user password"
              source="password"
              type="password"
              validate={[required()]}
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              label="First Name"
              source="firstName"
              sx={commonStyles.flexBoxItem}
            />
            <TextInput
              label="Last Name"
              source="lastName"
              sx={commonStyles.flexBoxItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              label="Company Name"
              source="companyName"
              sx={commonStyles.flexBoxItem}
            />
            <CustomDateInput
              label="Valid Until"
              source="validUntil"
              sx={commonStyles.flexBoxItem}
              minDate={dayjs()}
            />
          </Box>
        </Stack>
      </Box>
    </SimpleForm>
  );
};
