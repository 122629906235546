import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import {
  Button,
  CreateProps,
  useRecordContext,
  SimpleForm,
  useCreate,
  useNotify,
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import TestInput from '../common/TestInput';
import {
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_EQUIPMENT_TYPE_TEST,
} from '../constants';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';

const EquipmentTypeTestCreateDialog: React.FC<CreateProps> = (
  props
): ReactElement => {
  const ctx: UserContext = getContext();
  const notify = useNotify();
  const record = useRecordContext();
  const [create] = useCreate();
  const [open, setOpen] = useState(false);
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_EQUIPMENT_TYPE,
    true
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setOpen(false);
    await invalidateQueries();
  }, [invalidateQueries]);

  const handleSubmit = useCallback(
    async (data) => {
      await create(
        RESOURCE_EQUIPMENT_TYPE_TEST,
        {
          data: {
            ...data,
            projectId: ctx?.projectId,
            equipTypeId: Number(record.id),
          },
        },
        {
          onSuccess: onSubmit,
          onError: (error) => {
            notify(getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
        }
      );
    },
    [create, record, ctx, onSubmit, notify]
  );

  return (
    <Fragment>
      <Button label="Add test" onClick={handleOpen}>
        <Add />
      </Button>
      <CreateDialog
        {...props}
        title={<span>Add Equipment Type Test</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <SimpleForm
          id="create_equipment_type_test_form"
          onSubmit={handleSubmit}
        >
          <Box sx={commonStyles.createBoxHalfWidth}>
            <TestInput
              name="testFormNo_inc"
              optionText="testFormNo"
              detailText="testName"
              sx={commonStyles.flexBoxLongItem}
              isRequired
            />
          </Box>
          <Box sx={commonStyles.createBoxHalfWidth}>
            <LocationInput sx={commonStyles.flexBoxLongItem} isRequired />
          </Box>
        </SimpleForm>
      </CreateDialog>
    </Fragment>
  );
};

export default EquipmentTypeTestCreateDialog;
