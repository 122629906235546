import React, { ReactElement } from 'react';
import ChoicesLegend from '../../common/choicesLegend/ChoicesLegend';

const PresAutomationLegend: React.FC = (): ReactElement => (
  <ChoicesLegend
    title="Inherit Pres Sign Offs Settings Descriptions"
    items={[
      {
        name: 'No',
        description:
          'Selecting "No" allows the Preservation Item Activity to be closed out without filling out the "Verified By" and "Verified Date" flelds (the "Verified By" and "Verified Date" fields are optional).',
        isDefault: true,
      },
      {
        name: 'Yes',
        description:
          'Selecting "Yes" causes the system to verify that the "Verified By" and "Verified Date" fields are filled out before the Preservation Item Activity is closed out (the "Verified By" and "Verified Date" fields are required fields).',
        isMarked: true,
      },
    ]}
  />
);

export default PresAutomationLegend;
