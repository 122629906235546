import { MarkdownField } from '@react-admin/ra-markdown';
import { useRecordContext } from 'react-admin';

const MarkdownUncacheableField = (props) => {
  const record = useRecordContext();

  // key is needed for force re render on record update
  return <MarkdownField {...props} key={record?.updatedAt} />;
};

export default MarkdownUncacheableField;
