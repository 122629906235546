import React, { ReactElement } from 'react';
import { ChipField, ReferenceArrayField } from 'react-admin';
import { RESOURCE_SECURITY_GROUP } from '../../constants';
import SmartSingleFieldList from 'src/components/common/SmartSingleFieldList';

type GroupFieldProps = Omit<
  React.ComponentProps<typeof ReferenceArrayField>,
  'reference'
>;

const GroupField: React.FC<GroupFieldProps> = ({
  label,
  ...rest
}): ReactElement => (
  <ReferenceArrayField
    label={label ?? ''}
    reference={RESOURCE_SECURITY_GROUP}
    {...rest}
  >
    <SmartSingleFieldList>
      <ChipField source="groupName" />
    </SmartSingleFieldList>
  </ReferenceArrayField>
);

export default GroupField;
