import React, { ReactElement } from 'react';
import {
  IconMenu,
  MenuItemList,
  useAppLocationState,
} from '@react-admin/ra-navigation';
import CardContent from '@mui/material/CardContent/CardContent';
import ChildIconMenuItems from './ChildIconMenuItems';
import { MenuItemProps } from './constants';
import { getParentMenuItemList } from './helper';

const IconMenuItems: React.FC<MenuItemProps> = ({
  menuItems,
}): ReactElement => {
  const [location] = useAppLocationState();
  const parentMenu = getParentMenuItemList(menuItems, location?.path);

  return menuItems.map((menuItem) => {
    if (
      !menuItem.hasOwnProperty('items') &&
      !menuItem.hasOwnProperty('resource')
    ) {
      return (
        <IconMenu.Item
          key={menuItem.label}
          name={menuItem.name}
          label={menuItem.label}
          to={menuItem.to ? menuItem.to : ''}
          icon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
        />
      );
    }
    if (menuItem.hasOwnProperty('resource')) {
      return (
        <IconMenu.Item
          key={menuItem.resource}
          name={menuItem.resource}
          label={menuItem.label}
          to={menuItem.to ? menuItem.to : ''}
          icon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
        />
      );
    }
    if (menuItem.hasOwnProperty('items') && !!menuItem.items.length) {
      return (
        <IconMenu.Item
          key={menuItem.label}
          name={menuItem.name}
          label={menuItem.label}
          icon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
          className={parentMenu.includes(menuItem.name) ? 'active' : ''}
        >
          <CardContent key={menuItem.label}>
            <MenuItemList>
              <ChildIconMenuItems
                menuItems={menuItem.items}
                menuName={menuItem.name}
              />
            </MenuItemList>
          </CardContent>
        </IconMenu.Item>
      );
    }
  });
};

export default IconMenuItems;
