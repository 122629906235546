import React, { ReactElement } from 'react';
import { SelectInput, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { permissionTypeChoices } from './constants';

interface PermissionTypeInputProps {
  validate?: Validator[];
  alwaysOn?: boolean;
  emptyText?: string;
  sx: SxProps<Theme>;
  source?: string;
}

const PermissionTypeInput: React.FC<PermissionTypeInputProps> = ({
  validate,
  alwaysOn,
  emptyText,
  sx,
}): ReactElement => (
  <SelectInput
    source="permissionType"
    label="Permission Type"
    choices={permissionTypeChoices}
    alwaysOn={!!alwaysOn}
    emptyText={emptyText ?? ''}
    sx={sx}
    validate={validate}
  />
);

PermissionTypeInput.defaultProps = { source: 'permissionType' };

export default PermissionTypeInput;
