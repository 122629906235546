import {
  EditProps,
  RaRecord,
  SelectInputProps as BaseSelectInputProps,
  FieldProps,
  SelectFieldProps,
  TextFieldProps,
} from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

export interface EditPropsWithLocation<
  RecordType extends RaRecord = RaRecord,
  MutationOptionsError = unknown,
> extends EditProps<RecordType, MutationOptionsError> {
  syncWithLocation: boolean;
  noCache?: boolean;
  record?: Record<string, unknown>;
  withoutActions?: boolean;
}

export interface MultipleExportButtonInfo {
  label: string;
  columns?: Omit<AvailableColumn, 'index'>[];
  fileName?: string;
  includeAllColumns?: boolean;
  resource?: string;
  singleButton?: boolean;
}

export interface ProjectSettings {
  inheritCertSignoffsSys?: YesNoType;
  inheritCertSignOffsSs?: YesNoType;
  inheritCertSignoffsComponent?: YesNoType;
  enforceCertGatingSys?: StrictPromptType;
  enforceCertGatingSs?: StrictPromptType;
  enforceCertGatingComponent?: StrictPromptType;
  isDuplicateItrEnabled?: boolean;
  isItrPunchForcedCreationEnabled?: boolean;
  isPresPunchForcedCreationEnabled?: boolean;
  isPinCodeEntryEnabled?: boolean;
  isItrMobile?: boolean;
  isPresMobile?: boolean;
  presAutomation?: YesNoType;
}

export type YesNoType = 'YES' | 'NO';

export enum YesNoEnum {
  YES = 'YES',
  NO = 'NO',
}

export type StrictPromptType = 'STRICT' | 'PROMPT' | 'STRICT_WITH_PROMPT';

export type ActivityCategory = 'SYSTEM' | 'SUBSYSTEM' | 'COMPONENT';

export type TemplateType = 'CERTIFICATE' | 'PUNCH' | 'PRESERVATION' | 'ITR';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectInputProps
  extends Omit<
    BaseSelectInputProps,
    'field' | 'name' | 'formState' | 'fieldState'
  > {}

export interface SelectInputMultipleProps {
  source?: string;
  label?: string;
  isRequired?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  alwaysOn?: boolean;
  sx?: SxProps<Theme>;
}

export type StatusFieldType =
  | 'accepted'
  | 'answered'
  | 'cleared'
  | 'closed'
  | 'raised'
  | 'verified';

export interface AvailableColumn {
  index?: string;
  source?: string;
  label: string;
}

export interface SideFilter {
  defaultFilter: object;
}

export type canAccessListType = {
  list: boolean;
  create: boolean;
  edit: boolean;
  show: boolean;
  delete: boolean;
  forceDelete: boolean;
  export: boolean;
  import: boolean;
  certGen: boolean;
  certOverride: boolean;
  closeout: boolean;
  itrAttachment: boolean;
  templateUpdate: boolean;
  pdfGen: boolean;
  sendMobile: boolean;
};

export type ShowActions = {
  show?: boolean;
  edit?: boolean;
  delete?: boolean;
};

export type RecordWithAnyData = Record<string, unknown>;

export type FieldPropsWithOptionalSource = Omit<FieldProps, 'source'> & {
  source?: FieldProps['source'];
};

export type SelectFieldPropsWithOptionalSource = Omit<
  SelectFieldProps,
  'source'
> & {
  source?: SelectFieldProps['source'];
};

export type TextFieldPropsWithOptionalSource = Omit<
  TextFieldProps,
  'source'
> & {
  source?: TextFieldProps['source'];
};
