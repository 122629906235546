import { Create, CreateProps } from 'react-admin';
import React, { ReactNode, useCallback } from 'react';
import CreateTitle from './CreateTitle';
import useInvalidateResourceQueryCache from './hooks/useInvalidateResourceQueryCache';

interface CreateWithTitleProps extends CreateProps {
  children: ReactNode;
}

const CreateWithTitle: React.FC<CreateWithTitleProps> = ({
  title,
  children,
  mutationOptions,
  ...rest
}) => {
  const invalidateQueries = useInvalidateResourceQueryCache(undefined, true);

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <Create
      title={<CreateTitle title={title as string} />}
      {...rest}
      mutationOptions={{
        ...mutationOptions,
        onSettled: clearCache,
      }}
    >
      {children}
    </Create>
  );
};

export default CreateWithTitle;
