import { alpha, styled } from '@mui/material/styles';
import { MenuItemClasses } from '@react-admin/ra-navigation';
import { MENU_ITEM_PREFIX } from './constants';

const RootMenuItem = styled('div', {
  name: MENU_ITEM_PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.hoverOpacity
    ),
  },
  [`& .${MenuItemClasses.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0',
  },
  [`& .${MenuItemClasses.link}`]: {
    color: 'inherit',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    display: 'flex',
  },
  [`& .${MenuItemClasses.active}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${MenuItemClasses.menuIcon}`]: {
    alignItems: 'center',
    color: 'inherit',
    minWidth: theme.spacing(4),
  },
  [`& .${MenuItemClasses.icon}`]: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: theme.spacing(2),
    color: 'inherit',
  },
  [`& .${MenuItemClasses.button}`]: {
    color: 'inherit',
  },
  [`& .${MenuItemClasses.nestedList}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingLeft: theme.spacing(2),
  },
  [`& .${MenuItemClasses.hiddenNestedList}`]: {
    display: 'none',
  },
  [`& .${MenuItemClasses.itemButton}`]: {
    display: 'flex',
    padding: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default RootMenuItem;
