import React, { ReactElement } from 'react';
import { ListProps } from 'react-admin';
import ListTitle from './ListTitle';
import { ListWithPagination } from './ListWithPagination';

const ListNotDeleted: React.FC<ListProps> = ({
  title,
  children,
  ...rest
}): ReactElement => (
  <ListWithPagination
    title={title || <ListTitle />}
    {...rest}
    filterDefaultValues={{ isDeleted: false }}
  >
    {children}
  </ListWithPagination>
);

export default ListNotDeleted;
