import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import CreateWithTitle from '../../common/CreateWithTitle';
import FooterToolbar from '../../common/FooterToolbar';
import PurchaseOrderRequestForm from './components/PurchaseOrderRequestForm';

const PurchaseOrderRequestCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <CreateWithTitle redirect="edit">
      <SimpleForm
        toolbar={<FooterToolbar />}
        defaultValues={{ projectId: ctx?.projectId }}
      >
        <PurchaseOrderRequestForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default PurchaseOrderRequestCreate;
