import { TextField, useRecordContext } from 'react-admin';
import { completedChoices } from '../constants';

interface CompletedFieldProps {
  source: string;
}

const CompletedField: React.FC<CompletedFieldProps> = ({ source }) => {
  const record = useRecordContext();

  if (!record) return null;

  const displayValue = completedChoices.find(
    (completedChoice) => completedChoice?.id === record.completed
  )?.name;

  return (
    <TextField source={source} record={{ ...record, [source]: displayValue }} />
  );
};

export default CompletedField;
