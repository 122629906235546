import React, { ReactElement } from 'react';
import { Button } from 'react-admin';
import { TemplateType } from '../types';
import { useCopyTemplates } from './hooks';
import { BUTTON_IN_PROGRESS_LABEL, BUTTON_LABEL } from './constants';

interface BulkSyncTemplatesButtonProps {
  templateType: TemplateType;
  onSuccess: () => void;
  resourceContext?: string;
  lambdaMode?: boolean;
}

const BulkCopyTemplatesButton: React.FC<BulkSyncTemplatesButtonProps> = ({
  templateType,
  onSuccess,
  resourceContext,
  lambdaMode,
}): ReactElement => {
  const { copyTemplates, isCopyingTemplates } = useCopyTemplates(
    templateType,
    onSuccess,
    resourceContext,
    lambdaMode
  );

  return (
    <Button
      label={isCopyingTemplates ? BUTTON_IN_PROGRESS_LABEL : BUTTON_LABEL}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick={copyTemplates}
      disabled={isCopyingTemplates}
    />
  );
};

export default BulkCopyTemplatesButton;
