import { useResourceDefinitions } from 'react-admin';
import useCanAccess from './useCanAccess';

const useGetLinkType = (resource: string) => {
  const { edit, show } = useCanAccess(resource);
  const definitions = useResourceDefinitions();

  const resourceDefinition = Object.values(definitions).find(
    (definition) => definition.name === resource
  );

  if (resourceDefinition) {
    if (edit && resourceDefinition.hasEdit) return 'edit';
    if (show && resourceDefinition.hasShow) return 'show';
  }

  return false;
};

export default useGetLinkType;
