import React, { ReactElement } from 'react';
import { Tooltip as BaseTooltip, TooltipProps } from '@mui/material';

// Fix for error: Invalid prop `children` supplied to `ForwardRef(Tooltip)`
// Related to issue: https://github.com/mui/material-ui/issues/31261
const Tooltip: React.FC<TooltipProps> = ({
  children,
  ...rest
}): ReactElement => (
  <BaseTooltip {...rest}>
    <span>{children}</span>
  </BaseTooltip>
);

export default Tooltip;
