import React, { Fragment, ReactElement } from 'react';
import {
  ReferenceManyField,
  TextField,
  useRecordContext,
  DatagridConfigurable,
} from 'react-admin';
import SelectField from '../../common/SelectField';
import DeleteWithConfirmIconButton from '../../common/DeleteWithConfirmIconButton';
import { RESOURCE_PENDING_PUNCH } from '../../constants';
import { statuses } from '../constants';
import PendingPunchCreateInDialog from './PendingPunchCreateInDialog';
import PendingPunchEdit from './PendingPunchEdit';

interface PendingPunchFormTabProps {
  targetId: string;
  readonly?: boolean;
  outerResource?: string;
  originator?: string;
}

const PendingPunchFormTab: React.FC<PendingPunchFormTabProps> = ({
  targetId,
  readonly,
  outerResource,
  originator,
}): ReactElement => {
  const record = useRecordContext();

  return (
    <Fragment>
      <ReferenceManyField
        reference={RESOURCE_PENDING_PUNCH}
        target={targetId}
        perPage={100}
        fullWidth={true}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          expand={
            <PendingPunchEdit
              redirect={false}
              syncWithLocation={false}
              readonly={readonly}
              outerResource={outerResource}
            />
          }
          rowClick={false}
          expandSingle
        >
          <TextField source="id" label="ID" />
          <TextField source="description" label="Description" />
          <SelectField source="status" label="Status" choices={statuses} />
          {!readonly && (
            <DeleteWithConfirmIconButton
              className="deleteButton"
              label="Actions"
            />
          )}
        </DatagridConfigurable>
      </ReferenceManyField>
      {!readonly && (
        <PendingPunchCreateInDialog
          record={record}
          originator={originator}
          targetId={targetId}
        />
      )}
    </Fragment>
  );
};

export default PendingPunchFormTab;
