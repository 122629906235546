import { BLACK, BLUE, GREEN } from '../../../theme/colors';
import auditStyles from '../styles';

const StyledDiffDataString = (action: string, key: string, value: string) => {
  const getDiffDataColor = (action: string) => {
    switch (action) {
      case 'add':
        return GREEN;
      case 'replace':
        return BLUE;
      default:
        return BLACK;
    }
  };

  const getPascalCaseKey = (key: string) => {
    const result = key.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const color = getDiffDataColor(action);

  return (
    <div key={key + value} style={auditStyles.diffDataString(color)}>
      <span style={auditStyles.diffDataStringKey(color)}>
        {getPascalCaseKey(key)}:
      </span>
      {` ${value}`}
    </div>
  );
};

export default StyledDiffDataString;
