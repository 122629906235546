import {
  CreateProps,
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import React from 'react';
import commonStyles from '../common/commonStyles';
import DeleteWithConfirmIconButton from '../common/DeleteWithConfirmIconButton';
import CommentEdit from './CommentEdit';
import MarkdownUncacheableField from '../common/MarkdownUncacheableField';
import CustomBooleanField from '../common/CustomBooleanField';
import CommentShowInDialog from './components/CommentShowInDialog';
import ColumnActions from '../common/ColumnActions';

export interface CommentListProps extends CreateProps {
  resource: string;
  targetId: string;
  commentType: string;
  withActions?: boolean;
}

const CommentEmpty = () => (
  <div id="comment_empty" style={{ marginBottom: '18px' }}>
    No comment found
  </div>
);

const CommentList: React.FC<CommentListProps> = ({
  resource,
  targetId,
  commentType,
  withActions,
}) => {
  return (
    <ReferenceManyField
      reference={resource}
      target={targetId}
      label={false}
      perPage={100}
      fullWidth={true}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filter={{ commentType }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        empty={<CommentEmpty />}
        sx={commonStyles.bottomMargin}
        id="comment_list"
      >
        <MarkdownUncacheableField
          source="comment"
          sx={commonStyles.markdownItem}
        />
        <CustomBooleanField
          source="isIncludeInReport"
          label="Show in report?"
        />
        <DateField source="updatedAt" label="Updated At" />
        <TextField source="updatedByName" label="Updated By Name" />
        {withActions && (
          <ColumnActions
            label="Actions"
            showActions={{ show: false, edit: false, delete: false }}
          >
            <CommentShowInDialog />
            <CommentEdit />
            <DeleteWithConfirmIconButton redirect={false} />
          </ColumnActions>
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};

export default CommentList;
