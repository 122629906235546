import moment from 'moment';
import { useCallback, useState } from 'react';
import zipy from 'zipyai';
import { getContext } from '../../../provider/userContext';
import packageJson from '../../../../package.json';
import { environment } from '../../../provider/api';

const PROJECT_SDK_KEY_DEV = '39bb78d2';
const PROJECT_SDK_KEY_PROD = '026d6050';
const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

const sdkKey =
  environment === 'prod' ? PROJECT_SDK_KEY_PROD : PROJECT_SDK_KEY_DEV;

export const useRecordBrowserActivity = () => {
  const [withRecording, setWithRecording] = useState<boolean>(false);
  const [recordLink, setRecordLink] = useState<string>();
  const [recordingInProgress, setRecordingInProgress] = useState(false);
  const [lastRecordTimestampStart, setLastRecordTimestampStart] =
    useState<string>();
  const [lastRecordTimestampEnd, setLastRecordTimestampEnd] =
    useState<string>();
  const ctx = getContext();

  const startRecording = useCallback(async () => {
    const { id, fullName, projectId, company } = ctx;
    setWithRecording(true);
    setRecordingInProgress(true);
    setLastRecordTimestampStart(moment().format(DATE_TIME_FORMAT));
    await zipy.init(sdkKey, { releaseVer: packageJson.version });
    zipy.identify(id, {
      fullName,
      projectId,
      company,
      email: id,
    });
  }, [
    setLastRecordTimestampStart,
    setRecordingInProgress,
    setWithRecording,
    ctx,
  ]);

  const stopRecording = useCallback(() => {
    setRecordLink(zipy.getCurrentSessionURL());
    zipy.record.stop();
    setRecordingInProgress(false);
    setLastRecordTimestampEnd(moment().format(DATE_TIME_FORMAT));
  }, [setLastRecordTimestampEnd, setRecordingInProgress, setRecordLink]);

  const utcOffset = moment().utcOffset() / 60;
  const timezoneRegion = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const lastRecordTimeRange =
    withRecording &&
    `${lastRecordTimestampStart} - ${lastRecordTimestampEnd} UTC${utcOffset > 0 ? '+' : '-'}${Math.abs(utcOffset)} (${timezoneRegion})`;

  return {
    recordingInProgress,
    lastRecordTimeRange,
    startRecording,
    stopRecording,
    withRecording,
    setWithRecording,
    recordLink,
  };
};
