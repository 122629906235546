import React, { ReactElement } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { getGatingStatusFieldColor } from './helpers';

interface ColoredGatingStatusFieldProps {
  source: string;
  label: string;
}

const ColoredGatingStatusField: React.FC<ColoredGatingStatusFieldProps> = ({
  source,
  label,
}): ReactElement => {
  const record = useRecordContext();
  const value = get(record, source);

  return (
    <TextField
      source={source}
      label={label}
      sx={{ color: getGatingStatusFieldColor(value), fontWeight: 'bold' }}
    />
  );
};

export default ColoredGatingStatusField;
