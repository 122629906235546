import React, { ReactElement } from 'react';
import { ArrayField, ChipField } from 'react-admin';
import SmartSingleFieldList from '../common/SmartSingleFieldList';

interface EquipmentTypeTestFieldProps {
  label?: string;
  source?: string;
  sortable?: boolean;
}

const EquipmentTypeTest: React.FC<EquipmentTypeTestFieldProps> = ({
  label,
  source,
  sortable,
}): ReactElement => (
  <ArrayField source={source} label={label} sortable={sortable}>
    <SmartSingleFieldList linkType={false}>
      <ChipField source="test.testFormNo" clickable={false} />
    </SmartSingleFieldList>
  </ArrayField>
);

EquipmentTypeTest.defaultProps = { source: 'tblEquipmentTypeTests' };

export default EquipmentTypeTest;
