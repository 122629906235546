import React, { ReactElement } from 'react';
import { BooleanInput, maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import CustomDateInput from '../common/CustomDateInput';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const DivisionCreate: React.FC = (): ReactElement => {
  return (
    <CreateWithTitle redirect="list">
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="divisionNumber"
            label="Division Number"
            validate={[required(), maxLength(50)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="divisionName"
            label="Division Name"
            validate={[required(), maxLength(200)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CustomDateInput
            source="startDate"
            label="Start Date"
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            source="stopDate"
            label="Stop Date"
            sx={commonStyles.flexBoxItem}
          />
          <BooleanInput source="isActive" label="Active" defaultValue={true} />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default DivisionCreate;
