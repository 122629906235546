import React, { ReactElement } from 'react';
import QrScannerForm from './QrScannerForm';

interface QrScannerShowProps {
  withoutActions?: boolean;
}

const QrScannerShow: React.FC<QrScannerShowProps> = ({
  withoutActions,
  ...rest
}): ReactElement => <QrScannerForm {...rest} withoutActions={withoutActions} />;

export default QrScannerShow;
