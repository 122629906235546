import React, { ReactElement } from 'react';
import ShowComponent from '../common/ShowComponent';
import EditTitle from '../common/EditTitle';
import CommentShowTemplate from './components/CommentShowTemplate';

const CommentShow: React.FC = (props): ReactElement => {
  return (
    <ShowComponent {...props} title={<EditTitle title="Comment" />}>
      <CommentShowTemplate />
    </ShowComponent>
  );
};

export default CommentShow;
