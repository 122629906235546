import React, { ReactElement } from 'react';
import { NumberField, NumberFieldProps } from 'react-admin';

interface PriceFieldProps extends Omit<NumberFieldProps, 'source'> {
  label?: string;
  source?: string;
}

const PriceField: React.FC<PriceFieldProps> = ({
  label,
  source,
  ...rest
}): ReactElement => (
  <NumberField
    {...rest}
    label={label}
    source={source}
    transform={(value) => Number(value) / 100}
  />
);

PriceField.defaultProps = {
  label: 'Price',
  source: 'price',
};

export default PriceField;
