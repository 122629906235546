import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { Report } from './type';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import ReportForm from './ReportForm';

const ReportCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: Report): Report => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <ReportForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default ReportCreate;
