import React, { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import ColumnActions from '../../common/ColumnActions';
import commonStyles from '../../common/commonStyles';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import {
  DEFAULT_ROWS_PER_PAGE,
  RESOURCE_PRESERVATION_SERIES,
} from '../../constants';
import IssueButton from '../../preservationSeries/IssueButton';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import { CustomPagination } from '../../common/CustomPagination';

interface PreservationSeriesListTabProps {
  edit?: boolean;
}

export const PreservationSeriesListTab: React.FC<
  PreservationSeriesListTabProps
> = ({ edit }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ReferenceManyField
      reference={RESOURCE_PRESERVATION_SERIES}
      target="preservation_item_id"
      label={false}
      perPage={DEFAULT_ROWS_PER_PAGE}
      sort={{ field: 'dueDate', order: 'ASC' }}
      pagination={<CustomPagination sx={{ width: '100%' }} />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        sx={commonStyles.bottomMargin}
        id="series_list"
      >
        <TextField source="id" label="ID" />
        <DateField source="dueDate" label={labels['dueDate'] || 'Due Date'} />
        <DateField
          source="lateDate"
          label={labels['lateDate'] || 'Late Date'}
        />
        {renderMetaDataFields()}
        {edit && (
          <ColumnActions
            label="Actions"
            showActions={{ edit: false, show: false }}
          >
            <IssueButton />
          </ColumnActions>
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};
