import React, {
  Fragment,
  ReactElement,
  useCallback,
  ReactNode,
  ElementType,
} from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useFormContext } from 'react-hook-form';
import { isFunction } from 'lodash';
import Box from '@mui/material/Box';
import {
  DeleteButton,
  DeleteButtonProps,
  ListButton,
  ListButtonProps,
  OnSuccess,
  Toolbar,
} from 'react-admin';
import { ReactComponent as CancelIcon } from '../../assets/icons/functionalIcons/Cancel.svg';
import commonStyles from './commonStyles';
import { RED } from '../../theme/colors';
import SaveButton from './SaveButton';
import AutoSave from './AutoSave';

export interface FooterToolbarProps {
  autoSave?: boolean;
  deleteButtonProps?: DeleteButtonProps & { show?: boolean };
  cancelButtonProps?: ListButtonProps;
  cancelButtonResource?: string;
  onCancel?: () => void;
  syncWithLocation?: boolean;
  onAutoSaveSuccess?: OnSuccess;
  debounce?: number;
  children?: ReactNode;
  CancelButtonComponent?: ElementType;
  SaveButtonComponent?: ReactNode;
}

const FooterToolbar: React.FC<FooterToolbarProps> = ({
  autoSave,
  deleteButtonProps = { show: false },
  cancelButtonResource,
  cancelButtonProps,
  onCancel,
  onAutoSaveSuccess,
  syncWithLocation,
  debounce,
  children,
  CancelButtonComponent = ListButton,
  SaveButtonComponent = <SaveButton />,
}): ReactElement => {
  const { reset } = useFormContext();

  const { show: showDeleteButton, ...restDeleteButtonProps } =
    deleteButtonProps;

  const handleCancelButtonClick = useCallback(() => {
    reset();
    if (onCancel && isFunction(onCancel)) {
      onCancel();
    }
  }, [reset, onCancel]);

  return (
    <Toolbar>
      <Box sx={commonStyles.footerActionsBox}>
        {SaveButtonComponent}
        {autoSave ? (
          <Fragment>
            <AutoSave
              debounce={debounce}
              typographyProps={{ sx: { alignSelf: 'center' } }}
              onSuccess={onAutoSaveSuccess}
            />
            {syncWithLocation && (
              <ListButton
                label="Back"
                icon={<ArrowBack />}
                {...cancelButtonProps}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p style={{ width: '100%', marginLeft: '10px', color: RED }}>
              Auto-save disabled! Do not forget to save your changes!
            </p>
            <CancelButtonComponent
              label="Cancel"
              icon={<CancelIcon />}
              onClick={handleCancelButtonClick}
              resource={cancelButtonResource}
              {...cancelButtonProps}
            />
          </Fragment>
        )}
        {showDeleteButton && <DeleteButton {...restDeleteButtonProps} />}
        {children}
      </Box>
    </Toolbar>
  );
};

export default FooterToolbar;
