import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import commonStyles from '../../../../common/commonStyles';
import { RESOURCE_MOC_SYSTEM } from '../../../../constants';
import RelatedForm from '../RelatedForm';
import SystemField from '../../../../common/SystemField';
import SystemFormRow from './SystemFormRow';

const SystemForm: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <RelatedForm
      formRow={<SystemFormRow recordId={record?.id} />}
      reference={RESOURCE_MOC_SYSTEM}
    >
      <SystemField sx={commonStyles.rowFormField} />
    </RelatedForm>
  );
};

export default SystemForm;
