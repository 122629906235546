import React, { ReactElement } from 'react';
import { TextField, WrapperField } from 'react-admin';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ConfigurationShow from './ConfigurationShow';
import ConfigurationEdit from './ConfigurationEdit';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import { Box } from '@mui/material';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import commonStyles from '../common/commonStyles';
import ValueField from './components/ValueField';
import ColumnActions from '../common/ColumnActions';
import useResourceTitle from '../common/hooks/useResourceTitle';
import { setPageTitle } from '../common/helpers/setPageTitle';
import { ListWithPagination } from '../common/ListWithPagination';

const ConfigurationList: React.FC = (): ReactElement => {
  setPageTitle(useResourceTitle(true));

  return (
    <ListWithPagination
      actions={
        <ListActions
          showAction={{ import: false, export: false, create: false }}
        />
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<ConfigurationShow withoutActions />}
        EditComponent={<ConfigurationEdit syncWithLocation={false} />}
      >
        <TextField source="id" label="ID" />
        <TextField source="cfgName" label="Configuration" />
        <ValueField label="Value" />

        {renderMetaDataFields()}
        <WrapperField label="Action">
          <Box sx={commonStyles.columnActionsBox}>
            <PreviewButton />
            <DownloadButton />
            <ColumnActions label="Actions" showActions={{ delete: false }} />
          </Box>
        </WrapperField>
      </DatagridConfigurableRbac>
    </ListWithPagination>
  );
};

export default ConfigurationList;
