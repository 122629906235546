import { SxProps, Theme } from '@mui/material/styles';
import React, { ReactElement, useEffect } from 'react';
import { BooleanInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface VerifiedInputProps {
  sx?: SxProps<Theme>;
}

const VerifiedInput: React.FC<VerifiedInputProps> = ({ sx }): ReactElement => {
  const record = useRecordContext();
  const { watch, setValue, getValues } = useFormContext();
  const completedBy = watch('completedBy');
  const completedDateTime = watch('completedDateTime');

  useEffect(() => {
    if (
      completedBy === record?.completedBy &&
      completedDateTime === record?.completedDateTime
    ) {
      return;
    }
    const isVerified = getValues('isVerified');
    if (!completedBy && !completedDateTime && isVerified) {
      setValue('isVerified', false, { shouldDirty: true });
    }
    if ((completedBy || completedDateTime) && !isVerified) {
      setValue('isVerified', true, { shouldDirty: true });
    }
  }, [completedBy, completedDateTime, record, getValues, setValue]);

  return <BooleanInput source="isVerified" label="Verified" sx={sx} />;
};

export default VerifiedInput;
