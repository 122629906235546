import React, { ReactElement } from 'react';
import { TextField, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ListTitle from '../common/ListTitle';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { ListWithPagination } from '../common/ListWithPagination';
import commonStyles from '../common/commonStyles';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { Book } from '../book/type';
import { BookSectionActions } from './components/BookSectionActions';
import SectionTypeField from './components/SectionTypeField';
import EmptyBookSections from './components/EmptyBookSections';
import { LinkToBooks } from './components/LinkToBooks';
import { ColumnActions } from './components/ColumnActions';
import BookSectionShow from './BookSectionShow';
import BookSectionEdit from './BookSectionEdit';
import { RESOURCE_BOOK, RESOURCE_BOOK_SECTION } from '../constants';

const BookSectionList: React.FC = (): ReactElement => {
  const { bookId } = useParams();
  const { data: book } = useGetOne<Book>(RESOURCE_BOOK, { id: bookId });

  return (
    <ListWithPagination
      empty={<EmptyBookSections />}
      title={<ListTitle title={`Book: ${book?.title}`} />}
      filters={
        <Box sx={{ ...commonStyles.columnActionsBox, alignItems: 'center' }}>
          <Box sx={{ flexBasis: 300 }}>
            <FreeTextSearchFilter key="filter" />
          </Box>
          <LinkToBooks />
        </Box>
      }
      sort={{ field: 'section', order: 'ASC' }}
      actions={<BookSectionActions />}
      resource={RESOURCE_BOOK_SECTION}
      filter={{
        bookId,
      }}
      queryOptions={{
        meta: { excludeFields: ['attachmentType', 'drawingTypes'] },
      }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<BookSectionShow withoutActions />}
        EditComponent={<BookSectionEdit syncWithLocation={false} />}
      >
        <TextField source="id" label="ID" />
        <TextField source="section" label="Section" />
        <SectionTypeField sx={commonStyles.flexBoxItem} label="Type" />
        <TextField source="title" label="Title" />
        <TextField source="description" label="Description" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithPagination>
  );
};

export default BookSectionList;
