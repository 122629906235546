import React, { ReactElement } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { ReactComponent as DownloadIcon } from '../../assets/icons/functionalIcons/Download.svg';
import { IconButton } from '@mui/material';
import EmptyIconButtonPlaceholder from './EmptyIconButtonPlaceholder';

type DownloadButtonProps = {
  showEmptyIcon?: boolean;
};

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  showEmptyIcon = true,
}): ReactElement => {
  const record = useRecordContext();

  return record.downloadUrl ? (
    <Link to={record.downloadUrl} download title={record.fileName}>
      <IconButton
        size="small"
        color="primary"
        title="Download"
        className="downloadButton"
      >
        <DownloadIcon />
      </IconButton>
    </Link>
  ) : showEmptyIcon ? (
    <EmptyIconButtonPlaceholder />
  ) : null;
};

export default DownloadButton;
