import React, { Fragment, ReactElement, useEffect } from 'react';
import { maxLength, useGetOne } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import { ContactCategories } from '../common/contactInput/constants';
import WithFieldCheckAccess, { Actions } from '../common/WithFieldCheckAccess';
import ContactInput from '../common/contactInput/ContactInput';
import PunchPriorityInput from '../common/PunchPriorityInput';
import PunchCategoryInput from '../common/PunchCategoryInput';
import EquipmentTypeInput from '../common/EquipmentTypeInput';
import { validateStatusFields } from '../common/validators';
import CustomDateInput from '../common/CustomDateInput';
import DisciplineInput from '../common/DisciplineInput';
import ComponentInput from '../common/ComponentInput';
import SubsystemInput from '../common/SubsystemInput';
import { getPunchNoValidation } from './validators';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import VendorInput from '../common/VendorInput/VendorInput';
import TextInput from '../common/TextInput';
import {
  required,
  useDictionaryLabelsWithResource,
} from '../../utils/UtilityFunctions';
import { RESOURCE_COMPONENT } from '../constants';
import useCanAccess from '../common/hooks/useCanAccess';

interface PunchFormProps {
  editMode?: boolean;
  bulkEditMode?: boolean;
}

const PunchForm: React.FC<PunchFormProps> = ({
  editMode,
  bulkEditMode,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const { setValue, getValues } = useFormContext();
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;
  const canAccess = useCanAccess();
  const componentId = useWatch({ name: 'componentId' });
  const { data: component } = useGetOne(
    RESOURCE_COMPONENT,
    { id: componentId },
    { enabled: !!componentId }
  );

  useEffect(() => {
    const formValue = getValues();

    if (!component) {
      return;
    }

    setValue('subsystemId', component.subsystemId);
    setValue('locationId', component.locationId);
    setValue('disciplineId', component.disciplineId);
    !formValue.vendorId && setValue('vendorId', component.vendorId);
    !formValue.equipTypeId && setValue('equipTypeId', component.equipTypeId);
  }, [component, setValue, getValues]);

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        {editMode && (
          <TextInput
            source="status"
            label={labels['status'] || 'Status'}
            sx={commonStyles.flexBoxItem}
            disabled
          />
        )}
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <TextInput
            source="punchNo"
            label={labels['punchNo'] || 'Punch No'}
            validate={getPunchNoValidation(editMode, bulkEditMode)}
            sx={commonStyles.flexBoxItem}
            disabled={bulkEditMode}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <TextInput
            source="description"
            label={labels['description'] || 'Description'}
            validate={[...requiredValidation, maxLength(200)]}
            sx={commonStyles.flexBoxDescriptionItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <ComponentInput
            sx={commonStyles.flexBoxItem}
            label={labels['componentId']}
          />
          <PunchPriorityInput
            isRequired={isRequired}
            sx={commonStyles.flexBoxItem}
            label={labels['priorityId']}
          />
        </WithFieldCheckAccess>
        <EquipmentTypeInput
          source={editMode ? 'component.equipTypeId' : 'equipTypeId'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          {!editMode && (
            <SubsystemInput
              isRequired={isRequired}
              sx={commonStyles.flexBoxItem}
              label={labels['subsystemId']}
            />
          )}
        </WithFieldCheckAccess>
      </Box>
      {editMode && (
        <Fragment>
          <Box sx={commonStyles.flexBox}>
            <WithFieldCheckAccess
              action={Actions.EDIT}
              hasResourceEditAccess={canAccess.edit}
            >
              <SubsystemInput
                isRequired={isRequired}
                sx={commonStyles.flexBoxItem}
                label={labels['subsystemId']}
              />
              <TextInput
                source="subsystem.description"
                label="SubSystem Description"
                sx={commonStyles.flexBoxLongItem}
                disabled
              />
            </WithFieldCheckAccess>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="subsystem.system.systemName"
              label="System Name"
              sx={commonStyles.flexBoxItem}
              disabled
            />
            <TextInput
              source="subsystem.system.description"
              label="System Description"
              sx={commonStyles.flexBoxLongItem}
              disabled
            />
          </Box>
        </Fragment>
      )}
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="raisedBy"
            label={labels['raisedBy'] || 'Raised By'}
            validate={[...requiredValidation, maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            source="raisedDate"
            label={labels['raisedDate'] || 'Raised Date *'}
            validate={requiredValidation}
            sx={commonStyles.flexBoxItem}
          />
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="clearedBy"
            label={labels['clearedBy'] || 'Cleared By'}
            validate={[validateStatusFields('cleared'), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            source="clearedDate"
            label={labels['clearedDate'] || 'Cleared Date'}
            validate={validateStatusFields('cleared')}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="acceptedBy"
            label={labels['acceptedBy'] || 'Accepted By'}
            validate={[validateStatusFields('accepted'), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            source="acceptedDate"
            label={labels['acceptedDate'] || 'Accepted Date'}
            validate={validateStatusFields('accepted')}
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            source="requiredDate"
            label={labels['requiredDate'] || 'Required Date'}
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            source="estimatedClearDate"
            label={labels['estimatedClearDate'] || 'Estimated Clear Date'}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="responsible"
            label={labels['responsible'] || 'Responsible'}
            validate={maxLength(75)}
            sx={commonStyles.flexBoxItem}
          />
          <PunchCategoryInput
            sx={commonStyles.flexBoxItem}
            label={labels['punchCatId']}
          />
          <LocationInput
            sx={commonStyles.flexBoxItem}
            label={labels['locationId']}
          />
          <DisciplineInput
            label={labels['disciplineId']}
            isRequired={isRequired}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="actionee"
            label={labels['actionee'] || 'Actionee'}
            validate={maxLength(75)}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="poRmaNo"
            label={labels['poRmaNo'] || 'Po Rma No'}
            validate={maxLength(75)}
            sx={commonStyles.flexBoxItem}
          />
          <VendorInput />
          <TextInput
            source="workpackOverride"
            label={labels['workpackOverride'] || 'Workpack Override'}
            validate={maxLength(75)}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <TextInput
            source="correctiveAction"
            label={labels['correctiveAction'] || 'Corrective Action'}
            validate={maxLength(512)}
            sx={commonStyles.flexBoxLongItem}
          />
          {labels['cfText01'] ? (
            <TextInput
              source="cfText01"
              label={labels['cfText01']}
              validate={maxLength(512)}
              sx={commonStyles.flexBoxLongItem}
            />
          ) : null}
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          {labels['cfText02'] ? (
            <TextInput
              source="cfText02"
              label={labels['cfText02']}
              validate={maxLength(512)}
              sx={commonStyles.flexBoxLongItem}
            />
          ) : null}
          {labels['cfText03'] ? (
            <TextInput
              source="cfText03"
              label={labels['cfText03']}
              validate={maxLength(512)}
              sx={commonStyles.flexBoxLongItem}
            />
          ) : null}
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          {labels['cfText04'] ? (
            <TextInput
              source="cfText04"
              label={labels['cfText04']}
              validate={maxLength(512)}
              sx={commonStyles.flexBoxLongItem}
            />
          ) : null}
          {labels['cfText05'] ? (
            <TextInput
              source="cfText05"
              label={labels['cfText05']}
              validate={maxLength(512)}
              sx={commonStyles.flexBoxLongItem}
            />
          ) : null}
        </WithFieldCheckAccess>
      </Box>
    </Fragment>
  );
};

export default PunchForm;
