import {
  BooleanField,
  DateField,
  Labeled,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import React from 'react';
import { MarkdownField } from '@react-admin/ra-markdown';
import commonStyles from '../../common/commonStyles';
import Box from '@mui/material/Box';

const CommentShowTemplate: React.FC = () => {
  return (
    <SimpleShowLayout sx={commonStyles.bottomMargin}>
      <MarkdownField source="comment" label={false} />
      <Box>
        <Labeled label="Show in report?" sx={commonStyles.flexBoxItem}>
          <BooleanField source="isIncludeInReport" />
        </Labeled>
        <Labeled label="Updated At" sx={commonStyles.flexBoxItem}>
          <DateField source="updatedAt" />
        </Labeled>
        <Labeled label="Updated By Name" sx={commonStyles.flexBoxItem}>
          <TextField source="updatedByName" />
        </Labeled>
      </Box>
    </SimpleShowLayout>
  );
};

export default CommentShowTemplate;
