import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import { RESOURCE_MOC_COMPONENT } from '../../../../constants';
import ComponentField from '../../../../common/ComponentField';
import commonStyles from '../../../../common/commonStyles';
import RelatedForm from '../RelatedForm';
import ComponentFormRow from './ComponentFormRow';

const ComponentForm: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <RelatedForm
      formRow={<ComponentFormRow recordId={record?.id} />}
      reference={RESOURCE_MOC_COMPONENT}
    >
      <ComponentField sx={commonStyles.rowFormField} />
    </RelatedForm>
  );
};

export default ComponentForm;
