import { useGetList } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_CONTACT } from '../../constants';

export const useContactList = (category = '', filters = {}) => {
  const ctx: UserContext = getContext();

  return useGetList(RESOURCE_CONTACT, {
    filter: {
      ...(category && { 'group.category': category }),
      'group.projectId': ctx?.projectId,
      projectId: ctx?.projectId,
      ...filters,
    },
  });
};
