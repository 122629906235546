import grey from '@mui/material/colors/grey';
import yellow from '@mui/material/colors/yellow';
import orange from '@mui/material/colors/orange';

export const RED = '#f14158';
export const GREEN = '#2bab2b';
export const GREEN_LIGHT = '#dce7da';
export const YELLOW = yellow[500];
export const GREY = grey[500];
export const GREY_TEXT = '#241f1f';
export const BLACK = '#000000';
export const BLUE = '#22559E';
export const ORANGE = orange[500];
export const WHITE = '#fff';
export const MENU_TEXT = '#263238';
