import React, { ReactElement } from 'react';
import { AutocompleteInput } from 'react-admin';
import { ACTIVITY_ITR_STATUS_CHOICES } from './constants';
import { SelectInputMultipleProps } from './types';

const ActivityCertGatingItrStatusInput: React.FC<SelectInputMultipleProps> = ({
  source,
  label,
  ...rest
}): ReactElement => (
  <AutocompleteInput
    {...rest}
    source={source}
    label={label}
    choices={ACTIVITY_ITR_STATUS_CHOICES}
  />
);

ActivityCertGatingItrStatusInput.defaultProps = {
  label: 'ITR Status',
  source: 'certGatingStatus->itr_status',
};

export default ActivityCertGatingItrStatusInput;
