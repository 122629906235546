import React, { ReactElement, useEffect, useState } from 'react';
import { useGetOne } from 'react-admin';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, service } from 'powerbi-client';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_DASHBOARD } from '../constants';
import {
  getPowerBIDefaultSettings,
  getPowerBIProjectIdFilter,
  getPowerBITimeZoneFilter,
} from '../report/components/reportHelper';

const DashPowerBIView: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const [embedConfig, setEmbedConfig] = useState(null);
  const { data: embedData, isLoading } = useGetOne(RESOURCE_DASHBOARD, {
    id: 0,
  });

  useEffect(() => {
    setEmbedConfig({
      type: 'report',
      embedUrl: embedData?.embedUrl,
      accessToken: embedData?.accessToken,
      tokenType: models.TokenType.Embed,
      settings: getPowerBIDefaultSettings(),
      filters: [
        getPowerBITimeZoneFilter(),
        getPowerBIProjectIdFilter(ctx?.projectId),
      ],
    });
  }, [ctx.projectId, embedData?.accessToken, embedData?.embedUrl]);

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        // eslint-disable-next-line no-console
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        // eslint-disable-next-line no-console
        console.log('Report has rendered');
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<never>) {
        if (event) {
          // eslint-disable-next-line no-console
          console.error(event.detail);
        }
      },
    ],
  ]);

  return !isLoading && embedConfig ? (
    <PowerBIEmbed
      embedConfig={embedConfig}
      eventHandlers={eventHandlersMap}
      cssClassName={'power-bi-component'}
    />
  ) : null;
};

export default DashPowerBIView;
