import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  DatagridConfigurable,
  DatagridProps,
  usePermissions,
  useRecordContext,
  useResourceContext,
  useStore,
} from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import isEqual from 'lodash/isEqual';
import useCanAccess from './hooks/useCanAccess';
import useDidUpdateEffect from './hooks/useDidUpdateEffect';
import useUploadDatagridSettings from './hooks/useUploadDatagridSettings';
import { Actions } from './WithFieldCheckAccess';

interface DatagridConfigurableRbacProps extends DatagridProps {
  children: ReactNode[];
  EditComponent?: ReactElement;
  ShowComponent?: ReactElement;
  defaultVisibleColumns?: string[];
  preferenceKey?: string;
  checkFieldsAccess?: boolean;
}

const DatagridConfigurableRbac: React.FC<DatagridConfigurableRbacProps> = ({
  children,
  EditComponent,
  ShowComponent,
  defaultVisibleColumns,
  preferenceKey,
  checkFieldsAccess,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const resourceAccessList = useCanAccess();
  const uploadDatagridSettings = useUploadDatagridSettings();
  const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;

  const [currentAvailableColumns] = useStore(
    `preferences.${finalPreferenceKey}.availableColumns`,
    []
  );
  const [defaultAvailableColumns, setDefaultAvailableColumns] = useStore(
    `preferences.${resource}.datagrid.availableColumns`,
    []
  );
  const [customAvailableColumns] = useStore(
    `preferences.${resource}.dict.availableColumns`,
    []
  );
  const [, setOmit] = useStore(`preferences.${finalPreferenceKey}.omit`, []);
  const [defaultColumns, setDefaultColumns] = useStore(
    `preferences.${resource}.datagrid.columns`,
    []
  );
  const [currentColumns] = useStore(
    `preferences.${finalPreferenceKey}.columns`,
    []
  );

  // Check if there is at least one field with edit permission.
  const hasResourceEditAccess = useMemo(() => {
    return (
      checkFieldsAccess &&
      children.some((child: ReactElement) =>
        canAccess({
          permissions,
          action: Actions.EDIT,
          resource: `${resource}.${child?.props?.source}`,
        })
      )
    );
  }, [children, checkFieldsAccess, permissions, resource]);

  useEffect(() => {
    const order = [...customAvailableColumns].map((item) => item.index);
    const reorderedDefaultColumns = [...defaultAvailableColumns].sort(
      (a, b) => order.indexOf(a.index) - order.indexOf(b.index)
    );

    if (!isEqual(reorderedDefaultColumns, defaultAvailableColumns)) {
      setDefaultAvailableColumns(reorderedDefaultColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(customAvailableColumns)]);

  useEffect(() => {
    if (!isEqual(currentColumns, defaultColumns)) {
      setDefaultColumns(currentColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentColumns)]);

  const hasAccess = useCallback(
    (child): boolean => {
      const permissionExists = permissions?.some(
        (permission) =>
          permission.resource === `${resource}.${child?.props?.source}`
      );

      return permissionExists
        ? canAccess({
            permissions,
            action: Actions.SHOW,
            resource: `${resource}.${child?.props?.source}`,
          })
        : true;
    },
    [permissions, resource]
  );

  const omitColumns = useMemo(() => {
    const columnsToOmit = [];

    if (defaultVisibleColumns && defaultVisibleColumns.length) {
      currentAvailableColumns.forEach((availableColumn) => {
        if (
          availableColumn.source &&
          !defaultVisibleColumns.includes(availableColumn.source)
        ) {
          columnsToOmit.push(availableColumn.source);
        }
      });
    }

    return columnsToOmit;
  }, [currentAvailableColumns, defaultVisibleColumns]);

  useDidUpdateEffect(uploadDatagridSettings, [
    JSON.stringify(currentAvailableColumns),
    JSON.stringify(currentColumns),
  ]);

  useEffect(() => {
    if (omitColumns.length) {
      setOmit(omitColumns);
    }
  }, [omitColumns, setOmit]);

  let expand = null;
  if (resourceAccessList.edit) {
    expand = EditComponent;
  } else if (resourceAccessList.show) {
    // If at least one field has edit permission, show the edit form.
    if (hasResourceEditAccess && EditComponent) {
      expand = EditComponent;
    } else if (ShowComponent) {
      expand = React.cloneElement(ShowComponent, { syncWithLocation: false });
    }
  }

  const processedChildrenWithCheckAccess = useMemo(() => {
    return children.filter((child: ReactElement) => {
      return hasAccess(child);
    });
  }, [children, hasAccess]);

  return (
    <DatagridConfigurable
      bulkActionButtons={false}
      rowClick={false}
      expand={expand && <ExpandWrapper expandElement={expand} />}
      expandSingle
      preferenceKey={preferenceKey}
      {...rest}
    >
      {processedChildrenWithCheckAccess}
    </DatagridConfigurable>
  );
};

export default DatagridConfigurableRbac;

interface ExpandWrapperProps {
  expandElement: React.ReactElement;
}

const ExpandWrapper: React.FC<ExpandWrapperProps> = ({
  expandElement,
}): ReactElement => {
  const record = useRecordContext();
  const resource = useResourceContext();

  return React.cloneElement(expandElement, {
    id: record.id,
    record,
    resource,
  });
};
