import React from 'react';
import { ReactComponent as StopRecordingIcon } from 'src/assets/icons/functionalIcons/StopRecording.svg';

interface RecordingInProgressProps {
  onClick: () => void;
}

export const RecordingInProgress: React.FC<RecordingInProgressProps> = ({
  onClick,
}) => (
  <div
    style={{
      padding: '8px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    }}
    onClick={onClick}
  >
    <StopRecordingIcon />
  </div>
);
