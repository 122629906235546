import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import CustomBooleanField from 'src/components/common/CustomBooleanField';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import StructureAssetEdit from './StructureAssetEdit';
import StructureAssetShow from './StructureAssetShow';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import RegionField from '../common/RegionField';

const StructureAssetList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('division');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      actions={<ListActions />}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'asset', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<StructureAssetShow withoutActions />}
        EditComponent={<StructureAssetEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'asset',
          'description',
          'regionId',
          'isIncludeInReport',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="asset" label="Asset" />
        <TextField source="description" label="Description" />
        <RegionField source="regionId" />
        <CustomBooleanField
          source="isIncludeInReport"
          label="Show in report?"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default StructureAssetList;
