import React, { ReactElement, Fragment } from 'react';
import { RESOURCE_SMART_TAG_ITR_REVIEW } from '../../constants';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import BulkEditButton from '../../common/BulkEditButton';
import SmartTagITRReviewForm from './SmartTagITRReviewForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <CustomBulkUpdateButton
        resource={RESOURCE_SMART_TAG_ITR_REVIEW}
        data={{ mobileTestStatus: 'APPROVED_PROCESSED' }}
        label="Process"
        icon={null}
      />
      <BulkEditButton
        resource={RESOURCE_SMART_TAG_ITR_REVIEW}
        form={<SmartTagITRReviewForm />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
