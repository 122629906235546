import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

const companySubscriptionDataProvider = {
  getCompanySubscriptionPortal: () =>
    axios.get(
      `${CRUD_API_URL}/company-subscription/portal-link`,
      getRequestConfig()
    ),
  sendSubscriptionAdminEmail: () =>
    axios.post(
      `${CRUD_API_URL}/company-subscription/send-email/admin`,
      {},
      getRequestConfig()
    ),
};

export default companySubscriptionDataProvider;
