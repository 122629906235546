import React, { ReactElement } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { IconButton } from '@mui/material';
import { RESOURCE_REPORT_VIEW } from '../../constants';
import { ReactComponent as ReportsIcon } from '../../../assets/icons/navigationIcons/Reports.svg';

export const ReportViewerButton: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <Link to={`${record.id}/${RESOURCE_REPORT_VIEW}/0`}>
      <IconButton>
        <ReportsIcon />
      </IconButton>
    </Link>
  );
};

export default ReportViewerButton;
