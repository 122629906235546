import React, { ReactElement } from 'react';
import {
  SaveButton as BaseSaveButton,
  SaveButtonProps as BaseSaveButtonProps,
} from 'react-admin';
import { ReactComponent as SaveIcon } from '../../assets/icons/functionalIcons/Save.svg';

interface SaveButtonProps extends BaseSaveButtonProps {
  label?: string;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: React.ReactElement<
    string,
    string | React.JSXElementConstructor<string>
  >;
  onClick?: (event) => void;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  label = 'Save',
  variant = 'text',
  icon = <SaveIcon style={{ marginRight: '8px' }} />,
  onClick,
  ...rest
}): ReactElement => {
  return (
    <BaseSaveButton
      {...rest}
      icon={icon}
      label={label}
      variant={variant}
      alwaysEnable={undefined}
      onClick={onClick}
    />
  );
};

export default SaveButton;
