import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Identifier } from 'react-admin';
import commonStyles from './commonStyles';
import MaterialInput from './MaterialInput';

interface MaterialRowFormProps {
  targetId: Identifier;
  targetField: 'componentId';
}

const MaterialRowForm: React.FC<MaterialRowFormProps> = ({
  targetId,
  targetField,
}): ReactElement => (
  <RowForm
    transform={(data) => ({
      ...data,
      [targetField]: targetId,
    })}
  >
    <MaterialInput sx={commonStyles.rowFormInput} isRequired={true} />
  </RowForm>
);

export default MaterialRowForm;
