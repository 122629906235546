import React, { ReactElement } from 'react';
import { TextField, useStore, WrapperField } from 'react-admin';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListWithTitle from '../common/ListWithTitle';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import RoleField from '../common/RoleField';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import { RESOURCE_SECURITY_ROLE_PERMISSION } from '../constants';
import SecurityRolePermissionShow from './SecurityRolePermissionShow';
import SecurityRolePermissionEdit from './SecurityRolePermissionEdit';
import SecurityRolePermissionSidebarFilters from './SecurityRolePermissionSidebarFilters';
import ResourceField from './ResourceField';
import ActionField from './ActionField';

const SecurityRolePermissionList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_SECURITY_ROLE_PERMISSION);
  const [showSideFilter] = useStore(sideFilter, false);

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      actions={
        <ListActions sideFilter={sideFilter} showAction={{ effective: true }} />
      }
      aside={showSideFilter ? <SecurityRolePermissionSidebarFilters /> : null}
    >
      <DatagridConfigurableRbac
        ShowComponent={<SecurityRolePermissionShow withoutActions />}
        EditComponent={<SecurityRolePermissionEdit syncWithLocation={false} />}
      >
        <TextField source="id" label="ID" />
        <RoleField source="roleId" />
        <TextField source="permissionType" label="Permission Type" />
        <WrapperField label="Resource" source="resourceName">
          <ResourceField label="Resource" />
        </WrapperField>
        <WrapperField label="Role Action" source="action">
          <ActionField />
        </WrapperField>
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SecurityRolePermissionList;
