import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_SECURITY_ROLE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface RoleFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}
const RoleField: React.FC<RoleFieldProps> = ({
  label,
  source,
  sortBy,
}): ReactElement => {
  return (
    <SmartLinkField
      source={source}
      label={label}
      resource={RESOURCE_SECURITY_ROLE}
    >
      <TextField source={sortBy} />
    </SmartLinkField>
  );
};

RoleField.defaultProps = {
  label: 'Role',
  source: 'roleId',
  sortBy: 'role.role',
};

export default RoleField;
