import React, { ReactElement } from 'react';
import { Typography, Box } from '@mui/material';

const FilterTypeLegend: React.FC = (): ReactElement => (
  <Box sx={{ maxWidth: '320px' }}>
    <hr />
    <Typography variant="body2" fontWeight="bold">
      Special Characters:
    </Typography>
    <Typography variant="body2">
      <b>.</b> - Matches any single character. <br />
      <b>*</b> - Matches zero or more repetitions of the preceding element.
    </Typography>
    <Typography variant="body2" fontWeight="bold">
      Search is case-insensitive.
    </Typography>
    <Typography variant="body2">
      <b>Exact Match:</b>
      <br />
      Search for an exact phrase by typing it directly.
    </Typography>
    <Typography variant="body2">
      <b>Flexible Match:</b>
      <br />
      Use dot &quot;<b>.</b>&quot; to match any single character.
      &quot;J.hn&quot; will match &quot;John.&quot;
    </Typography>
    <Typography variant="body2">
      Use dot and asterisk together &quot;<b>.*</b>&quot; to match any number of
      characters. &quot;PO.*234&quot; will match &quot;PO-U-SC-234&quot; and
      &quot;PO-U-CW-234&quot;.
    </Typography>
  </Box>
);

export default FilterTypeLegend;
