import { useCallback, useState } from 'react';
import { downloadFile } from '../../../utils/UtilityFunctions';

const useRevisionDetailsJsonDownload = () => {
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const downloadJson = useCallback(async (record) => {
    try {
      setDownloadInProgress(true);
      const details = JSON.stringify(record?.details, null, 2);
      downloadFile(
        `attachment_revision_details_${record?.id}`,
        details,
        'application/json',
        'json'
      );
    } catch (err) {
      throw err;
    } finally {
      setDownloadInProgress(false);
    }
  }, []);

  return { downloadInProgress, downloadJson };
};

export default useRevisionDetailsJsonDownload;
