import React, { Fragment, ReactElement } from 'react';
import BulkEditButton from '../../common/BulkEditButton';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_SUBMIT_PUNCH } from '../../constants';
import SubmitPunchForm from '../SubmitPunchForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_SUBMIT_PUNCH}
        form={<SubmitPunchForm bulkEditMode />}
      />
      <CustomBulkUpdateButton
        data={{ status: 'SUBMITTED' }}
        label="Submit"
        icon={null}
        resource={RESOURCE_SUBMIT_PUNCH}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
