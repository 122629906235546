import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import DownloadButton from './DownloadButton';
import PreviewButton from './PreviewButton';
import ColumnActions from './ColumnActions';

interface DrawingFormTabProps {
  target: string;
  reference: string;
}

const DrawingTab: React.FC<DrawingFormTabProps> = ({
  target,
  reference,
}): ReactElement => {
  return (
    <ReferenceManyField target={target} reference={reference}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        sx={{ width: '50%' }}
      >
        <TextField
          label="Drawing"
          source="drawing.drawingName"
          sortBy="drawing.drawing_name"
        />
        <TextField label="Description" source="drawing.description" />
        <TextField
          label="Type"
          source="drawing.drawingType.drawingType"
          sortBy="`drawing->drawingType`.drawing_type"
        />
        <TextField
          label="Discipline"
          source="drawing.drawingDiscipline.drawingDiscipline"
          sortBy="`drawing->drawingDiscipline`.drawing_discipline"
        />
        <TextField
          label="Group"
          source="drawing.group.groupName"
          sortBy="`drawing->group`.group_name"
        />
        <ColumnActions
          label="Actions"
          showActions={{ show: false, edit: false, delete: false }}
        >
          <PreviewButton />
          <DownloadButton />
        </ColumnActions>
      </Datagrid>
    </ReferenceManyField>
  );
};

export default DrawingTab;
