import React, { ReactElement, useCallback } from 'react';
import { Button, useGetList, useResourceContext, useStore } from 'react-admin';
import commonStyles from '../../common/commonStyles';
import { LOOKUP_PAGE_SIZE } from '../../common/constants';

interface ExpandCollapseButtonProps {
  defaultFilter: object;
  isExpand?: boolean;
}

export const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({
  defaultFilter,
  isExpand = false,
}): ReactElement => {
  const resource: string = useResourceContext();
  const { data: reviews, isLoading } = useGetList(resource, {
    filter: defaultFilter,
    pagination: {
      page: 1,
      perPage: LOOKUP_PAGE_SIZE,
    },
  });
  const [, setExpandedRecords] = useStore(`${resource}.datagrid.expanded`);

  const handleButtonClick = useCallback(() => {
    const ids = isLoading ? [] : reviews.map((review) => review.id);
    setExpandedRecords(!!isExpand ? ids : []);
  }, [isExpand, isLoading, reviews, setExpandedRecords]);

  return (
    <Button
      label={!!isExpand ? 'Expand' : 'Collapse'}
      onClick={handleButtonClick}
      className={!!isExpand ? 'expandButton' : 'collapseButton'}
      sx={commonStyles.smResponsiveButton}
    >
      <span>{!!isExpand ? 'Expand' : 'Collapse'}</span>
    </Button>
  );
};

export default ExpandCollapseButton;
