import React, {
  Fragment,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Button, Labeled, SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import useCopyBookToProject from '../../common/hooks/useCopyBookToProject';
import ModalDialog from '../../common/ModalDialog';
import { RESOURCE_PROJECT } from '../../constants';

interface CopyBookButtonProps {
  label?: string;
}

const CopyBookButton: React.FC<CopyBookButtonProps> = ({
  label,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const ctxProjectIds = useMemo(() => {
    const ctxProjectIds = ctx
      ? Object.keys(ctx?.companies?.[ctx?.company]?.projects || {})
      : ['0'];
    return ctxProjectIds.filter((id) => id !== String(ctx?.projectId));
  }, [ctx]);
  const { copyBookToProject } = useCopyBookToProject();
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);
  const projectRequestParams = useMemo(() => {
    return {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'project',
        order: 'ASC' as const,
      },
      filter: {
        id: ctxProjectIds,
        isActive: true,
        divisionId: ctx?.companies?.[ctx?.company]?.defaultDivisionId,
      },
    };
  }, [ctx?.companies, ctx?.company, ctxProjectIds]);

  const submit = useCallback(
    async ({ projectIds }) => {
      await copyBookToProject(projectIds);
      closeDialog();
    },
    [copyBookToProject, closeDialog]
  );

  return (
    <Fragment>
      <Button label={label} onClick={openDialog} />

      <ModalDialog title="Copying Books" open={open} onClose={closeDialog}>
        <SimpleForm id="copy_book_form" onSubmit={submit}>
          <Labeled
            label="Select which project(s) to copy the book(s) to"
            sx={commonStyles.flexBoxLongItem}
          >
            <AutocompleteReferenceInput
              resource={RESOURCE_PROJECT}
              label="Project(s)"
              name="project_ids"
              optionText="project"
              source="projectIds"
              sx={commonStyles.flexBoxLongItem}
              requestParams={projectRequestParams}
              includeFields={['id', 'project']}
              multiple
            />
          </Labeled>
        </SimpleForm>
      </ModalDialog>
    </Fragment>
  );
};

CopyBookButton.defaultProps = { label: 'Copy Book(s) to other projects' };

export default CopyBookButton;
