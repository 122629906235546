import React, { ReactElement, useCallback, useState } from 'react';
import {
  Identifier,
  useGetList,
  useRecordContext,
  useStore,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import TextInput from '../../../components/common/TextInput';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { required } from '../../../utils/UtilityFunctions';
import { Test } from '../../test/types';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_BOOK_SECTION_TEST } from '../../constants';
import { BookSectionTest } from '../type';
import { bookSectionIdStore } from '../constants';
import { SelectTestDialog } from './SelectTestDialog';

export const SelectTestInput: React.FC = (): ReactElement => {
  const [bookSectionId] = useStore<Identifier>(bookSectionIdStore, null);
  const record = useRecordContext<BookSectionTest>();
  const [isOpen, setIsOpen] = useState(false);
  const { setValue } = useFormContext<BookSectionTest>();
  const { data: testList } = useGetList<BookSectionTest>(
    RESOURCE_BOOK_SECTION_TEST,
    {
      pagination: {
        page: 1,
        perPage: MAX_RECORD_PER_PAGE,
      },
      sort: { field: 'sortOrder', order: 'ASC' },
      filter: {
        bookSectionId,
      },
    }
  );

  const validateDuplication = useCallback(
    (value: string) => {
      if (
        testList.some(
          (test) => test.id !== record?.id && test.testName === value
        )
      ) {
        return `${value} is already in the list`;
      }

      return undefined;
    },
    [testList, record]
  );

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelect = (test: Test) => {
    setValue('testId', test.id);
    setValue('testName', test.testFormNo, {
      shouldTouch: true,
      shouldValidate: true,
    });
    onClose();
  };

  return (
    <Box sx={{ ...commonStyles.flexBox, alignItems: 'baseline' }}>
      <Button sx={commonStyles.flexBoxItem} onClick={onOpen}>
        Select Test
      </Button>
      <TextInput
        disabled
        source="testName"
        label="Test Form No"
        validate={[required(), validateDuplication]}
        sx={commonStyles.flexBoxItem}
      />
      <SelectTestDialog open={isOpen} onClose={onClose} onSelect={onSelect} />
    </Box>
  );
};
