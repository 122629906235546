import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import PunchCategoryField from '../common/PunchCategoryField';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import AuditListTab from '../audit/AuditListTab';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';

const PunchTemplateShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Punch Template">
          <Labeled>
            <TextField
              source="templateName"
              label="Template Name"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <PunchCategoryField
              source="punchTemplateCategory.punchCategoryId"
              label={labels['punchCatId']}
              textFieldSource="punchTemplateCategory.punchCat.category"
            />
          </Labeled>
          <Labeled>
            <TextField
              source="externalFormId"
              label="External Form ID"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Box>{renderShowMetaDataFields()}</Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Revisions"
          id="revisions_tab"
          sx={commonStyles.formTabHeader}
        >
          <AttachmentRevisionFormTab
            targetId="punchTemplateId"
            withoutProjectId
            onlyShow
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_punch_template" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default PunchTemplateShow;
