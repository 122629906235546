import React, { ReactElement } from 'react';
import { TextField, DateField, useStore } from 'react-admin';
import AssetField from '../common/AssetField';
import AssetGroupField from '../common/AssetGroupField';
import FileField from '../common/FileField';
import RegionField from '../common/RegionField';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { RESOURCE_PROJECT } from '../constants';
import ProjectSideFilters from './components/ProjectSideFilters';
import ItrAutomationField from './components/ItrAutomationField';
import ProjectEdit from './ProjectEdit';
import ProjectShow from './ProjectShow';
import DeleteProjectWithConfirmIconButton from './components/DeleteProjectWithConfirmIconButton';

const ProjectList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('division', { isActive: true });
  const sideFilter = getSideFiltersKey(RESOURCE_PROJECT);
  const [showSideFilter] = useStore(sideFilter, false);

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      filterDefaultValues={defaultFilter}
      sort={{ field: 'project', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <ProjectSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      queryOptions={{ meta: { excludeFields: ['details'] } }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ProjectShow withoutActions />}
        EditComponent={<ProjectEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'attachment',
          'project',
          'description',
          'location',
          'isMobile',
          'regionId',
          'assetId',
          'assetGroupId',
        ]}
      >
        <TextField source="id" label="ID" />
        <FileField source="attachment" label="Logo" sortable={false} />
        <TextField source="project" label="Project" />
        <TextField source="description" label="Description" />
        <TextField source="location" label="Location" />
        <CustomBooleanField source="isItrMobile" label="Mobile ITRs" />
        <CustomBooleanField
          source="isPresMobile"
          label="Mobile Preservations"
        />
        <RegionField />
        <AssetField />
        <AssetGroupField />
        <TextField source="projectContractNo" label="Project Contract No" />
        <DateField source="startDate" label="Start Date" />
        <DateField source="stopDate" label="Stop Date" />
        <CustomBooleanField source="isArchived" label="Archived" />
        <DateField source="archivedDate" label="Archived Date" />
        <ItrAutomationField />
        <CustomBooleanField
          source="isDuplicateItrEnabled"
          label="Duplicate ITR Enabled"
        />
        <CustomBooleanField
          source="isItrPunchForcedCreationEnabled"
          label="ITR Punch Forced Creation Enabled"
        />
        <CustomBooleanField
          source="isPresPunchForcedCreationEnabled"
          label="Preservation Punch Forced Creation Enabled"
        />
        <CustomBooleanField label="Frequent" source="isFrequentlyUsed" />
        <CustomBooleanField
          source="isPinCodeEntryEnabled"
          label="Pin Code Entry Enabled"
        />
        {renderMetaDataFields()}
        <ColumnActions
          label="Actions"
          customDeleteButton={<DeleteProjectWithConfirmIconButton />}
        />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ProjectList;
