import { Identifier, RaRecord } from 'react-admin';

export interface MaterialReleased extends RaRecord {
  id: Identifier;
  projectId: number;
  orderNo: string;
  generateDate: Date;
  releasedDate?: Date;
  status?: MaterialReleasedStatus;
  releasedTo?: string;
  totalQty: number;
  updatedAt: Date;
  updatedBy: string;
  updatedByName: string;
}

export enum MaterialReleasedStatus {
  GENERATED = 'GENERATED',
  RELEASED = 'RELEASED',
  CANCELLED = 'CANCELLED',
}
