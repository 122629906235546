import axios from 'axios';
import { GetOneParams } from 'react-admin';
import { BOOK_GEN_API, getRequestConfig } from '../../api';

const getDashPowerBIList = async () => {
  try {
    return await axios.get(
      `${BOOK_GEN_API}/api/powerbi/dashboards`,
      getRequestConfig()
    );
  } catch (e) {
    return { data: {} };
  }
};

const dashDataProvider = {
  getOne: async (_resource: string, params: GetOneParams) => {
    const result = await getDashPowerBIList();
    return {
      data: { id: params.id, ...result.data },
    };
  },
};

export default dashDataProvider;
