import React, { ReactElement, useMemo } from 'react';
import { maxLength, minValue } from 'react-admin';
import { RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import CustomNumberInput from '../../../common/CustomNumberInput';
import { required } from '../../../../utils/UtilityFunctions';
import { integer } from '../../../../utils/UtilityFunctions';
import { getQtyToReleaseValidator } from '../validators';
import TextInput from '../../../common/TextInput';

export interface MRMaterialStoragesEditRowFormProps {
  isReleased: boolean;
  onSuccess: () => Promise<void>;
}

const MRMaterialStoragesEditRowForm: React.FC<
  MRMaterialStoragesEditRowFormProps
> = ({ isReleased, onSuccess }): ReactElement => {
  const { close } = useRowContext();

  const mutationOptions = useMemo(
    () => ({
      onSuccess: async () => {
        await onSuccess();
        close();
      },
    }),
    [close, onSuccess]
  );

  return (
    <RowForm mutationOptions={mutationOptions}>
      <TextInput label="Material" source="material.material" disabled />
      <TextInput label="Description" source="material.description" disabled />
      <TextInput label="Type" source="material.materialType.type" disabled />
      <TextInput
        label="Storage Location"
        source={isReleased ? 'storageStr' : 'storage.storage_location'}
        disabled
      />
      <TextInput
        label="On Hand Qty"
        source={isReleased ? 'qty' : 'materialStorage.qtyOnHand'}
        disabled
        className="fixed"
      />
      <CustomNumberInput
        label="Qty to Release"
        source="qtyOnReleased"
        isPositive
        defaultNull
        className="fixed"
        validate={[
          minValue(0),
          integer(),
          required(),
          getQtyToReleaseValidator('materialStorage.qtyOnHand'),
        ]}
      />
      <TextInput
        label="Comment"
        source="comment"
        validate={[maxLength(255)]}
        className="fixed2x"
      />
    </RowForm>
  );
};

export default MRMaterialStoragesEditRowForm;
