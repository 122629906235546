import React from 'react';
import { CreateProps, maxLength, SimpleForm, BooleanInput } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import RegionInput from '../common/RegionInput';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const StructureAssetCreate: React.FC<CreateProps> = (props) => {
  const ctx: UserContext = getContext();

  const transform = (data) => ({
    ...data,
    divisionId: ctx?.defaultDivisionId,
  });

  return (
    <CreateWithTitle {...props} redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="asset"
              label="Asset"
              validate={[required(), maxLength(150)]}
              sx={commonStyles.flexBoxItem}
            />
            <TextInput
              source="description"
              label="Description"
              validate={maxLength(255)}
              sx={commonStyles.flexBoxLongItem}
              fullWidth
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <RegionInput isRequired />
            <BooleanInput
              source="isIncludeInReport"
              label="Show in report?"
              defaultValue={true}
              sx={commonStyles.flexBoxItem}
            />
          </Box>
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default StructureAssetCreate;
