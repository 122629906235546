import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../../../utils/UtilityFunctions';
import commonStyles from '../../../common/commonStyles';
import CustomDateInput from '../../../common/CustomDateInput';
import TextInput from '../../../common/TextInput';
import StatusInput from './StatusInput';

const MaterialReleasedForm: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="orderNo"
        label="Released Order No"
        validate={[maxLength(100)]}
        sx={commonStyles.flexBoxItem}
      />
      <StatusInput validate={[required()]} sx={commonStyles.flexBoxItem} />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <CustomDateInput
        source="generateDate"
        label="Date Generated"
        sx={commonStyles.flexBoxItem}
        defaultValue={new Date()}
        disabled
      />
      <CustomDateInput
        source="releasedDate"
        label="Date Released"
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="releasedTo"
        label="Released To"
        validate={[maxLength(100)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
  </Fragment>
);

export default MaterialReleasedForm;
