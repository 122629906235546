import React, { ReactElement } from 'react';
import CertDetailsEditTab from '../certDetails/CertDetailsEditTab';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import MetaDataInputs from '../common/MetaDataInputs';
import SystemField from '../common/SystemField';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_SYSTEM_ACTIVITY } from '../constants';
import SystemActivityForm from './components/SystemActivityForm';

const SystemActivityEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="System Activity"
        id="system_activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <SystemActivityForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Certificates"
        id="certificates_tab"
        sx={commonStyles.formTabHeader}
      >
        <CertDetailsEditTab
          target="systemActivityId"
          targetReference={RESOURCE_SYSTEM_ACTIVITY}
        >
          <SystemField />
        </CertDetailsEditTab>
      </LazyFormTab>
      <HistoryTab tableName="tbl_system_activity" />
    </TabbedEditForm>
  );
};

export default SystemActivityEdit;
