import { useCallback, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { getErrorMessage } from '../../../utils/UtilityFunctions';

const useHardDelete = () => {
  const [deletingProgress, setDeletingProgress] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const hardDeleteFile = useCallback(
    async (resource: string, params: { id: number }) => {
      try {
        setDeletingProgress(true);
        return await dataProvider.hardDelete(resource, params);
      } catch (err) {
        notify(getErrorMessage(err), {
          type: 'error',
          undoable: false,
        });
      }
    },
    [dataProvider, notify]
  );

  return { deletingProgress, hardDeleteFile };
};

export default useHardDelete;
