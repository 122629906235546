import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DrawingDisciplineEdit from './DrawingDisciplineEdit';
import DrawingDisciplineShow from './DrawingDisciplineShow';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ListWithTitle from '../common/ListWithTitle';

const DrawingDisciplineList: React.FC = (): ReactElement => (
  <ListWithTitle
    filters={<FreeTextSearchFilter />}
    actions={<ListActions />}
    sort={{ field: 'drawingDiscipline', order: 'ASC' }}
  >
    <DatagridConfigurableRbac
      ShowComponent={<DrawingDisciplineShow withoutActions />}
      EditComponent={<DrawingDisciplineEdit syncWithLocation={false} />}
      defaultVisibleColumns={['id', 'drawingDiscipline', 'description']}
    >
      <TextField source="id" label="ID" />
      <TextField source="drawingDiscipline" label="Drawing Discipline" />
      <TextField source="description" label="Description" />
      {renderMetaDataFields()}
      <ColumnActions label="Actions" />
    </DatagridConfigurableRbac>
  </ListWithTitle>
);

export default DrawingDisciplineList;
