import {
  getContactItem,
  // getGroupItem,
  getUserGroupItem,
  getUserItem,
} from '../../../preservationItemActivity/helpers';
import { ContactCategories } from '../../contactInput/constants';
import { useContactList } from '../../contactInput/useContactList';
// import useGroupsList from '../../hooks/useGroupList';
import useUserGroupsList from '../../hooks/useUserGroupList';
import useUserList from '../../hooks/useUserList';
import { OptionType } from '../types';

const useOptionList = (
  category: ContactCategories,
  filters?: object
): OptionType[] => {
  const { data: contacts = [] } = useContactList(category, filters);
  // const { data: groups = [] } = useGroupsList(category, filters);
  const { data: userGroups = [] } = useUserGroupsList(filters);
  const { data: users = [] } = useUserList();
  const contactsGroup = contacts.map((contact) => getContactItem(contact));
  // const groupsGroup = groups.map((group) => getGroupItem(group));
  const userGroupsGroup = userGroups.map((userGroup) =>
    getUserGroupItem(userGroup)
  );
  const usersGroup = users.map((user) => getUserItem(user));

  return [
    ...contactsGroup,
    // ...groupsGroup,
    ...userGroupsGroup,
    ...usersGroup,
  ];
};

export default useOptionList;
