import React, { ReactElement, Fragment } from 'react';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import AssignResponsibleButton from '../../common/AssignResponsibleButton';
import { ContactCategories } from '../../common/contactInput/constants';
import BulkGeneratePdfButton from '../../common/BulkGeneratePdfButton';
import { RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY } from '../../constants';
import useCanAccess from '../../common/hooks/useCanAccess';
import BulkCloseOutButton from './BulkCloseOutButton';
import InfoButton from './InfoButton';

const BulkActionButtons: React.FC = (): ReactElement => {
  const invalidateCache = useInvalidateResourceQueryCache();
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <AssignResponsibleButton
        category={ContactCategories.preservationEmpWorkGrp}
      />
      <BulkGeneratePdfButton onSuccess={invalidateCache} />
      {canAccessList.closeout && (
        <Fragment>
          <BulkCloseOutButton
            resource={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
            onUpdate={invalidateCache}
          />
          <InfoButton />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BulkActionButtons;
