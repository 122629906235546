import LazyFormTab from './LazyFormTab';
import commonStyles from './commonStyles';
import AuditListTab from '../audit/AuditListTab';
import React, { ReactElement } from 'react';
import { FormTabProps } from 'react-admin';

interface HistoryTabProps extends Omit<FormTabProps, 'label' | 'id'> {
  tableName?: string;
}

const HistoryTab: React.FC<HistoryTabProps> = ({
  tableName,
  ...props
}): ReactElement => (
  <LazyFormTab
    {...props}
    label="History"
    id="audit_tab"
    sx={commonStyles.formTabHeader}
  >
    <AuditListTab tableName={tableName} />
  </LazyFormTab>
);

export default HistoryTab;
