import { GREY_TEXT } from '../../theme/colors';

export const styles = {
  generateBookPage: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  generateBookPageLeftColumn: {
    flex: 2,
    marginRight: 1,
  },
  generateBookPageRightColumn: {
    flex: 1,
    marginLeft: 1,
  },
  printBookButton: {
    marginTop: '10px',
    width: '50%',
  },
  printQueueButton: { marginTop: '20px' },
  printedBooksButton: {
    marginTop: '20px',
    marginLeft: '20px',
  },
  select: { maxWidth: '288px' },
  selectListTitle: { marginTop: '25px' },
  divider: {
    minWidth: '100%',
    marginTop: '25px',
    marginBottom: '5px',
    height: 0,
  },
  printQueueSubsystemList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  statusIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '16px',
  },
  errorText: {
    marginTop: '12px',
    marginBottom: '12px',
    fontSize: 12,
  },
  bookListItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bookListItemHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectedItemsDivider: {
    minWidth: '100%',
    marginTop: '0px',
    marginBottom: '15px',
    height: 0,
  },
  circularProgressWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  outputFiles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 16px',
  },
  downloadButton: {
    padding: '8px 0',
  },
  downloadButtonText: {
    marginLeft: '8px',
    fontSize: '14px',
    color: GREY_TEXT,
  },
  attachmentFilterActions: {
    '& .RaList-actions': { alignItems: 'center', marginBottom: '10px' },
  },
  attachmentSearchFilter: {
    minWidth: '305px',
    marginTop: '6px',
  },
};
