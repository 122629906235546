import React, { Fragment } from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import AttachmentShowButton from '../attachment/components/AttachmentShowButton';
import DeleteWithConfirmIconButton from '../common/DeleteWithConfirmIconButton';
import { ImageField, useRecordContext } from 'react-admin';
import commonStyles from '../common/commonStyles';
import { ImageModal, useModal } from './ImageModal';

interface ImageItemProps {
  onlyShow: boolean;
}

const ImageItem: React.FC<ImageItemProps> = ({ onlyShow }) => {
  const record = useRecordContext();
  const { openModal, isOpenedModal, closeModal } = useModal();

  return (
    <Fragment>
      <ImageListItem onClick={openModal}>
        <ImageField
          record={record}
          source={'[downloadUrl]'}
          title={record.fileName}
          sx={commonStyles.imageGalleryItemImageField}
        />
        <ImageListItemBar
          position="below"
          subtitle={record.fileName}
          sx={commonStyles.imageListItemBar}
          actionIcon={
            <Fragment>
              <AttachmentShowButton isImage />
              {!onlyShow && (
                <DeleteWithConfirmIconButton
                  redirect={false}
                  sx={{ minWidth: 'auto' }}
                />
              )}
            </Fragment>
          }
        />
      </ImageListItem>
      <ImageModal isOpenedModal={isOpenedModal} closeModal={closeModal} />
    </Fragment>
  );
};

export default ImageItem;
