import React, { useCallback, useState, Fragment, ReactElement } from 'react';
import {
  useResourceContext,
  useRecordContext,
  Confirm,
  useNotify,
  useListContext,
  useRefresh,
} from 'react-admin';
import clsx from 'clsx';
import { getErrorMessage } from '../../../../utils/UtilityFunctions';
import CircularProgress from '../../../common/CircularProgress';
import DeleteIconButton from '../../../common/DeleteIconButton';
import useHardDelete from '../../../common/hooks/useHardDelete';
import { HARD_DELETE_CONFIRMATION_MESSAGE } from './constants';

type AdminAttachmentHardDeleteButtonProps = {
  className?: string;
  confirmTitle?: string;
  confirmContent?: string;
  label?: string;
  resource?: string;
  afterDelete?: () => void;
};

const AdminAttachmentHardDeleteButton: React.FC<
  AdminAttachmentHardDeleteButtonProps
> = (props): ReactElement => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { deletingProgress, hardDeleteFile } = useHardDelete();
  const { refetch } = useListContext();
  const {
    className,
    confirmTitle = `Delete attachment ${record.fileName}`,
    confirmContent = HARD_DELETE_CONFIRMATION_MESSAGE,
    label,
    afterDelete,
    ...rest
  } = props;

  const handleDelete = useCallback(async () => {
    try {
      await hardDeleteFile(resource, { id: record.id as number });
      await refetch();

      if (afterDelete) {
        afterDelete();
      }
    } catch (error) {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    } finally {
      setIsOpenDialog(false);
      refresh();
    }
  }, [
    afterDelete,
    hardDeleteFile,
    notify,
    record.id,
    refetch,
    refresh,
    resource,
  ]);

  const handleDialogOpen = useCallback((event) => {
    event.stopPropagation();

    setIsOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => setIsOpenDialog(false), []);

  return (
    <Fragment>
      <DeleteIconButton
        onClick={handleDialogOpen}
        className={clsx('ra-delete-button', className)}
        key="button"
        label={label}
        {...rest}
      />
      <Confirm
        isOpen={isOpenDialog}
        loading={deletingProgress}
        title={confirmTitle}
        content={deletingProgress ? <CircularProgress /> : confirmContent}
        translateOptions={{
          name: 'attachment',
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default AdminAttachmentHardDeleteButton;
