import React, { ReactElement, useCallback } from 'react';
import { Button, useNotify } from 'react-admin';
import useBulkGeneratePdf from './hooks/useBulkGeneratePdf';

interface BulkGeneratePdfButtonProps {
  label?: string;
  inProgressLabel?: string;
  onSuccess?: () => Promise<void>;
}

const BulkGeneratePdfButton: React.FC<BulkGeneratePdfButtonProps> = ({
  label,
  inProgressLabel,
  onSuccess,
}): ReactElement => {
  const notify = useNotify();

  const handleSuccess = useCallback(async () => {
    notify(
      "The PDF generation has been requested. You will receive a notification when It's completed.",
      { type: 'success' }
    );
    return onSuccess?.();
  }, [notify, onSuccess]);

  const { bulkGeneratePdf, isBulkGeneratingPdf } =
    useBulkGeneratePdf(handleSuccess);

  const handleOnClick = useCallback(() => {
    bulkGeneratePdf();
  }, [bulkGeneratePdf]);

  return (
    <Button
      label={isBulkGeneratingPdf ? inProgressLabel : label}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick={handleOnClick}
      disabled={isBulkGeneratingPdf}
    />
  );
};

BulkGeneratePdfButton.defaultProps = {
  label: 'Generate PDFs',
  inProgressLabel: 'Generating PDFs...',
};

export default BulkGeneratePdfButton;
