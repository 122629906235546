import { RED, YELLOW } from '../../theme/colors';

export const statuses = [
  { id: 'NONE', name: 'None' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
];

export const legends = [
  {
    label: 'Status NONE',
    color: RED,
  },
  {
    label: 'Status IN PROGRESS',
    color: YELLOW,
  },
];
