import React, { ReactElement } from 'react';
import { SelectInput } from 'react-admin';
import { engineeringCodeChoices } from '../constants';
import commonStyles from '../../common/commonStyles';
import { required } from '../../../utils/UtilityFunctions';

export const SelectEngCodeInput: React.FC = (): ReactElement => {
  return (
    <SelectInput
      source="engineeringCode"
      choices={engineeringCodeChoices}
      validate={[required()]}
      sx={commonStyles.flexBoxItem}
    />
  );
};
