import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import { SecurityRole } from './types';

const SecurityRoleEdit: React.FC<EditPropsWithLocation<SecurityRole>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="Role" id="role_tab" sx={commonStyles.formTabHeader}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="role"
          label="Role"
          validate={[required(), maxLength(35)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_security_role" />
  </TabbedEditForm>
);

export default SecurityRoleEdit;
