import React, { ReactElement } from 'react';
import { FunctionField } from 'react-admin';
import { convertResourceKeyToName } from '../common/helpers/getResourceLists';

interface ResourceFieldProps {
  label?: string;
}

const ResourceField: React.FC<ResourceFieldProps> = ({
  label,
}): ReactElement => (
  <FunctionField
    label={label ?? ''}
    render={(permission) => convertResourceKeyToName(permission.resourceName)}
  />
);

export default ResourceField;
