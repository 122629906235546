import { DateField, Labeled, TextField } from 'react-admin';
import commonStyles from '../commonStyles';

const renderShowMetaDataFields = () =>
  [
    <Labeled key="date" label="Updated At" sx={commonStyles.flexBoxItem}>
      <DateField source="updatedAt" />
    </Labeled>,
    <Labeled key="updateBy" label="Updated By" sx={commonStyles.flexBoxItem}>
      <TextField source="updatedBy" />
    </Labeled>,
    <Labeled
      key="updatedByName"
      label="Updated By Name"
      sx={commonStyles.flexBoxItem}
    >
      <TextField source="updatedByName" />
    </Labeled>,
  ].map((i) => i);

export default renderShowMetaDataFields;
