import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  Edit,
  EditProps,
  ValidateForm,
  TabbedForm,
  TabbedFormTabs,
} from 'react-admin';
import useAutoSaveEnabled from './hooks/useAutoSaveEnabled';
import EditTitle from './EditTitle';
import FooterToolbar, { FooterToolbarProps } from './FooterToolbar';
import commonStyles from './commonStyles';
import useInvalidateResourceQueryCache from './hooks/useInvalidateResourceQueryCache';
import DataProviderErrorsWrapper from '../DataProviderErrors/DataProviderErrorsWrapper';

type TabbedFormProps = Omit<EditProps, 'autoSave'> & {
  children: ReactElement[] | ReactElement;
  syncWithLocation?: boolean;
  footerToolbarProps?: FooterToolbarProps;
  validate?: ValidateForm;
  autoSave?: boolean | string;
  onAutoSaveSuccess?: () => void;
  SaveButtonComponent?: ReactNode;
};

const TabbedEditForm: React.FC<TabbedFormProps> = ({
  title,
  children,
  syncWithLocation = true,
  footerToolbarProps,
  validate,
  mutationOptions,
  onAutoSaveSuccess,
  SaveButtonComponent,
  ...rest
}) => {
  const [isAutoSaveEnabled] = useAutoSaveEnabled();
  const { autoSave, ...restProps } = rest;
  const autoSaveEnabled = Boolean(
    autoSave === undefined ? isAutoSaveEnabled : autoSave
  );

  const invalidateQueries = useInvalidateResourceQueryCache(undefined, true);

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <DataProviderErrorsWrapper action={'getOne'} ignoreCodes={[400, 404, 500]}>
      <Edit
        {...restProps}
        mutationOptions={{
          ...mutationOptions,
          onSettled: clearCache,
        }}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle title={title as string} />}
      >
        <TabbedForm
          tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
          toolbar={
            <FooterToolbar
              autoSave={autoSaveEnabled}
              syncWithLocation={syncWithLocation}
              onAutoSaveSuccess={onAutoSaveSuccess}
              SaveButtonComponent={SaveButtonComponent}
              {...footerToolbarProps}
            />
          }
          resetOptions={{ keepDirtyValues: autoSaveEnabled }}
          style={{ width: '100%' }}
          sx={commonStyles.tabbedFormBoldHeader}
          syncWithLocation={syncWithLocation}
          validate={validate}
        >
          {children}
        </TabbedForm>
      </Edit>
    </DataProviderErrorsWrapper>
  );
};

export default TabbedEditForm;
