import { DateField, TextField } from 'react-admin';

const renderMetaDataFields = (showTime?: boolean) =>
  [
    <DateField
      key="date"
      source="updatedAt"
      label="Updated At"
      showTime={showTime}
    />,
    <TextField key="updateBy" source="updatedBy" label="Updated By" />,
    <TextField
      key="updatedByName"
      source="updatedByName"
      label="Updated By Name"
    />,
  ].map((i) => i);

export default renderMetaDataFields;
