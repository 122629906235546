import React, { ReactElement } from 'react';
import { FilterList, FilterListItem, useListContext } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import commonStyles from '../common/commonStyles';
import { categoryChoices } from './constants';
import isEmpty from 'lodash/isEmpty';

const GroupFilterSidebar: React.FC = (): ReactElement => {
  const { data, isLoading, filterValues } = useListContext();

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={commonStyles.filterSidebar}>
      <CardContent>
        <FilterList label="Category" icon={<CategoryIcon />}>
          {categoryChoices.map((categoryChoice) => (
            <FilterListItem
              key={categoryChoice.id}
              label={categoryChoice.name}
              value={{ category: categoryChoice.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};
export default GroupFilterSidebar;
