import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import DisciplineInput from '../common/DisciplineInput';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { Position } from './types';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';

const PositionEdit: React.FC<EditPropsWithLocation<Position>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Position"
      id="position_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="position"
            label="Position"
            validate={[maxLength(55)]}
            sx={commonStyles.flexBoxItem}
            required
          />
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
            multiline
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <DisciplineInput />
        </Box>
        <MetaDataInputs />
      </Box>
    </LazyFormTab>
    <HistoryTab tableName="tbl_position" />
  </TabbedEditForm>
);

export default PositionEdit;
