import React, { ReactElement } from 'react';
import { MenuItemLink } from 'react-admin';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import commonStyles from '../common/commonStyles';
import { MenuItemProps } from './constants';

const MenuItems: React.FC<MenuItemProps> = ({ menuItems }): ReactElement => {
  return menuItems.map((menuItem) => {
    if (
      !menuItem.hasOwnProperty('items') &&
      !menuItem.hasOwnProperty('resource')
    ) {
      return (
        <MultiLevelMenu.Item
          name={menuItem.name}
          to={menuItem.to ? menuItem.to : ''}
          key={menuItem.label}
          label={menuItem.label}
          icon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
        />
      );
    }
    if (menuItem.hasOwnProperty('resource')) {
      return (
        <MenuItemLink
          key={menuItem.resource}
          primaryText={menuItem.label}
          to={menuItem.to ? menuItem.to : ''}
          leftIcon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
        />
      );
    }
    if (menuItem.hasOwnProperty('items') && !!menuItem.items.length) {
      return (
        <MultiLevelMenu.Item
          key={menuItem.label}
          name={menuItem.name}
          label={menuItem.label}
          icon={menuItem.icon ? menuItem.icon : null}
          to={menuItem.to ? menuItem.to : ''}
          id={menuItem.id ? menuItem.id : null}
          sx={commonStyles.sideMenuMultiLevelItem}
        >
          <MenuItems menuItems={menuItem.items} />
        </MultiLevelMenu.Item>
      );
    }
  });
};

export default MenuItems;
