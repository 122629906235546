import React, { ReactElement } from 'react';
import { DateField, TextField, useStore } from 'react-admin';
import ComponentActivitySideFilters from './components/ComponentActivitySideFilters';
import CertStatusWithColorDotField from '../common/CertStatusWithColorDotField';
import ActivityDurationUnitField from '../common/ActivityDurationUnitField';
import GenerateCertificateButton from '../common/GenerateCertificateButton';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ActivityConstraintField from '../common/ActivityConstraintField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import BulkActionButtons from './components/BulkActionButtons';
import ActivityPhaseField from '../common/ActivityPhaseField';
import AssignedField from '../common/Assigned/AssignedField';
import ActivityTypeField from '../common/ActivityTypeField';
import CertTemplateField from '../common/CertTemplateField';
import ComponentActivityEdit from './ComponentActivityEdit';
import ComponentActivityShow from './ComponentActivityShow';
import { RESOURCE_COMPONENT_ACTIVITY } from '../constants';
import DisciplineField from '../common/DisciplineField';
import { activityCategory } from '../common/constants';
import ComponentField from '../common/ComponentField';
import ListWithTitle from '../common/ListWithTitle';
import ColumnActions from '../common/ColumnActions';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import ListActions from '../common/ListActions';
import SubsystemField from '../common/SubsystemField';
import SystemField from '../common/SystemField';

const ComponentActivityList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_COMPONENT_ACTIVITY);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      aside={
        showSideFilter ? (
          <ComponentActivitySideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'componentActivity', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ComponentActivityShow withoutActions />}
        EditComponent={<ComponentActivityEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'componentActivity',
          'componentId',
          'activityPhaseId',
          'activityTypeId',
          'certTemplateId',
          'certGatingStatus.certStatus',
          'actions',
        ]}
      >
        <TextField source="id" label="ID" />
        <CertStatusWithColorDotField />
        <TextField source="componentActivity" label="Activity" />
        <ComponentField />
        <TextField source="sequence" label="Sequence" />
        <SubsystemField
          source="component.subsystemId"
          textSource="component.subsystem.subsystem"
          sortBy="`component.subsystem.subsystem`"
        />
        <TextField
          source="component.subsystem.description"
          label="Subsystem Description"
          sortBy="`component.subsystem.description`"
        />
        <SystemField
          source="component.subsystem.systemId"
          textSource="component.subsystem.system.systemName"
          sortBy="`component.subsystem.system.systemName`"
        />
        <ActivityPhaseField />
        <DisciplineField />
        <ActivityTypeField />
        <TextField source="description" label="Description" />
        <ActivityConstraintField />
        <TextField source="duration" label="Duration" />
        <TextField source="actualDuration" label="Actual Duration" />
        <ActivityDurationUnitField />
        <DateField source="plannedDate" label="Planned Date" />
        <DateField source="actualDate" label="Actual Date" />
        <CertTemplateField />
        <TextField
          source="certDetails.certTotalSignatures"
          label="Certificate Total Signatures"
          sortable={false}
        />
        <TextField
          source="certDetails.certSigned"
          label="Certificate Signed"
          sortable={false}
        />
        <TextField
          source="certDetails.certSignedBy"
          label="Certificate Signed By"
          sortable={false}
        />
        <AssignedField
          sourcePrefix="certDetails"
          label="Certificate Responsible"
          sortable={false}
        />
        <TextField
          source="certGatingStatus.itrStatus"
          label="ITR Status"
          sortBy="certGatingStatus.itr_status"
        />
        <TextField
          source="certGatingStatus.punchStatus"
          label="Punch Status"
          sortBy="certGatingStatus.punch_status"
        />
        <TextField
          source="certGatingStatus.certificateStatus"
          label="Predecessor Certificate Status"
          sortBy="certGatingStatus.certificate_status"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <GenerateCertificateButton
            activityCategory={activityCategory.component}
          />
          <PreviewButton />
          <DownloadButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ComponentActivityList;
