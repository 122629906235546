import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import {
  isStringControl,
  RankedTester,
  rankWith,
  ControlProps,
} from '@jsonforms/core';
import { useFormContext } from 'react-hook-form';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Unwrapped } from '@jsonforms/material-renderers';
import { ResettableTextField } from 'ra-ui-materialui';
import { Grid } from '@mui/material';
import { getContext, UserContext } from '../../../../provider/userContext';
import StepAction from './components/StepAction';
import SignatureField from '../SignatureField';
import {
  isImage,
  isSignatureField,
  isPrintNameField,
} from '../../../../utils/UtilityFunctions';

const { MaterialTextControl } = Unwrapped;

export const TextControl: React.FC<ControlProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const formContext = useFormContext();
  const formData = formContext?.watch('formData');
  const [stepAction, setStepAction] = useState({});
  const isSignature = isSignatureField(props.path);
  const isPrintName = isPrintNameField(props.path);
  const [value, setValue] = useState('');
  const isValid = props?.errors?.length === 0;

  const handleOnClick = useCallback(
    (event) => {
      if (!props.enabled) return;
      if (isImage(value)) return;
      if (isSignature) {
        const isClearHandle = event?.target?.closest(
          'button[aria-label="Clear value"]'
        );
        props.handleChange(props.path, isClearHandle ? '' : ctx?.fullName);
      }
      if (isPrintName && !props.data) {
        props.handleChange(props.path, ctx?.fullName);
      }
    },
    [props, ctx, isSignature, isPrintName, value]
  );

  useEffect(() => {
    if (formData?.stepActions?.[props.path]) {
      setStepAction(formData.stepActions[props.path]);
    }
  }, [formData, setStepAction, props]);

  useEffect(() => {
    setValue(props.data);
  }, [props]);

  const renderContent = () => {
    if (props.enabled && isSignature) {
      if (isImage(value)) {
        return (
          <SignatureField
            value={value}
            label={props.label}
            onConfirm={setValue}
          />
        );
      }

      const errorStyle = { '& > *': { color: '#d32f2f!important' } };
      return (
        <ResettableTextField
          id={props.id}
          label={props.label}
          required={props.required}
          onChange={() => {}}
          variant="filled"
          value={value}
          resettable
          fullWidth
          sx={{ marginBottom: '5px', ...(!isValid ? errorStyle : {}) }}
          helperText={!isValid ? 'is a required property' : ''}
        />
      );
    } else {
      return <MaterialTextControl {...props} />;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <span onClick={handleOnClick}>{renderContent()}</span>
        {stepAction && <StepAction data={stepAction} />}
      </Grid>
    </Grid>
  );
};

export const TextControlTester: RankedTester = rankWith(2, isStringControl);

export default withJsonFormsControlProps(TextControl);
