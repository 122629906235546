import axios from 'axios';
import { Identifier } from 'react-admin';
import {
  BOOK_GEN_API,
  ContentType,
  CRUD_API_URL,
  getRequestConfig,
} from '../../api';

export interface QrUploadData {
  file: File;
  fileName: string;
  projectId: Identifier;
  numOfParts?: number;
  partIndex?: number;
  uploadId?: number;
}

interface QrUploadParams {
  qrData: QrUploadData;
  signal?: AbortSignal;
}

export const getQrScannerDataProvider = () => ({
  uploadQrPdfFiles(resource: string, params: QrUploadParams) {
    return axios
      .post(`${BOOK_GEN_API}/api/qr-code/start`, params.qrData, {
        ...getRequestConfig(ContentType.formData),
        signal: params.signal,
      })
      .catch((error) => {
        if (error?.code !== 'ERR_CANCELED') {
          throw error;
        }
      });
  },
  getFilesScanStatusByIds(resource: string, jobIds: Identifier[]) {
    return axios.post(
      encodeURI(
        `${BOOK_GEN_API}/api/qr-code/status?jobIds=${jobIds.join('&jobIds=')}`
      ),
      '',
      getRequestConfig()
    );
  },
  getFilesScanStatus() {
    return axios.get(
      encodeURI(`${BOOK_GEN_API}/api/qr-code/status`),
      getRequestConfig()
    );
  },
  startProcess(
    resource: string,
    data: { attachmentId: Identifier; revisionIds: Identifier[] }
  ) {
    return axios.post(
      `${CRUD_API_URL}/${resource}/process`,
      data,
      getRequestConfig()
    );
  },
  deleteScanItem(resource: string, id: Identifier) {
    return axios.delete(
      `${CRUD_API_URL}/${resource}/qr-scan-item/${id}`,
      getRequestConfig()
    );
  },
});
