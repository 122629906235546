import { useCallback } from 'react';
import { useGetList } from 'react-admin';
import { RESOURCE_AUDIT_TABLE_NAME } from '../../constants';

const useAuditTableName = () => {
  const { data: auditTables } = useGetList(RESOURCE_AUDIT_TABLE_NAME);
  return useCallback(
    (table: string) => {
      return (auditTables || []).find(({ id }) => id === table)?.name;
    },
    [auditTables]
  );
};
export default useAuditTableName;
