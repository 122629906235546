import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_SUBSYSTEM } from '../constants';
import { required } from '../../utils/UtilityFunctions';

interface SubsystemInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
}

const SubsystemInput: React.FC<SubsystemInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'subsystem', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_SUBSYSTEM}
      requestParams={requestParams}
      name="subsystem_inc"
      optionText="subsystem"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'subsystem']}
    />
  );
};

SubsystemInput.defaultProps = { source: 'subsystemId', label: 'Subsystem' };

export default SubsystemInput;
