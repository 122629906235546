import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import SmartLinkField from './SmartLinkField';

interface SubSystemActivityFieldProps {
  resource: string;
  activitySource: string;
  label?: string;
  source?: string;
  sx?: SxProps<Theme>;
}

const ActivityField: React.FC<SubSystemActivityFieldProps> = ({
  resource,
  activitySource,
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={resource}
    sortBy={activitySource}
  >
    <TextField source={activitySource} sx={sx} />
  </SmartLinkField>
);

ActivityField.defaultProps = {
  label: 'Activity',
  source: 'id',
};

export default ActivityField;
