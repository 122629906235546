import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PUNCH } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PunchItemFieldProps {
  label?: string;
  source?: string;
  sx?: object;
}

const PunchField: React.FC<PunchItemFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_PUNCH}
    sortBy="punchNo"
  >
    <TextField source="punchNo" sx={sx} />
  </SmartLinkField>
);

PunchField.defaultProps = {
  label: 'Punch No',
  source: 'punchId',
};

export default PunchField;
