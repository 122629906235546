import { getContext, UserContext } from '../../provider/userContext';
import { useCreate, useCreateSuggestionContext, useRefresh } from 'react-admin';
import React, { ReactElement, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';

interface CreateAutoCompleteCategoryDialogProps {
  reference: string;
  keyValue: string;
}

const CreateAutoCompleteCategoryDialog: React.FC<
  CreateAutoCompleteCategoryDialogProps
> = ({ reference, keyValue }): ReactElement => {
  const ctx: UserContext = getContext();
  const refresh = useRefresh();
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || '');

  const handleCategoryNameChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const data = { projectId: ctx?.projectId };
      data[keyValue] = value;
      create(
        reference,
        { data },
        {
          onSuccess: (data) => {
            setValue('');
            onCreate(data);
            refresh();
          },
        }
      );
    },
    [create, ctx?.projectId, keyValue, onCreate, reference, refresh, value]
  );

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New Category"
            value={value}
            onChange={handleCategoryNameChange}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateAutoCompleteCategoryDialog;
