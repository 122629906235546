import React from 'react';
import { ShowInDialogButton } from '@react-admin/ra-form-layout';
import CommentShowTemplate from './CommentShowTemplate';

const CommentShowInDialog: React.FC = () => {
  return (
    <ShowInDialogButton
      inline
      fullWidth
      maxWidth="md"
      ButtonProps={{ className: 'showButton' }}
    >
      <CommentShowTemplate />
    </ShowInDialogButton>
  );
};

export default CommentShowInDialog;
