import React, { ReactElement } from 'react';
import { maxLength, SelectInput } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import CustomDateInput from '../common/CustomDateInput';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { Group } from './types';
import { categoryChoices } from './constants';
import { EditPropsWithLocation } from '../common/types';
import ContactsTabEdit from './components/groupContactFormTab/ContactsTabEdit';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const GroupEdit: React.FC<EditPropsWithLocation<Group>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="Group" id="group_tab" sx={commonStyles.formTabHeader}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="groupName"
          label="Group"
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(255)}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <SelectInput
          source="category"
          label="Category"
          choices={categoryChoices}
          validate={required()}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="updatedAt"
          label="Updated at"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="updatedBy"
          label="Updated by"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="updatedByName"
          label="Updated by name"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
    </LazyFormTab>
    <ContactsTabEdit />
    <HistoryTab tableName="tbl_group" />
  </TabbedEditForm>
);

export default GroupEdit;
