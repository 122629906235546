import { RESOURCE_SUBMIT_PUNCH } from '../constants';

export enum Originator {
  Standalone = 'STANDALONE',
  ITR = 'ITR',
  Preservation = 'PRESERVATION',
}

export const originators = [
  { id: Originator.Standalone, name: 'Standalone' },
  { id: Originator.ITR, name: 'ITR' },
  { id: Originator.Preservation, name: 'Preservation' },
];

export const SUBMIT_BUTTON_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'component',
    resource: RESOURCE_SUBMIT_PUNCH,
  },
  {
    label: 'Export All',
    fileName: 'component_all',
    includeAllColumns: true,
    resource: RESOURCE_SUBMIT_PUNCH,
  },
];

export const getFilterStatuses = (defaultValue: string[]) => [
  { id: defaultValue, name: 'All' },
  { id: 'NONE', name: 'None' },
  { id: 'REJECTED', name: 'Rejected' },
];
