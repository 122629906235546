import React, { Fragment, ReactElement } from 'react';
import VendorTabList from './VendorTabList';
import VendorTabAdd from './VendorTabAdd';

const VendorTab: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <VendorTabList />
      <VendorTabAdd />
    </Fragment>
  );
};

export default VendorTab;
