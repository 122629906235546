import { regex, maxLength } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';

export const getPunchNoValidation = (
  editMode: boolean,
  bulkEditMode: boolean
) => {
  const validation = [
    maxLength(50),
    regex(
      /^[a-zA-Z0-9]+([-_.\/#]?[a-zA-Z0-9]+){0,2}$/,
      'Should contain only numbers, letters and characters " - _ # / . " (e.g. "PL", "PL-001", "PL.2-004" )'
    ),
  ];
  if (editMode && !bulkEditMode) {
    validation.push(required());
  }
  return validation;
};
