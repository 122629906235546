export enum CompletedType {
  ISSUED = 'ISSUED',
  YES = 'YES',
  NO = 'NO',
  NA = 'NA',
}

export const completedChoices = [
  { id: CompletedType.ISSUED, name: 'Issued' },
  { id: CompletedType.YES, name: 'Completed' },
  { id: CompletedType.NO, name: 'Not Completed' },
  { id: CompletedType.NA, name: 'Not Required' },
];

export const FORCE_DELETE_CONFIRMATION_MESSAGE =
  'You want to delete the Preservation Item Activity currently in progress. Are you sure?';

export const FORCE_DELETE_SUCCESS_MESSAGE =
  'Preservation Item Activity deleted';
