import { Box } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import {
  useListContext,
  useResourceContext,
  useUnselectAll,
} from 'react-admin';
import { isUserAdminLevel } from '../../common/helpers/checkSecurityLevel';
import RestoreButton from './RestoreButton';

const BulkActionButtons: React.FC = (): ReactElement => {
  const unselectAll = useUnselectAll(useResourceContext());

  const { selectedIds, data } = useListContext();
  const selectedAudit = useMemo(
    () => data.filter(({ id }) => selectedIds.includes(id)),
    [data, selectedIds]
  );

  return (
    <Box>
      {isUserAdminLevel() && (
        <RestoreButton audit={selectedAudit} onComplete={unselectAll} />
      )}
    </Box>
  );
};

export default BulkActionButtons;
