import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import AuditListTab from '../audit/AuditListTab';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import commonStyles from '../common/commonStyles';

const SecurityRoleShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Role">
        <Labeled label="Role">
          <TextField source="role" />
        </Labeled>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_security_role" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default SecurityRoleShow;
