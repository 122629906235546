import { Datagrid, TextField } from 'react-admin';
import React, { ReactElement, Fragment } from 'react';
import { Subsystem } from '../../types';
import { RESOURCE_SUBSYSTEM } from '../../../constants';
import FreeTextSearchFilter from '../../../common/FreeTextSearchFilter';
import { UserContext, getContext } from '../../../../provider/userContext';
import SubsystemSelectButton from './SubsystemSelectButton';
import { ListWithPagination } from '../../../common/ListWithPagination';

interface SubsystemSelectionProps {
  onSelectSubsystems: (subsystem: Subsystem[]) => void;
}

export const SubsystemSelection: React.FC<SubsystemSelectionProps> = ({
  onSelectSubsystems: onSelectSubsystemItems,
}): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <ListWithPagination
      title={<Fragment />}
      resource={RESOURCE_SUBSYSTEM}
      filter={{
        projectId: ctx.projectId,
      }}
      actions={<FreeTextSearchFilter />}
      sx={{
        '& .RaFilter-form': { flex: '1 0 auto' },
      }}
    >
      <Datagrid
        bulkActionButtons={
          <SubsystemSelectButton onSelect={onSelectSubsystemItems} />
        }
        rowClick={false}
      >
        <TextField source="system.systemName" label="System" sortable={false} />
        <TextField source="subsystem" label="Subsystem" sortable={false} />
        <TextField source="description" label="Description" sortable={false} />
      </Datagrid>
    </ListWithPagination>
  );
};

export default SubsystemSelection;
