import { Fade, styled, Typography, TypographyProps } from '@mui/material';
import { UseAutoSaveParams, useAutoSave } from '@react-admin/ra-form-layout';
import React, { ReactElement, useState } from 'react';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import useInvalidateResourceQueryCache from './hooks/useInvalidateResourceQueryCache';

type AutoSaveProps = UseAutoSaveParams & {
  typographyProps?: TypographyProps;
};

// https://marmelab.com/react-admin/AutoSave.html
// Library component cannot accept 'onSuccess'

const AutoSave: React.FC<AutoSaveProps> = (props): ReactElement => {
  const {
    debounce = 3000,
    onSuccess,
    onError,
    transform,
    typographyProps,
  } = props;
  const [lastSaveAt, setLastSaveAt] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);
  const invalidateQueries = useInvalidateResourceQueryCache(undefined, true);

  const isSaving = useAutoSave({
    debounce,
    transform,
    onSuccess: async (response, variables, context) => {
      setLastSaveAt(new Date());
      setError(null);

      await invalidateQueries();

      if (onSuccess) {
        onSuccess(response, variables, context);
      }
    },
    onError: (error, variables, context) => {
      setError(getErrorMessage(error));

      if (onError) {
        onError(error, variables, context);
      }
    },
  });

  if (error) {
    return (
      <Root
        color="error"
        className={AutoSaveClasses.error}
        {...typographyProps}
      >
        Server error, changes are not saved: {error}
      </Root>
    );
  }

  if (isSaving) {
    return (
      <Root color="text.secondary" {...typographyProps}>
        Saving...
      </Root>
    );
  }

  return (
    <Fade in={!!lastSaveAt}>
      <Root color="text.secondary" {...typographyProps}>
        All changes saved
      </Root>
    </Fade>
  );
};

export default AutoSave;

const PREFIX = 'RaAutoSave';

export const AutoSaveClasses = {
  error: `${PREFIX}-error`,
};

const Root = styled(Typography, {
  name: PREFIX,
  overridesResolver: (_props, styles) => styles.root,
})(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  [`&.${AutoSaveClasses.error}`]: {},
}));
