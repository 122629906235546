import { useNotify } from 'react-admin';
import { FileRejection, ErrorCode } from 'react-dropzone';
import { formatBytes } from '../helpers/formatBytes';
import { useCallback } from 'react';

interface Props {
  maxSize?: number;
  minSize?: number;
  accept?: Record<string, string[]>;
  multiple?: boolean;
  maxFiles?: number;
}

export const useNotifyFileDropErrors = (props: Props) => {
  const { accept, maxSize, minSize, multiple, maxFiles } = props;
  const notify = useNotify();
  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if ((rejectedFiles?.length && !multiple) || (multiple && maxFiles)) {
        const exeededFileLimit = rejectedFiles.filter((fileRejection) =>
          fileRejection.errors.some(
            (error) => error.code === ErrorCode.TooManyFiles
          )
        );

        if (exeededFileLimit.length) {
          const message = multiple
            ? `You can upload up to ${maxFiles} files`
            : `You can only upload 1 file`;

          notify(message, {
            type: 'error',
          });
          return;
        }

        const exeededSizeLimit = rejectedFiles.filter((detail) =>
          detail.errors.some((error) => error.code === ErrorCode.FileTooLarge)
        );
        const tooSmallFiles = rejectedFiles.filter((detail) =>
          detail.errors.some((error) => error.code === ErrorCode.FileTooSmall)
        );
        const invalidTypeFiles = rejectedFiles.filter((detail) =>
          detail.errors.some(
            (error) => error.code === ErrorCode.FileInvalidType
          )
        );

        exeededSizeLimit.forEach((fileRejection) => {
          notify(
            `${fileRejection.file.name} is too large, max size - ${formatBytes(maxSize)}`,
            { type: 'error' }
          );
        });
        tooSmallFiles.forEach((fileRejection) => {
          notify(
            `${fileRejection.file.name} is too small, min size - ${formatBytes(minSize)}`,
            { type: 'error' }
          );
        });
        invalidTypeFiles.forEach((fileRejection) => {
          const acceptedTypes = Array.isArray(accept)
            ? accept.join(', ')
            : accept;
          notify(
            `${fileRejection.file.name} is not accepted, upload only ${acceptedTypes} files`,
            { type: 'error' }
          );
        });
      }
    },
    [accept, maxFiles, maxSize, minSize, multiple, notify]
  );

  return handleDrop;
};
