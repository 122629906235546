import React, { ReactElement } from 'react';
import { CreateButton } from 'react-admin';
import { Box, Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import { LinkToBooks } from './LinkToBooks';

export const EmptyBookSections: React.FC = (): ReactElement => {
  return (
    <Box sx={{ flex: '1', margin: '15px' }}>
      <Box>
        <LinkToBooks />
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          opacity: 0.5,
          margin: '0px 1em',
          color: 'inherit',
        }}
      >
        <InboxIcon sx={{ height: '216px', width: '216px' }} />
        <Typography variant="h4" paragraph>
          No Book Section yet.
        </Typography>
        <Typography variant="body1">Do you want to add one?</Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '2em' }}>
        <CreateButton variant="contained" to="create" />
      </Box>
    </Box>
  );
};

export default EmptyBookSections;
