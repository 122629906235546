import axios from 'axios';
import { CRUD_API_URL, ContentType, getRequestConfig } from '../../api';

export const supportDataProvider = {
  createTicket: async (resource, ticketInfo: TicketInfo) => {
    const formData = new FormData();

    ticketInfo.attachments?.forEach((attachment) => {
      formData.append('files', attachment.rawFile);
    });
    delete ticketInfo.attachments;

    formData.append('json', JSON.stringify(ticketInfo));

    return axios.post(
      `${CRUD_API_URL}/${resource}`,
      formData,
      getRequestConfig(ContentType.formData)
    );
  },
};

export interface TicketInfo {
  page: string;
  message: string;
  attachments?: { rawFile: File }[];
}
