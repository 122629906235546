import React, { Fragment, ReactElement } from 'react';
import ComponentTabList from './ComponentTabList';
import ComponentTabAdd from './ComponentTabAdd';

interface ComponentTabProps {
  targetId?: string;
}

const ComponentTab: React.FC<ComponentTabProps> = (): ReactElement => {
  return (
    <Fragment>
      <ComponentTabList />
      <ComponentTabAdd />
    </Fragment>
  );
};

export default ComponentTab;
