import { useState } from 'react';

export const useCompanySwitchModal = () => {
  const [companySwitchModalOpen, setCompanySwitchModalOpen] = useState(false);

  const setCompanySwitchModalState = (value) => {
    setCompanySwitchModalOpen(value);
  };

  return {
    companySwitchModalOpen,
    setCompanySwitchModalState,
  };
};
