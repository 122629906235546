import { useLayoutEffect, useState } from 'react';

export const useIframeHeight = () => {
  const [windowHeight, setWindowHeight] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  if (windowHeight > 600) {
    return 480;
  }

  if (windowHeight > 530) {
    return 400;
  }

  if (windowHeight > 470) {
    return 350;
  }

  return 300;
};
