import React, { ReactElement, useCallback, useMemo } from 'react';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Card, CardContent } from '@mui/material';
import { RESOURCE_ATTACHMENT } from '../../../constants';
import useSidebarFilters from '../../../common/hooks/useSidebarFilters';
import sideFilterStyles from '../../../common/sideFilterStyles';
import AttachmentResourceFilterInput from './AttachmentResourceFilterInput';
import { RESOURCE_CHOICES } from './constants';
import DateRangeFilter from '../../../common/DateRangeFilter';
import TextInput from '../../../common/TextInput';
import { SideFilter } from '../../../common/types';
import AttachmentTypeInput from '../../../common/AttachmentTypeInput';

const AdminAttachmentFilterSidebar: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    setFilters,
    displayedFilters,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_ATTACHMENT, defaultFilter);

  const attachmentResourceFilterValue = useMemo(() => {
    let resource = null;
    let resourceId = null;
    const resourcePossibleOptions = RESOURCE_CHOICES.map((choice) => choice.id);
    const filterOptions = Object.keys(filterValues);

    for (let i = 0; i < resourcePossibleOptions.length; i++) {
      const resourceOption = resourcePossibleOptions[i];
      if (filterOptions.includes(resourceOption)) {
        resource = resourceOption;
        resourceId = filterValues[resourceOption];
        break;
      }
    }

    return { resource, resourceId };
  }, [filterValues]);

  const handleAttachmentResourceChange = useCallback(
    (resource: string, resourceId: string) => {
      setFilters(
        {
          ...(attachmentResourceFilterValue
            ? omit(filterValues, attachmentResourceFilterValue.resource)
            : filterValues),
          [resource]: resourceId,
        },
        displayedFilters
      );
    },
    [attachmentResourceFilterValue, displayedFilters, filterValues, setFilters]
  );

  const handleClearButtonClick = useCallback(() => {
    handleClearFilters();
  }, [handleClearFilters]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="File Name"
            name="fileName_inc"
            source="fileName"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Description"
            name="description_inc"
            source="description"
            fullWidth
            alwaysOn
          />
          <AttachmentTypeInput alwaysOn fullWidth />
          <AttachmentResourceFilterInput
            onChange={handleAttachmentResourceChange}
            value={attachmentResourceFilterValue}
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearButtonClick} />
      </CardContent>
    </Card>
  );
};

export default AdminAttachmentFilterSidebar;
