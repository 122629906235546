import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { WorkPack } from './types';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const WorkPackEdit: React.FC<EditPropsWithLocation<WorkPack>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Work Pack"
      id="work_pack_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="workPack"
          label="Work Pack"
          validate={[required(), maxLength(150)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(255)}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_work_pack" />
  </TabbedEditForm>
);

export default WorkPackEdit;
