import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { LucidDiagram } from '../type';
import { lucidDiagramAuthProvider as authProvider } from '../../../provider/authProvider/providers/lucidDiagramAuthProvider';
import LucidDiagramSelectConfirmation from './LucidDiagramSelectConfirmation';
import LucidDiagramRequiredFieldMessage from './LucidDiagramRequiredFieldMessage';
import './styles.css';
import { lucidDocumentPickerUrl } from '../constants';

const LucidEmbedSelect: React.FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [embedToken, setEmbedToken] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { setValue, getValues } = useFormContext<LucidDiagram>();
  const documentId = getValues('documentId');

  const onDocumentSelect = useCallback(
    ({ data }) => {
      if (
        data &&
        data.type === 'LucidEmbedEvent' &&
        data.event === 'EmbedCreated'
      ) {
        setValue('documentId', data.documentId);
        setValue('embedId', data.embedId);
        setShowConfirmation(true);
      }
    },
    [setValue, setShowConfirmation]
  );

  useEffect(() => {
    window.addEventListener('message', onDocumentSelect);
    return () => {
      window.removeEventListener('message', onDocumentSelect);
    };
  }, [onDocumentSelect]);

  useEffect(() => {
    authProvider
      .requestEmbedToken()
      .then((embedToken) => {
        setEmbedToken(embedToken);
        setIsLoading(false);
      })
      .catch(() => {
        authProvider.requestAccess();
      });
  }, [setIsLoading, setEmbedToken]);

  const url = useMemo(
    () => (isLoading ? '' : lucidDocumentPickerUrl(embedToken)),
    [isLoading, embedToken]
  );

  const closeConfirmation = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  return (
    <Fragment>
      <iframe className="lucid-embed lucid-embed--create" src={url} />
      <LucidDiagramRequiredFieldMessage />
      <LucidDiagramSelectConfirmation
        open={showConfirmation}
        onClose={closeConfirmation}
        documentId={documentId}
      />
    </Fragment>
  );
};

export default LucidEmbedSelect;
