import React, { ReactElement, useMemo } from 'react';
import { useSimpleFormIteratorItem } from 'react-admin';
import { useWatch } from 'react-hook-form';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_USER_GROUP } from '../../constants';

interface UserGroupInputProps {
  label: string;
  source: string;
  readOnly?: boolean;
}

const UserGroupInput: React.FC<UserGroupInputProps> = ({
  label,
  source,
  readOnly,
}): ReactElement => {
  const { index } = useSimpleFormIteratorItem();
  const ctx: UserContext = getContext();
  const projectList = useWatch({
    name: `companies.${ctx?.company}.projects`,
  });
  const requestParams = useMemo(() => {
    return {
      filter: { projectId: projectList?.[index]?.id || 0 },
      sort: { field: 'userGroup', order: 'ASC' as const },
    };
  }, [projectList, index]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_USER_GROUP}
      requestParams={requestParams}
      source={source}
      label={label}
      name="userGroup_inc"
      optionText="userGroup"
      multiple
      sx={commonStyles.flexBoxSelectItem}
      readOnly={!projectList?.[index]?.id || readOnly}
      includeFields={['id', 'userGroup']}
    />
  );
};

export default UserGroupInput;
