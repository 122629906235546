import { Box } from '@mui/material';
import { useRecordContext, NumberInput, BooleanInput } from 'react-admin';
import CustomImageInput from '../../common/CustomImageInput';
import commonStyles from '../../../components/common/commonStyles';
import { CONFIGURATION_TYPE } from '../constants';
import { Configuration } from '../types';
import TextInput from '../../../components/common/TextInput';
import { required } from '../../../utils/UtilityFunctions';

const ConfigurationEditForm = () => {
  const record = useRecordContext<Configuration>();

  switch (record?.cfgValueType) {
    case CONFIGURATION_TYPE.FILE:
      return (
        <Box sx={commonStyles.createBox}>
          <CustomImageInput label="Value" source="file" isHideRemoveButton />
        </Box>
      );
    case CONFIGURATION_TYPE.NUMBER:
      return (
        <Box sx={commonStyles.flexBox}>
          <NumberInput label="Value" source="cfgValue" />
        </Box>
      );
    case CONFIGURATION_TYPE.TEXT:
      return (
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="cfgValue"
            label="Value"
            validate={[required()]}
            sx={commonStyles.flexBoxItem}
            multiline
          />
        </Box>
      );
    case CONFIGURATION_TYPE.STRING:
      return (
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="cfgValue"
            label="Value"
            validate={[required()]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
      );
    case CONFIGURATION_TYPE.BOOLEAN:
      return (
        <Box sx={commonStyles.flexBox}>
          <BooleanInput
            source="cfgValue"
            label="Value"
            sx={commonStyles.flexBoxItem}
          />
        </Box>
      );
    default:
      return null;
  }
};

export default ConfigurationEditForm;
