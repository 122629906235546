import axios from 'axios';
import { Identifier } from 'react-admin';
import { Attachment } from '../../../components/attachment/types';
import { Subsystem } from '../../../components/bookGeneration/types';
import { BOOK_GEN_API, getRequestConfig } from '../../api';

export interface BookGenerationParams {
  companyName?: string;
  bookId: Identifier;
  projectId?: Identifier;
  userId?: Identifier;
  userName?: string;
  subSystems: Subsystem[];
  sendEmail: boolean;
  outputType: string;
  bookType: string;
  testSortOrder: string;
  bookAttachments?: Attachment[];
}

export const getBookGenerationDataProvider = () => ({
  startBookGeneration(resource: string, params: BookGenerationParams) {
    return axios.post(
      `${BOOK_GEN_API}/api/book-gen/start`,
      params,
      getRequestConfig()
    );
  },
  getInitialStatus() {
    return axios.get(
      encodeURI(`${BOOK_GEN_API}/api/book-gen/status`),
      getRequestConfig()
    );
  },
  getStatus(resource: string, jobIds: Identifier[]) {
    return axios.get(
      encodeURI(
        `${BOOK_GEN_API}/api/book-gen/status?jobIds=${jobIds.join('&jobIds=')}`
      ),
      getRequestConfig()
    );
  },
});
