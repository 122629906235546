import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput, GetListParams } from 'react-admin';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_TEST } from '../constants';
import { required } from '../../utils/UtilityFunctions';

interface TestInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  sx?: object;
  disabled?: boolean;
  source?: string;
  label?: string;
  name?: string;
  requestParams?: Partial<GetListParams>;
  optionText?: string;
  detailText?: string;
}

const TestInput: React.FC<TestInputProps> = ({
  isRequired,
  requestParams,
  optionText,
  detailText,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const customRequestParams = useMemo<Partial<GetListParams>>(() => {
    return {
      filter: { divisionId: ctx?.defaultDivisionId },
      sort: { field: 'testFormNo', order: 'ASC' as const },
    };
  }, [ctx]);
  const optionRenderer = (option) =>
    detailText
      ? `${option[optionText]} - ${option[detailText]}`
      : `${option[optionText]}`;

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_TEST}
      requestParams={requestParams || customRequestParams}
      optionText={optionRenderer}
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'testName', 'testFormNo']}
      additionalParams={{ skipListAdditionalData: true }}
    />
  );
};

TestInput.defaultProps = {
  source: 'testId',
  label: 'Test',
  optionText: 'testName',
};

export default TestInput;
