import { TextField } from 'react-admin';
import { RESOURCE_TEST } from '../constants';
import SmartLinkField from './SmartLinkField';
import React, { ReactElement } from 'react';

interface TestFormNoFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}

const TestFormNoField: React.FC<TestFormNoFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_TEST}>
    <TextField source={textSource} />
  </SmartLinkField>
);

TestFormNoField.defaultProps = {
  label: 'Test Form No',
  source: 'testId',
  textSource: 'test.testFormNo',
  sortBy: '`test.testFormNo`',
};

export default TestFormNoField;
