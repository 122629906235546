import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import { EditPropsWithLocation } from '../common/types';
import { LucidDiagram } from './type';
import React, { ReactElement } from 'react';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import { BooleanInput, maxLength } from 'react-admin';
import LucidEmbedView from './components/LucidEmbedView';

const LucidDiagramEdit: React.FC<EditPropsWithLocation<LucidDiagram>> = (
  props
): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Lucid Diagram"
        id="lucid_diagram_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="title"
            label="Title"
            validate={[required(), maxLength(125)]}
            sx={commonStyles.flexBoxDescriptionItem}
          />
          <BooleanInput
            source="isForDashboard"
            label="Display on Dashboard"
            sx={commonStyles.rowFormInput}
          />
        </Box>
        <LucidEmbedView className="lucid-embed--edit" />
      </LazyFormTab>
    </TabbedEditForm>
  );
};

export default LucidDiagramEdit;
