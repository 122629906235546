import axios from 'axios';
import { TemplateType } from '../../../components/common/types';
import { CRUD_API_URL, getRequestConfig } from '../../api';

interface CopyTemplatesData {
  divisionId: number;
  templateIds: number[];
  templateType: TemplateType;
}

export const getTemplateDataProvider = () => ({
  copyTemplates: (resource: string, data: CopyTemplatesData) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/copy-templates`,
      data,
      getRequestConfig()
    );
  },
});
