import {
  useDataProvider,
  useListContext,
  useNotify,
  useResourceContext,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../utils/UtilityFunctions';

const useBulkGeneratePdf = (onSuccessCallback?: () => Promise<void>) => {
  const resource = useResourceContext();
  const { selectedIds, onUnselectItems } = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: bulkGeneratePdf, isPending: isBulkGeneratingPdf } =
    useMutation({
      mutationFn: () => dataProvider.bulkGeneratePdf(resource, selectedIds),
      onSuccess: async () => {
        onUnselectItems();
        if (onSuccessCallback) {
          await onSuccessCallback();
        }
      },
      onError: async (error: AxiosError) => {
        let errorMessage = error.message;
        if (error?.response?.data instanceof Blob) {
          errorMessage = await error?.response?.data.text();
          if (error?.response?.data.type === 'application/json') {
            errorMessage = JSON.parse(errorMessage);
          }
        }

        notify(getErrorMessage(errorMessage, 'PDF files generation failed!'), {
          type: 'error',
          undoable: false,
          multiLine: true,
        });
      },
    });

  return { bulkGeneratePdf, isBulkGeneratingPdf };
};

export default useBulkGeneratePdf;
