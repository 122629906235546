import React, { ReactElement } from 'react';
import {
  CreateButton,
  SelectColumnsButton,
  TopToolbar,
  useResourceContext,
  useStore,
} from 'react-admin';
import { Box } from '@mui/material';
import ExpandCollapseButton from '../smartTagITRReview/components/ExpandCollapseButton';
import useCanAccess from './hooks/useCanAccess';
import TopPaginationBar from './TopPaginationBar';
import FilterSidebarButton from './FilterSidebarButton';
import ImportButton from './ImportButton';
import MultipleExportButton from './MultipleExportButton';
import UpdateEffectivePermissionButton from './UpdateEffectivePermissionButton';
import { MultipleExportButtonInfo } from './types';
import commonStyles from './commonStyles';

type ShowAction = {
  import?: boolean;
  export?: boolean;
  effective?: boolean;
  create?: boolean;
  select?: boolean;
  expand?: boolean;
  collapse?: boolean;
  topPagination?: boolean;
};

interface ListActionsProps {
  sideFilter?: string;
  defaultFilter?: object;
  multipleExportButtonsInfo?: MultipleExportButtonInfo[];
  actions?: ReactElement[] | ReactElement;
  showAction?: ShowAction;
  preferenceKey?: string;
  additionalImportButton?: ReactElement;
  additionalExportButton?: ReactElement;
}

const defaultShowAction = {
  import: true,
  export: true,
  effective: false,
  create: true,
  select: true,
  expand: false,
  collapse: false,
  topPagination: true,
};

const ListActions: React.FC<ListActionsProps> = ({
  sideFilter,
  defaultFilter,
  multipleExportButtonsInfo,
  actions,
  showAction: propsShowAction,
  preferenceKey,
  additionalImportButton,
  additionalExportButton,
}): ReactElement => {
  const canAccessList = useCanAccess();
  const mergedShowAction = { ...defaultShowAction, ...propsShowAction };
  const showAction = {
    import: mergedShowAction.import && canAccessList.import,
    export: mergedShowAction.export && canAccessList.export,
    effective: mergedShowAction.effective,
    create: mergedShowAction.create && canAccessList.create,
    select: mergedShowAction.select,
    expand: mergedShowAction.expand,
    collapse: mergedShowAction.collapse,
    topPagination: mergedShowAction.topPagination,
  };
  const resourceContext = useResourceContext();
  const [listParams] = useStore(`${resourceContext}.listParams`);

  return (
    <TopToolbar sx={{ flex: '1 0 auto' }}>
      {showAction.topPagination && <TopPaginationBar />}
      <Box sx={{ flex: '1 0 auto' }} />
      {sideFilter ? (
        <FilterSidebarButton
          sideFilter={sideFilter}
          filters={listParams?.filter || defaultFilter}
          defaultFilter={defaultFilter}
        />
      ) : null}
      {actions}
      {showAction.import && additionalImportButton}
      {showAction.export && additionalExportButton}
      {showAction.import && <ImportButton />}
      {showAction.export &&
        (multipleExportButtonsInfo ? (
          <MultipleExportButton
            buttonsInfo={multipleExportButtonsInfo}
            preferenceKey={preferenceKey}
          />
        ) : (
          <MultipleExportButton preferenceKey={preferenceKey} />
        ))}
      {showAction.effective && <UpdateEffectivePermissionButton />}
      {showAction.select && (
        <SelectColumnsButton
          className="selectColumnsButton"
          preferenceKey={preferenceKey}
        />
      )}
      {showAction.create && (
        <CreateButton
          className="createButton"
          sx={commonStyles.mdResponsiveButton}
        />
      )}
      {showAction.expand && (
        <ExpandCollapseButton defaultFilter={defaultFilter} isExpand />
      )}
      {showAction.collapse && (
        <ExpandCollapseButton defaultFilter={defaultFilter} />
      )}
    </TopToolbar>
  );
};

export default ListActions;
