import React, { ReactElement } from 'react';
import {
  SimpleForm,
  ListButton,
  TextField,
  Labeled,
  DateField,
} from 'react-admin';
import { Box } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';
import SmartTabWidthButtons from '../../common/SmartTabWidthButtons';
import DisciplineTestField from '../../common/DisciplineTestField';
import SubsystemField from '../../common/SubsystemField';
import FooterToolbar from '../../common/FooterToolbar';
import LocationField from '../../common/LocationField';
import TestTypeField from '../../common/TestTypeField';
import ModuleField from '../../common/ModuleField';
import TestField from '../../common/TestField';
import TextInput from '../../common/TextInput';
import commonStyles from '../../common/commonStyles';
import JSONFormTab from './JSONFormTab';

interface SmartTagITRFormProps {
  onCancel?: () => void;
  setPendingPunchTitle?: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const SmartTagITRForm: React.FC<SmartTagITRFormProps> = ({
  onCancel,
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => (
  <SimpleForm
    toolbar={
      <FooterToolbar autoSave={true} onCancel={onCancel}>
        <ListButton label="Cancel" icon={<CancelIcon />} onClick={onCancel} />
      </FooterToolbar>
    }
  >
    <Box sx={{ ...commonStyles.columnFlexBox, padding: '0 16px 30px' }}>
      <SmartTabWidthButtons />
      <Labeled label="Tag">
        <TextField source="component.tag" />
      </Labeled>
      <Labeled label="Tag Discipline">
        <TextField source="component.discipline.discipline" />
      </Labeled>
      <Labeled label="Tag Description">
        <TextField source="component.tagDescription" />
      </Labeled>
      <Labeled label="Workpack">
        <TextField source="component.workPack.workPack" />
      </Labeled>
      <Labeled label="ITR Name">
        <TestField source="testId" textSource="test.testFormNo" />
      </Labeled>
      <Labeled label="ITR Description">
        <TextField source="test.testName" />
      </Labeled>
      <Labeled label="ITR Type">
        <TestTypeField
          source="test.testTypeId"
          textSource="test.testType.testType"
        />
      </Labeled>
      <Labeled label="ITR Discipline Name">
        <DisciplineTestField
          source="test.disciplineTest.id"
          textSource="test.disciplineTest.disciplineTest"
        />
      </Labeled>
      <Labeled label="SubSystem Name">
        <SubsystemField
          source="component.subsystemId"
          textSource="component.subsystem.subsystem"
        />
      </Labeled>
      <Labeled label="SubSystem Description">
        <TextField source="component.subsystem.description" />
      </Labeled>
      <Labeled label="Location">
        <LocationField />
      </Labeled>
      <Labeled label="Module">
        <ModuleField
          source="component.moduleId"
          textSource="component.module.moduleName"
        />
      </Labeled>
      <Labeled label="Completed By">
        <TextField source="completedBy" />
      </Labeled>
      <Labeled label="Completed Test Date">
        <DateField source="completedDateTime" />
      </Labeled>
      <Labeled label="Review Comments">
        <TextInput
          source="reviewComments"
          label=""
          disabled
          multiline
          fullWidth
        />
      </Labeled>
    </Box>

    <JSONFormTab
      setPendingPunchTitle={setPendingPunchTitle}
      setPendingPunchCreateDialogCloseDisabled={
        setPendingPunchCreateDialogCloseDisabled
      }
    />
  </SimpleForm>
);

export default SmartTagITRForm;
