import { useResourceContext } from 'react-admin';
import { RESOURCE_SMART_TAG_ITR } from '../../constants';
import useCanAccess from './useCanAccess';

const useAttachmentCanAccess = (
  onlyShow: boolean,
  outerResource?: string
): boolean => {
  const resource: string = useResourceContext();
  // Please use outerResource only for getting permissions
  const canAccessList = useCanAccess(outerResource);
  return (
    onlyShow ||
    (resource === RESOURCE_SMART_TAG_ITR
      ? !canAccessList.itrAttachment
      : !canAccessList.edit)
  );
};

export default useAttachmentCanAccess;
