import React, { useCallback, useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { FormControlLabel, Switch } from '@mui/material';

const FilterMyItemsButton = (props) => {
  const { setFilters, filterValues } = useListContext();
  const [isMyItem, setIsMyItem] = useState(!!filterValues?.mobileResponsible);

  useEffect(() => {
    setIsMyItem(!!filterValues?.mobileResponsible);
  }, [filterValues, setIsMyItem]);

  const handleSwitchChange = useCallback(
    (e) => {
      const mergedFilterValues = {
        ...(props.defaultFilter || {}),
        ...filterValues,
        ...(e.target.checked && { mobileResponsible: true }),
      };

      if (!e.target.checked) {
        delete mergedFilterValues.mobileResponsible;
      }

      setFilters(mergedFilterValues, []);
      setIsMyItem(e.target.checked);
    },
    [props.defaultFilter, setFilters, setIsMyItem, filterValues]
  );

  return (
    <FormControlLabel
      control={
        <Switch size="small" checked={isMyItem} onChange={handleSwitchChange} />
      }
      label={props.label || 'My ITR'}
    />
  );
};

export default FilterMyItemsButton;
