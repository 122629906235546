import React, { ReactElement } from 'react';
import { Toolbar } from 'react-admin';
import SaveButton from './SaveButton';

const SaveOnlyToolbar: React.FC = (): ReactElement => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export default SaveOnlyToolbar;
