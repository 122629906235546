import { ReactElement } from 'react';
import LazyFormTab from '../../../common/LazyFormTab';
import commonStyles from '../../../common/commonStyles';
import ContactFormTab from './ContactFormTab';
import { useIsShowContactsTab } from '../../hooks';

const ContactsTabEdit = (props): ReactElement => {
  const isShowContactsTab = useIsShowContactsTab();

  return (
    isShowContactsTab && (
      <LazyFormTab
        label="Contacts"
        id="contacts_tab"
        sx={commonStyles.formTabHeader}
        {...props}
      >
        <ContactFormTab />
      </LazyFormTab>
    )
  );
};

export default ContactsTabEdit;
