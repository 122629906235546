import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  DateField,
  NumberField,
  TextField,
  useStore,
  useResourceContext,
  RowClickFunction,
  SelectField,
  useListContext,
} from 'react-admin';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import { completedChoices } from '../../preservationItemActivity/constants';
import PreservationSeriesField from '../../common/PreservationSeriesField';
import PreservationItemField from '../../common/PreservationItemField';
import SignatureTextField from '../../common/SignatureTextField';
import AssignedField from '../../common/Assigned/AssignedField';
import ComponentField from '../../common/ComponentField';
import ColumnActions from '../../common/ColumnActions';
import LocationField from '../../common/LocationField';
import BulkActionButtons from './BulkActionButtons';
import TestField from '../../common/TestField';
import { getRowStyle } from './helpers';

interface SmartPreservationItemActivityListDatagridProps {
  setSelectedRecordId: (id: number | null) => void;
  defaultVisibleColumns: string[];
}

const SmartPreservationItemActivityListDatagrid: React.FC<
  SmartPreservationItemActivityListDatagridProps
> = ({ setSelectedRecordId, defaultVisibleColumns }): ReactElement => {
  const { labels, preferenceKey } = useDictionaryLabelsWithResource();
  const queryClient = useQueryClient();
  const resource: string = useResourceContext();
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const { data: items = [], isLoading } = useListContext();

  const getRowStyles = useCallback(
    (record) => getRowStyle(record, expanded),
    [expanded]
  );

  const rowClick: RowClickFunction = useCallback(
    async (id, resource): Promise<false> => {
      if (!expanded.length || !expanded.includes(id)) {
        await queryClient.invalidateQueries({
          queryKey: [resource, 'getOne', { id: String(id) }],
        });
        setExpanded([id]);
      } else {
        setExpanded([]);
      }

      return false;
    },
    [expanded, setExpanded, queryClient]
  );

  useEffect(() => {
    if (!isLoading) {
      const listIds = items.map((item) => item.id);
      if (!expanded.every((id) => listIds.includes(id))) {
        setExpanded([]);
        setSelectedRecordId(null);
      } else {
        setSelectedRecordId(!expanded?.length ? null : expanded[0]);
      }
    }
  }, [setSelectedRecordId, expanded, items, setExpanded, isLoading]);

  return (
    <DatagridConfigurableRbac
      defaultVisibleColumns={defaultVisibleColumns}
      preferenceKey={preferenceKey}
      bulkActionButtons={<BulkActionButtons />}
      rowClick={rowClick}
      rowStyle={getRowStyles}
      hover
    >
      <TextField source="id" label="ID" />
      <PreservationItemField label={labels['preservationItemId']} />
      <PreservationSeriesField label={labels['preservationSeriesId']} />
      <ComponentField label={labels['componentId']} />
      <TestField
        label={labels['testId']}
        source="testId"
        textSource="test.testFormNo"
        sortBy="`test.testFormNo`"
      />

      <LocationField label={labels['locationId']} />
      <AssignedField
        label={labels['assigned'] || 'Responsible'}
        sortable={false}
      />
      <NumberField
        source="frequency"
        label={labels['frequency'] || 'Frequency'}
      />
      <DateField source="dueDate" label={labels['dueDate'] || 'Due Date'} />
      <DateField source="lateDate" label={labels['lateDate'] || 'Late Date'} />
      <TextField source="sequence" label={labels['sequence'] || 'Sequence'} />
      <TextField
        source="storagePreservation"
        label={labels['storagePreservation'] || 'Storage Preservation'}
      />
      <NumberField
        source="estimatedManHours"
        label={labels['estimatedManHours'] || 'Estimated Man Hours'}
      />
      <NumberField
        source="actualManHours"
        label={labels['actualManHours'] || 'Actual Man Hours'}
      />
      <NumberField
        source="adminManHours"
        label={labels['adminManHours'] || 'Admin Man Hours'}
      />
      <DateField
        source="onSiteDate"
        label={labels['onSiteDate'] || 'On Site Date'}
      />
      <SignatureTextField
        source="inspector"
        label={labels['inspector'] || 'Inspector'}
      />
      <TextField
        source="inspectorPrintName"
        label={labels['inspectorPrintName'] || 'Inspector: Print Name'}
      />
      <TextField
        source="inspectorCompany"
        label={labels['inspectorCompany'] || 'Inspector: Company'}
      />
      <DateField
        source="signOffDate"
        label={labels['signOffDate'] || 'Sign Off Date'}
      />
      <TextField
        source="printedBy"
        label={labels['printedBy'] || 'Printed By'}
      />
      <DateField
        source="printDate"
        label={labels['printDate'] || 'Print Date'}
      />
      <SelectField
        sortable={false}
        source="completed"
        label={labels['completed'] || 'Status'}
        choices={completedChoices}
      />
      <SignatureTextField
        source="verifiedBy"
        label={labels['verifiedBy'] || 'Verified By'}
      />
      <TextField
        source="verifiedPrintName"
        label={labels['verifiedPrintName'] || 'Verified Print Name'}
      />
      <DateField
        source="verifiedDate"
        label={labels['verifiedDate'] || 'Verified Date'}
      />

      <NumberField
        source="plusDate"
        label={labels['plusDate'] || 'Plus Date'}
      />

      {renderMetaDataFields()}
      <ColumnActions
        label="Actions"
        showActions={{ edit: false, delete: false, show: false }}
      >
        <Button>Show/Hide</Button>
      </ColumnActions>
    </DatagridConfigurableRbac>
  );
};

export default SmartPreservationItemActivityListDatagrid;
