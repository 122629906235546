import React from 'react';
import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from './FreeTextSearchInput';

const FreeTextSearchFilter: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  return (
    <Filter {...props} variant={'outlined'}>
      <FreeTextSearchInput source="q" label="Search" name="Search" alwaysOn />
    </Filter>
  );
};

export default FreeTextSearchFilter;
