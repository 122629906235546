import axios from 'axios';
import { Identifier } from 'react-admin';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export const getCopyBookToProjectDataProvider = () => ({
  copyBookToProject(
    resource: string,
    data: { bookIds: Identifier[]; projectIds: Identifier[] }
  ) {
    return axios.post(
      `${CRUD_API_URL}/${resource}/copy-to-project`,
      data,
      getRequestConfig()
    );
  },
});
