export const getPowerBIProjectIdFilter = (projectId: number) => ({
  $schema: 'http://powerbi.com/product/schema#basic',
  target: {
    table: 'Project Filters',
    column: 'project_id',
  },
  operator: 'In',
  values: [projectId],
});

export const getPowerBITimeZoneFilter = () => {
  const date = new Date();
  const timezoneOffsetInHours = -1 * Math.round(date.getTimezoneOffset() / 60);
  return {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'TimeZone',
      column: 'Offset',
    },
    operator: 'In',
    values: [timezoneOffsetInHours],
  };
};

export const getPowerBITimeOffsetParam = () => {
  const date = new Date();
  const timezoneOffsetInHours = -1 * Math.round(date.getTimezoneOffset() / 60);
  return {
    name: 'timeoffset',
    value: '' + timezoneOffsetInHours, // It must be a string
  };
};
export const getPowerBIDefaultSettings = () => ({
  panes: {
    filters: {
      visible: false,
    },
    pageNavigation: {
      visible: false,
    },
  },
  bars: {
    statusBar: {
      visible: true,
    },
  },
});
