import React, { ReactElement, Fragment } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import SystemInput from '../common/SystemInput';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

interface SubSystemFormProps {
  bulkEditMode?: boolean;
}

const SubSystemForm: React.FC<SubSystemFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <SystemInput
          isRequired={isRequired}
          sx={commonStyles.flexBoxSelectItem}
        />
        <TextInput
          source="subsystem"
          validate={[...requiredValidation, maxLength(85)]}
          name="subsystem"
          sx={commonStyles.flexBoxItem}
          disabled={bulkEditMode}
        />
        <TextInput
          source="description"
          name="description"
          validate={[maxLength(150), ...requiredValidation]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="phase"
          name="phase"
          validate={[maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="sequence"
          name="sequence"
          validate={[maxLength(15)]}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput source="isScoped" name="isScoped" label="Scoped" />
        <BooleanInput source="isVerified" name="isVerified" label="Verified" />
      </Box>
    </Fragment>
  );
};

export default SubSystemForm;
