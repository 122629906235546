import { Box } from '@mui/material';
import { useRowContext } from '@react-admin/ra-editable-datagrid';
import { CreateDialog } from '@react-admin/ra-form-layout';
import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  Identifier,
  maxLength,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { required } from '../../../../utils/UtilityFunctions';
import commonStyles from '../../../common/commonStyles';
import CustomNumberInput from '../../../common/CustomNumberInput';
import { getQtyToReleaseValidator } from '../validators';
import MRMaterialStorageInput from './MRMaterialStorageInput';

export interface MRMaterialStoragesCreateFormProps {
  onSuccess: () => Promise<void>;
  parentId: Identifier;
  disabled: boolean;
}

const MRMaterialStoragesCreateForm: React.FC<
  MRMaterialStoragesCreateFormProps
> = ({ onSuccess, parentId, disabled }): ReactElement => {
  const { close } = useRowContext();
  const notify = useNotify();

  const mutationOptions = useMemo(
    () => ({
      onSuccess: async () => {
        await onSuccess();
        close();
      },
    }),
    [close, onSuccess]
  );

  useEffect(() => {
    if (disabled) {
      notify(
        'New material can not be added. The record is already in "Released" status',
        { type: 'warning', multiLine: true }
      );
      close();
    }
  }, [disabled, notify, close]);

  if (disabled) return null;

  return (
    <CreateDialog
      fullWidth
      maxWidth="md"
      isOpen
      close={close}
      title=""
      mutationOptions={mutationOptions}
    >
      <SimpleForm defaultValues={{ materialReleasedId: parentId }}>
        <Box sx={commonStyles.flexBox}>
          <MRMaterialStorageInput />
          <CustomNumberInput
            label="Qty to Release"
            source="qtyOnReleased"
            isPositive
            defaultNull
            validate={[required(), getQtyToReleaseValidator('qtyOnHand')]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <TextInput
          label="Comment"
          source="comment"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxDescriptionItem}
        />
      </SimpleForm>
    </CreateDialog>
  );
};

export default MRMaterialStoragesCreateForm;
