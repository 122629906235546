import React, { ReactElement } from 'react';
import { maxLength, SelectInput } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { categoryChoices } from './constants';
import { ActivityType } from './types';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const ActivityTypeEdit: React.FC<EditPropsWithLocation<ActivityType>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Activity Type"
      id="ActivityType_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="activityType"
          label="Activity Type"
          validate={[required(), maxLength(30)]}
          sx={commonStyles.flexBoxItem}
        />
        <SelectInput
          source="category"
          label="Category"
          choices={categoryChoices}
          validate={required()}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_activity_type" />
  </TabbedEditForm>
);

export default ActivityTypeEdit;
