import React, { Fragment, ReactElement } from 'react';
import { Popover, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoButton: React.FC = (): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <InfoIcon onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>
          Close Out ITR(s) Button: Selecting the &apos;Close Out ITR(s)&apos;
          button will finalize the digital ITR,
          <br />
          compile the punch, and attach any images or documents you have added
          to the ITR.
          <br />
          The digital form will be converted to a PDF and sent to the project
          CMS Admin for review and close out.
        </Typography>
        <Typography sx={{ p: 2 }}>
          If the CMS Admin finds any issues then they will &apos;Reject&apos;
          the ITR and it will show back up in your Tag-ITR list.
          <br />
          The &apos;Status&apos; of the Tag-ITR will show as
          &apos;Rejected&apos; and there should be a comment added to the ITR as
          to why the ITR was rejected.
        </Typography>
      </Popover>
    </Fragment>
  );
};

export default InfoButton;
