import React, { ReactElement } from 'react';
import InstrumentationFormTab from './InstrumentationFormTab';
import useCanAccess from '../../common/hooks/useCanAccess';
import BulkEditButton from '../../common/BulkEditButton';
import MetaDataInputs from '../../common/MetaDataInputs';
import { RESOURCE_COMPONENT } from '../../constants';
import commonStyles from '../../common/commonStyles';
import ElectricalFormTab from './ElectricalFormTab';
import MechanicalFormTab from './MechanicalFormTab';
import LazyFormTab from '../../common/LazyFormTab';
import PipingFormTab from './PipingFormTab';
import ComponentForm from './ComponentForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  const tabs = [
    <LazyFormTab
      label="Component"
      id="component_tab"
      sx={commonStyles.formTabHeader}
      key="component_tab"
    >
      <ComponentForm bulkEditMode />
      <MetaDataInputs />
    </LazyFormTab>,
    <ElectricalFormTab bulkEditMode key="electrical_tab" />,
    <PipingFormTab key="piping_tab" />,
    <MechanicalFormTab key="mechanical_tab" />,
    <InstrumentationFormTab key="instrumentation_tab" />,
  ];

  return <BulkEditButton resource={RESOURCE_COMPONENT} tabs={tabs} />;
};

export default BulkActionButtons;
