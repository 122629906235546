import React, { ReactElement } from 'react';
import { isEmpty } from 'lodash';
import { FormHelperText, Box } from '@mui/material';
import { DispatchCell, useJsonForms } from '@jsonforms/react';
import {
  ControlElement,
  JsonSchema,
  Resolve,
  JsonFormsRendererRegistryEntry,
  JsonFormsCellRendererRegistryEntry,
  encode,
} from '@jsonforms/core';
import { ctxToFieldProps, OwnPropsOfField } from '../../helpers';

interface FieldComponentProps {
  path: string;
  propName?: string;
  schema: JsonSchema;
  rootSchema: JsonSchema;
  errors: string;
  enabled: boolean;
  renderers?: JsonFormsRendererRegistryEntry[];
  cells?: JsonFormsCellRendererRegistryEntry[];
  isValid: boolean;
}

const FieldComponent = React.memo(function FieldComponent({
  path,
  propName,
  schema,
  rootSchema,
  errors,
  enabled,
  renderers,
  cells,
  isValid,
}: FieldComponentProps): ReactElement {
  const fieldPath = `#/properties/${encode(propName)}`;
  const uischema: ControlElement = {
    ...Resolve.schema(schema, fieldPath, rootSchema),
    type: 'Control',
    scope: fieldPath,
  };

  return (
    <Box>
      <DispatchCell
        schema={schema}
        uischema={uischema}
        path={path.substring(0, path.lastIndexOf('.'))}
        enabled={enabled}
        renderers={renderers}
        cells={cells}
      />
      <FormHelperText error={!isValid}>{!isValid && errors}</FormHelperText>
    </Box>
  );
});

const ArrayField = (ownProps: OwnPropsOfField): ReactElement => {
  const ctx = useJsonForms();
  const fieldProps = ctxToFieldProps(ctx, ownProps);

  const isValid = isEmpty(fieldProps.errors);
  return <FieldComponent {...fieldProps} isValid={isValid} />;
};

export default ArrayField;
