import { Button, SaveButton, Toolbar } from 'react-admin';
import React, { useCallback } from 'react';
import commonStyles from '../../commonStyles';
import { useFormContext } from 'react-hook-form';

interface ImportToolbarProps {
  onClose: () => void;
}

const ImportToolbar: React.FC<ImportToolbarProps> = ({ onClose }) => {
  const { resetField } = useFormContext();
  const resetForm = useCallback(() => {
    setTimeout(() => resetField('file'), 0); // ugly hack because mutationOptions don't work with type="submit"
  }, [resetField]);
  return (
    <Toolbar style={commonStyles.footerActionsBox}>
      <SaveButton label="Import" onClick={resetForm} />
      <Button label="Close" onClick={onClose} type="reset" />
    </Toolbar>
  );
};

export default ImportToolbar;
