import React, { ReactElement } from 'react';
import {
  AutocompleteArrayInput,
  SelectInput,
  SelectInputProps,
  useGetList,
} from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { getContext, UserContext } from '../../../provider/userContext';
import { CUSTOM_ROUTE_LOOK_UP } from '../../constants';

interface PhaseSearchInputProps
  extends Omit<
    SelectInputProps,
    'field' | 'name' | 'formState' | 'fieldState'
  > {
  source: string;
  label: string;
  sx: SxProps<Theme>;
  multiple?: boolean;
}

const PhaseSearchInput: React.FC<PhaseSearchInputProps> = ({
  source,
  label,
  sx,
  multiple = false,
}): ReactElement => {
  const phaseKey = 'phase';
  const ctx: UserContext = getContext();
  const { data: choices } = useGetList(CUSTOM_ROUTE_LOOK_UP, {
    filter: { project_id: ctx?.projectId },
    sort: { field: phaseKey, order: 'ASC' as const },
    meta: {
      model: 'TblSubsystem',
      includeFields: [phaseKey],
    },
  });
  const props = {
    source,
    label,
    sx,
    choices,
    optionText: phaseKey,
    optionValue: phaseKey,
  };

  if (multiple) {
    return <AutocompleteArrayInput multiple {...props} />;
  }

  return <SelectInput {...props} />;
};

export default PhaseSearchInput;
