import {
  RESOURCE_PRESERVATION_ITEM,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_SERIES,
  RESOURCE_PUNCH,
} from '../constants';

export const resourceChoices = [
  { id: RESOURCE_PUNCH, name: 'Punch' },
  { id: RESOURCE_PRESERVATION_ITEM, name: 'Preservation Item' },
  { id: RESOURCE_PRESERVATION_SERIES, name: 'Preservation Series' },
  {
    id: RESOURCE_PRESERVATION_ITEM_ACTIVITY,
    name: 'Preservation Item Activity',
  },
];
