import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

const useInvalidateUserEffectivePermission = (
  projectId: number,
  userId?: string
) => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    const queryKey = ['user-effective-permission', projectId];
    if (userId) {
      queryKey.push(userId);
    }
    await queryClient.invalidateQueries({ queryKey, exact: false });
  }, [projectId, queryClient, userId]);
};

export default useInvalidateUserEffectivePermission;
