import { useCallback, useRef, useState } from 'react';
import { FILTER_TYPES } from './constants';

export const useAdditionalSearchFilter = () => {
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterType, setFilterType] = useState(FILTER_TYPES.contains);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const addFilterAnchor = useCallback(
    (value) => {
      if (value === '') return value;

      switch (filterType) {
        case FILTER_TYPES.startsWith: {
          return `^${value}`;
        }
        case FILTER_TYPES.endsWith: {
          return `${value}$`;
        }
        case FILTER_TYPES.contains:
        case FILTER_TYPES.custom:
        default: {
          return value;
        }
      }
    },
    [filterType]
  );
  const getFilterName = useCallback(
    (name) => {
      switch (filterType) {
        case FILTER_TYPES.startsWith:
        case FILTER_TYPES.endsWith:
        case FILTER_TYPES.custom: {
          return name.replace(/_inc$/, '').concat('_regexp');
        }
        case FILTER_TYPES.contains:
        default: {
          return name;
        }
      }
    },
    [filterType]
  );
  const handleChangeFilterType = (value) => {
    setFilterType(value);
  };
  const handleInputFocus = useCallback(() => setIsInputFocused(true), []);
  const handleInputBlur = useCallback(() => setIsInputFocused(false), []);
  const handleMenuOpen = useCallback(() => {
    setMenuOpen((prevOpen) => !prevOpen);
  }, []);
  const handleMenuClose = useCallback((event: Event) => {
    if (
      menuRef.current &&
      menuRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  }, []);

  return {
    menuOpen,
    isInputFocused,
    filterType,
    addFilterAnchor,
    getFilterName,
    handleChangeFilterType,
    handleInputFocus,
    handleInputBlur,
    handleMenuOpen,
    handleMenuClose,
    menuRef,
  };
};
