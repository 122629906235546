import React, { ReactElement } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import TextInput from '../common/TextInput';
import DisciplineTestInput from '../common/DisciplineTestInput';
import DateRangeFilter from '../common/DateRangeFilter';
import TestTypeInput from '../common/TestTypeInput';
import { SideFilter } from '../common/types';
import sideFilterStyles from '../common/sideFilterStyles';
import { RESOURCE_TEST } from '../constants';

const TestSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_TEST, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="testFormNo"
            label="Test Form No"
            name="testFormNo_inc"
            alwaysOn
          />
          <TextInput
            source="testName"
            label="Test Name"
            name="testName_inc"
            alwaysOn
          />
          <TextInput
            source="testResp"
            label="Test Responsible"
            name="testResp_inc"
            alwaysOn
          />
          <DisciplineTestInput alwaysOn multiple />
          <TestTypeInput alwaysOn multiple />
          <NullableBooleanInput
            label="Multi Tag"
            source="multiTag"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Preservation"
            source="isPreservation"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Loop"
            source="loop"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="duration"
            label="Duration"
            name="duration_inc"
            alwaysOn
          />
          <TextInput source="class" label="Class" name="class_inc" alwaysOn />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button
          label="Clear filters"
          onClick={handleClearFilters}
          id="clearFilterButton"
        />
      </CardContent>
    </Card>
  );
};

export default TestSidebarFilters;
