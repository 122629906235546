import React, { ReactElement } from 'react';
import ChoicesLegend from '../../common/choicesLegend/ChoicesLegend';

const EnforceCertGatingLegend: React.FC = (): ReactElement => (
  <ChoicesLegend
    title="Enforce Certificate Gating Settings Descriptions"
    items={[
      {
        name: 'Strict',
        description:
          'Selecting "Strict" causes the system to verify ' +
          'that the certificate you are about to generate has met all the ' +
          '"Gating" requirements that you set forth. If the ' +
          '"Gating" requirements are not met then the certificate will not ' +
          'be allowed to generate.',
      },
      {
        name: 'Prompt',
        description:
          'Selecting "Prompt" causes the ' +
          '"Gating" check to be performed and you will receive a notice if ' +
          'you do not meet the "Gating" requirements but you can select a ' +
          'button labeled "Proceed Anyways" to generate the certificate. ' +
          'There are no additional security roles required.',
        isDefault: true,
      },
      {
        name: 'Strict with Prompt',
        description:
          'Selecting "Strict with Prompt" is ' +
          'similar to "Strict" in that it verifies you have met the ' +
          '"Gating" requirements for that certificate, but if you have not ' +
          'met the requirements, then the system prompts you with a warning but you ' +
          'can override the block and proceed with the certificate generation if you ' +
          'have the proper security role to bypass the "Gating" requirements.',
      },
    ]}
  />
);

export default EnforceCertGatingLegend;
