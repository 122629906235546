import React, { ReactElement } from 'react';
import { MenuItemLink, useUserMenu, MenuItemLinkProps } from 'react-admin';

const CustomMenuItemLink: React.FC<MenuItemLinkProps> = ({
  to,
  primaryText,
  leftIcon,
  onClick,
  ...rest
}): ReactElement => {
  const { onClose } = useUserMenu();

  const handleClick = (e) => {
    onClick?.(e);
    onClose();
  };

  return (
    <MenuItemLink
      to={to}
      primaryText={primaryText}
      leftIcon={leftIcon}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default CustomMenuItemLink;
