import { Project } from '../project/types';

export const getProjectItem = (project: Project) => ({
  group: project.isActive ? 'Active Projects' : 'Inactive Projects',
  option: {
    title: `${project.project}`,
    value: project.id,
  },
  isActive: project.isActive,
});
