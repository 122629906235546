import React, { ReactElement, useCallback } from 'react';
import { activityCategory, commentType } from '../common/constants';
import AttachmentFormTab from '../common/AttachmentFormTab';
import { EditPropsWithLocation } from '../common/types';
import CommentFormTab from '../common/CommentFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import ActivitiesTab from '../common/ActivitiesTab';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import SystemForm from './SystemForm';

const SystemEdit: React.FC<EditPropsWithLocation> = ({
  id,
  ...props
}): ReactElement => {
  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      systemId: recordId,
      commentType: commentType.system,
    }),
    []
  );

  return (
    <TabbedEditForm {...props} id={id}>
      <LazyFormTab
        label="System"
        id="system_tab"
        sx={commonStyles.formTabHeader}
      >
        <SystemForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Comment"
        id="comments_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="system_id"
          commentType={commentType.system}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachments_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab targetId="systemId" />
      </LazyFormTab>
      <LazyFormTab
        label="Activities"
        id="activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <ActivitiesTab category={activityCategory.system} />
      </LazyFormTab>
      <HistoryTab tableName="tbl_system" />
    </TabbedEditForm>
  );
};

export default SystemEdit;
