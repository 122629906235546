import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_MODULE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface ModuleFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}
const ModuleField: React.FC<ModuleFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField source={source} label={label} resource={RESOURCE_MODULE}>
    <TextField source={textSource} />
  </SmartLinkField>
);

ModuleField.defaultProps = {
  label: 'Module',
  source: 'moduleId',
  textSource: 'module.moduleName',
  sortBy: '`module.moduleName`',
};

export default ModuleField;
