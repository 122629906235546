import React, { ReactElement } from 'react';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { EditPropsWithLocation } from '../common/types';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import TestForm from './TestForm';
import { Test } from './types';
import TestPositionFormTab from './components/TestPositionFormTab';

const TestEdit: React.FC<EditPropsWithLocation<Test>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="Test" id="test_tab" sx={commonStyles.formTabHeader}>
      <TestForm />
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab
      label="Revisions"
      id="attachment_revision_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentRevisionFormTab targetId="testId" withoutProjectId />
    </LazyFormTab>
    <LazyFormTab
      label="Details"
      id="test_position_tab"
      sx={commonStyles.formTabHeader}
    >
      <TestPositionFormTab />
    </LazyFormTab>
    <HistoryTab tableName="tbl_test" />
  </TabbedEditForm>
);

export default TestEdit;
