import { canAccessListType, ShowActions } from '../types';

/**
 * Merges access and action permissions based on provided rules.
 * @param canAccessList - An object containing boolean values representing various access permissions.
 * @param showActions - An object containing boolean values representing which actions are allowed to be shown.
 * @returns An object containing merged access and action permissions.
 */

const mergeAccessActionList = (
  canAccessList: canAccessListType,
  showActions: ShowActions
): canAccessListType => {
  // Initialize an object to store the merged access and action permissions
  const mergedAccessActionsList = {};

  // Iterate over the keys of canAccessList
  Object.keys(canAccessList).forEach((key) => {
    // If the key exists in showActions, merge the access and action permissions
    // Otherwise, retain only the access permission
    mergedAccessActionsList[key] =
      showActions[key] !== undefined
        ? showActions[key] && canAccessList[key]
        : canAccessList[key];
  });

  // Return the merged access and action permissions
  return mergedAccessActionsList as canAccessListType;
};

export default mergeAccessActionList;
