import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import SmartLinkField from '../../common/SmartLinkField';
import { RESOURCE_DISCIPLINE } from '../../constants';

interface MechanicalDisciplineFieldProps {
  label?: string;
  source?: string;
}

const MechanicalDisciplineField: React.FC<MechanicalDisciplineFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_DISCIPLINE}>
    <TextField source="mechanical.discipline.discipline" />
  </SmartLinkField>
);

MechanicalDisciplineField.defaultProps = {
  label: 'Discipline',
  source: 'mechanical.disciplineId',
};

export default MechanicalDisciplineField;
