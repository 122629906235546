import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  TextField,
  useStore,
  RowClickFunction,
  useResourceContext,
  useListContext,
} from 'react-admin';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useCheckCurrentProjectResourceAccessSettings from '../../common/hooks/useCheckCurrentProjectResourceAccessSettings';
import MobileTestStatusField from '../../componentTest/components/MobileTestStatusField';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import EquipmentTypeField from '../../common/EquipmentTypeField';
import AssignedField from '../../common/Assigned/AssignedField';
import SubsystemField from '../../common/SubsystemField';
import ComponentField from '../../common/ComponentField';
import WorkPackField from '../../common/WorkPackField';
import LocationField from '../../common/LocationField';
import ColumnActions from '../../common/ColumnActions';
import BulkActionButtons from './BulkActionButtons';
import SystemField from '../../common/SystemField';
import VendorField from '../../common/VendorField';
import TestField from '../../common/TestField';
import { RED } from '../../../theme/colors';
import { SmartTagItr } from '../types';

interface SmartTagITRListDatagridProps {
  setSelectedRecordId: (id: number | null) => void;
  defaultVisibleColumns: string[];
}

const SmartTagITRListDatagrid: React.FC<SmartTagITRListDatagridProps> = ({
  setSelectedRecordId,
  defaultVisibleColumns,
}): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const queryClient = useQueryClient();
  const resource: string = useResourceContext();
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const { data: items = [], isLoading } = useListContext();

  const rowClick: RowClickFunction = useCallback(
    async (id, resource): Promise<false> => {
      if (!expanded.length || !expanded.includes(id)) {
        await queryClient.invalidateQueries({
          queryKey: [resource, 'getOne', { id: String(id) }],
        });
        setExpanded([id]);
      } else {
        setExpanded([]);
      }

      return false;
    },
    [expanded, setExpanded, queryClient]
  );

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  useEffect(() => {
    if (!isLoading) {
      const listIds = items.map((item) => item.id);
      if (!expanded.every((id) => listIds.includes(id))) {
        setExpanded([]);
        setSelectedRecordId(null);
      } else {
        setSelectedRecordId(!expanded?.length ? null : expanded[0]);
      }
    }
  }, [setSelectedRecordId, expanded, items, setExpanded, isLoading]);

  const getRowStyle = (record: SmartTagItr) => ({
    backgroundColor: expanded.includes(record.id)
      ? 'rgba(0, 0, 0, 0.04)'
      : 'initial',
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  });

  const getRowBorderColor = (record: SmartTagItr) => {
    switch (record.mobileTestStatus) {
      case 'REJECTED':
        return RED;
      default:
        return 'transparent';
    }
  };

  return (
    <DatagridConfigurableRbac
      bulkActionButtons={<BulkActionButtons onUpdate={onCancel} />}
      defaultVisibleColumns={defaultVisibleColumns}
      rowClick={rowClick}
      rowStyle={getRowStyle}
      hover
    >
      <TextField source="id" label="ID" />
      <ComponentField source="componentId" label="Tag" />
      <TextField
        source="component.tagDescription"
        label="Description"
        sortBy="component.tag_description"
      />
      <TestField
        source="testId"
        textSource="test.testFormNo"
        sortBy="test.test_form_no"
        label="ITR"
      />
      <TextField
        source="test.testName"
        label="ITR Name"
        sortBy="test.test_name"
      />
      <EquipmentTypeField source="equipTypeId" />
      <AssignedField label="Responsible" sortable={false} />
      <VendorField
        source="responsibleVendorId"
        textSource="responsibleVendor.vendor"
        sortBy="`responsibleVendor.vendor`"
      />
      <MobileTestStatusField source="mobileTestStatus" label="Status" />
      <TextField
        source="pendingPunchCounts.mobilePunch"
        label="Mobile Punch"
        sortBy="`pendingPunchCounts.mobilePunch`"
      />
      <SubsystemField
        source="component.subsystemId"
        textSource="component.subsystem.subsystem"
        sortBy="`component.subsystem.subsystem`"
      />
      <TextField
        source="component.subsystem.description"
        label="SubSystem Description"
        sortBy="`component.subsystem.description`"
      />
      <LocationField />
      <WorkPackField
        source="component.workPackId"
        textSource="component.workPack.workPack"
        sortBy="`component.workPack.workPack`"
      />
      <SystemField
        source="component.subsystem.systemId"
        textSource="component.subsystem.system.systemName"
        sortBy="`component.subsystem.system.systemName`"
        label="System"
      />
      <TextField
        source="component.subsystem.system.description"
        label="System Description"
        sortBy="`component.subsystem.system.description`"
      />
      {renderMetaDataFields()}
      <ColumnActions
        label="Actions"
        showActions={{ edit: false, delete: false, show: false }}
      >
        <Button>Show/Hide</Button>
      </ColumnActions>
    </DatagridConfigurableRbac>
  );
};

export default SmartTagITRListDatagrid;
