import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import DrawingDisciplineField from '../common/DrawingDisciplineField';
import DrawingTypeField from '../common/DrawingTypeField';
import commonStyles from '../common/commonStyles';
import LocationField from '../common/LocationField';
import CommentList from '../comment/CommentList';
import { RESOURCE_COMMENT } from '../constants';
import GroupField from '../common/GroupField';
import { commentType } from '../common/constants';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import SubsystemFormShow from './components/SubsystemFormTab/SubsystemFormShow';
import ComponentFormShow from './components/ComponentFormTab/ComponentFormShow';
import AuditListTab from '../audit/AuditListTab';

const DrawingShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Drawing">
        <Box>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="drawingName" label="Drawing Name" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="description" label="Description" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="sheet" label="Sheet" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <GroupField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DrawingTypeField />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DrawingDisciplineField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <LocationField />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Subsystems"
        id="subsystems_tab"
        sx={commonStyles.formTabHeader}
      >
        <SubsystemFormShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Components"
        id="components_tab"
        sx={commonStyles.formTabHeader}
      >
        <ComponentFormShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="comments" path="comments" id="comments_tab">
        <CommentList
          resource={RESOURCE_COMMENT}
          targetId="drawing_id"
          commentType={commentType.drawing}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Revisions"
        id="revisions_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentRevisionFormTab targetId="drawingId" onlyShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_drawing" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default DrawingShow;
