import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import DisciplineTestShow from './DisciplineTestShow';
import DisciplineTestEdit from './DisciplineTestEdit';

const DisciplineTestList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('division');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'disciplineTestCode', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<DisciplineTestShow withoutActions />}
        EditComponent={<DisciplineTestEdit syncWithLocation={false} />}
      >
        <TextField source="id" label="ID" />
        <TextField source="disciplineTest" label="Discipline Test" />
        <TextField source="disciplineTestCode" label="Discipline Test Code" />
        <TextField source="resource" label="Resource" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default DisciplineTestList;
