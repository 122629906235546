import React, { ReactElement } from 'react';
import { DateField, TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import MocEdit from './MocEdit';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import MocShow from './MocShow';
import MocParentField from './components/ParentMocField';
import MocTypeField from './components/MocTypeField';

const MocList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'mocNo', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<MocShow withoutActions />}
        EditComponent={<MocEdit syncWithLocation={false} />}
        defaultVisibleColumns={[
          'id',
          'mocNo',
          'title',
          'details',
          'description',
          'mocTypeId',
          'parentMocId',
          'raisedBy',
          'raisedDate',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="mocNo" label="MoC" />
        <TextField source="title" label="Title" />
        <TextField source="details" label="Details" />
        <TextField source="description" label="Description" />
        <MocTypeField />
        <MocParentField />
        <TextField source="raisedBy" label="Raised By" />
        <DateField source="raisedDate" label="Raised Date" />
        <TextField source="answeredBy" label="Answered By" />
        <DateField source="answeredDate" label="Answered Date" />
        <TextField source="closedBy" label="Closed By" />
        <DateField source="closedDate" label="Closed Date" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default MocList;
