import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

const purchaseOrderMaterialsDataProvider = {
  refillMaterials: (resource: string, materialPurchaseOrderId: number) =>
    axios.post(
      `${CRUD_API_URL}/${resource}/refill-materials`,
      { materialPurchaseOrderId },
      getRequestConfig()
    ),
};

export default purchaseOrderMaterialsDataProvider;
