import React, { ReactElement } from 'react';
import {
  CreateProps,
  Datagrid,
  TextField,
  FunctionField,
  WrapperField,
  useStore,
} from 'react-admin';
import AttachmentRevision from '../attachment/components/AttachmentRevision';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { titleFromResource } from '../common/hooks/useResourceTitle';
import ColumnActions from '../common/ColumnActions';
import DownloadButton from '../common/DownloadButton';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_IMPORT } from '../constants';
import ImportShow from './ImportShow';
import ImportSideFilters from './ImportSideFilters';

export interface ImportListProps extends CreateProps {
  targetId: string;
  commentType?: string;
}

const ImportList: React.FC<ImportListProps> = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_IMPORT);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: false,
            expand: false,
            collapse: false,
          }}
        />
      }
      aside={
        showSideFilter ? (
          <ImportSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        expand={<AttachmentRevision onlyShow />}
        expandSingle
      >
        <TextField source="id" />
        <TextField source="fileName" />
        <WrapperField label="Resource" source="resourceName" sortable={true}>
          <FunctionField
            label="Resource"
            render={(record) =>
              titleFromResource?.[record.resourceName]?.[0] ||
              record.resourceName
            }
          />
        </WrapperField>
        {renderMetaDataFields()}
        <ColumnActions
          showActions={{ show: false, edit: false, delete: false }}
          label="Actions"
        >
          <ImportShow />
          <DownloadButton />
        </ColumnActions>
      </Datagrid>
    </ListWithTitle>
  );
};

export default ImportList;
