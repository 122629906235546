import React, { ReactElement, Fragment, useCallback } from 'react';
import BulkEditButton from '../../../common/BulkEditButton';
import BulkGeneratePdfButton from '../../../common/BulkGeneratePdfButton';
import useCanAccess from '../../../common/hooks/useCanAccess';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_MATERIAL_PURCHASE_ORDER } from '../../../constants';
import PurchaseOrderRequestForm from './PurchaseOrderRequestForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_MATERIAL_PURCHASE_ORDER,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkGeneratePdfButton onSuccess={clearCache} />
      <BulkEditButton
        resource={RESOURCE_MATERIAL_PURCHASE_ORDER}
        form={<PurchaseOrderRequestForm bulkEditMode />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
