import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import './styles.css';
import { lucidDocumentEditUrl } from '../constants';

export interface LucidDiagramUnavailableMessageProps {
  documentId: string;
}

const LucidDiagramUnavailableMessage: React.FC<
  LucidDiagramUnavailableMessageProps
> = ({ documentId }): ReactElement => {
  return (
    <Stack direction="column" className="lucid-unavailable-message">
      <Box className="lucid-unavailable-message--header">
        Lucid Diagram cannot be displayed.
      </Box>
      <Box>
        Please contact the user who added the diagram and ask them to make it
        visible to other users. <br />
        The owner should open the diagram by visiting&nbsp;
        <a
          href={lucidDocumentEditUrl(documentId)}
          target="_blank"
          rel="noreferrer"
        >
          Lucid.app
        </a>
        <br />
        Then go to the menu &apos;Share&apos; &gt; &apos;Embed&apos;, click
        &apos;Activate Embed Code&apos; and &apos;Done&apos;
      </Box>
    </Stack>
  );
};

export default LucidDiagramUnavailableMessage;
