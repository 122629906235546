import React, { ReactElement, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { Identifier, useListContext, useUnselectAll } from 'react-admin';
import { QrScanItem } from '../../../types';

interface DataSelectButtonProps {
  data: QrScanItem[];
  resource: string;
  onClick: (Data: Identifier[]) => void;
  label: string;
  disabled: boolean;
}

const ProcessButton: React.FC<DataSelectButtonProps> = ({
  data,
  resource,
  onClick,
  label,
  disabled,
}): ReactElement => {
  const { selectedIds } = useListContext();
  const unselectAll = useUnselectAll(resource);

  const handleClick = useCallback(() => {
    const selectedItems = data
      .filter((item) => selectedIds.includes(item.recordId))
      .map((item) => item.id);
    onClick(selectedItems);
    unselectAll();
  }, [data, onClick, selectedIds, unselectAll]);

  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button disabled={disabled} onClick={handleClick}>
      {label}
    </Button>
  );
};

export default ProcessButton;
