import { useSidebarState, Layout } from 'react-admin';
import { CoreLayoutProps } from 'ra-core';
import { LayoutWrapper } from '@react-admin/ra-enterprise';
import { AppLocationContext } from '@react-admin/ra-navigation';
import AppBar from './AppBar';
import Menu from './components/menu/Menu';
import CustomIconMenu from './components/menu/CustomIconMenu';

export const AppLayout = (props: CoreLayoutProps) => {
  const [isOpenSidebar] = useSidebarState();

  return (
    <AppLocationContext>
      <LayoutWrapper>
        <Layout
          {...props}
          menu={isOpenSidebar ? Menu : CustomIconMenu}
          appBar={AppBar}
        />
      </LayoutWrapper>
    </AppLocationContext>
  );
};
