import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_DRAWING } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { required } from '../../utils/UtilityFunctions';

interface DrawingInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  disabled?: boolean;
  source?: string;
  label?: string;
}

const DrawingInput: React.FC<DrawingInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'drawing_name', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_DRAWING}
      requestParams={requestParams}
      name="drawingName_inc"
      optionText="drawingName"
      id="id"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'drawingName']}
      additionalParams={{ skipListAdditionalData: true }}
    />
  );
};

DrawingInput.defaultProps = { source: 'drawingId', label: 'Drawing Name' };

export default DrawingInput;
