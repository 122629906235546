import React, { ReactElement } from 'react';
import { IconMenu } from '@react-admin/ra-navigation';
import { MenuProps } from 'ra-ui-materialui';
import useGetMenuItems from './hooks';
import IconMenuItems from './IconMenuItems';

const CustomIconMenu: React.FC = (props: MenuProps): ReactElement => {
  const menuItemsToRender = useGetMenuItems();

  return (
    <IconMenu {...props}>
      <IconMenuItems menuItems={menuItemsToRender} />
    </IconMenu>
  );
};

export default CustomIconMenu;
