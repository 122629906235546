import {
  MultiLevelMenu,
  useAppLocationState,
} from '@react-admin/ra-navigation';
import { MenuProps } from 'ra-ui-materialui';
import useGetMenuItems from './hooks';
import MenuItems from './MenuItems';
import { getParentMenuItemList } from './helper';
import commonStyles from '../common/commonStyles';

const Menu = (props: MenuProps) => {
  const menuItemsToRender = useGetMenuItems();
  const [location] = useAppLocationState();
  const parentMenu = getParentMenuItemList(menuItemsToRender, location?.path);

  return (
    <MultiLevelMenu
      {...props}
      openItemList={parentMenu}
      sx={commonStyles.sideMenu}
    >
      <MenuItems menuItems={menuItemsToRender} />
    </MultiLevelMenu>
  );
};

export default Menu;
