import { isFunction } from 'lodash';
import React, { useCallback, ReactElement } from 'react';
import { DeleteButton, DeleteButtonProps, Button, Toolbar } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import SaveButton from '../../common/SaveButton';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';

interface FormToolBarProps {
  onCancel?: () => void;
  deleteButtonProps?: DeleteButtonProps & { show: boolean };
}

const FormToolBar: React.FC<FormToolBarProps> = ({
  onCancel,
  deleteButtonProps,
}): ReactElement => {
  const handleCancelButtonClick = useCallback(() => {
    if (onCancel && isFunction(onCancel)) {
      onCancel();
    }
  }, [onCancel]);

  const { show: showDeleteButton, ...restDeleteButtonProps } =
    deleteButtonProps;

  return (
    <Toolbar>
      <Box sx={commonStyles.footerActionsBox}>
        <SaveButton />
        <Button
          startIcon={<CancelIcon />}
          label="Cancel"
          onClick={handleCancelButtonClick}
        />
        {showDeleteButton && <DeleteButton {...restDeleteButtonProps} />}
      </Box>
    </Toolbar>
  );
};

export default FormToolBar;
