import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { DrawingDiscipline } from './types';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const DrawingDisciplineEdit: React.FC<
  EditPropsWithLocation<DrawingDiscipline>
> = (props): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Drawing Discipline"
      id="drawing_discipline_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="drawingDiscipline"
          label="Drawing Discipline"
          validate={[required(), maxLength(75)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="description"
          label="Description"
          validate={[maxLength(150)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_drawing_discipline" />
  </TabbedEditForm>
);

export default DrawingDisciplineEdit;
