import React, { Fragment, ReactElement } from 'react';
import StorageTabAdd from './StorageTabAdd';
import StorageTabList from './StorageTabList';

interface StorageTab {
  readonly?: boolean;
}

const StorageTab: React.FC<StorageTab> = ({ readonly }): ReactElement => {
  return (
    <Fragment>
      <StorageTabList showActions={!readonly} />
      {!readonly && <StorageTabAdd />}
    </Fragment>
  );
};

export default StorageTab;
