import React, { Fragment, useCallback } from 'react';
import { useRedirect, useResourceContext, useStore } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import SaveButton from '../../common/SaveButton';
import { PendingPunch } from '../../pendingPunch/types';

const SaveButtons: React.FC = () => {
  const ctx: UserContext = getContext();
  const resource: string = useResourceContext();
  const redirect = useRedirect();

  const transform = (data: PendingPunch): PendingPunch => ({
    ...data,
    status: 'SUBMITTED',
    projectId: ctx?.projectId,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);

  const onSaveSubmit = useCallback(() => {
    setExpanded([]);
    redirect('list', resource);
  }, [setExpanded, redirect, resource]);

  return (
    <Fragment>
      <SaveButton
        label="Save & Submit"
        type="button"
        transform={transform}
        mutationOptions={{ onSuccess: onSaveSubmit }}
      />
      <SaveButton />
    </Fragment>
  );
};

export default SaveButtons;
