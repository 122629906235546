import React, { ReactElement } from 'react';
import { CreateProps, ReferenceManyField } from 'react-admin';
import ImageList from '@mui/material/ImageList';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import { RESOURCE_ATTACHMENT } from '../constants';
import ImageItem from './ImageGalleryItem';
import SmartSingleFieldList from '../common/SmartSingleFieldList';

export interface AttachmentListProps extends CreateProps {
  targetId: string;
  onlyShow?: boolean;
  attachmentType?: string[];
}

const ImageGallery: React.FC<AttachmentListProps> = ({
  targetId,
  onlyShow,
}): ReactElement => {
  return (
    <ReferenceManyField
      reference={RESOURCE_ATTACHMENT}
      target={targetId}
      perPage={100}
      filter={{
        isDeleted: false,
        attachmentType: ATTACHMENT_TYPE_ENUMS.IMAGE,
      }}
    >
      <SmartSingleFieldList linkType={false} component={ImageList}>
        <ImageItem onlyShow={onlyShow} />
      </SmartSingleFieldList>
    </ReferenceManyField>
  );
};

export default ImageGallery;
