import { useGetList } from 'react-admin';
import { RESOURCE_CONTACT } from '../../constants';
import { Contact } from '../../contact/types';

const useContactCompanyList = () => {
  const { data: contacts } = useGetList<Contact>(RESOURCE_CONTACT);
  return Array.from(new Set(contacts?.map((c) => c.company))).filter(
    (c) => !!c
  );
};

export default useContactCompanyList;
