import { ACCEPTED_FORMATS_IMAGE_ALL } from '../common/constants';
import { AttachmentFileType } from './types';

export const splitFileToСhunks = (file: Blob, chunkSize: number) => {
  const chunksTotal = Math.ceil(file.size / chunkSize);
  const chunks: Blob[] = [];

  for (let i = 0; i < chunksTotal; i++) {
    chunks.push(
      file.slice(
        i * chunkSize,
        i !== chunksTotal ? (i + 1) * chunkSize : undefined
      )
    );
  }

  return chunks;
};

export const getAttachmentFileExtension = (
  fileName: string
): string | undefined => fileName.split('.').pop()?.toLowerCase();

export const getAttachmentFileType = (fileName: string): AttachmentFileType => {
  const fileExtension = getAttachmentFileExtension(fileName);
  const imageExtensions = Object.values(ACCEPTED_FORMATS_IMAGE_ALL).flat();

  if (imageExtensions.includes('.' + fileExtension)) {
    return AttachmentFileType.image;
  } else if (fileExtension === 'pdf') {
    return AttachmentFileType.pdf;
  } else {
    return AttachmentFileType.other;
  }
};
