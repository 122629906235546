import React, { ReactElement, ReactNode } from 'react';
import Box from '@mui/material/Box';
import useCanAccess from './hooks/useCanAccess';
import mergeAccessActionList from './helpers/mergeAccessActionList';
import EditIconButton from './EditIconButton';
import DeleteWithConfirmIconButton from './DeleteWithConfirmIconButton';
import ShowIconButton from './ShowIconButton';
import { FieldPropsWithOptionalSource, ShowActions } from './types';
import commonStyles from './commonStyles';

interface ColumnActionsProps extends FieldPropsWithOptionalSource {
  onDelete?: (record) => void;
  children?: ReactNode;
  showActions?: ShowActions;
  customDeleteButton?: ReactElement;
  renderChildrenOnRightSide?: boolean;
}

const ColumnActions: React.FC<ColumnActionsProps> = ({
  onDelete = null,
  children,
  showActions: propsShowActions = {},
  customDeleteButton,
  renderChildrenOnRightSide,
}): ReactElement => {
  const canAccessList = useCanAccess();
  const mergedAccessActionsList = mergeAccessActionList(
    canAccessList,
    propsShowActions
  );

  return (
    <Box sx={commonStyles.columnActionsBox}>
      {!renderChildrenOnRightSide && children}
      {mergedAccessActionsList.show && <ShowIconButton />}
      {mergedAccessActionsList.edit && <EditIconButton />}
      {mergedAccessActionsList.delete &&
        (customDeleteButton || (
          <DeleteWithConfirmIconButton
            className="deleteButton"
            onDelete={onDelete}
          />
        ))}
      {renderChildrenOnRightSide && children}
    </Box>
  );
};

export default ColumnActions;
