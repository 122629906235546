import React, { ReactElement } from 'react';
import { BooleanInput, maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import useContactCompanyList from '../common/hooks/useContactCompanyList';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import SelectInputWithFreeText from '../common/SelectInputWithFreeText';
import commonStyles from '../common/commonStyles';
import EmailInput from './Emailinput';
import { Contact } from './types';

const ContactCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const companies = useContactCompanyList();

  const transform = (data: Contact): Contact => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="nickname"
            label="Nick Name"
            validate={[required(), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="firstName"
            label="First Name"
            validate={[maxLength(50)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="lastName"
            label="Last Name"
            validate={[maxLength(50)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <EmailInput />
          <SelectInputWithFreeText
            source="company"
            label="Company"
            sx={commonStyles.flexBoxItem}
            options={companies}
            validate={[maxLength(100)]}
          />

          <TextInput
            source="jobTitle"
            label="Job Title"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box>
          <TextInput
            source="phone"
            label="Phone"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="address"
            label="Address"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
            multiline
          />
        </Box>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxLongItem}
            fullWidth
            multiline
          />
          <BooleanInput
            source="isMobileUser"
            label="Mobile User"
            defaultValue={true}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default ContactCreate;
