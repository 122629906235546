import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PUNCH_CATEGORY } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PunchCategoryFieldProps {
  label?: string;
  source?: string;
  textFieldSource?: string;
  sortBy?: string;
}

const PunchCategoryField: React.FC<PunchCategoryFieldProps> = ({
  label,
  source,
  textFieldSource,
  sortBy,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_PUNCH_CATEGORY}
    sortBy={sortBy}
  >
    <TextField source={textFieldSource} />
  </SmartLinkField>
);

PunchCategoryField.defaultProps = {
  label: 'Punch Category',
  source: 'punchCatId',
  textFieldSource: 'punchCat.category',
};

export default PunchCategoryField;
