import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_WORK_PACK } from '../constants';
import SmartLinkField from './SmartLinkField';

interface WorkPackFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}

const WorkPackField: React.FC<WorkPackFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_WORK_PACK}>
    <TextField source={textSource} />
  </SmartLinkField>
);

WorkPackField.defaultProps = {
  label: 'Work Pack',
  source: 'workPackId',
  textSource: 'workPack.workPack',
  sortBy: '`workPack.workPack`',
};

export default WorkPackField;
