import React, { ReactElement, Fragment } from 'react';
import { RESOURCE_TEST } from '../../constants';
import BulkEditButton from '../../common/BulkEditButton';
import TestForm from '../TestForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_TEST}
        form={<TestForm bulkEditMode />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
