import { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import AssetInput from './AssetInput';
import FooterToolbar from '../common/FooterToolbar';
import commonStyles from '../common/commonStyles';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const StructureAssetGroupCreate: React.FC = (): ReactElement => (
  <CreateWithTitle redirect="list">
    <SimpleForm toolbar={<FooterToolbar />}>
      <Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="assetGroup"
            label="Asset Group"
            validate={[required(), maxLength(150)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={maxLength(255)}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <AssetInput isRequired />
        </Box>
      </Box>
    </SimpleForm>
  </CreateWithTitle>
);

export default StructureAssetGroupCreate;
