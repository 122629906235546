import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../../../common/AutocompleteReferenceInput';
import { getContext, UserContext } from '../../../../../provider/userContext';
import { RESOURCE_PUNCH_PRIORITY } from '../../../../constants';
import { required } from '../../../../../utils/UtilityFunctions';

interface CertTemplateInputProps {
  isRequired?: boolean;
  sx?: object;
  additionalFilters?: object;
}

const PunchPriorityInput: React.FC<CertTemplateInputProps> = ({
  isRequired,
  sx,
  additionalFilters,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        ...additionalFilters,
      },
      sort: { field: 'priority', order: 'ASC' as const },
    };
  }, [additionalFilters, ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_PUNCH_PRIORITY}
      requestParams={requestParams}
      source="punchPriorityId"
      label="Punch Priority"
      name="priority_inc"
      optionText="priority"
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'priority']}
    />
  );
};

export default PunchPriorityInput;
