import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import Inbox from '@mui/icons-material/Inbox';
import commonStyles from '../common/commonStyles';

interface EmptyProps {
  emptyText: string;
}

const Empty: React.FC<EmptyProps> = ({ emptyText }): ReactElement => {
  return (
    <Box sx={commonStyles.emptyBox}>
      <Box className="empty-message">
        <Inbox className="empty-icon" />
        <Typography variant="h4" paragraph>
          {emptyText}
        </Typography>
      </Box>
    </Box>
  );
};

export default Empty;
