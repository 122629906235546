import axios from 'axios';
import { CRUD_API_URL, environment, getRequestConfig } from '../../api';
import { RESOURCE_LUCID_DIAGRAM } from '../../../components/constants';
import {
  getLocalStorageItem,
  isEmpty,
  popLocalStorageItem,
  setLocalStorageItem,
} from '../../../utils/UtilityFunctions';
import { lucidGrandAccessUrl } from '../../../components/lucidDiagram/constants';

const getLucidDiagramAuthProvider = () => {
  switch (environment) {
    case 'local':
      return new LucidDiagramAuthProvider({
        clientId: 'FIqd-T-Uqsyo86AcYQ79uaTnV4b0PN9ZFGu8_vSu',
        redirectUrl: 'http://localhost:3000/lucid-auth-callback',
      });
    case 'dev':
      return new LucidDiagramAuthProvider({
        clientId: 'FIqd-T-Uqsyo86AcYQ79uaTnV4b0PN9ZFGu8_vSu',
        redirectUrl:
          'https://tracker-check-dev.trackersuite.co/lucid-auth-callback',
      });
    case 'prod':
      return new LucidDiagramAuthProvider({
        clientId: 'FIqd-T-Uqsyo86AcYQ79uaTnV4b0PN9ZFGu8_vSu',
        redirectUrl:
          'https://tracker-check.trackersuite.co/lucid-auth-callback',
      });
    default:
      throw `Unknown environment:${environment}`;
  }
};

const LUCID_AUTH_TOKEN = 'lucid_auth_token';
const LUCID_AUTH_ORIGINATOR_URI = 'lucid_auth_originator_uri';

class LucidDiagramAuthProvider {
  resource: string = RESOURCE_LUCID_DIAGRAM;
  clientId: string;
  redirectUrl: string;

  constructor(args: { clientId: string; redirectUrl: string }) {
    const { clientId, redirectUrl } = args;
    this.clientId = clientId;
    this.redirectUrl = redirectUrl;
  }

  public async requestEmbedToken(): Promise<string> {
    const authToken = getLocalStorageItem(LUCID_AUTH_TOKEN);
    if (isEmpty(authToken)) {
      this.requestAccess();
    } else {
      return axios
        .post(
          `${CRUD_API_URL}/${this.resource}/embed-token`,
          { authToken },
          getRequestConfig()
        )
        .then((r) => r.data.embedToken);
    }
  }

  public requestAccess(): void {
    setLocalStorageItem(LUCID_AUTH_ORIGINATOR_URI, window.location.href);
    window.location.href = lucidGrandAccessUrl(this.clientId, this.redirectUrl);
  }

  public async provideAuthTokenAndGetRedirect(): Promise<string> {
    const urlParams = new URLSearchParams(window.location.search);
    const accessCode = urlParams.get('code');
    const errorCode = urlParams.get('error');

    if (errorCode) {
      // user did not grant access to lucid - nothing to do with that.
      return Promise.resolve('/');
    } else {
      return axios
        .post(
          `${CRUD_API_URL}/${this.resource}/auth-token`,
          { accessCode },
          getRequestConfig()
        )
        .then(({ data }) => {
          const { authToken, expiresIn } = data;
          setLocalStorageItem(LUCID_AUTH_TOKEN, authToken, expiresIn);
          return popLocalStorageItem(LUCID_AUTH_ORIGINATOR_URI);
        });
    }
  }
}

export const lucidDiagramAuthProvider = getLucidDiagramAuthProvider();
