import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import React, { Fragment, ReactElement } from 'react';
import { FILE_UPLOAD_STATUSES } from './constants';
import { QRProcessingType, StatusType } from './types';

export const getStatusMessage = (qrStatus: QRProcessingType): StatusType => {
  switch (qrStatus) {
    case 'WAITING':
      return 'Waiting in the queue';
    case 'PROCESSING_QR_READ':
      return 'Reading QR';
    case 'PROCESSING_FILE_SPLIT':
    case 'PROCESSING_DB_UPDATE':
      return 'Processing';
    case 'FINISHED_FAILED':
      return 'Failed';
    case 'FINISHED_SUCCESS':
      return 'Done';
  }
  throw 'Not supported qrStatus:' + qrStatus;
};

export const getProgressIcon = (status: string): ReactElement => {
  switch (status) {
    case 'WAITING':
    case 'PROCESSING_QR_READ':
    case 'PROCESSING_FILE_SPLIT':
    case 'PROCESSING_DB_UPDATE':
    case FILE_UPLOAD_STATUSES.UPLOADING:
    case FILE_UPLOAD_STATUSES.SUCCESS: {
      return <CircularProgress />;
    }
    case 'FINISHED_SUCCESS': {
      return <CheckCircleOutlineIcon />;
    }
    case 'FINISHED_FAILED':
    case status.startsWith(FILE_UPLOAD_STATUSES.ERROR) ? status : '': {
      return <ErrorOutlineIcon />;
    }
    default:
      return <Fragment />;
  }
};
