import React, { ReactElement, useState } from 'react';
import {
  Identifier,
  NumberField,
  ReferenceManyField,
  TextField,
  useStore,
} from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_BOOK_SECTION_TEST } from '../../constants';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { EditForm } from './EditForm';
import { CreateForm } from './CreateForm';
import commonStyles from '../../common/commonStyles';
import { ListComponentTitle } from '../components/ListComponentTitle';
import { Box, Button } from '@mui/material';
import { SelectTestDialog } from './SelectTestDialog';
import { Test } from '../../test/types';
import { useBulkUploadTests } from './useBulkUploadTests';
import { EditableDatagridCreateButton } from '../components/EditableDatagridCreateButton';
import { bookSectionIdStore } from '../constants';

export const BookSectionTestListEdit: React.FC = (): ReactElement => {
  const [bookSectionId] = useStore<Identifier>(bookSectionIdStore, null);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const { create, isLoading } = useBulkUploadTests();

  const onOpen = () => {
    setIsBulkUploadOpen(true);
  };

  const onClose = () => {
    setIsBulkUploadOpen(false);
  };

  const onSelect = (tests: Test[]) => {
    create(
      tests.map((test) => ({
        bookSectionId,
        testId: test.id,
        testName: test.testFormNo,
        sortOrder: 1,
      }))
    ).then(() => {
      onClose();
    });
  };

  return (
    <Box sx={commonStyles.editableDataGrid}>
      <Box sx={{ ...commonStyles.flexBox, justifyContent: 'space-between' }}>
        <ListComponentTitle label="Test" />
        <Button onClick={onOpen}>Add Bulk Tests</Button>
      </Box>
      <ReferenceManyField
        reference={RESOURCE_BOOK_SECTION_TEST}
        target="bookSectionId"
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'sortOrder', order: 'ASC' }}
      >
        <EditableDatagrid
          disableAutofocus
          isLoading={isLoading}
          bulkActionButtons={false}
          editForm={<EditForm />}
          createForm={<CreateForm />}
          empty={
            <EditableDatagridCreateButton label="Create Book Section Test" />
          }
        >
          <TextField
            source="test.testFormNo"
            label="Test Form No"
            sortable={false}
          />
          <TextField
            source="test.testName"
            label="Test Name"
            sortable={false}
          />
          <NumberField source="sortOrder" sortable={false} />
        </EditableDatagrid>
      </ReferenceManyField>
      <SelectTestDialog
        bulkUpload
        onClose={onClose}
        open={isBulkUploadOpen}
        onSelect={onSelect}
      />
    </Box>
  );
};

export default BookSectionTestListEdit;
