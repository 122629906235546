import React, { ReactElement } from 'react';
import { useEditableDatagridContext } from '@react-admin/ra-editable-datagrid';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface EditableDatagridCreateButtonProps {
  label: string;
}

export const EditableDatagridCreateButton: React.FC<
  EditableDatagridCreateButtonProps
> = ({ label }): ReactElement => {
  const { openStandaloneCreateForm } = useEditableDatagridContext();

  return (
    <Box>
      <Button onClick={openStandaloneCreateForm} startIcon={<AddIcon />}>
        {label}
      </Button>
    </Box>
  );
};
