import React, { ReactElement } from 'react';
import { SelectInput } from 'react-admin';
import { STRICT_CHOICES } from './constants';
import { SelectInputProps } from './types';

const StrictOptionInput: React.FC<SelectInputProps> = (props): ReactElement => (
  <SelectInput {...props} choices={STRICT_CHOICES} />
);

export default StrictOptionInput;
