import { useCallback, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { FormDataWithFiles } from '../../attachment/types';

type options = {
  onSuccess?: () => void;
};

const useUpdateUpload = (options: options) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const upload = useCallback(
    async (resource: string, data: FormDataWithFiles) => {
      try {
        setUploadInProgress(true);
        const formData = new FormData();
        const { files, ...formValues } = data;

        formData.append('file', files[0]);

        Object.keys(formValues).forEach((key: keyof FormDataWithFiles) => {
          if (key !== 'files') {
            formData.append(key, data[key]);
          }
        });

        const result = await dataProvider.updateWithUpload(resource, formData);

        if (options.onSuccess) {
          options.onSuccess();
        }

        return result;
      } catch (err) {
        notify(getErrorMessage(err), {
          type: 'error',
          undoable: false,
        });
      } finally {
        setUploadInProgress(false);
      }
    },
    [dataProvider, notify, options]
  );

  return { uploadInProgress, upload };
};

export default useUpdateUpload;
