import * as React from 'react';
import { Fragment, useState } from 'react';
import { FormTab, FormTabProps } from 'react-admin';

const LazyFormTab = (props: FormTabProps, sx) => {
  const [loaded, setLoaded] = useState(false);

  if (!loaded && (props.intent === 'header' || !props.hidden)) {
    setLoaded(true);
  }

  return loaded ? (
    <FormTab sx={{ display: 'flex' } || sx} {...props} />
  ) : (
    <Fragment />
  );
};

LazyFormTab.displayName = 'LazyFormTab';

export default LazyFormTab;
