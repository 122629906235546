import React, { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { RESOURCE_PUNCH } from '../constants';
import PunchField from './PunchField';
import commonStyles from './commonStyles';

interface PunchFormShowProps {
  target: string;
}
const PunchFormShow: React.FC<PunchFormShowProps> = ({
  target,
}): ReactElement => (
  <ReferenceManyField target={target} reference={RESOURCE_PUNCH}>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      sx={commonStyles.formTabDatagrid}
    >
      <PunchField source="id" />
      <TextField
        label="Description"
        source="description"
        sx={commonStyles.flexBoxLongItem}
      />
      <TextField source="raisedBy" label="Raised By" />
      <DateField source="raisedDate" label="Raised Date" />
      <TextField source="clearedBy" label="Cleared By" />
      <DateField source="clearedDate" label="Cleared Date" />
      <TextField source="acceptedBy" label="Accepted By" />
      <DateField source="acceptedDate" label="Accepted Date" />
    </Datagrid>
  </ReferenceManyField>
);

export default PunchFormShow;
