import { NumberField, ReferenceManyField, useRecordContext } from 'react-admin';
import {
  EditableDatagrid,
  EditRowButton,
} from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import TestPositionRowForm from './TestPositionRowForm';
import PositionField from '../../common/PositionField';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_TEST_POSITION } from '../../constants';
import DeleteWithConfirmIconButton from '../../common/DeleteWithConfirmIconButton';

const TestPositionFormTab: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <ReferenceManyField target="test_id" reference={RESOURCE_TEST_POSITION}>
      <EditableDatagrid
        disableAutofocus
        bulkActionButtons={false}
        mutationMode="pessimistic"
        createForm={<TestPositionRowForm recordId={record.id} />}
        editForm={<TestPositionRowForm recordId={record.id} />}
        sx={commonStyles.formTabDatagrid}
        actions={
          <>
            <EditRowButton />
            <DeleteWithConfirmIconButton />
          </>
        }
      >
        <PositionField sx={commonStyles.flexBoxItem} />
        <NumberField
          label="Quantity"
          source="posQty"
          sx={commonStyles.flexBoxItem}
        />
        <NumberField
          label="Hours"
          source="posHours"
          sx={commonStyles.flexBoxItem}
        />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default TestPositionFormTab;
