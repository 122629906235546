import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_DIVISION } from '../../constants';
import { required } from '../../../utils/UtilityFunctions';

interface DivisionInputProps {
  label: string;
  source: string;
  isRequired?: boolean;
}

const DivisionInput: React.FC<DivisionInputProps> = ({
  label,
  source,
  isRequired,
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'divisionName', order: 'ASC' as const },
    };
  }, []);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_DIVISION}
      requestParams={requestParams}
      source={source}
      label={label}
      name="divisionName_inc"
      optionText="divisionName"
      validate={isRequired ? [required()] : []}
      sx={commonStyles.flexBoxSelectItem}
      includeFields={['id', 'divisionName']}
    />
  );
};

export default DivisionInput;
