import React, { ReactElement } from 'react';
import { FileInput, FileInputProps } from 'react-admin';
import { useNotifyFileDropErrors } from './hooks/useNotifyFileDropErrors';

const CustomFileInput: React.FC<FileInputProps> = (props): ReactElement => {
  const { multiple, options, maxSize, minSize, accept } = props;

  const handleDrop = useNotifyFileDropErrors({
    accept,
    maxFiles: options?.maxFiles,
    maxSize,
    minSize,
    multiple,
  });

  return (
    <FileInput
      {...props}
      accept={accept}
      options={{ ...options, onDrop: handleDrop }}
    >
      {props.children}
    </FileInput>
  );
};

export default CustomFileInput;
