import { useRecordContext } from 'react-admin';
import React, { ReactElement, useMemo } from 'react';
import commonStyles from '../../../../common/commonStyles';
import { RESOURCE_CERT_GATING_PRED_CERT } from '../../../../constants';
import { CertGating } from '../../../types';
import GatingFormRow from '../GatingFormRow';
import GatingForm from '../GatingForm';
import CertTemplateField from './CertTemplateField';
import CertTemplateInput from './CertTemplateInput';
import { getContext, UserContext } from '../../../../../provider/userContext';

const CertTemplatesForm: React.FC = (): ReactElement => {
  const record = useRecordContext<CertGating>();
  const ctx: UserContext = getContext();

  const additionalFilters = useMemo(() => {
    const selectedIds = record.tblCertGatingPredCerts.map(
      (predCertTemplate) => predCertTemplate.predCertTemplate.id
    );
    if (selectedIds.length > 0) return { id_neq_any: selectedIds };
  }, [record.tblCertGatingPredCerts]);

  const formRow = (
    <GatingFormRow
      recordId={record?.id}
      projectId={ctx?.projectId}
      reference={RESOURCE_CERT_GATING_PRED_CERT}
    >
      <CertTemplateInput
        sx={commonStyles.rowFormInput}
        isRequired
        label="Predecessor Certificate"
        additionalFilters={additionalFilters}
      />
    </GatingFormRow>
  );

  return (
    <GatingForm formRow={formRow} reference={RESOURCE_CERT_GATING_PRED_CERT}>
      <CertTemplateField
        sx={commonStyles.rowFormField}
        label="Predecessor Certificate"
      />
    </GatingForm>
  );
};

export default CertTemplatesForm;
