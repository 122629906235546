import { TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import { RESOURCE_TEST_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface TestTypeProps {
  label?: string;
  source?: string;
  sx?: object;
  textSource?: string;
  sortBy?: string;
}

const TestTypeField: React.FC<TestTypeProps> = ({
  label,
  source,
  sx,
  textSource,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_TEST_TYPE}>
    <TextField source={textSource} sx={sx} />
  </SmartLinkField>
);

TestTypeField.defaultProps = {
  label: 'Test Type',
  source: 'testTypeId',
  textSource: 'testType.testType',
  sortBy: '`testType.testType`',
};

export default TestTypeField;
