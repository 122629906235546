import React, { ReactElement } from 'react';
import { getContext, UserContext } from '../../../../../provider/userContext';
import commonStyles from '../../../../common/commonStyles';
import ComponentInput from '../../../../common/ComponentInput';
import { RESOURCE_MOC_COMPONENT } from '../../../../constants';
import { FormRow } from '../../../types';
import RelatedFormRow from '../RelatedFormRow';

const ComponentFormRow: React.FC<FormRow> = ({ recordId }): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <RelatedFormRow
      recordId={recordId}
      projectId={ctx?.projectId}
      reference={RESOURCE_MOC_COMPONENT}
    >
      <ComponentInput sx={commonStyles.rowFormInput} isRequired />
    </RelatedFormRow>
  );
};

export default ComponentFormRow;
