import { ContactCategories } from '../common/contactInput/constants';

export const categoryChoices = [
  { id: ContactCategories.component, name: 'Component' },
  { id: ContactCategories.email, name: 'eMail' },
  { id: ContactCategories.testPack, name: 'Test Pack' },
  { id: ContactCategories.testUnits, name: 'Test Units' },
  { id: ContactCategories.punchSignOff, name: 'Punch Sign Off' },
  {
    id: ContactCategories.preservationEmpWorkGrp,
    name: 'Preservation Employee Work Group',
  },
  { id: ContactCategories.itrEmpWorkGrp, name: 'ITR Employee Work Group' },
  {
    id: ContactCategories.drawingEngineeringGrp,
    name: 'Drawing Engineering Group',
  },
  { id: ContactCategories.certSignOff, name: 'Certificate Sign Off' },
];

export const LIST_CATEGORIES_WITHOUT_CONTACTS = [
  ContactCategories.component,
  ContactCategories.email,
  ContactCategories.testPack,
  ContactCategories.testUnits,
];
