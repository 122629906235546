import React, { ReactElement, Fragment } from 'react';
import { RESOURCE_EQUIPMENT_TYPE } from '../constants';
import BulkEditButton from '../common/BulkEditButton';
import EquipmentTypeForm from './EquipmentTypeForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_EQUIPMENT_TYPE}
        form={<EquipmentTypeForm bulkEditMode />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
