import React, { ReactElement } from 'react';
import {
  SingleFieldList,
  SingleFieldListProps,
  useResourceContext,
} from 'react-admin';
import useGetLinkType from './hooks/useGetLinkType';

const SmartSingleFieldList: React.FC<SingleFieldListProps> = ({
  ...props
}): ReactElement => {
  const { children, ...rest } = props;
  const resource = useResourceContext();

  return (
    <SingleFieldList linkType={useGetLinkType(resource)} {...rest}>
      {children}
    </SingleFieldList>
  );
};

export default SmartSingleFieldList;
