import React, { ReactElement, useMemo } from 'react';
import { SxProps, Theme } from '@mui/material';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from '../common/AutocompleteReferenceInput';
import { RESOURCE_SECURITY_ROLE } from '../constants';

interface SecurityRoleInputProps {
  sx?: SxProps<Theme>;
}

const SecurityRoleInput: React.FC<SecurityRoleInputProps> = ({
  sx,
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'role', order: 'ASC' as const },
      filter: { isDeleted: false },
    };
  }, []);

  const fromGroupRolesToRolesIds = (groupRoles) =>
    groupRoles?.map(({ roleId }) => roleId);

  const fromRoleIdsToGroupRoles = (roleIds) =>
    roleIds?.map((roleId) => ({ roleId }));

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_SECURITY_ROLE}
      requestParams={requestParams}
      label="Roles"
      optionText="role"
      name="role_inc"
      source="groupRoles"
      format={fromGroupRolesToRolesIds}
      parse={fromRoleIdsToGroupRoles}
      validate={[required()]}
      multiple
      fullWidth
      sx={sx}
      includeFields={['id', 'role']}
    />
  );
};

export default SecurityRoleInput;
