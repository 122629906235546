import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_EQUIPMENT_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface EquipmentTypeFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}
const EquipmentTypeField: React.FC<EquipmentTypeFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField
    source={source}
    label={label}
    resource={RESOURCE_EQUIPMENT_TYPE}
  >
    <TextField source={textSource} />
  </SmartLinkField>
);

EquipmentTypeField.defaultProps = {
  label: 'Equipment Type',
  source: 'equipTypeId',
  textSource: 'equipType.equipType',
  sortBy: '`equipType.equipType`',
};

export default EquipmentTypeField;
