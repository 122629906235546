import React, { ReactElement } from 'react';
import { DateField, TextField, useRecordContext } from 'react-admin';
import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import commonStyles from '../../../../common/commonStyles';
import ComponentField from '../../../../common/ComponentField';
import CustomBooleanField from '../../../../common/CustomBooleanField';
import TestField from '../../../../common/TestField';
import { RESOURCE_COMPONENT_TEST } from '../../../../constants';
import { FormTabProps, QrScanList } from '../../../types';
import DataFormTab from '../DataFormTab';
import ItrFormRow from './ItrFormRow';

const ItrFormTab: React.FC<FormTabProps> = ({ editMode }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const record = useRecordContext<QrScanList>();
  const data = record.qrs.itrs;

  return (
    <DataFormTab
      data={data}
      attachmentId={record.id}
      resource={RESOURCE_COMPONENT_TEST}
      editFormRow={<ItrFormRow data={data} />}
      editMode={editMode}
    >
      <ComponentField
        source="component.id"
        label={labels['tagNo'] || 'Tag No'}
      />
      <TestField
        source="test.id"
        textSource="test.testFormNo"
        label={labels['testNo'] || 'Test No'}
      />
      <TextField
        source="completedBy"
        label={labels['completedBy'] || 'Completed By'}
        sx={commonStyles.rowFormField}
      />
      <DateField
        source="completedDateTime"
        label={labels['completedTestDate'] || 'Completed Test Date'}
        sx={commonStyles.rowFormField}
      />
      <CustomBooleanField
        source="isVerified"
        label="Verified"
        sx={commonStyles.rowFormShortField}
      />
    </DataFormTab>
  );
};

export default ItrFormTab;
