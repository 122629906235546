import React, { ReactElement } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { getStatusValue } from '../../helpers';
import { MaterialReleased } from '../types';

const StatusField: React.FC<
  Omit<FunctionFieldProps<MaterialReleased>, 'render'>
> = (props): ReactElement => (
  <FunctionField {...props} render={getStatusValue} />
);

StatusField.defaultProps = {
  source: 'status',
  label: 'Status',
};

export default StatusField;
