import React, { ReactElement } from 'react';
import { SelectInput } from 'react-admin';
import { PRES_AUTOMATION_CHOICES } from '../constants';
import commonStyles from '../../common/commonStyles';

const PresAutomationInput: React.FC = (): ReactElement => {
  return (
    <SelectInput
      source="presAutomation"
      label="Require Verified By and Verified Date"
      choices={PRES_AUTOMATION_CHOICES}
      sx={commonStyles.flexBoxSelectItem}
    />
  );
};

export default PresAutomationInput;
