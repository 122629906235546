import { SxProps, Theme } from '@mui/material/styles';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { minValue, NumberInput, NumberInputProps } from 'react-admin';
import { ensureArray, required } from '../../../utils/UtilityFunctions';
import { formatFromCents, parseToCents } from './helpers';

export interface PriceInputProps extends Omit<NumberInputProps, 'source'> {
  label?: string;
  source?: string;
  name?: string;
  sx?: SxProps<Theme>;
  min?: number;
  isRequired?: boolean;
}

const PriceInput: React.FC<PriceInputProps> = ({
  label,
  source,
  name,
  sx,
  min,
  isRequired,
  validate = [minValue(0)],
  format = (val) => val,
  parse = (val) => val,
  ...rest
}): ReactElement => {
  const validators = useMemo(() => {
    const result = [...ensureArray(validate)];
    if (isRequired) {
      result.push(required());
    }
    return result;
  }, [validate, isRequired]);

  const formatter = useCallback(
    (val) => format(formatFromCents(val)),
    [format]
  );
  const parser = useCallback((val) => parse(parseToCents(val)), [parse]);

  return (
    <NumberInput
      {...rest}
      source={source}
      name={name}
      label={label}
      min={min}
      validate={validators}
      format={formatter}
      parse={parser}
      sx={sx}
    />
  );
};

PriceInput.defaultProps = {
  label: 'Price',
  source: 'price',
  name: 'price',
  min: 0,
};

export default PriceInput;
