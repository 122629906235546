import React, { ReactElement } from 'react';
import { RESOURCE_SYSTEM } from '../../../constants';
import RelatedArrayField from './RelatedArrayField';

interface RelatedSystemsFieldProps {
  label?: string;
}

const RelatedSystemsField: React.FC<
  RelatedSystemsFieldProps
> = (): ReactElement => (
  <RelatedArrayField
    source="tblMocSystems"
    resource={RESOURCE_SYSTEM}
    recordName="system"
    itemNameResource="system.systemName"
  />
);

RelatedSystemsField.defaultProps = { label: 'Systems' };

export default RelatedSystemsField;
