import React from 'react';
import ContactSupportLink from '../../common/ContactSupportLink';

const Error400: React.FC = () => {
  return (
    <div>
      <p>
        We have problems with your request, please contact with support{' '}
        <ContactSupportLink />
      </p>
    </div>
  );
};

export default Error400;
