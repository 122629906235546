import React, { ReactElement, useCallback } from 'react';
import { useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import useCurrentProjectSettings from '../../common/hooks/useCurrentProjectSettings';
import { getDiffObject, getStepActions } from '../../common/JSONForm/helpers';
import { getContext, UserContext } from '../../../provider/userContext';
import useCanAccess from '../../common/hooks/useCanAccess';
import JSONForm from '../../common/JSONForm/JSONForm';
import TextInput from '../../common/TextInput';
import { SmartTagItr } from '../types';

interface JSONFormTabProps {
  setPendingPunchTitle: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const JSONFormTab: React.FC<JSONFormTabProps> = ({
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => {
  const record = useRecordContext();
  const ctx: UserContext = getContext();
  const canAccessList = useCanAccess();
  const { getValues, setValue } = useFormContext<SmartTagItr>();
  const {
    currentProjectSettings: { isItrPunchForcedCreationEnabled },
  } = useCurrentProjectSettings();

  const onChange = useCallback(
    (errors: object[], data: object) => {
      // Remove Required function in JSON forms
      // https://trackertech.monday.com/boards/195709125/views/141874149/pulses/7315991016
      // if (data && errors.length === 0) {
      if (data) {
        const formData = getValues('formData') || {};
        const { diffObj, op, key } = getDiffObject(formData, data);
        if (diffObj && key) {
          data['stepActions'] = {
            ...(data['stepActions'] || {}),
            ...getStepActions(key, op, ctx?.fullName),
          };

          // Open Create Pending Punch Dialog
          if (diffObj[0]?.value === 'punchlist') {
            setPendingPunchTitle(
              record?.formSchema?.properties?.[key]?.title || null
            );
            if (isItrPunchForcedCreationEnabled) {
              setPendingPunchCreateDialogCloseDisabled(true);
            }
          }
        }

        setValue('formData', data, { shouldDirty: true });
      }
    },
    [
      getValues,
      setValue,
      ctx?.fullName,
      setPendingPunchTitle,
      record?.formSchema?.properties,
      isItrPunchForcedCreationEnabled,
      setPendingPunchCreateDialogCloseDisabled,
    ]
  );

  return (
    <Box>
      <TextInput name="formData" source="formData" hidden={true} />
      <TextInput
        name="attachmentRevisionId"
        source="attachmentRevisionId"
        hidden={true}
      />
      <JSONForm
        formSchema={record?.formSchema}
        formUiSchema={record?.formUiSchema}
        formData={record?.formData}
        readonly={!canAccessList.edit}
        onChange={onChange}
      />
    </Box>
  );
};

export default JSONFormTab;
