import { useRecordContext } from 'react-admin';
import { AssignedType } from '../enums';

const useAssignedField = (sourcePrefix?: string) => {
  const record = useRecordContext();

  const {
    assignedContactId, // assignedGroupId,
    assignedUserGroupId,
    assignedEmail,
  } = sourcePrefix ? record?.[sourcePrefix] || {} : record;
  const prefix = sourcePrefix ? sourcePrefix + '.' : '';

  if (assignedContactId) {
    return {
      assignedType: AssignedType.CONTACT,
      source: prefix + 'assignedContactId',
      textFieldSource: prefix + 'assignedContact.nickname',
    };
  }

  // if (assignedGroupId) {
  //   return {
  //     assignedType: AssignedType.GROUP,
  //     source: prefix + 'assignedGroupId',
  //     textFieldSource: prefix + 'assignedGroup.groupName',
  //   };
  // }

  if (assignedUserGroupId) {
    return {
      assignedType: AssignedType.USER_GROUP,
      source: prefix + 'assignedUserGroupId',
      textFieldSource: prefix + 'assignedUserGroup.userGroup',
    };
  }

  if (assignedEmail) {
    return {
      assignedType: AssignedType.EMAIL,
      source: prefix + 'assignedEmail',
    };
  }

  return null;
};

export default useAssignedField;
