import React, { useMemo, ReactElement } from 'react';
import { DateField, maxLength, TextField } from 'react-admin';
import { RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import CertTemplateField from '../common/CertTemplateField';
import { ContactCategories } from '../common/contactInput/constants';
import ContactInput from '../common/contactInput/ContactInput';
import CustomDateInput from '../common/CustomDateInput';
import DisciplineField from '../common/DisciplineField';
import ActivityPhaseField from '../common/ActivityPhaseField';
import commonStyles from '../common/commonStyles';
import { CertDetailsRowFormProps } from './types';
import { validateStatusFields } from '../common/validators';
import VerifiedField from './components/VerifiedField';

const CertDetailsRowForm: React.FC<CertDetailsRowFormProps> = ({
  recordId,
  onSuccess,
  children,
}): ReactElement => {
  const { close } = useRowContext();

  const mutationOptions = useMemo(() => {
    if (onSuccess) {
      return {
        onSuccess: async () => {
          await onSuccess();
          close();
        },
      };
    } else {
      return undefined;
    }
  }, [close, onSuccess]);

  return (
    <RowForm
      transform={(data) => ({
        ...data,
        drawingId: recordId,
      })}
      mutationOptions={mutationOptions}
    >
      <TextField source="id" label="ID" />
      {children}
      <ActivityPhaseField />
      <CertTemplateField />
      <DisciplineField />
      <TextField source="generatedBy" label="Generated By" />
      <DateField
        source="generatedDate"
        label="Generated Date"
        showTime={false}
      />
      <ContactInput
        category={ContactCategories.certSignOff}
        source="acceptedBy"
        label="Accepted By"
        validate={[validateStatusFields('accepted'), maxLength(75)]}
        sx={commonStyles.rowFormInput}
      />
      <CustomDateInput
        label="Accepted Date"
        source="acceptedDate"
        validate={validateStatusFields('accepted')}
        sx={commonStyles.rowFormInput}
      />
      <VerifiedField />
    </RowForm>
  );
};

export default CertDetailsRowForm;
