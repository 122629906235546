import React, { ReactElement } from 'react';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import TestInput from '../../common/TestInput';
import commonStyles from '../../common/commonStyles';
import { getContext, UserContext } from '../../../provider/userContext';

interface PreservationTestInputProps {
  isRequired?: boolean;
}

const PreservationTestInput: React.FC<PreservationTestInputProps> = ({
  isRequired = true,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <TestInput
      sx={commonStyles.flexBoxItem}
      label={labels['testId']}
      name="testFormNo_inc"
      optionText="testFormNo"
      requestParams={{
        filter: { is_preservation: true, divisionId: ctx?.defaultDivisionId },
        sort: { field: 'testFormNo', order: 'ASC' },
      }}
      isRequired={isRequired}
    />
  );
};

export default PreservationTestInput;
