import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import commonStyles from '../../../../common/commonStyles';
import { RESOURCE_MOC_SUBSYSTEM } from '../../../../constants';
import RelatedForm from '../RelatedForm';
import SubsystemField from '../../../../common/SubsystemField';
import SubSystemFormRow from './SubSystemFormRow';

const SubSystemForm: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <RelatedForm
      formRow={<SubSystemFormRow recordId={record?.id} />}
      reference={RESOURCE_MOC_SUBSYSTEM}
    >
      <SubsystemField sx={commonStyles.rowFormField} />
    </RelatedForm>
  );
};

export default SubSystemForm;
