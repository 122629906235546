import React, { Fragment, ReactElement, useCallback } from 'react';
import TextField from '@mui/material/TextField';

interface NumberRangeFilterProps {
  label: string;
  source: string;
  numberFrom: { [key: string]: number };
  numberTo: { [key: string]: number };
  handleNumberFromChange: (value: number, type: string) => void;
  handleNumberToChange: (value: number, type: string) => void;
  alwaysOn?: boolean;
  fullWidth?: boolean;
}

const NumberRangeFilter: React.FC<NumberRangeFilterProps> = ({
  label,
  source,
  numberFrom,
  numberTo,
  handleNumberFromChange: handleNumberFromChangeFromProps,
  handleNumberToChange: handleNumberToChangeFromProps,
  fullWidth,
}): ReactElement => {
  const handleNumberFromChange = useCallback(
    (event) => {
      handleNumberFromChangeFromProps(event?.target?.value, source);
    },
    [handleNumberFromChangeFromProps, source]
  );
  const handleNumberToChange = useCallback(
    (event) => {
      handleNumberToChangeFromProps(event?.target?.value, source);
    },
    [handleNumberToChangeFromProps, source]
  );

  return (
    <Fragment>
      <TextField
        label={`${label} From`}
        value={numberFrom[source] || ''}
        onChange={handleNumberFromChange}
        InputProps={{ inputProps: { max: numberTo[source] } }}
        sx={{ mb: 3 }}
        type="number"
        fullWidth={fullWidth}
      />
      <TextField
        label={`${label} To`}
        value={numberTo[source] || ''}
        onChange={handleNumberToChange}
        InputProps={{ inputProps: { min: numberFrom[source] } }}
        type="number"
        fullWidth={fullWidth}
      />
    </Fragment>
  );
};

export default NumberRangeFilter;
