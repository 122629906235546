import React from 'react';
import { UserContext, getContext } from '../../provider/userContext';
import { DrawingPrefixType } from './type';
import CreateWithTitle from '../common/CreateWithTitle';
import { SimpleForm, maxLength } from 'react-admin';
import FooterToolbar from '../common/FooterToolbar';
import { Box } from '@mui/material';
import commonStyles from '../common/commonStyles';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const DrawingPrefixCreate: React.FC = (): React.ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: DrawingPrefixType): DrawingPrefixType => ({
    ...data,
    projectId: ctx?.projectId,
  });
  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="prefix"
            label="Prefix"
            sx={commonStyles.flexBoxItem}
            validate={[required(), maxLength(50)]}
          />
        </Box>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="description"
            label="Description"
            sx={commonStyles.flexBoxLongItem}
            validate={[maxLength(255)]}
            fullWidth
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default DrawingPrefixCreate;
