import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_LOCATION } from '../constants';
import { required } from '../../utils/UtilityFunctions';

interface LocationInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
}

const LocationInput: React.FC<LocationInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'location', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_LOCATION}
      requestParams={requestParams}
      name="location_inc"
      optionText="location"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'location']}
    />
  );
};

LocationInput.defaultProps = { source: 'locationId', label: 'Location' };

export default LocationInput;
