import React, { ReactElement } from 'react';
import { RESOURCE_SUBSYSTEM } from '../../../constants';
import RelatedArrayField from './RelatedArrayField';

interface RelatedSubsystemsFieldProps {
  label?: string;
}

const RelatedSubsystemsField: React.FC<
  RelatedSubsystemsFieldProps
> = (): ReactElement => (
  <RelatedArrayField
    source="tblMocSubsystems"
    resource={RESOURCE_SUBSYSTEM}
    recordName="subsystem"
    itemNameResource="subsystem.subsystem"
  />
);

RelatedSubsystemsField.defaultProps = { label: 'Subsystems' };

export default RelatedSubsystemsField;
