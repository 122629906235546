import React, { ReactElement, useCallback, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  UserContext,
  getContext,
  updateContext,
} from '../../provider/userContext';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import { RESOURCE_USER } from '../constants';
import Checkbox from './Checkbox';
import IframeSection from './IframeSection';
import { useIframeHeight } from './useIframeHeight';
import {
  STATIC_DOCS_BASE_URL,
  PRIVACY_POLICY_FILE_PATH,
  TERMS_AND_CONDITIONS_FILE_PATH,
} from './constants';
import { styles } from './styles';

const ModalApproval: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const [privacyPolicyLoaded, setPrivacyPolicyLoaded] = useState(false);
  const [termConditionsLoaded, setTermConditionsLoaded] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false);
  const [isPrivacyPolicyIframe, setIsPrivacyPolicyIframe] = useState(true);
  const [modalVisible, setModalVisible] = useState(
    !ctx?.isTermsAndPrivacyPolicyAccepted
  );
  const iframeHeight = useIframeHeight();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate } = useMutation({
    mutationFn: () =>
      dataProvider.update(RESOURCE_USER, {
        id: ctx.id,
        data: { isTermsAndPrivacyPolicyAccepted: true },
        previousData: {},
      }),
    onSuccess: async ({ data }) => {
      if (data?.isTermsAndPrivacyPolicyAccepted) {
        updateContext({
          isTermsAndPrivacyPolicyAccepted: data.isTermsAndPrivacyPolicyAccepted,
        });
        setModalVisible(false);
      }
    },
    onError: (error) => {
      notify(getErrorMessage(error, 'Update User failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  const handleCheckboxChange = useCallback(
    (isPrivacyPolicyCheckbox = false) =>
      (event) => {
        if (isPrivacyPolicyCheckbox) {
          setPrivacyPolicyChecked(event.target.checked);
        } else {
          setTermsAndConditionsChecked(event.target.checked);
        }
      },
    []
  );

  const handlePrivacyPolicyNextBtnClick = useCallback(() => {
    setIsPrivacyPolicyIframe(false);
  }, []);

  const handleTermsAndConditionsBackBtnClick = useCallback(() => {
    setIsPrivacyPolicyIframe(true);
  }, []);

  const handleTermsAndConditionsNextBtnClick = useCallback(async () => {
    setTermConditionsLoaded(false);
    mutate();
  }, [mutate]);

  const handlePrivacyPolicyLoad = () => {
    setPrivacyPolicyLoaded(true);
  };

  const handleTermConditionsLoad = () => {
    setTermConditionsLoaded(true);
  };

  const currentSection = isPrivacyPolicyIframe
    ? 'privacy-policy'
    : 'term-conditions';

  const mapper = {
    'privacy-policy': {
      loaded: privacyPolicyLoaded,
      onLoad: handlePrivacyPolicyLoad,
      src: `${STATIC_DOCS_BASE_URL}/${PRIVACY_POLICY_FILE_PATH}`,
      checked: privacyPolicyChecked,
      label: 'I Agree to the Privacy Policy',
      onChange: handleCheckboxChange,
      nextBtnClick: handlePrivacyPolicyNextBtnClick,
      backBtnClick: null,
    },
    'term-conditions': {
      loaded: termConditionsLoaded,
      onLoad: handleTermConditionsLoad,
      src: `${STATIC_DOCS_BASE_URL}/${TERMS_AND_CONDITIONS_FILE_PATH}`,
      checked: termsAndConditionsChecked,
      label: 'I Agree to the Terms of Use',
      onChange: handleCheckboxChange,
      nextBtnClick: handleTermsAndConditionsNextBtnClick,
      backBtnClick: handleTermsAndConditionsBackBtnClick,
    },
  };

  return (
    <Dialog
      open={modalVisible}
      maxWidth="xl"
      sx={{ zIndex: 100000 }}
      id="modal_approval"
    >
      <DialogContent sx={{ width: '920px' }}>
        <IframeSection
          loaded={mapper[currentSection].loaded}
          onLoad={mapper[currentSection].onLoad}
          src={mapper[currentSection].src}
          iframeHeight={iframeHeight}
        />
        <Box sx={styles.modalFormWrapper}>
          <Checkbox
            disabled={!mapper[currentSection].loaded}
            label={mapper[currentSection].label}
            onChange={mapper[currentSection].onChange(
              currentSection === 'privacy-policy'
            )}
            checked={mapper[currentSection].checked}
            required
            id="terms_policy_agree_checkbox"
          />
          <Box sx={styles.modalButtonWrapper}>
            {mapper[currentSection].backBtnClick && (
              <Button
                id="back_button"
                startIcon={<ArrowBack />}
                onClick={mapper[currentSection].backBtnClick}
              >
                Back
              </Button>
            )}
            <Button
              id="next_button"
              variant="contained"
              endIcon={<ArrowForward />}
              onClick={mapper[currentSection].nextBtnClick}
              disabled={
                !mapper[currentSection].checked ||
                (currentSection === 'term-conditions' && !privacyPolicyChecked)
              }
            >
              Next
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalApproval;
