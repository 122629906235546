import { Fragment, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import {
  useRefresh,
  useDelete,
  Confirm,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DeleteIconButton from '../../../components/common/DeleteIconButton';
import useHardDelete from '../../..//components/common/hooks/useHardDelete';
import { RESOURCE_ATTACHMENT } from '../../..//components/constants';
import useRefreshCompanyContext from '../../..//components/common/hooks/useRefreshCompanyContext';
import canUserAccessProject from '../helpers/canUserAccessProject';
import isProjectCurrentProject from '../helpers/isProjectCurrentProject';

const DeleteProjectWithConfirmIconButton = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const { hardDeleteFile } = useHardDelete();
  const refreshCompanyContext = useRefreshCompanyContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const refresh = useRefresh();
  const [deleteOne] = useDelete();

  const handleDeleteClick = useCallback(() => {
    if (isProjectCurrentProject(record.id)) {
      setIsDialogOpen(true);
    } else {
      setIsConfirmOpen(true);
    }
  }, [record.id]);

  const handleConfirmDelete = useCallback(async () => {
    const isCurrentUserProject = canUserAccessProject(record.id);

    await deleteOne(resource, { id: record.id, previousData: record });
    setIsConfirmOpen(false);

    if (record?.attachment?.id) {
      await hardDeleteFile(RESOURCE_ATTACHMENT, {
        id: Number(record.attachment.id),
      });
    }

    if (isCurrentUserProject) {
      await refreshCompanyContext();
      await refresh();
    }
  }, [
    deleteOne,
    hardDeleteFile,
    record,
    refresh,
    refreshCompanyContext,
    resource,
  ]);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleConfirmClose = useCallback(() => {
    setIsConfirmOpen(false);
  }, []);

  return (
    <Fragment>
      <DeleteIconButton
        onClick={handleDeleteClick}
        className="ra-delete-button"
      />
      <Confirm
        isOpen={isConfirmOpen}
        title={`Delete Project: ${record?.project}`}
        content={'Are you sure you want to delete this Project?'}
        onConfirm={handleConfirmDelete}
        onClose={handleConfirmClose}
      />
      <Dialog open={isDialogOpen}>
        <DialogTitle>Cannot Remove Project</DialogTitle>
        <DialogContent>
          You cannot remove the active project. Please switch to a different
          project and try again.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeleteProjectWithConfirmIconButton;
