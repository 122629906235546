import React, { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';

const SecurityRoleCreate: React.FC = (): ReactElement => (
  <CreateWithTitle redirect="list">
    <SimpleForm toolbar={<FooterToolbar />}>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="role"
          label="Role"
          validate={[required(), maxLength(35)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </SimpleForm>
  </CreateWithTitle>
);

export default SecurityRoleCreate;
