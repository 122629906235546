import React, { ReactElement, useState } from 'react';
import { useRedirect, useLogin, Button } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getAuthProvider } from '../provider/authProvider/authProvider';

const EmptyLogin: React.FC = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRedirecting, setIsRedirecting] = useState(true);
  const redirect = useRedirect();
  const login = useLogin();

  if (searchParams.has('apiKey')) {
    login({})
      .then(() => {
        redirect('/');
      })
      .catch(() => {
        setIsRedirecting(false);
        setSearchParams({});
      });
  } else {
    getAuthProvider()
      .checkAuth(undefined)
      .then(() => redirect('/'))
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
      .then();
  }

  const handleClick = () => (window.location.href = '/');

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '150px',
      }}
    >
      <Typography component="h4" color={'textSecondary'} align={'center'}>
        <Box>
          {isRedirecting
            ? 'Redirecting login page...'
            : 'Invalid apiKey or smartITRUser'}
        </Box>
        <Button onClick={handleClick} label="Go to Login" />
      </Typography>
    </Box>
  );
};

export default EmptyLogin;
