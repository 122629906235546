import { useMemo } from 'react';
import { UserContext, getContext } from '../../../provider/userContext';

const useGetLookupFilter = () => {
  const ctx: UserContext = getContext();

  return useMemo(
    () => ({
      projectId: ctx?.projectId,
      divisionId: ctx?.defaultDivisionId,
    }),
    [ctx?.defaultDivisionId, ctx?.projectId]
  );
};

export default useGetLookupFilter;
