import React, { ReactElement } from 'react';
import { AutocompleteInput, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { resourceChoices } from './constants';

interface ResourceInputProps {
  validate?: Validator[];
  sx: SxProps<Theme>;
  alwaysOn?: boolean;
  source?: string;
}

const ResourceInput: React.FC<ResourceInputProps> = ({
  validate,
  sx,
  alwaysOn,
}): ReactElement => (
  <AutocompleteInput
    source="resourceName"
    label="Resource"
    choices={resourceChoices}
    validate={validate ?? []}
    sx={sx}
    alwaysOn={alwaysOn}
  />
);

ResourceInput.defaultProps = { source: 'resource' };

export default ResourceInput;
