import React, { ReactElement, useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { RESOURCE_MOC } from '../../constants';
import { getContext, UserContext } from '../../../provider/userContext';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../../utils/UtilityFunctions';

export interface ParentMocInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx: SxProps<Theme>;
}

const ParentMocInput: React.FC<ParentMocInputProps> = ({
  isRequired,
  alwaysOn,
  multiple,
  sx,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const record = useRecordContext();

  const filter = useMemo(() => {
    return {
      projectId: ctx?.projectId,
      ...(record?.id && { id_neq: record.id }),
    };
  }, [ctx?.projectId, record?.id]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_MOC}
      requestParams={{ filter }}
      label="Parent MoC"
      source="parentMocId"
      name="mocNo_inc"
      optionText="mocNo"
      sx={sx}
      multiple={multiple}
      alwaysOn={alwaysOn}
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'mocNo']}
    />
  );
};

export default ParentMocInput;
