import React, { ReactElement } from 'react';
import { ImportResult } from '../types';
import Box from '@mui/material/Box';
import { DownloadLink } from '../../DownloadLink';

interface DownloadFileLinkProps {
  importResult: ImportResult;
  onClick?: (e) => void;
}

const DownloadErrorFileLink: React.FC<DownloadFileLinkProps> = ({
  importResult,
  onClick,
}): ReactElement => {
  if (!importResult.errorsFile) {
    return null;
  }

  return (
    <Box>
      <span>You can</span>
      <DownloadLink
        onClick={onClick}
        downloadUrl={importResult.errorsFile.downloadUrl as string}
        title="File with errors"
      />
      file with error rows
    </Box>
  );
};

export default DownloadErrorFileLink;
