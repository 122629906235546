import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useResourceContext, useStore } from 'react-admin';
import { checkIsMenuOpenAndCloseIt, openMenu } from '../../menu/helper';

const applyClassNames = (smartTabWide: boolean) => {
  const smartList = document.getElementsByClassName('smartList')?.[0];
  if (smartList) {
    smartTabWide
      ? smartList.classList.add('wide')
      : smartList.classList.remove('wide');
  }
};

const useSmartTabWideNarrow = (
  visibleColumnsInWideModeInit: string[],
  defaultVisiableColumnsInit: string[]
) => {
  const resource = useResourceContext();

  const [visibleColumnsInWideMode] = useState(visibleColumnsInWideModeInit);
  const [defaultVisibleColumns] = useState(defaultVisiableColumnsInit);

  const [availableColumns] = useStore(
    `preferences.${resource}.datagrid.availableColumns`
  );

  const defaultVisibleColumnsIndexes = useMemo(() => {
    return availableColumns
      ?.filter(
        (column) =>
          defaultVisibleColumns.includes(column.source) ||
          column.label === 'Actions'
      )
      .map((column) => column.index);
  }, [defaultVisibleColumns, availableColumns]);

  const visibleColumnsInWideModeIndexes = useMemo(() => {
    return availableColumns
      ?.filter(
        (column) =>
          visibleColumnsInWideMode.includes(column.source) ||
          column.label === 'Actions'
      )
      .map((column) => column.index);
  }, [visibleColumnsInWideMode, availableColumns]);

  const [smartTabWide] = useStore(
    `preferences.${resource}.datagrid.smartTabWide`,
    true
  );
  const [columns, setColumns] = useStore(
    `preferences.${resource}.datagrid.columns`,
    defaultVisibleColumnsIndexes
  );
  const [previousColumns, setPreviousColumns] = useStore(
    `preferences.${resource}.datagrid.previousColumns`
  );
  const [expanded] = useStore(`${resource}.datagrid.expanded`, []);

  const wideAdjustmentsApplied = useRef(false);
  const wasMenuOpenRef = useRef(false);

  const setVisibleColumns = useCallback(() => {
    !previousColumns && setPreviousColumns(columns);
    setColumns(visibleColumnsInWideModeIndexes);
  }, [
    columns,
    previousColumns,
    setColumns,
    setPreviousColumns,
    visibleColumnsInWideModeIndexes,
  ]);

  const doWideAdjustments = useCallback(() => {
    applyClassNames(true);
    wasMenuOpenRef.current = checkIsMenuOpenAndCloseIt();
    setVisibleColumns();
  }, [setVisibleColumns]);

  const undoWideAdjustments = useCallback(() => {
    applyClassNames(false);
    if (previousColumns) {
      setColumns(previousColumns);
      setPreviousColumns(undefined);
    }
    if (wasMenuOpenRef.current) {
      openMenu();
    }
  }, [previousColumns, setColumns, setPreviousColumns]);

  const isWide = !!expanded.length && smartTabWide;

  useEffect(() => {
    if (isWide) {
      if (wideAdjustmentsApplied.current) {
        return;
      }
      wideAdjustmentsApplied.current = true;
      doWideAdjustments();
    } else {
      if (!wideAdjustmentsApplied.current) {
        return;
      }
      wideAdjustmentsApplied.current = false;
      undoWideAdjustments();
    }
  }, [doWideAdjustments, isWide, undoWideAdjustments]);

  useEffect(() => {
    return () => {
      if (wasMenuOpenRef.current) {
        openMenu();
      }
    };
  }, []);

  return isWide;
};

export default useSmartTabWideNarrow;
