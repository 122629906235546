import React, { ReactElement } from 'react';
import { ListProps, TextField } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import { EQUIPMENT_TYPE_TEST_EXPORT_BUTTON_INFO } from './constants';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import MultipleExportButton from '../common/MultipleExportButton';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import CustomBooleanField from '../common/CustomBooleanField';
import DisciplineField from '../common/DisciplineField';
import ListWithTitle from '../common/ListWithTitle';
import EquipmentTypeShow from './EquipmentTypeShow';
import EquipmentTypeEdit from './EquipmentTypeEdit';
import ColumnActions from '../common/ColumnActions';
import EquipmentTypeTest from './EquipmentTypeTest';
import BulkActionButtons from './BulkActionButtons';
import ImportButton from '../common/ImportButton';
import ListActions from '../common/ListActions';

const EquipmentTypeList: React.FC<ListProps> = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          additionalExportButton={
            <MultipleExportButton
              buttonsInfo={EQUIPMENT_TYPE_TEST_EXPORT_BUTTON_INFO}
            />
          }
          additionalImportButton={
            <ImportButton
              source="equipment-type-with-test"
              label="Import Eq. Type Tests"
            />
          }
        />
      }
      sort={{ field: 'equipType', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<EquipmentTypeShow withoutActions />}
        EditComponent={<EquipmentTypeEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'equipType',
          'description',
          'disciplineId',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="equipType" label="Type" />
        <TextField source="description" label="Description" />
        <EquipmentTypeTest label="Tests" sortable={false} />
        <TextField source="hardSoft" label="Hard/Soft" />
        <CustomBooleanField source="metricsCount" label="Metrics Count" />
        <TextField source="grouping" label="Grouping" sortable={false} />
        <DisciplineField source="disciplineId" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default EquipmentTypeList;
