import React, { ReactElement } from 'react';
import {
  SimpleForm,
  ListButton,
  TextField,
  DateField,
  Labeled,
  useRecordContext,
  maxLength,
} from 'react-admin';
import { Card, Box, Typography, CardContent } from '@mui/material';
import {
  getValidateRequiredFunc,
  getValidateRequiredFuncByValue,
} from '../../preservationItemActivity/validators';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';
import CompletedInput from '../../preservationItemActivity/components/CompletedInput';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import { CompletedType } from '../../preservationItemActivity/constants';
import { ContactCategories } from '../../common/contactInput/constants';
import SmartTabWidthButtons from '../../common/SmartTabWidthButtons';
import ContactInput from '../../common/contactInput/ContactInput';
import { SmartPreservationItemActivityType } from '../types';
import useCanAccess from '../../common/hooks/useCanAccess';
import CustomDateInput from '../../common/CustomDateInput';
import SignatureInput from '../../common/SignatureInput';
import FooterToolbar from '../../common/FooterToolbar';
import LocationField from '../../common/LocationField';
import commonStyles from '../../common/commonStyles';
import TestField from '../../common/TestField';
import TextInput from '../../common/TextInput';
import PrintNameInput from './PrintNameInput';
import WithFieldCheckAccess, {
  Actions,
} from '../../common/WithFieldCheckAccess';
import JSONFormTab from './JSONFormTab';

interface SmartPreservationItemActivityFormProps {
  onCancel?: () => void;
  setPendingPunchTitle?: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const SmartPreservationItemActivityForm: React.FC<
  SmartPreservationItemActivityFormProps
> = ({
  onCancel,
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const canAccessList = useCanAccess();
  const record = useRecordContext<SmartPreservationItemActivityType>();
  const isSimpleForm = record && !!record.storagePreservation;

  return (
    <SimpleForm
      toolbar={
        <FooterToolbar autoSave={true} onCancel={onCancel}>
          <ListButton label="Cancel" icon={<CancelIcon />} onClick={onCancel} />
        </FooterToolbar>
      }
    >
      <Box sx={{ ...commonStyles.columnFlexBox, padding: '0 16px 30px' }}>
        <SmartTabWidthButtons />
        <Labeled label={labels['componentId'] || 'Tag'}>
          <TextField source="component.tag" />
        </Labeled>
        <Labeled label="Tag Description">
          <TextField source="component.tagDescription" />
        </Labeled>
        <Labeled label={labels['testId'] || 'Test'}>
          <TestField source="testId" textSource="test.testFormNo" />
        </Labeled>
        <Labeled label="Test Description">
          <TextField source="test.testName" />
        </Labeled>
        <Labeled label={labels['dueDate'] || 'Due Date'}>
          <DateField source="dueDate" />
        </Labeled>
        <Labeled label={labels['locationId'] || 'Location'}>
          <LocationField />
        </Labeled>
        <Labeled
          label={labels['storagePreservation'] || 'Storage Preservation'}
        >
          <TextField source="storagePreservation" />
        </Labeled>
        <Labeled label="Review Comments">
          <TextInput source="reviewComments" label="" multiline fullWidth />
        </Labeled>
      </Box>

      {!isSimpleForm && (
        <JSONFormTab
          setPendingPunchTitle={setPendingPunchTitle}
          setPendingPunchCreateDialogCloseDisabled={
            setPendingPunchCreateDialogCloseDisabled
          }
        />
      )}

      <Card sx={{ marginBottom: '20px', width: '100%' }}>
        <Typography variant="h5" sx={{ margin: '20px' }}>
          {`Status${isSimpleForm ? ', Comments and Signatures' : ''}`}
        </Typography>
        <CardContent>
          <WithFieldCheckAccess
            action={Actions.EDIT}
            hasResourceEditAccess={canAccessList.edit}
          >
            <CompletedInput
              source="completed"
              label={labels['completed'] || 'Status'}
              sx={commonStyles.flexBoxFullWidthItem}
              disabled={!canAccessList.edit}
            />
          </WithFieldCheckAccess>

          {isSimpleForm && (
            <WithFieldCheckAccess
              action={Actions.EDIT}
              hasResourceEditAccess={canAccessList.edit}
            >
              <TextInput
                source="comments"
                label="Comments"
                sx={{ ...commonStyles.flexBoxFullWidthItem, flexGrow: 1 }}
                fullWidth
                multiline
                disabled={!canAccessList.edit}
              />
              <SignatureInput
                source="inspector"
                label={labels['inspector'] || 'Inspector'}
                sx={[
                  commonStyles.flexBoxFullWidthItem,
                  commonStyles.bottomMargin,
                ]}
                disabled={!canAccessList.edit}
              >
                <ContactInput
                  source="inspector"
                  label={labels['inspector'] || 'Inspector'}
                  sx={commonStyles.flexBoxFullWidthItem}
                  validate={[
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                  ]}
                  category={ContactCategories.preservationEmpWorkGrp}
                  disabled={!canAccessList.edit}
                />
              </SignatureInput>
              <PrintNameInput
                source="inspectorPrintName"
                label={labels['inspectorPrintName'] || 'Inspector Print Name'}
                sx={commonStyles.flexBoxFullWidthItem}
                disabled={!canAccessList.edit}
                validate={[
                  getValidateRequiredFuncByValue(
                    'completed',
                    CompletedType.YES
                  ),
                  maxLength(100),
                ]}
              />
              <CustomDateInput
                source="signOffDate"
                label={labels['signOffDate'] || 'Sign Off Date'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFuncByValue(
                    'completed',
                    CompletedType.YES
                  ),
                ]}
                disabled={!canAccessList.edit}
              />
              <SignatureInput
                source="verifiedBy"
                label={labels['verifiedBy'] || 'Verified By'}
                sx={[
                  commonStyles.flexBoxFullWidthItem,
                  commonStyles.bottomMargin,
                ]}
                disabled={!canAccessList.edit}
              >
                <ContactInput
                  source="verifiedBy"
                  label={labels['verifiedBy'] || 'Verified By'}
                  sx={commonStyles.flexBoxFullWidthItem}
                  validate={[
                    getValidateRequiredFunc('verifiedDate', 'Verified Date'),
                  ]}
                  category={ContactCategories.preservationEmpWorkGrp}
                  disabled={!canAccessList.edit}
                />
              </SignatureInput>
              <PrintNameInput
                source="verifiedPrintName"
                label={labels['verifiedPrintName'] || 'Verified Print Name'}
                sx={commonStyles.flexBoxFullWidthItem}
                disabled={!canAccessList.edit}
                validate={[maxLength(100)]}
              />
              <CustomDateInput
                source="verifiedDate"
                label={labels['verifiedDate'] || 'Verified Date'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFunc('verifiedBy', 'Verified By'),
                ]}
                disabled={!canAccessList.edit}
              />
            </WithFieldCheckAccess>
          )}
        </CardContent>
      </Card>
    </SimpleForm>
  );
};

export default SmartPreservationItemActivityForm;
