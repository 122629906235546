import { useGetList } from 'react-admin';
import { RESOURCE_PROJECT } from '../../constants';
import { getProjectItem } from '../helpers';

const useOptionList = (params) => {
  const { data: projects = [] } = useGetList(RESOURCE_PROJECT, params);
  const activeProjects = projects.filter((project) => project.isActive) ?? [];
  const inActiveProjects = projects.filter((project) => !project.isActive);

  return [...activeProjects, ...inActiveProjects].map((project) =>
    getProjectItem(project)
  );
};

export default useOptionList;
