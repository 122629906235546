import React, { ReactElement } from 'react';
import { startCase } from 'lodash';
import { IconButton, Tooltip, Box, alpha } from '@mui/material';
import { WithDeleteDialogSupport } from '@jsonforms/material-renderers';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  JsonSchema,
  Paths,
  JsonFormsCellRendererRegistryEntry,
  ArrayTranslations,
} from '@jsonforms/core';
import ArrayField from './ArrayField';

const styles = {
  deleteBtn: {
    width: '50px',
    height: '50px',
    marginLeft: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 1,
    borderColor: 'divider',
    marginTop: 3,
    marginBottom: 5,

    '&:hover': {
      backgroundColor: alpha('#000', 0.04),
    },
  },
};

interface ArrayRowProps {
  childPath: string;
  schema: JsonSchema;
  rowIndex: number;
  enabled: boolean;
  cells?: JsonFormsCellRendererRegistryEntry[];
  path: string;
  translations: ArrayTranslations;
}
const ArrayRow = React.memo(
  ({
    childPath,
    schema,
    rowIndex,
    openDeleteDialog,
    enabled,
    cells,
    translations,
  }: ArrayRowProps & WithDeleteDialogSupport): ReactElement => {
    const getValidColumnProps = (scopedSchema: JsonSchema) =>
      Object.keys(scopedSchema.properties).filter(
        (prop) => scopedSchema.properties[prop].type !== 'array'
      );

    return (
      <Box key={childPath} sx={styles.row}>
        {getValidColumnProps(schema).map((prop) => {
          const fieldPath = Paths.compose(childPath, prop);
          const props = {
            propName: prop,
            schema,
            title: schema.properties?.[prop]?.title ?? startCase(prop),
            rowPath: childPath,
            fieldPath,
            enabled,
            cells,
          };
          return <ArrayField key={fieldPath} {...props} />;
        })}
        {enabled ? (
          <Box sx={styles.deleteBtn}>
            <Tooltip
              id="tooltip-remove"
              title={translations.removeTooltip}
              placement="bottom"
            >
              <IconButton
                aria-label={translations.removeAriaLabel}
                onClick={() => openDeleteDialog(childPath, rowIndex)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
    );
  }
);

export default ArrayRow;
