import React, { ReactElement, useEffect } from 'react';
import { BooleanInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import commonStyles from '../../../../common/commonStyles';

const VerifiedInput: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const { watch, setValue, getValues } = useFormContext();
  const acceptedBy = watch('acceptedBy');
  const acceptedDate = watch('acceptedDate');

  useEffect(() => {
    if (
      acceptedBy === record?.acceptedBy &&
      acceptedDate === record?.acceptedDate
    ) {
      return;
    }
    const isVerified = getValues('isVerified');
    if (!acceptedBy && !acceptedDate && isVerified) {
      setValue('isVerified', false);
    }
    if (acceptedBy && acceptedDate && !isVerified) {
      setValue('isVerified', true);
    }
  }, [acceptedBy, acceptedDate, record, getValues, setValue]);

  return (
    <BooleanInput
      source="isVerified"
      label="Verified"
      sx={commonStyles.rowFormShortInput}
    />
  );
};

export default VerifiedInput;
