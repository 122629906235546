import { ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import {
  EditRowButton,
  EditableDatagrid,
} from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import DrawingRowForm from './DrawingRowForm';
import commonStyles from './commonStyles';
import DrawingField from './DrawingField';
import PreviewButton from './PreviewButton';
import DownloadButton from './DownloadButton';
import DeleteWithConfirmIconButton from './DeleteWithConfirmIconButton';

interface DrawingFormTabProps {
  target: 'componentId' | 'subsystemId';
  reference: string;
}

const DrawingFormTab: React.FC<DrawingFormTabProps> = ({
  target,
  reference,
}): ReactElement => {
  const record = useRecordContext();
  return (
    <ReferenceManyField target={target} reference={reference}>
      <EditableDatagrid
        disableAutofocus
        bulkActionButtons={false}
        mutationMode="pessimistic"
        editForm={<DrawingRowForm targetId={record.id} targetField={target} />}
        createForm={
          <DrawingRowForm targetId={record.id} targetField={target} />
        }
        sx={commonStyles.formTabDatagrid}
        actions={
          <>
            <PreviewButton />
            <DownloadButton />
            <EditRowButton />
            <DeleteWithConfirmIconButton />
          </>
        }
      >
        <DrawingField sx={commonStyles.formTabInput} source="drawingId" />
        <TextField
          label="Description"
          source="drawing.description"
          sx={commonStyles.flexBoxLongItem}
        />
        <TextField
          label="Type"
          source="drawing.drawingType.drawingType"
          sortBy="`drawing->drawingType`.drawing_type"
        />
        <TextField
          label="Discipline"
          source="drawing.drawingDiscipline.drawingDiscipline"
          sortBy="`drawing->drawingDiscipline`.drawing_discipline"
        />
        <TextField
          label="Group"
          source="drawing.group.groupName"
          sortBy="`drawing->group`.group_name"
        />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

export default DrawingFormTab;
