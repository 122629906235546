import { useInput } from 'react-admin';

const useInputs = () => {
  const { field: contactId } = useInput({
    source: 'assignedContactId',
  });
  // const { field: assignedGroupId } = useInput({
  //   source: 'assignedGroupId',
  // });
  const { field: assignedUserGroupId } = useInput({
    source: 'assignedUserGroupId',
  });
  const { field: assignedEmail } = useInput({
    source: 'assignedEmail',
  });

  return {
    contactId,
    // assignedGroupId,
    assignedUserGroupId,
    assignedEmail,
  };
};

export default useInputs;
