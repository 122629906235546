import React, { ReactElement, useMemo } from 'react';
import { TextFieldProps, useRecordContext } from 'react-admin';
import useAuditTableName from '../../common/hooks/useAuditTableName';

const AuditTableNameField: React.FC<TextFieldProps> = (props): ReactElement => {
  const tableNameFormatter = useAuditTableName();
  const record = useRecordContext(props);
  const tableName = useMemo(
    () => tableNameFormatter(record.tableName),
    [tableNameFormatter, record]
  );
  return <>{tableName}</>;
};

export default AuditTableNameField;
