import React, { ReactElement } from 'react';
import ImageGalleryFormTab from '../common/ImageGalleryFormTab';
// import AttachmentFormTab from '../common/AttachmentFormTab';
// import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import { EditPropsWithLocation } from '../common/types';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import PendingPunchForm from './PendingPunchForm';
import LazyFormTab from '../common/LazyFormTab';
import { PendingPunch } from './types';

const PendingPunchEdit: React.FC<EditPropsWithLocation<PendingPunch>> = (
  props
): ReactElement => {
  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="Smart Punch Review"
        id="pending_punch_tab"
        sx={commonStyles.formTabHeader}
      >
        <PendingPunchForm />
        <MetaDataInputs />
      </LazyFormTab>
      {/* <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="pendingPunchId"
          attachmentType={[ATTACHMENT_TYPE_ENUMS.IMAGE]}
          simpleView
          isImage
        />
      </LazyFormTab> */}
      <LazyFormTab
        label="Image Gallery"
        id="gallery_tab"
        sx={commonStyles.formTabHeader}
      >
        <ImageGalleryFormTab targetId="pendingPunchId" />
      </LazyFormTab>
    </TabbedEditForm>
  );
};

export default PendingPunchEdit;
