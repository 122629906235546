import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { BookGenStatus, SubsystemGenStatus } from './types';

export const getBookJobProgressIcon = (status: BookGenStatus) => {
  switch (status) {
    case 'PRINTING':
    case 'WAITING': {
      return <HourglassEmptyIcon />;
    }
    case 'FINISHED_SUCCESS': {
      return <CheckCircleOutlineIcon />;
    }
    case 'CANCELED':
    case 'FINISHED_FAILED': {
      return <ErrorOutlineIcon />;
    }
    default:
      return <></>;
  }
};

export const getSubsystemProgressIcon = (status: SubsystemGenStatus) => {
  switch (status) {
    case 'GENERATING_PDF':
    case 'GENERATING_ZIP':
    case 'PRINTING_COVER':
    case 'PRINTING_SECTION':
    case 'PRINTING_SPINE':
    case 'PRINTING_TOC':
    case 'WAITING': {
      return <HourglassEmptyIcon />;
    }
    case 'FINISHED_SUCCESS': {
      return <CheckCircleOutlineIcon />;
    }
    case 'CANCELED':
    case 'FINISHED_FAILED': {
      return <ErrorOutlineIcon />;
    }
    default:
      return <></>;
  }
};

export const getFileName = (path: string) => path.replace(/^.*[\\/]/, '');
