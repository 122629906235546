import React, { ReactElement } from 'react';
import commonStyles from './commonStyles';
import { RESOURCE_STRUCTURE_REGION } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { required } from '../../utils/UtilityFunctions';

interface RegionInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
}

const RegionInput: React.FC<RegionInputProps> = ({
  isRequired,
  multiple,
  sx = commonStyles.flexBoxItem,
  ...rest
}): ReactElement => {
  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_STRUCTURE_REGION}
      requestParams={{ sort: { field: 'region', order: 'ASC' } }}
      label="Region"
      name="region_inc"
      optionText="region"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'region']}
    />
  );
};

RegionInput.defaultProps = { source: 'regionId' };

export default RegionInput;
