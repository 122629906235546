import React, { Fragment, ReactElement } from 'react';
import PresAutomationInput from './PresAutomationInput';
import PresAutomationLegend from './PresAutomationLegend';

const ProjectFormPresAutomationTab: React.FC = (): ReactElement => (
  <Fragment>
    <PresAutomationInput />
    <PresAutomationLegend />
  </Fragment>
);

export default ProjectFormPresAutomationTab;
