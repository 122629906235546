import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { RESOURCE_AUDIT_TABLE_NAME } from '../../constants';

interface AuditTableNameInputProps {
  alwaysOn?: boolean;
  source?: string;
  multiple?: boolean;
}

const AuditTableNameInput: React.FC<AuditTableNameInputProps> = ({
  alwaysOn,
  multiple,
  ...rest
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'name', order: 'ASC' as const },
    };
  }, []);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_AUDIT_TABLE_NAME}
      requestParams={requestParams}
      label="Table Name"
      optionText="name"
      alwaysOn={alwaysOn}
      multiple={multiple}
      includeFields={['id', 'name']}
      withAdditionalFilter={false}
    />
  );
};

AuditTableNameInput.defaultProps = { source: 'tableNameId' };

export default AuditTableNameInput;
