import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import GatingTestTypesField from './components/gatingFields/GatingTestTypesField';
import GatingPunchPrioritiesField from './components/gatingFields/GatingPunchPrioritiesField';
import GatingCertTemplatesField from './components/gatingFields/GatingCertTemplatesField';
import CertGatingEdit from './CertGatingEdit';
import CertGatingShow from './CertGatingShow';

const CertGatingList: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const defaultFilter = useDefaultFilter('division', {
    'tblCertGatingPredCerts->projectId': ctx.projectId,
    'tblCertGatingPunches->projectId': ctx.projectId,
    'tblCertGatingTests->projectId': ctx.projectId,
  });

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: true,
          }}
        />
      }
      sort={{ field: 'certName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<CertGatingShow withoutActions />}
        EditComponent={
          <CertGatingEdit syncWithLocation={false} withoutActions />
        }
        defaultVisibleColumns={[
          'id',
          'certName',
          'description',
          'testType',
          'punchPriority',
          'predCertTemplate',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="certName" label="Certificate" />
        <TextField source="description" label="Description" />
        <GatingTestTypesField />
        <GatingPunchPrioritiesField />
        <GatingCertTemplatesField label="Predecessor Certificates" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" showActions={{ delete: false }} />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default CertGatingList;
