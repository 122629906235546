import React, { useCallback, useState, ReactElement, Fragment } from 'react';
import { withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import { DeleteDialog } from '@jsonforms/material-renderers';
import {
  ArrayLayoutProps,
  RankedTester,
  isObjectArrayControl,
  rankWith,
} from '@jsonforms/core';
import { ArrayControl } from './controls/ArrayControl';

export const ArrayControlRenderer: React.FC<ArrayLayoutProps> = (
  props
): ReactElement => {
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState(undefined);
  const [rowData, setRowData] = useState(undefined);
  const { removeItems, visible } = props;

  const openDeleteDialog = useCallback(
    (p: string, rowIndex: number) => {
      setOpen(true);
      setPath(p);
      setRowData(rowIndex);
    },
    [setOpen, setPath, setRowData]
  );
  const deleteCancel = useCallback(() => setOpen(false), [setOpen]);
  const deleteConfirm = useCallback(() => {
    const p = path.substring(0, path.lastIndexOf('.'));
    removeItems(p, [rowData])();
    setOpen(false);
  }, [setOpen, path, rowData, removeItems]);
  const deleteClose = useCallback(() => setOpen(false), [setOpen]);

  if (!visible) {
    return null;
  }

  return (
    <Fragment>
      <ArrayControl {...props} openDeleteDialog={openDeleteDialog} />
      <DeleteDialog
        open={open}
        onCancel={deleteCancel}
        onConfirm={deleteConfirm}
        onClose={deleteClose}
        acceptText={props.translations.deleteDialogAccept}
        declineText={props.translations.deleteDialogDecline}
        title={props.translations.deleteDialogTitle}
        message={props.translations.deleteDialogMessage}
      />
    </Fragment>
  );
};

export const arrayControlTester: RankedTester = rankWith(
  5,
  isObjectArrayControl
);

export default withJsonFormsArrayLayoutProps(ArrayControlRenderer);
