import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { LIST_CATEGORIES_WITHOUT_CONTACTS } from './constants';

export const useIsShowContactsTab = () => {
  const record = useRecordContext();

  return useMemo(
    () => !LIST_CATEGORIES_WITHOUT_CONTACTS.includes(record?.category),
    [record]
  );
};
