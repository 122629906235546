import React, { ReactElement } from 'react';
import {
  Labeled,
  TabbedShowLayout,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import InheritCertSignOffsLegend from './components/InheritCertSignOffsLegend';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import EnforceCertGatingLegend from './components/EnforceCertGatingLegend';
import PresAutomationLegend from './components/PresAutomationLegend';
import ItrAutomationLegend from './components/ItrAutomationLegend';
import StrictOptionField from '../common/StrictOptionField';
import ProjectUsersTab from './components/ProjectUsersTab';
import AttachmentList from '../attachment/AttachmentList';
import PresField from './components/PresAutomationField';
import AssetGroupField from '../common/AssetGroupField';
import ItrField from './components/ItrAutomationField';
import commonStyles from '../common/commonStyles';
import AuditListTab from '../audit/AuditListTab';
import RegionField from '../common/RegionField';
import AssetField from '../common/AssetField';
import YesNoField from '../common/YesNoField';
import LogoField from '../common/FileField';

const ProjectShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Project">
        <Box>
          <Labeled label="Logo">
            <LogoField source="attachment" width={300} height={150} />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="project"
              label="Project"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={{ maxWidth: '470px' }}>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxLongItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="location"
              label="Location"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <RegionField source="regionId" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <AssetField label="Asset" source="assetId" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <AssetGroupField source="assetGroupId" />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="projectContractNo"
              label="Project Contract No"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="startDate" label="Start Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="stopDate" label="Stop Date" />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <BooleanField
              source="isItrMobile"
              label="Mobile ITRs"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <BooleanField
              source="isPresMobile"
              label="Mobile Preservations"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <BooleanField
              source="isFrequentlyUsed"
              label="Frequent"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <BooleanField
              label="Duplicate ITR Enabled. (Feature for Mob App users)"
              source="isDuplicateItrEnabled"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <BooleanField
              label="ITR Punch Forced Creation Enabled"
              source="isItrPunchForcedCreationEnabled"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <BooleanField
              label="Preservation Punch Forced Creation Enabled"
              source="isPresPunchForcedCreationEnabled"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <BooleanField
              label="Pin Code Entry Enabled"
              source="isPinCodeEntryEnabled"
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <BooleanField
              source="isArchived"
              label="Archived"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <DateField
              source="archivedDate"
              label="Archived Date"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Details">
        <Box>
          <Labeled>
            <TextField
              source="documentNumber"
              label="Document Number"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="owner"
              label="Owner"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="workWeek"
              label="Work Week"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="gsap"
              label="GSAP"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Inherit Cert Sign Offs"
        id="inherit_cert_sigh_offs_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <Labeled>
            <YesNoField
              source="inheritCertSignoffsSys"
              label="Inherit Cert Sign Offs System"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <YesNoField
              source="inheritCertSignOffsSs"
              label="Inherit Cert Sign Offs SubSystem"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <YesNoField
              source="inheritCertSignoffsComponent"
              label="Inherit Cert Sign Offs Component"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBox}>
          <InheritCertSignOffsLegend />
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Enforce Cert Gating"
        id="enforce_cert_gating_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <Labeled>
            <StrictOptionField
              source="enforceCertGatingSys"
              label="Enforce Cert Gating System"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <StrictOptionField
              source="enforceCertGatingSs"
              label="Enforce Cert Gating SubSystem"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <StrictOptionField
              source="enforceCertGatingComponent"
              label="Enforce Cert Gating Component"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBox}>
          <EnforceCertGatingLegend />
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="ITR Automation"
        id="itr_automation_tab"
        sx={commonStyles.formTabHeader}
      >
        <Labeled>
          <ItrField />
        </Labeled>
        <ItrAutomationLegend />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Pres Automation"
        id="pres_automation_tab"
        sx={commonStyles.formTabHeader}
      >
        <Labeled>
          <PresField />
        </Labeled>
        <PresAutomationLegend />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Users"
        id="users_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectUsersTab />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentList targetId="refProjectId" onlyShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_project" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ProjectShow;
