import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import AuditListTab from '../audit/AuditListTab';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import commonStyles from '../common/commonStyles';
import SecurityRoleField from './SecurityRoleField';

const SecurityGroupShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Group">
        <Box>
          <Labeled label="Group Name" sx={commonStyles.flexBoxItem}>
            <TextField source="groupName" />
          </Labeled>
          <Labeled label="Roles" sx={commonStyles.flexBoxItem}>
            <SecurityRoleField />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_security_group" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default SecurityGroupShow;
