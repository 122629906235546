import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListNotDeleted from '../common/ListNotDeleted';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import SecurityGroupEdit from './SecurityGroupEdit';
import SecurityRoleField from './SecurityRoleField';
import SecurityGroupShow from './SecurityGroupShow';

const SecurityGroupList: React.FC = (): ReactElement => (
  <ListNotDeleted
    filters={<FreeTextSearchFilter />}
    actions={<ListActions showAction={{ effective: true }} />}
    sort={{ field: 'groupName', order: 'ASC' }}
  >
    <DatagridConfigurableRbac
      ShowComponent={<SecurityGroupShow withoutActions />}
      EditComponent={<SecurityGroupEdit syncWithLocation={false} />}
    >
      <TextField source="id" label="ID" />
      <TextField source="groupName" label="Group Name" />
      <SecurityRoleField label="Roles" />
      {renderMetaDataFields()}
      <ColumnActions label="Actions" />
    </DatagridConfigurableRbac>
  </ListNotDeleted>
);

export default SecurityGroupList;
