import React, { ReactElement } from 'react';
import { ReferenceField, ReferenceFieldProps, TextField } from 'react-admin';
import useGetLinkType from './hooks/useGetLinkType';

interface SmartReferenceFieldProps extends ReferenceFieldProps {
  textSource: string;
}

const SmartReferenceField: React.FC<SmartReferenceFieldProps> = ({
  textSource,
  reference,
  ...rest
}): ReactElement => {
  const link = useGetLinkType(reference);

  return link ? (
    <ReferenceField reference={reference} link={link} {...rest}>
      <TextField source={textSource} />
    </ReferenceField>
  ) : (
    <TextField source={textSource} />
  );
};

export default SmartReferenceField;
