import { useCallback, ReactElement } from 'react';
import { useRecordContext, Button, useUpdate } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import useInvalidateManyReferenceQueryCache from '../common/hooks/useInvalidateManyReferenceQueryCache';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_ATTACHMENT_REVISION,
} from '../constants';

type AttachmentRevisionSetActiveProps = {
  onUpload: () => void;
};

const AttachmentRevisionSetActive = ({
  onUpload,
}: AttachmentRevisionSetActiveProps): ReactElement => {
  const [update] = useUpdate();
  const record = useRecordContext();
  const queryClient = useQueryClient();
  const invalidateQueries =
    useInvalidateManyReferenceQueryCache(RESOURCE_ATTACHMENT);

  const onSubmit = useCallback(async () => {
    await invalidateQueries();
    await queryClient.invalidateQueries({
      queryKey: [
        RESOURCE_ATTACHMENT,
        'getOne',
        { id: String(record.attachmentId) },
      ],
    });

    if (onUpload) {
      onUpload();
    }
  }, [invalidateQueries, onUpload, queryClient, record]);

  const onClick = useCallback(async () => {
    await update(
      RESOURCE_ATTACHMENT_REVISION,
      {
        id: record.id,
        data: { ...record, isActive: true },
        previousData: record,
      },
      { onSuccess: onSubmit }
    );

    await queryClient.invalidateQueries({
      queryKey: [RESOURCE_ATTACHMENT_REVISION, 'getList'],
    });
  }, [record, update, queryClient, onSubmit]);

  const { isActive } = record;

  return (
    !isActive && (
      <Box sx={commonStyles.columnActionsBox}>
        <Button
          label="set active"
          onClick={onClick}
          className="setActiveButton"
        />
      </Box>
    )
  );
};

export default AttachmentRevisionSetActive;
