import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';
import get from 'lodash/get';
import { isImage } from '../../utils/UtilityFunctions';
import SignatureField from './JSONForm/SignatureField';

type SignatureInputProps = {
  source: string;
  label: string;
  children: ReactElement;
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

const SignatureInput = (props: SignatureInputProps): ReactElement => {
  const { source, label, children, sx, disabled } = props;
  const record = useRecordContext(props);
  const { setValue } = useFormContext();
  const [val, setVal] = useState();

  useEffect(() => {
    setVal(get(record, source)?.toString());
  }, [record, source]);

  const handleOnConfirm = useCallback(() => {
    setValue(source, null, { shouldDirty: true });
    setVal(null);
  }, [source, setValue]);

  if (isImage(val)) {
    return (
      <SignatureField
        value={val}
        label={label}
        onConfirm={handleOnConfirm}
        sx={sx}
        disabled={disabled}
      />
    );
  }

  return children;
};

export default SignatureInput;
