import React, { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { RESOURCE_COMPONENT_TEST } from '../constants';
import LocationField from './LocationField';
import TestNameField from './TestNameField';
import TestFormNoField from './TestFormNo';
import CustomBooleanField from './CustomBooleanField';

const TestITRsTab: React.FC = (): ReactElement => (
  <ReferenceManyField
    target="componentId"
    reference={RESOURCE_COMPONENT_TEST}
    filter={{ isDeleted: false }}
  >
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TestFormNoField />
      <TestNameField />
      <LocationField />
      <CustomBooleanField source="isVerified" label="Verified" />
      <DateField source="completedDateTime" label="Completed Test Date" />
      <TextField source="completedBy" />
    </Datagrid>
  </ReferenceManyField>
);

export default TestITRsTab;
