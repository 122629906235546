import React, { ReactElement } from 'react';
import { BooleanInput, maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { required } from '../../utils/UtilityFunctions';
import useContactCompanyList from '../common/hooks/useContactCompanyList';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import TextInput from '../common/TextInput';
import SelectInputWithFreeText from '../common/SelectInputWithFreeText';
import { EditPropsWithLocation } from '../common/types';
import commonStyles from '../common/commonStyles';
import EmailInput from './Emailinput';
import { Contact } from './types';

const ContactEdit: React.FC<EditPropsWithLocation<Contact>> = (
  props
): ReactElement => {
  const companies = useContactCompanyList();

  return (
    <TabbedEditForm {...props} transform={(data) => ({ ...data })}>
      <LazyFormTab
        label="Contact"
        id="contact_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="nickname"
            label="Nick Name"
            validate={[required(), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="firstName"
            label="First Name"
            validate={[maxLength(50)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="lastName"
            label="Last Name"
            validate={[maxLength(50)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <EmailInput />
          <SelectInputWithFreeText
            source="company"
            label="Company"
            sx={commonStyles.flexBoxItem}
            options={companies}
            validate={[maxLength(100)]}
          />
          <TextInput
            source="jobTitle"
            label="Job Title"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxLongItem}
            multiline
          />
          <BooleanInput source="isMobileUser" label="Mobile User" />
        </Box>
        <MetaDataInputs />
      </LazyFormTab>
      <HistoryTab tableName="tbl_contact" />
    </TabbedEditForm>
  );
};

export default ContactEdit;
