import moment from 'moment/moment';
import { Dayjs } from 'dayjs';

export const getFiltersWithoutRange = (filters, fieldName) => {
  const filtersObj = JSON.parse(JSON.stringify(filters));
  delete filtersObj[`${fieldName}_lte`];
  delete filtersObj[`${fieldName}_gte`];
  delete filtersObj[`${fieldName}_between`];
  return filtersObj;
};

export const validateDate = (date) => {
  return date && date.isValid();
};

export const fromDate = (date) => {
  return `${date.format('YYYY-MM-DD')}T00:00:00.000Z`;
};

export const toDate = (date) => {
  return `${date.format('YYYY-MM-DD')}T23:59:59.999Z`;
};

export const makeDateFilter = (
  dateFrom: Dayjs | null,
  dateTo: Dayjs | null,
  fieldName: string
) => {
  if (dateFrom === null && dateTo === null) {
    return {};
  }

  if (validateDate(dateFrom) && validateDate(dateTo)) {
    return {
      [`${fieldName}_between`]: [fromDate(dateFrom), toDate(dateTo)],
    };
  } else {
    if (validateDate(dateFrom)) {
      return { [`${fieldName}_gte`]: fromDate(dateFrom) };
    }

    if (validateDate(dateTo)) {
      return { [`${fieldName}_lte`]: toDate(dateTo) };
    }

    return {};
  }
};

export const stringToDate = (dateText, format) => {
  const dateParts = dateText.split('/');
  const month = parseInt(dateParts[0], 10);
  const day = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);
  const date = new Date(year, month - 1, day);
  return moment(date).format(format);
};
