import { TextField, NumberField } from 'react-admin';
import ActivePoListField from '../material/materialPage/components/ActivePoListField';
import MaterialField from './MaterialField';
import MaterialTypeField from './MaterialTypeField';

const MaterialDatagridColumns = () => {
  return [
    <MaterialField key="materialField" />,
    <TextField
      key="descriptionField"
      source="material.description"
      label="Description"
      sortBy="`material.description`"
    />,
    <MaterialTypeField
      key="materialTypeField"
      source="material.materialTypeId"
      textSource="material.materialType.type"
      sortBy="`material.materialType.type`"
    />,
    <NumberField
      key="totalQtyField"
      source="material.totalQty"
      label="Total Qty"
      sortBy="`material.totalQty`"
    />,
    <NumberField
      key="orderPointField"
      source="material.orderPoint"
      label="Order Point"
      sortBy="`material.orderPoint`"
    />,
    <ActivePoListField
      key="activePoListField"
      label="Active PO"
      materialRecordPath="material.materialPurchaseOrderMaterials"
      sortable={false}
    />,
  ];
};

export default MaterialDatagridColumns;
