import React, { ReactElement, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { useCreate, useCreateSuggestionContext, useNotify } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import useInvalidateResourceQueryCache from '../hooks/useInvalidateResourceQueryCache';
import { RESOURCE_VENDOR } from '../../constants';
import { getErrorMessage } from '../../../utils/UtilityFunctions';

const VendorCreateDialog: React.FC = (): ReactElement => {
  const [validationError, setValidationError] = useState('');
  const ctx: UserContext = getContext();
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_VENDOR);
  const notify = useNotify();

  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (value.trim().length === 0) {
        setValidationError('Required');
      } else {
        await create(
          RESOURCE_VENDOR,
          {
            data: {
              vendor: value.trim(),
              projectId: ctx?.projectId,
            },
          },
          {
            onSuccess: (data) => {
              setValue('');
              onCreate(data);
              invalidateQueries();
            },
            onError: (error) => {
              notify(getErrorMessage(error), {
                type: 'error',
                undoable: false,
              });
            },
          }
        );
      }
    },
    [create, ctx?.projectId, invalidateQueries, notify, onCreate, value]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setValidationError('');
  };

  return (
    <Dialog open onClose={onCancel}>
      <form>
        <DialogContent>
          <TextField
            label="New Vendor"
            value={value}
            onChange={handleChange}
            autoFocus
            error={!!validationError}
            helperText={validationError}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!!validationError} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VendorCreateDialog;
