import React, { ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { RESOURCE_GROUP_CONTACT } from '../../../constants';

interface GroupContactFieldProps {
  label?: string;
}

const GroupContactField: React.FC<
  GroupContactFieldProps
> = (): ReactElement => {
  return (
    <ReferenceManyField target="group_id" reference={RESOURCE_GROUP_CONTACT}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="contact.nickname" label="Nickname" />
        <TextField source="contact.firstName" label="First Name" />
        <TextField source="contact.lastName" label="Last Name" />
        <TextField source="contact.email" label="Email" />
        <TextField source="contact.phone" label="Phone" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default GroupContactField;
