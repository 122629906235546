import React, { ReactElement } from 'react';
import { DateField, SelectField, TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import { categoryChoices } from './constants';
import GroupEdit from './GroupEdit';
import GroupFilterSidebar from './GroupFilterSidebar';
import GroupShow from './GroupShow';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';

const GroupList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      aside={<GroupFilterSidebar />}
      sort={{ field: 'groupName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<GroupShow withoutActions />}
        EditComponent={<GroupEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'groupName', 'description', 'category']}
      >
        <TextField source="id" label="ID" />
        <TextField source="groupName" label="Group" />
        <TextField source="description" label="Description" />
        <SelectField
          source="category"
          choices={categoryChoices}
          label="Category"
        />
        <DateField source="updatedAt" label="Updated at" />
        <TextField source="updatedBy" label="Updated by" />
        <TextField source="updatedByName" label="Updated by name" />
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default GroupList;
