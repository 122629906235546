import React, { ReactElement, useMemo } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import ProjectInputWithGroup from '../common/ProjectInputWithGroup';
import commonStyles from '../common/commonStyles';

const UserGroupCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const projectInputRequestParams = useMemo(() => {
    return {
      filter: { divisionId: ctx?.defaultDivisionId },
      sort: { field: 'project', order: 'ASC' as const },
    };
  }, [ctx?.defaultDivisionId]);

  return (
    <CreateWithTitle redirect="list">
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="userGroup"
            label="User Group"
            validate={[required(), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={maxLength(255)}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
          <ProjectInputWithGroup
            sx={commonStyles.flexBoxItem}
            isRequired
            requestParams={projectInputRequestParams}
            includeFields={['id', 'project', 'isActive']}
            getOptionDisabled={(option) => !option.isActive}
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default UserGroupCreate;
