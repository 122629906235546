import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import SmartLinkField from './SmartLinkField';
import { RESOURCE_VENDOR } from '../constants';

interface VendorFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sortable?: boolean;
}

const VendorField: React.FC<VendorFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_VENDOR}>
    <TextField source={textSource} />
  </SmartLinkField>
);

VendorField.defaultProps = {
  label: 'Responsible Vendor',
  textSource: 'vendor.vendor',
  source: 'vendorId',
  sortBy: '`vendor.vendor`',
};

export default VendorField;
