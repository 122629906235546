import React, { ReactElement } from 'react';
import { RESOURCE_POSITION } from '../constants';
import commonStyles from '../common/commonStyles';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { required } from '../../utils/UtilityFunctions';

interface PositionInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  source?: string;
}
const PositionInput: React.FC<PositionInputProps> = ({
  multiple,
  source,
  isRequired,
}): ReactElement => {
  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_POSITION}
      label="Position Name"
      name="position_inc"
      optionText="position"
      sx={commonStyles.flexBoxItem}
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      source={source}
      includeFields={['id', 'position']}
    />
  );
};

PositionInput.defaultProps = { source: 'positionId' };

export default PositionInput;
