import { useMemo } from 'react';
import {
  useDataProvider,
  useListContext,
  useNotify,
  useResourceContext,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { getContext, UserContext } from '../../../provider/userContext';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import {
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_TEST,
} from '../../constants';
import useInvalidateResourceQueryCache from '../hooks/useInvalidateResourceQueryCache';
import { TemplateType } from '../types';
import { templateType } from '../constants';

export const useCopyTemplates = (
  templateType: TemplateType,
  onSuccess: () => void,
  resource?: string,
  lambdaMode?: boolean
) => {
  const resourceContext = useResourceContext();
  const { selectedIds, onUnselectItems } = useListContext();
  const dataProvider = useDataProvider();
  const relatedResource = useGetRelatedResourceByTemplateType(templateType);
  const invalidateQueries = useInvalidateResourceQueryCache(relatedResource);
  const notify = useNotify();
  const actualResource = resource || resourceContext;
  const ctx: UserContext = getContext();
  const successMessage = lambdaMode
    ? 'The import template process has started. You will receive a notification once the import is completed.'
    : 'Selected templates were imported successfully!';

  const { mutate: copyTemplates, isPending: isCopyingTemplates } = useMutation({
    mutationFn: () =>
      dataProvider.copyTemplates(actualResource, {
        divisionId: ctx?.defaultDivisionId,
        templateIds: selectedIds,
        templateType,
      }),
    onSuccess: async () => {
      onSuccess();
      onUnselectItems();
      notify(successMessage, {
        type: 'success',
      });
      await invalidateQueries();
    },
    onError: (error) => {
      notify(getErrorMessage(error, 'Template import failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  return { copyTemplates, isCopyingTemplates };
};

const useGetRelatedResourceByTemplateType = (tmplType: TemplateType): string =>
  useMemo(() => {
    switch (tmplType) {
      case templateType.certificate:
        return RESOURCE_CERT_TEMPLATE;
      case templateType.itr:
        return RESOURCE_TEST;
      case templateType.punch:
        return RESOURCE_PUNCH_TEMPLATE;
      default:
        return '';
    }
  }, [tmplType]);
