import React, { ReactElement, Fragment } from 'react';
import {
  Button,
  Labeled,
  SimpleShowLayout,
  TextField,
  RaRecord,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import CertTemplateField from '../CertTemplateField';
import commonStyles from '../commonStyles';
import StrictOptionField from '../StrictOptionField';
import { ActivityCategory } from '../types';
import { getActivityFieldSource } from '../helpers/activity';
import ActivityCategoryParentField from './ActivityCategoryParentField';
import ColoredGatingStatusField from './ColoredGatingStatusField';
import { getGatingEnforcementLabel } from './helpers';

interface CertificateValidationFormProps {
  record: RaRecord;
  activityCategory: ActivityCategory;
  onGenButtonClick: () => void;
  showGenButton: boolean;
  genButtonLabel: string;
  genButtonDisabled: boolean;
}

const CertificateValidationForm: React.FC<CertificateValidationFormProps> = ({
  record,
  activityCategory,
  onGenButtonClick,
  showGenButton,
  genButtonLabel,
  genButtonDisabled,
}): ReactElement => {
  return (
    <SimpleShowLayout record={record}>
      <Typography component="h6" sx={commonStyles.headerWithBorder} paragraph>
        Activity Details
      </Typography>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <Labeled sx={commonStyles.flexBoxItem}>
          <CertTemplateField label="Certificate" />
        </Labeled>
        <Labeled sx={commonStyles.flexBoxItem}>
          <ActivityCategoryParentField category={activityCategory} />
        </Labeled>
        <Labeled sx={commonStyles.flexBoxItem}>
          <TextField
            source={getActivityFieldSource(activityCategory)}
            label="Activity"
          />
        </Labeled>
      </Box>
      <Box
        sx={{
          ...commonStyles.flexBoxWithWrap,
          ...commonStyles.bottomMargin,
        }}
      >
        <Labeled sx={commonStyles.flexBoxLongItem}>
          <TextField source="description" label="Description" />
        </Labeled>
      </Box>
      <Typography component="h6" sx={commonStyles.headerWithBorder} paragraph>
        Certificate Gating Status
      </Typography>
      <Box
        sx={{
          ...commonStyles.flexBoxWithWrap,
          ...commonStyles.bottomMargin,
        }}
      >
        <Labeled sx={commonStyles.flexBoxItem}>
          <ColoredGatingStatusField
            source="certGatingStatus.itrStatus"
            label="ITR"
          />
        </Labeled>
        <Labeled sx={commonStyles.flexBoxItem}>
          <ColoredGatingStatusField
            source="certGatingStatus.punchStatus"
            label="Punch"
          />
        </Labeled>
        <Labeled sx={commonStyles.flexBoxItem}>
          <ColoredGatingStatusField
            source="certGatingStatus.certificateStatus"
            label="Predecessor Certificate"
          />
        </Labeled>
      </Box>
      <Typography component="h6" sx={commonStyles.headerWithBorder} paragraph>
        {`${getGatingEnforcementLabel(activityCategory)} Gating Enforcement`}
      </Typography>
      <Box
        sx={{
          ...commonStyles.flexBoxWithWrap,
          ...commonStyles.bottomMargin,
        }}
      >
        <Labeled sx={commonStyles.flexBoxItem}>
          <StrictOptionField
            source="enforceCertGating"
            label="Gate Enforcement Type"
          />
        </Labeled>
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        {showGenButton ? (
          <Fragment>
            <Button
              label={genButtonLabel + ' Certificate'}
              onClick={onGenButtonClick.bind(this, 'CERT')}
              variant="contained"
              disabled={genButtonDisabled}
            />
            <Button
              label={genButtonLabel + ' Certificate And Release pdf'}
              onClick={onGenButtonClick.bind(this, 'PDF')}
              variant="contained"
              disabled={genButtonDisabled}
            />
            <Button
              label={genButtonLabel + ' Smart Certificate'}
              onClick={onGenButtonClick.bind(this, 'SMART')}
              variant="contained"
              disabled={genButtonDisabled}
            />
          </Fragment>
        ) : null}
      </Box>
    </SimpleShowLayout>
  );
};

export default CertificateValidationForm;
