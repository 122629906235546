import { ReactElement } from 'react';
import { SelectField } from 'react-admin';
import { PRES_AUTOMATION_CHOICES } from '../constants';

type PresAutomationFieldProps = {
  label?: string;
  source?: string;
};

const PresAutomationField: React.FC<PresAutomationFieldProps> = (
  props
): ReactElement => {
  const { label, source } = props;

  return (
    <SelectField
      label={label}
      source={source}
      choices={PRES_AUTOMATION_CHOICES}
    />
  );
};

PresAutomationField.defaultProps = {
  label: 'Require Verified By and Verified Date',
  source: 'presAutomation',
};

export default PresAutomationField;
