import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export const getPreservationDataProvider = () => ({
  createPreservationItemActivity: (
    resource: string,
    data?: Record<string, unknown>
  ) => axios.post(`${CRUD_API_URL}/${resource}`, data, getRequestConfig()),
  forceDeletePreservationItemActivity: (
    resource: string,
    preservationItemActivityId: number
  ) =>
    axios.delete(
      `${CRUD_API_URL}/${resource}/force-delete/${preservationItemActivityId}`,
      getRequestConfig()
    ),
});
