import React, { ReactElement, useCallback } from 'react';
import {
  DateField,
  NumberField,
  TextField,
  useResourceContext,
  useStore,
} from 'react-admin';
import ColumnActions from '../../common/ColumnActions';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../../common/helpers/storeKeys';
import useDefaultFilter from '../../common/hooks/useDefaultFilter';
import LegendButton from '../../common/LegendButton';
import ListActions from '../../common/ListActions';
import ListWithTitle from '../../common/ListWithTitle';
import SelectField from '../../common/SelectField';
import { RESOURCE_MATERIAL_PURCHASE_ORDER } from '../../constants';
import PriceField from '../common/PriceField';
import BulkActionButtons from './components/BulkActionButtons';
import PurchaseOrderRequestSidebarFilters from './components/PurchaseOrderRequestSidebarFilters';
import {
  getRowStyle,
  MATERIAL_PURCHASE_ORDER_EXPORT_BUTTONS,
  statusChoices,
  statusesLegend,
} from './constants';
import PurchaseOrderRequestEdit from './PurchaseOrderRequestEdit';
import PurchaseOrderRequestShow from './PurchaseOrderRequestShow';

const PurchaseOrderRequestList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_MATERIAL_PURCHASE_ORDER);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');
  const resource: string = useResourceContext();
  const [expanded] = useStore(`${resource}.datagrid.expanded`, []);

  const getRowStyles = useCallback(
    (record) => getRowStyle(record, expanded),
    [expanded]
  );

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          actions={<LegendButton legends={statusesLegend} />}
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{ import: false }}
          multipleExportButtonsInfo={MATERIAL_PURCHASE_ORDER_EXPORT_BUTTONS}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <PurchaseOrderRequestSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<PurchaseOrderRequestShow withoutActions />}
        EditComponent={<PurchaseOrderRequestEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        rowStyle={getRowStyles}
        defaultVisibleColumns={[
          'id',
          'orderNo',
          'clientOrderNo',
          'generateDate',
          'receivedDate',
          'status',
          'totalPrice',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="orderNo" label="PO Request No" />
        <TextField source="clientOrderNo" label="Client Order No" />
        <DateField source="generateDate" label="Date Generated" />
        <DateField source="receivedDate" label="Date Received" />
        <SelectField source="status" label="Status" choices={statusChoices} />
        <PriceField source="totalPrice" label="Total Price" />
        <NumberField source="totalQtyOrdered" label="Total Qty Ordered" />
        <NumberField source="totalQtyReceived" label="Total Qty Received" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PurchaseOrderRequestList;
