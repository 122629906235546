import {
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from 'react-admin';
import { RESOURCE_ATTACHMENT } from '../../../components/constants';
import { getDefaultDataProvider } from './defaultDataProvider';

const defaultProvider = getDefaultDataProvider();

const importDataProvider = {
  /**
   * import fakeDataProvider from 'ra-data-fakerest';
   * ...fakeDataProvider({}),
   */
  getList: (resource: string, params: GetListParams) => {
    params.filter = { ...params.filter, attachmentType: 'IMPORT' };
    return defaultProvider.getList(RESOURCE_ATTACHMENT, params);
  },
  getMany: (resource: string, params: GetManyParams) => {
    return defaultProvider.getMany(RESOURCE_ATTACHMENT, params);
  },
  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    return defaultProvider.getManyReference(RESOURCE_ATTACHMENT, params);
  },
  getOne: (resource: string, params: GetOneParams) => {
    params['filter'] = { ...params['filter'], attachmentType: 'IMPORT' };
    return defaultProvider.getOne(RESOURCE_ATTACHMENT, params);
  },
};

export default importDataProvider;
