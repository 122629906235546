import React, { ReactElement } from 'react';
import { AutocompleteInput, useGetList } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_USER } from '../../constants';
import { LOOKUP_PAGE_SIZE } from '../../common/constants';

interface UsersInputProps {
  source: string;
  label: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  page?: number;
  perPage?: number;
}

const UsersInput: React.FC<UsersInputProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const projectId: number = ctx.projectId;

  const {
    data: users,
    isLoading,
    error,
  } = useGetList(RESOURCE_USER, {
    filter: { projectId },
    sort: { field: 'id', order: 'ASC' },
    pagination: {
      page: props.page ?? 1,
      perPage: props.perPage ?? LOOKUP_PAGE_SIZE,
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <AutocompleteInput
      {...props}
      multiple
      choices={users?.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}(${user.id})`,
      }))}
    />
  );
};

export default UsersInput;
