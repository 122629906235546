import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { PaginationProps } from 'ra-ui-materialui';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useListPaginationContext } from 'react-admin';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import commonStyles from './commonStyles';

const TopPaginationBar: React.FC<PaginationProps> = (props): ReactElement => {
  const { rowsPerPageOptions = [10, 25, 50, 100] } = props;
  const {
    isLoading,
    hasNextPage,
    page,
    perPage,
    total,
    setPage,
    setPerPage,
    hasPreviousPage,
  } = useListPaginationContext();

  const from = perPage * (page - 1) + 1;
  const to = Math.min(from + perPage - 1, total);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
      setPage(1);
      setCurrentPage(1);
    },
    [setPage, setPerPage]
  );

  const totalPages = useMemo(() => {
    return total != null ? Math.ceil(total / perPage) : undefined;
  }, [perPage, total]);

  const handlePageChange = useCallback(
    (event) => {
      let value = event.target.value.replace(/[^\d]/g, '');
      if (value === '') {
        value = '0';
      }
      const valueInNumber = parseInt(value);
      setCurrentPage(valueInNumber);
    },
    [setCurrentPage]
  );

  const handleEnter = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        let number = currentPage;
        if (number < 1) number = 1;
        if (number > totalPages) number = totalPages;
        setPage(number);
        setCurrentPage(number);
      }
    },
    [totalPages, setPage, currentPage]
  );

  const handleIncreasePage = useCallback(() => {
    setCurrentPage(page + 1);
    setPage(page + 1);
  }, [page, setPage]);

  const handleDecreasePage = useCallback(() => {
    setCurrentPage(page - 1);
    setPage(page - 1);
  }, [page, setPage]);

  if (total === 0) {
    return null;
  }

  if (isLoading) {
    return <Toolbar variant="dense" />;
  }

  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          sm: 'none',
          md: 'none',
          lg: 'none',
          xl: 'flex',
        },
        alignItems: 'center',
        fontSize: '14px',
        '& fieldset': {
          display: 'none',
        },
      }}
      gap={2}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Rows per page:
        <Select
          value={perPage}
          onChange={handlePerPageChange}
          sx={{ ml: 1, height: '30px', fontSize: 'inherit', border: '0' }}
        >
          {rowsPerPageOptions.map((option) => {
            const key = typeof option === 'number' ? option : option.value;
            const value = typeof option === 'number' ? option : option.value;
            const displayText =
              typeof option === 'number' ? option : option.label;

            return (
              <MenuItem key={key} value={value}>
                {displayText}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '14px' }}>
          {from} - {to} of {total}
        </Typography>
      </Box>
      {totalPages !== 1 && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={handleDecreasePage}
            disabled={!hasPreviousPage}
            variant="text"
            style={commonStyles.paginationButton}
          >
            <ChevronLeftIcon />
          </Button>
          <TextField
            value={currentPage}
            onChange={handlePageChange}
            onKeyPress={handleEnter}
            sx={{
              width: '50px',
              height: '25px',
              padding: 0,
              margin: 0,
              '& input': {
                padding: '0 5px',
                textAlign: 'center',
                height: '25px',
                fontSize: '14px',
              },
            }}
          />
          <Button
            onClick={handleIncreasePage}
            disabled={!hasNextPage}
            variant="text"
            id="next-page-button"
            style={commonStyles.paginationButton}
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TopPaginationBar;
