import { isEmpty } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useFormState } from 'react-hook-form';

interface FormSpyProps {
  handleChange: (fields: object) => void;
}

const FormSpy: React.FC<FormSpyProps> = ({ handleChange }): ReactElement => {
  const { dirtyFields } = useFormState();

  useEffect(() => {
    if (!isEmpty(dirtyFields)) {
      handleChange(dirtyFields);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(dirtyFields)]);

  return;
};

export default FormSpy;
