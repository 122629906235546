import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { RESOURCE_EQUIPMENT_TYPE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';

interface EquipmentTypeInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
  disabled?: boolean;
}

const EquipmentTypeInput: React.FC<EquipmentTypeInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { projectId: ctx?.projectId },
      sort: { field: 'equipType', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_EQUIPMENT_TYPE}
      requestParams={requestParams}
      name="equipType_inc"
      optionText="equipType"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'equipType']}
    />
  );
};

EquipmentTypeInput.defaultProps = {
  source: 'equipTypeId',
  label: 'Equipment Type',
};

export default EquipmentTypeInput;
