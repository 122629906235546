/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useCallback, useContext } from 'react';
import { DataProviderErrorsContext } from './DataProviderErrorsContext';
import { useNotify } from 'react-admin';

const getStatusCode = (reason) => {
  return reason.status || reason.response?.status || 400;
};

const getErrorMessage = (reason) => {
  const data = reason.response?.data || reason.message;
  let errorMessage;
  try {
    errorMessage = JSON.parse(data);
  } catch (e) {
    if (data.message) {
      errorMessage = data;
    } else {
      errorMessage = {
        message: data,
      };
    }
  }
  return errorMessage;
};

const useAddDataProviderActionCatch = () => {
  const { setErrorState } = useContext(DataProviderErrorsContext);
  const notify = useNotify();

  return useCallback(
    (dataProvider) => {
      return Object.keys(dataProvider).reduce((dp, action) => {
        dp[action] = (resource: string, params: unknown) => {
          return dataProvider[action](resource, params)
            .then((data) => {
              setErrorState({
                path: `${resource}.${action}`,
                error: {
                  message: '',
                },
              });
              return data;
            })
            .catch((reason) => {
              const errorMessage = getErrorMessage(reason);
              setErrorState({
                path: `${resource}.${action}`,
                error: {
                  message: errorMessage?.message,
                  data: errorMessage?.data,
                  status: getStatusCode(reason),
                },
              });
              notify(errorMessage.message, { type: 'error' });

              return Promise.reject({
                ...reason,
                message: errorMessage.message,
              });
            });
        };
        return dp;
      }, {});
    },
    [setErrorState, notify]
  );
};

export default useAddDataProviderActionCatch;
