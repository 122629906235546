import React, { ReactElement, useCallback } from 'react';
import { Card, CardContent, Switch } from '@mui/material';
import {
  useListContext,
  Button,
  Filter,
  NullableBooleanInput,
} from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import sideFilterStyles from '../../common/sideFilterStyles';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import { RESOURCE_PROJECT } from '../../constants';
import RegionInput from '../../common/RegionInput';
import AssetInput from './AssetInput';
import AssetGroupInput from './AssetGroupInput';
import { useEffect, useState } from 'react';
import DateRangeFilter from '../../common/DateRangeFilter';
import TextInput from '../../common/TextInput';
import YesNoInput from '../../common/YesNoInput';
import FormControlLabel from '../../common/FormControlLabel';
import { YES_NO_CHOICES } from '../constants';

const ProjectSideFilters: React.FC<{
  isAdminList?: boolean;
  handleFavorite?: (val) => void;
  defaultFilter?: object;
}> = ({
  isAdminList = true,
  handleFavorite,
  defaultFilter = {},
}): ReactElement => {
  const [favoriteSwitch, setFavoriteSwitch] = useState(false);
  const { setFilters, displayedFilters } = useListContext();

  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_PROJECT, defaultFilter);

  useEffect(() => {
    if (handleFavorite) handleFavorite(favoriteSwitch);
  }, [favoriteSwitch, handleFavorite]);

  const handleFavoriteSwitch = () => setFavoriteSwitch((prev) => !prev);

  const handleClearButton = useCallback(() => {
    handleClearFilters();
    setFavoriteSwitch(false);
    setFilters(defaultFilter, displayedFilters);
  }, [
    handleClearFilters,
    setFavoriteSwitch,
    setFilters,
    defaultFilter,
    displayedFilters,
  ]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="Project"
            name="project_inc"
            source="project"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Description"
            name="description_inc"
            source="description"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Location"
            name="location_inc"
            source="location"
            fullWidth
            alwaysOn
          />
          {isAdminList && (
            <NullableBooleanInput
              label="Mobile ITRs"
              name="isItrMobile"
              source="isItrMobile"
              trueLabel="True"
              falseLabel="False"
              nullLabel="None"
              fullWidth
              alwaysOn
            />
          )}
          {isAdminList && (
            <NullableBooleanInput
              label="Mobile Preservations"
              name="isPresMobile"
              source="isPresMobile"
              trueLabel="True"
              falseLabel="False"
              nullLabel="None"
              fullWidth
              alwaysOn
            />
          )}
          <RegionInput
            multiple
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <AssetInput
            multiple
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <AssetGroupInput
            multiple
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TextInput
            source="projectContractNo"
            name="projectContractNo_inc"
            label="Project Contract No"
            alwaysOn
            fullWidth
          />
          {isAdminList && (
            <NullableBooleanInput
              label="Archived"
              name="isArchived"
              source="isArchived"
              trueLabel="True"
              falseLabel="False"
              nullLabel="None"
              fullWidth
              alwaysOn
            />
          )}
          {isAdminList && (
            <DateRangeFilter
              label="Updated"
              source="updatedAt"
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          )}
          {isAdminList && (
            <TextInput
              label="Updated By"
              name="updatedBy_inc"
              source="updatedBy"
              fullWidth
              alwaysOn
            />
          )}
          {isAdminList && (
            <TextInput
              label="Updated By Name"
              name="updatedByName_inc"
              source="updatedByName"
              fullWidth
              alwaysOn
            />
          )}
          {!isAdminList && (
            <FormControlLabel
              control={<Switch />}
              checked={favoriteSwitch}
              onClick={handleFavoriteSwitch}
              label="My Favorites"
              alwaysOn
              source="favorites"
            />
          )}
          <YesNoInput
            label="Frequent"
            source="isFrequentlyUsed"
            choices={YES_NO_CHOICES}
            sx={sideFilterStyles.sideFiltersSelectInput}
            alwaysOn
            fullWidth
          />
          {isAdminList && (
            <NullableBooleanInput
              label="Duplicate ITR Enabled"
              name="isDuplicateItrEnabled"
              source="isDuplicateItrEnabled"
              trueLabel="True"
              falseLabel="False"
              nullLabel="None"
              fullWidth
              alwaysOn
            />
          )}
          {isAdminList && (
            <NullableBooleanInput
              label="ITR Punch Forced Creation Enabled"
              name="isItrPunchForcedCreationEnabled"
              source="isItrPunchForcedCreationEnabled"
              trueLabel="True"
              falseLabel="False"
              nullLabel="None"
              fullWidth
              alwaysOn
            />
          )}
          {isAdminList && (
            <NullableBooleanInput
              label="Preservation Punch Forced Creation Enabled"
              name="isPresPunchForcedCreationEnabled"
              source="isPresPunchForcedCreationEnabled"
              trueLabel="True"
              falseLabel="False"
              nullLabel="None"
              fullWidth
              alwaysOn
            />
          )}
          <YesNoInput
            source="isPinCodeEntryEnabled"
            label="Pin Code Entry Enabled"
            name="isPinCodeEntryEnabled"
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={YES_NO_CHOICES}
            alwaysOn
            fullWidth
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearButton} />
      </CardContent>
    </Card>
  );
};

export default ProjectSideFilters;
