import React, { createContext, useCallback, useReducer, useState } from 'react';
import { Action, ErrorState } from './types';

const DataProviderErrorsContext = createContext<{
  errorState?: ErrorState;
  hash?: string;
  setErrorState?: (state: Action) => void;
}>({});

const reducer =
  (additionalAction: (state: ErrorState) => void) =>
  (state: ErrorState, action: Action) => {
    state[action.path] = action.error;
    additionalAction(state);
    return state;
  };

const DataProviderErrorsProvider = ({ children }) => {
  const [hash, setHash] = useState(''); // we need this to control render
  const additionalAction = useCallback(
    (state: ErrorState) => {
      const str = Object.keys(state)
        .sort((a, b) => a.localeCompare(b))
        .reduce((acc, key) => {
          return acc + state[key].message;
        }, '');
      setHash(str);
    },
    [setHash]
  );
  const [errorState, dispatch] = useReducer(reducer(additionalAction), {});

  const setErrorState = useCallback(
    (action: Action) => {
      dispatch(action);
    },
    [dispatch]
  );

  return (
    <DataProviderErrorsContext.Provider
      value={{ hash, errorState, setErrorState }}
    >
      {children}
    </DataProviderErrorsContext.Provider>
  );
};

export { DataProviderErrorsContext, DataProviderErrorsProvider };
