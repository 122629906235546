import React, { ReactElement } from 'react';
import { RESOURCE_MOC_TYPE } from '../../constants';
import { getContext, UserContext } from '../../../provider/userContext';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../../utils/UtilityFunctions';

export interface MocTypeInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx: SxProps<Theme>;
}

const MocTypeInput: React.FC<MocTypeInputProps> = ({
  isRequired,
  alwaysOn,
  multiple,
  sx,
}): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_MOC_TYPE}
      requestParams={{ filter: { projectId: ctx?.projectId } }}
      source="mocTypeId"
      label="MoC Type"
      name="mocType_inc"
      optionText="mocType"
      sx={sx}
      multiple={multiple}
      alwaysOn={alwaysOn}
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'mocType']}
    />
  );
};

export default MocTypeInput;
