import React, { ReactElement, useMemo } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import { getProgressIcon, getStatusMessage } from '../../helpers';
import { styles } from '../../styles';
import { ScanFileProgressListItem } from '../../types';

interface FileScanProgressProps {
  scanFileProgress: ScanFileProgressListItem;
}

const ScanFileProgress: React.FC<FileScanProgressProps> = ({
  scanFileProgress,
}): ReactElement => {
  const {
    partsUploadStatus,
    numOfParts,
    partIndex,
    fileName,
    uploadId,
    processingDetails,
  } = scanFileProgress;

  const statusText = useMemo(
    () =>
      uploadId && processingDetails?.processingStatus
        ? getStatusMessage(processingDetails?.processingStatus)
        : `${partsUploadStatus} / Part ${partIndex + 1} of ${numOfParts}`,
    [
      numOfParts,
      partIndex,
      partsUploadStatus,
      processingDetails?.processingStatus,
      uploadId,
    ]
  );

  return (
    <ListItem key={uploadId} sx={styles.fileProgressListItem}>
      <Box sx={styles.fileNameAndStatus}>
        <Box display={'flex'}>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText primary={fileName?.split('/')?.slice(-1)} />
        </Box>
        <Box sx={styles.fileUploadStatus}>
          <ListItemText secondary={statusText} sx={{ mr: 1 }} />
          {getProgressIcon(
            processingDetails?.processingStatus || partsUploadStatus
          )}
        </Box>
      </Box>
      {processingDetails?.errorMessages.length > 0 && (
        <Box ml={9}>
          <ListItemText>Errors:</ListItemText>
          {processingDetails?.errorMessages.map((errorMessage, index) => (
            <Box key={index}>
              <ListItemText secondary={errorMessage} />
            </Box>
          ))}
        </Box>
      )}
    </ListItem>
  );
};

export default ScanFileProgress;
