import React, { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { Vendor } from './types';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import ResponsibilityInput from './ResponsibilityInput';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const VendorCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: Vendor): Vendor => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="vendor"
            label="Vendor"
            validate={[required(), maxLength(100)]}
            sx={commonStyles.flexBoxItem}
          />
          <ResponsibilityInput sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
            multiline
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default VendorCreate;
