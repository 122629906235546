import React, { ReactElement } from 'react';
import { SelectField } from 'react-admin';
import { ACTIVITY_DURATION_UNIT_CHOICES } from './constants';
import { SelectFieldPropsWithOptionalSource } from './types';

const ActivityDurationUnitField: React.FC<
  SelectFieldPropsWithOptionalSource
> = ({ label, source, ...rest }): ReactElement => (
  <SelectField
    {...rest}
    label={label}
    source={source}
    choices={ACTIVITY_DURATION_UNIT_CHOICES}
  />
);

ActivityDurationUnitField.defaultProps = {
  label: 'Duration Unit',
  source: 'durationUnit',
};

export default ActivityDurationUnitField;
