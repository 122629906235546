import React, { useCallback, useEffect, useState, ReactElement } from 'react';
import { useListContext } from 'react-admin';
import { FormControlLabel, Switch } from '@mui/material';

const FilterMyCertificatesButton: React.FC<{ defaultFilter: object }> = ({
  defaultFilter,
}): ReactElement => {
  const { setFilters, filterValues } = useListContext();
  const [isMyCertificate, setIsMyCertificate] = useState(
    !!filterValues?.mobileResponsible
  );

  useEffect(() => {
    setIsMyCertificate(!!filterValues?.mobileResponsible);
  }, [filterValues, setIsMyCertificate]);

  const handleSwitchChange = useCallback(
    (e) => {
      const mergedFilterValues = {
        ...(defaultFilter || {}),
        ...filterValues,
        ...(e.target.checked && { mobileResponsible: true }),
      };

      if (!e.target.checked) {
        delete mergedFilterValues.mobileResponsible;
      }

      setFilters(mergedFilterValues, []);
      setIsMyCertificate(e.target.checked);
    },
    [defaultFilter, setFilters, setIsMyCertificate, filterValues]
  );

  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={isMyCertificate}
          onChange={handleSwitchChange}
        />
      }
      label="My Certificates"
    />
  );
};

export default FilterMyCertificatesButton;
