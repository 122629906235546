import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import SmartLinkField from './SmartLinkField';
import { RESOURCE_DRAWING } from '../constants';

interface DrawingFieldProps {
  label?: string;
  source?: string;
  sx?: object;
}

const DrawingField: React.FC<DrawingFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_DRAWING}
    sortBy="drawing.drawingName"
  >
    <TextField source="drawing.drawingName" sx={sx} />
  </SmartLinkField>
);

DrawingField.defaultProps = { label: 'Drawing', source: 'drawingId' };

export default DrawingField;
