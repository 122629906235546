import { getCurrentProjectPermissions } from '../../../provider/userContext';
import { securityLevel } from '../constants';

/*
Checks if userSecurityLevel is more advanced
or similar comparing to leastSecurityLevel we pass into.
The smaller the level number the more advanced the level is.
If leastSecurityLevel is undefined that will mean nothing to check - function will return True.
*/
const checkSecurityLevel = (leastSecurityLevel: number): boolean => {
  const projectPermission = getCurrentProjectPermissions();
  const userSecurityLevel = projectPermission?.effectiveSecurityLevel;
  return (
    leastSecurityLevel == undefined || userSecurityLevel <= leastSecurityLevel
  );
};

export const isUserAdminLevel = () => checkSecurityLevel(securityLevel.admin);

export default checkSecurityLevel;
