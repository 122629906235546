import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { TextField, useListContext } from 'react-admin';
import commonStyles from '../../../common/commonStyles';

const MRMaterialStoragesFooter: React.FC = (): ReactElement => {
  const { data } = useListContext();

  if (!data?.length) return null;

  return (
    <Box sx={commonStyles.flexBox} className="materials-footer">
      <Box className="total">Total</Box>
      <TextField label="" source="totalQty" className="fixed selected" />
      <Box className="fixed2x" />
      <Box className="action" />
    </Box>
  );
};

export default MRMaterialStoragesFooter;
