import Box from '@mui/material/Box';
import { CircularProgress as CircularProgressMui } from '@mui/material';
import React, { ReactElement } from 'react';
import { styles } from '../qrScanner/styles';

const CircularProgress: React.FC = (): ReactElement => (
  <Box sx={styles.circularProgressWrapper}>
    <CircularProgressMui />
  </Box>
);

export default CircularProgress;
