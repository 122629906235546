import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_STRUCTURE_ASSET_GROUP } from '../constants';
import SmartLinkField from './SmartLinkField';

interface AssetGroupFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}

const AssetGroupField: React.FC<AssetGroupFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_STRUCTURE_ASSET_GROUP}
  >
    <TextField source="assetGroup.assetGroup" />
  </SmartLinkField>
);

AssetGroupField.defaultProps = {
  label: 'Asset Group',
  source: 'assetGroupId',
  sortBy: '`assetGroup.assetGroup`',
};

export default AssetGroupField;
