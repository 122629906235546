import React, { ReactElement } from 'react';
import { useStore, AutocompleteInput, Validator } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';
import { PUNCH_CUSTOM_FIELDS } from '../common/constants';

interface FieldInputProps {
  validate?: Validator[];
  sx: SxProps<Theme>;
}

const FieldInput: React.FC<FieldInputProps> = ({
  validate,
  sx,
}): ReactElement => {
  const resource = useWatch({ name: 'resourceName' });
  const [availableColumns] = useStore(
    `preferences.${resource}.datagrid.availableColumns`,
    []
  );

  let choices = availableColumns
    .filter((item) => {
      if (item.label === 'Actions') {
        return false;
      }

      return !['id', 'updatedAt', 'updatedBy', 'updatedByName'].includes(
        item.source
      );
    })
    .map((item) => ({
      id: item.source,
      name: item.label || item.source,
    }));

  if (resource === 'punch') {
    choices = choices
      .filter((choice) => !choice.id.startsWith('cfText'))
      .concat(PUNCH_CUSTOM_FIELDS);
  }

  return (
    <AutocompleteInput
      source="field"
      label="Field"
      choices={choices}
      validate={validate ?? []}
      sx={sx}
    />
  );
};

export default FieldInput;
