import { SxProps, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import SystemInput from 'src/components/common/SystemInput';
import { getCertDetailsSystemSource } from './helpers';

interface CertDetailsSystemInputProps {
  label?: string;
  disabled: boolean;
  sx: SxProps<Theme>;
}

const CertDetailsSystemInput: React.FC<CertDetailsSystemInputProps> = ({
  label,
  disabled,
  sx,
}): ReactElement => {
  const record = useRecordContext();
  const { source } = getCertDetailsSystemSource(record);

  return (
    <SystemInput source={source} label={label} disabled={disabled} sx={sx} />
  );
};

CertDetailsSystemInput.defaultProps = {
  label: 'System',
};

export default CertDetailsSystemInput;
