import React, { Fragment, ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import FormToolBar from '../../attachment/components/FormToolBar';
import CustomNumberInput from '../../common/CustomNumberInput';
import CustomDateInput from '../../common/CustomDateInput';
import commonStyles from '../../common/commonStyles';

interface AttachmentRevisionEditSimpleFormProps {
  onClose: () => void;
  onSubmit: (data) => Promise<void>;
}

const AttachmentRevisionEditSimpleForm: React.FC<
  AttachmentRevisionEditSimpleFormProps
> = ({ onClose, onSubmit }): ReactElement => (
  <SimpleForm
    id="edit_attachment_revision_form"
    toolbar={
      <FormToolBar onCancel={onClose} deleteButtonProps={{ show: false }} />
    }
    onSubmit={onSubmit}
  >
    <Fragment>
      <CustomNumberInput
        source="revision"
        label="Revision"
        isPositive
        sx={commonStyles.flexBoxItem}
      />
      <CustomDateInput source="revisionDate" label="Revision Date" />
    </Fragment>
  </SimpleForm>
);

export default AttachmentRevisionEditSimpleForm;
