import React, { ReactElement, Fragment, useCallback } from 'react';
import { useNotify } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import AssignResponsibleButton from '../../common/AssignResponsibleButton';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import { ContactCategories } from '../../common/contactInput/constants';
import BulkGeneratePdfButton from '../../common/BulkGeneratePdfButton';
import { RESOURCE_SMART_CERT_DETAIL } from '../../constants';
import useCanAccess from '../../common/hooks/useCanAccess';
import InfoButton from './InfoButton';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const notify = useNotify();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_SMART_CERT_DETAIL,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  const onUpdate = useCallback(
    () =>
      notify(
        'The Close Out process has been started. You will receive a notification once the process is complete.',
        {
          type: 'success',
          undoable: false,
        }
      ),
    [notify]
  );

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkGeneratePdfButton onSuccess={clearCache} />
      <AssignResponsibleButton category={ContactCategories.certSignOff} />
      {canAccessList.closeout && (
        <Fragment>
          <CustomBulkUpdateButton
            data={{ status: 'COMPLETED' }}
            confirmation="Do you want to stop the certificate signing process and close out selected certificate(s)?"
            label="Force Close Out Certificate(s)"
            icon={<CheckIcon />}
            resource={RESOURCE_SMART_CERT_DETAIL}
            onUpdate={onUpdate}
          />
          <InfoButton />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BulkActionButtons;
