import React, { ReactElement, useEffect } from 'react';
import { Link, useRecordContext, useStore } from 'react-admin';
import { Button as ButtonMaterial } from '@mui/material';
import Box from '@mui/material/Box';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIconButton from '../../common/EditIconButton';
import ShowIconButton from '../../common/ShowIconButton';
import DeleteWithConfirmIconButton from '../../common/DeleteWithConfirmIconButton';
import commonStyles from '../../common/commonStyles';
import useCanAccess from '../../common/hooks/useCanAccess';
import { BookSection } from '../type';
import { FieldPropsWithOptionalSource } from '../../common/types';

export const ColumnActions: React.FC<
  FieldPropsWithOptionalSource
> = (): ReactElement => {
  const record = useRecordContext<BookSection>();
  const [, setBookSectionNext] = useStore<string>('bookSectionNext', undefined);
  const [, setBookSectionSub] = useStore<string>('bookSectionSub', undefined);
  const canAccessList = useCanAccess();

  useEffect(() => {
    setBookSectionNext(null);
    setBookSectionSub(null);
  }, [setBookSectionNext, setBookSectionSub]);

  const changeBookSectionNext = () => {
    const sections = record.section.split('.');
    const nextSection = +sections[sections.length - 1] + 1;
    sections.pop();
    const prefix = sections.length ? sections.join('.') + '.' : '';
    setBookSectionNext(prefix + String(nextSection).padStart(2, '0'));
  };

  const changeBookSectionSub = () => {
    setBookSectionNext(record.section + '.01');
  };

  return (
    <Box sx={commonStyles.columnActionsBox}>
      {canAccessList.create && (
        <Link to="create" key="create:next">
          <ButtonMaterial
            startIcon={<FormatListNumberedIcon />}
            onClick={changeBookSectionNext}
          >
            NEXT
          </ButtonMaterial>
        </Link>
      )}
      {canAccessList.create && (
        <Link to="create" key="create:sub">
          <ButtonMaterial
            startIcon={<PlaylistAddIcon />}
            onClick={changeBookSectionSub}
          >
            SUB
          </ButtonMaterial>
        </Link>
      )}
      {canAccessList.show && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <ShowIconButton to={`${record.id}/show`} key="show" />
      )}
      {canAccessList.edit && <EditIconButton to={`${record.id}`} key="edit" />}
      {canAccessList.delete && (
        <DeleteWithConfirmIconButton
          redirect={null}
          className="deleteButton"
          key="delete"
        />
      )}
    </Box>
  );
};

export default ColumnActions;
