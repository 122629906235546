import { useResourceDefinitions } from 'react-admin';
import { convertResourceKeyToName } from '../helpers/getResourceLists';

const useResourceChoices = () => {
  const resources = useResourceDefinitions();
  const choices = Object.keys(resources).map((key) => {
    return { id: key, name: convertResourceKeyToName(key) };
  });
  choices.unshift({ id: '*', name: 'All' });
  return choices;
};

export default useResourceChoices;
