import React, { Fragment, ReactElement } from 'react';
import { BooleanInput, maxLength, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import CertTemplateCategoryInput from '../../common/CertTemplateCategoryInput';
import { required } from '../../../utils/UtilityFunctions';
import commonStyles from '../../common/commonStyles';
import TextInput from '../../common/TextInput';

const CertTemplateForm: React.FC = (): ReactElement => {
  const record = useRecordContext();
  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="certName"
          label="Certificate"
          validate={[required(), maxLength(100)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label="Description"
          validate={[maxLength(100)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CertTemplateCategoryInput
          isRequired={!record}
          sx={commonStyles.flexBoxItem}
          disabled={!!record}
        />
        <TextInput
          source="externalFormId"
          label="External Form ID"
          validate={[maxLength(50)]}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
        <BooleanInput
          source="isVendorCert"
          label="Vendor"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Fragment>
  );
};

export default CertTemplateForm;
