import React, { ReactElement } from 'react';
import {
  ReferenceManyField,
  TextField,
  NumberField,
  Datagrid,
} from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_BOOK_SECTION_ENG_CODE } from '../../constants';
import { Box } from '@mui/material';
import commonStyles from '../../common/commonStyles';
import { ListComponentTitle } from '../components/ListComponentTitle';

export const BookSectionEngCodeListShow: React.FC = (): ReactElement => {
  return (
    <Box sx={commonStyles.editableDataGrid}>
      <ListComponentTitle variant="caption" label="Engineering List" />
      <ReferenceManyField
        reference={RESOURCE_BOOK_SECTION_ENG_CODE}
        target="bookSectionId"
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'sortOrder', order: 'ASC' }}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField
            source="engineeringCode"
            label="Eng. Code"
            sortable={false}
          />
          <NumberField source="sortOrder" sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};
