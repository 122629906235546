import React, { ReactElement } from 'react';
import {
  SelectField as RASelectField,
  SelectFieldProps as RASelectFieldProps,
} from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

interface SelectFieldProps extends RASelectFieldProps {
  source: string;
  label?: string;
  sx?: SxProps<Theme>;
  choices: object[];
}

const SelectField: React.FC<SelectFieldProps> = ({
  source,
  label,
  sx,
  choices,
}): ReactElement => (
  <RASelectField source={source} label={label} sx={sx} choices={choices} />
);

export default SelectField;
