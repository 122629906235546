import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AuditListTab from '../audit/AuditListTab';

const PunchCategoryShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Punch Category">
        <Box>
          <Labeled>
            <TextField
              source="category"
              label="Punch Category"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_punch_category" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default PunchCategoryShow;
