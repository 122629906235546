import React, { Fragment, ReactElement, useCallback } from 'react';
import {
  BooleanInput,
  PasswordInput,
  email,
  maxLength,
  Button,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Badge, Box } from '@mui/material';
import { UserContext, getContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import TextInput from '../common/TextInput';
import PinCodeInput from '../common/PinCodeInput';
import Tooltip from '../common/Tooltip';
import commonStyles from '../common/commonStyles';
import { RESOURCE_USER } from '../constants';
import DivisionInput from './components/DivisionInput';
import TempPasswordHint from './components/TempPasswordHint';

interface UserFormProps {
  createMode?: boolean;
}

const UserForm: React.FC<UserFormProps> = ({
  createMode = false,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext();
  const divisionSource = createMode
    ? 'divisionId'
    : `companies.${ctx?.company}.defaultDivisionId`;

  const { mutate: sendMicrosoftSSOInvitation, isPending: inviteInProgress } =
    useMutation({
      mutationFn: async (params: object) => {
        return dataProvider.sendMicrosoftSSOInvitation(RESOURCE_USER, params);
      },
      onSuccess: () => {
        notify(`Successfully sent microsoft sso invitation.`, {
          type: 'success',
        });
      },
      onError: (error) => {
        notify(error && error['message'], {
          type: 'error',
          undoable: false,
        });
      },
    });

  const { mutate: resetUserPassword, isPending: resetPasswordInProgress } =
    useMutation({
      mutationFn: async (params: object) => {
        return dataProvider.resetUserPassword(RESOURCE_USER, params);
      },
      onSuccess: () => {
        notify(`Successfully Reset User Password!`, {
          type: 'success',
        });
      },
      onError: (error) => {
        notify(error && error['message'], {
          type: 'error',
          undoable: false,
        });
      },
    });

  const sendSSOInvite = useCallback(() => {
    sendMicrosoftSSOInvitation({ user: record });
  }, [record, sendMicrosoftSSOInvitation]);

  const resetPassword = useCallback(() => {
    resetUserPassword({ user: record });
  }, [record, resetUserPassword]);

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="id"
          validate={createMode && [required(), email(), maxLength(150)]}
          label="Email"
          sx={commonStyles.flexBoxItem}
          disabled={!createMode}
        />
        <TextInput
          source="firstName"
          label="First Name"
          validate={[required(), maxLength(100)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="lastName"
          label="Last Name"
          validate={[required(), maxLength(100)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="jobTitle"
          label="Job Title"
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="externalCompany"
          label="Company"
          sx={commonStyles.flexBoxItem}
        />
        <PinCodeInput />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <DivisionInput source={divisionSource} label="Division" isRequired />
        <BooleanInput
          source={`companies.${ctx?.company}.isPreferred`}
          label="Preferred Company"
          defaultValue={true}
        />
      </Box>
      {!createMode && (
        <Fragment>
          <Box sx={commonStyles.flexBox}>
            {ctx?.isRev1Admin && (
              <BooleanInput
                source="isGlobalAccessDisabled"
                label="Global Access Disabled?"
                defaultValue={false}
              />
            )}
            <BooleanInput
              source={`companies.${ctx?.company}.isCompanyAccessDisabled`}
              label="Company Access Disabled?"
              defaultValue={false}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Button
              label={
                inviteInProgress
                  ? 'Sending Invitation ...'
                  : 'Send Microsoft SSO Invitation'
              }
              variant="contained"
              size="medium"
              disabled={inviteInProgress}
              onClick={sendSSOInvite}
            />
            <Button
              label={
                resetPasswordInProgress
                  ? 'Resetting Password ...'
                  : 'Reset Password'
              }
              variant="contained"
              size="medium"
              disabled={resetPasswordInProgress}
              onClick={resetPassword}
            />
          </Box>
        </Fragment>
      )}
      {createMode && (
        <Fragment>
          <Box sx={commonStyles.flexBox}>
            <BooleanInput
              source="sendEmail"
              label="Send Invitation Email"
              defaultValue={true}
            />
            <PasswordInput
              source="password"
              label="Temp Password"
              sx={{ ...commonStyles.flexBoxItem, marginRight: 0 }}
            />
            <Tooltip title={<TempPasswordHint />}>
              <Box
                sx={{ marginTop: '20px', marginRight: '20px', fontSize: 12 }}
              >
                <Badge
                  badgeContent="i"
                  color="primary"
                  sx={{ paddingLeft: '12px', paddingBottom: '5px' }}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <BooleanInput
              source="sendMicrosoftSSOInvitation"
              label="Send Microsoft SSO Invitation"
              defaultValue={false}
            />
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

UserForm.defaultProps = {
  createMode: false,
};

export default UserForm;
