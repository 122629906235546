import { BooleanInput, SimpleForm, maxLength } from 'react-admin';
import FooterToolbar from '../common/FooterToolbar';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import { UserContext, getContext } from '../../provider/userContext';
import { LucidDiagram } from './type';
import React, { ReactElement } from 'react';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import LucidEmbedSelect from './components/LucidEmbedSelect';

const LucidDiagramCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: LucidDiagram) => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="title"
            label="Title"
            validate={[required(), maxLength(125)]}
            sx={commonStyles.flexBoxDescriptionItem}
          />
          <BooleanInput
            source="isForDashboard"
            label="Display on Dashboard"
            sx={commonStyles.rowFormInput}
          />
        </Box>
        <LucidEmbedSelect />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default LucidDiagramCreate;
