import { useRecordContext } from 'react-admin';
import React, { ReactElement, useMemo } from 'react';
import { RESOURCE_CERT_GATING_TEST } from '../../../../constants';
import { getContext, UserContext } from '../../../../../provider/userContext';
import TestTypeField from '../../../../common/TestTypeField';
import TestTypeInput from '../../../../common/TestTypeInput';
import commonStyles from '../../../../common/commonStyles';
import { CertGating } from '../../../types';
import GatingFormRow from '../GatingFormRow';
import GatingForm from '../GatingForm';

const TestTypesForm: React.FC = (): ReactElement => {
  const record = useRecordContext<CertGating>();
  const ctx: UserContext = getContext();

  const additionalFilters = useMemo(() => {
    const selectedIds = record.tblCertGatingTests.map(
      (testType) => testType.testType.id
    );
    if (selectedIds.length > 0) return { id_neq_any: selectedIds };
  }, [record.tblCertGatingTests]);

  const formRow = (
    <GatingFormRow
      recordId={record?.id}
      projectId={ctx?.projectId}
      reference={RESOURCE_CERT_GATING_TEST}
    >
      <TestTypeInput
        sx={commonStyles.rowFormInput}
        isRequired
        additionalFilters={additionalFilters}
      />
    </GatingFormRow>
  );

  return (
    <GatingForm formRow={formRow} reference={RESOURCE_CERT_GATING_TEST}>
      <TestTypeField sx={commonStyles.rowFormField} />
    </GatingForm>
  );
};

export default TestTypesForm;
