import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecordContext, FormTabProps } from 'react-admin';
import MetaDataInputs from '../../common/MetaDataInputs';
import commonStyles from '../../common/commonStyles';
import LazyFormTab from '../../common/LazyFormTab';
import ElectricalForm from './ElectricalForm';

interface ElectricalFormTabProps extends Partial<FormTabProps> {
  bulkEditMode?: boolean;
}

const ElectricalFormTab: React.FC<ElectricalFormTabProps> = ({
  bulkEditMode,
  ...props
}): ReactElement => {
  const record = useRecordContext();
  const hasElectrical = useWatch({ name: 'hasElectrical' });

  return (
    <LazyFormTab
      {...props}
      label="Electrical"
      id="electrical_tab"
      sx={hasElectrical ? commonStyles.formTabHeader : commonStyles.displayNone}
    >
      <ElectricalForm bulkEditMode={bulkEditMode} />
      {record && <MetaDataInputs source="electrical" />}
    </LazyFormTab>
  );
};

export default ElectricalFormTab;
