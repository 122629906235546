import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_COMPONENT } from '../constants';
import SmartLinkField from './SmartLinkField';

interface ComponentFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sx?: object;
  sortBy?: string;
}

const ComponentField: React.FC<ComponentFieldProps> = ({
  label,
  source,
  textSource,
  sx,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_COMPONENT}>
    <TextField source={textSource} sx={sx} />
  </SmartLinkField>
);

ComponentField.defaultProps = {
  label: 'Tag',
  source: 'componentId',
  textSource: 'component.tag',
  sortBy: '`component.tag`',
};

export default ComponentField;
