import React, { Fragment, ReactElement } from 'react';
import { Badge } from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from '../../../../utils/UtilityFunctions';
import Tooltip from '../../../common/Tooltip';

interface MaterialSuggestedQtyInfoProps {
  suggestedQty?: number;
}

const POMaterialsSuggestedQtyInfo: React.FC<MaterialSuggestedQtyInfoProps> = ({
  suggestedQty,
}): ReactElement => {
  if (isEmpty(suggestedQty)) return null;

  return (
    <Fragment>
      <Tooltip title="The suggested quantity is calculated as 'Max Level' - 'Total Qty on Hand'">
        <Box sx={{ marginRight: '10px', fontSize: 12 }}>
          Suggested qty: {suggestedQty}
          <Badge
            badgeContent="i"
            color="info"
            sx={{ paddingLeft: '12px', paddingBottom: '5px' }}
          />
        </Box>
      </Tooltip>
    </Fragment>
  );
};

export default POMaterialsSuggestedQtyInfo;
