import React, { ReactElement, useCallback } from 'react';
import ImageGalleryFormTab from '../common/ImageGalleryFormTab';
import AttachmentFormTab from '../common/AttachmentFormTab';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import { EditPropsWithLocation } from '../common/types';
import CommentFormTab from '../common/CommentFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import { commentType } from '../common/constants';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import PunchForm from './PunchForm';
import { Punch } from './types';

const PunchEdit: React.FC<EditPropsWithLocation<Punch>> = (
  props
): ReactElement => {
  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      punchId: recordId,
      commentType: commentType.punch,
    }),
    []
  );

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab label="Punch" id="punch_tab" sx={commonStyles.formTabHeader}>
        <PunchForm editMode />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Comment"
        id="comments_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          targetId="punch_id"
          commentType={commentType.punch}
          transformFunc={commentTabTransformFunc}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="punchId"
          attachmentType={[
            ATTACHMENT_TYPE_ENUMS.GENERAL,
            ATTACHMENT_TYPE_ENUMS.IMAGE,
          ]}
          simpleView
        />
      </LazyFormTab>
      <LazyFormTab
        label="Image Gallery"
        id="gallery_tab"
        sx={commonStyles.formTabHeader}
      >
        <ImageGalleryFormTab targetId="punchId" />
      </LazyFormTab>
      <HistoryTab tableName="tbl_punch" />
    </TabbedEditForm>
  );
};

export default PunchEdit;
