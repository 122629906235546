import React, { ReactElement, Fragment } from 'react';
import { range } from 'lodash';
import { WithDeleteDialogSupport } from '@jsonforms/material-renderers';
import { Typography } from '@mui/material';
import {
  JsonSchema,
  Paths,
  JsonFormsCellRendererRegistryEntry,
  ArrayTranslations,
} from '@jsonforms/core';
import ArrayRow from './ArrayRow';

interface ArrayRowsProp {
  data: number;
  path: string;
  schema: JsonSchema;
  enabled: boolean;
  cells?: JsonFormsCellRendererRegistryEntry[];
  translations: ArrayTranslations;
}

const ArrayRows = ({
  data,
  path,
  schema,
  openDeleteDialog,
  enabled,
  cells,
  translations,
}: ArrayRowsProp & WithDeleteDialogSupport): ReactElement => {
  if (data === 0) {
    return <Typography align="left">{translations.noDataMessage}</Typography>;
  }

  return (
    <Fragment>
      {range(data).map((index: number) => {
        const childPath = Paths.compose(path, `${index}`);

        return (
          <ArrayRow
            key={childPath}
            childPath={childPath}
            rowIndex={index}
            schema={schema}
            openDeleteDialog={openDeleteDialog}
            enabled={enabled}
            cells={cells}
            path={path}
            translations={translations}
          />
        );
      })}
    </Fragment>
  );
};

export default ArrayRows;
