import React, { ReactElement } from 'react';
import { AutocompleteInput, GetListParams, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import useOptionList from '../userGroup/hooks/useOptionList';
import { Project } from '../project/types';
import { GroupHeader, GroupItems } from './Assigned/styles';
import { LOOKUP_PAGE_SIZE } from './constants';

interface ProjectInputProps {
  label?: string;
  source?: string;
  isRequired?: boolean;
  sx?: SxProps<Theme>;
  requestParams?: Partial<GetListParams>;
  getOptionDisabled?: (option: Project) => boolean;
  includeFields?: string[];
  readOnly?: boolean;
  validate?: Validator[];
  additionalParams?: Record<string, unknown>;
}

const ProjectInputWithGroup: React.FC<ProjectInputProps> = ({
  source,
  includeFields,
  sx,
  requestParams,
  ...rest
}): ReactElement => {
  const { filter, sort } = requestParams ?? {};
  const groupedProjects = useOptionList({
    filter,
    sort,
    pagination: {
      page: 1,
      perPage: LOOKUP_PAGE_SIZE,
    },
    meta: {
      includeFields,
    },
  });

  return (
    <AutocompleteInput
      id={source}
      choices={groupedProjects}
      source={source}
      groupBy={(item) => item.group}
      optionText="option.title"
      optionValue="option.value"
      sx={sx}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      {...rest}
    />
  );
};

ProjectInputWithGroup.defaultProps = {
  label: 'Project Name',
  source: 'projectId',
  includeFields: ['id', 'project'],
};

export default ProjectInputWithGroup;
