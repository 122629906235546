import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_PRESERVATION_ITEM } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PreservationItemFieldProps {
  label?: string;
  source?: string;
}

const PreservationItemField: React.FC<PreservationItemFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_PRESERVATION_ITEM}
    sortBy="preservationItem.id"
  >
    <TextField source="preservationItemId" />
  </SmartLinkField>
);

PreservationItemField.defaultProps = {
  label: 'Preservation Item',
  source: 'preservationItemId',
};

export default PreservationItemField;
