import React, { ReactElement, useState, useEffect } from 'react';
import {
  Edit,
  useResourceContext,
  useStore,
  useListContext,
} from 'react-admin';
import { Box, Tabs, Tab } from '@mui/material';
import EditTitle from '../common/EditTitle';
import CustomTabPanel from '../common/CustomTabPanel';
import { EditPropsWithLocation } from '../common/types';
import sideFilterStyles from '../common/sideFilterStyles';
import commonStyles from '../common/commonStyles';
import SmartCertDetailsForm from './components/SmartCertDetailsForm';

interface SmartCertDetailsEditProps extends EditPropsWithLocation {
  onCancel?: () => void;
}

const SmartCertDetailsEdit: React.FC<SmartCertDetailsEditProps> = (
  props
): ReactElement => {
  const { onCancel } = props;
  const [value, setValue] = useState(0);
  const resource: string = useResourceContext();
  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const { data: certs = [], isLoading } = useListContext();

  useEffect(() => {
    if (!isLoading) {
      const listIds = certs.map((item) => item.id);
      if (!expanded.every((id) => listIds.includes(id))) {
        setExpanded([]);
      }
    }
  }, [certs, expanded, setExpanded, isLoading]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={sideFilterStyles.sideEditCard} id="aside">
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle />}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Form"
              id="form_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} id="form_tab">
          <SmartCertDetailsForm onCancel={onCancel} />
        </CustomTabPanel>
      </Edit>
    </Box>
  );
};

export default SmartCertDetailsEdit;
