import React, { ReactElement } from 'react';
import { AutocompleteInput, Button, Filter } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import TextInput from '../common/TextInput';
import DateRangeFilter from '../common/DateRangeFilter';
import sideFilterStyles from '../common/sideFilterStyles';
import { SideFilter } from '../common/types';
import { RESOURCE_ATTACHMENT } from '../constants';
import useResourceChoices from '../common/hooks/useResourceChoices';

const ImportSideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_ATTACHMENT, defaultFilter);
  const resourceChoices = useResourceChoices()
    .filter((choice) => choice.id !== '*')
    .sort((choice1, choice2) => choice1.name.localeCompare(choice2.name));

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard} id="importSidebarFilter">
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="File name"
            name="file_name_inc"
            source="file_name"
            fullWidth
            alwaysOn
          />
          <AutocompleteInput
            label="Resource"
            source="resource_name"
            choices={resourceChoices}
            alwaysOn
            multiple
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default ImportSideFilters;
