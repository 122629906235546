import React, { ReactElement } from 'react';
import {
  AutocompleteArrayInput,
  SelectInput,
  SelectInputProps,
  Validator,
} from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

interface MobileTestStatusInputProps
  extends Omit<
    SelectInputProps,
    'field' | 'name' | 'formState' | 'fieldState'
  > {
  source: string;
  label: string;
  sx: SxProps<Theme>;
  choices: object[];
  validate?: Validator[];
  disabled?: boolean;
  multiple?: boolean;
  disableValue?: string;
}

const MobileTestStatusInput: React.FC<MobileTestStatusInputProps> = ({
  source,
  label,
  sx,
  choices,
  validate,
  disableValue,
  disabled = false,
  multiple = false,
}): ReactElement => {
  const props = {
    source,
    label,
    validate,
    sx,
    choices: choices,
  };

  if (multiple) {
    return (
      <AutocompleteArrayInput defaultValue={['NONE']} multiple {...props} />
    );
  }

  return (
    <SelectInput
      defaultValue={'NONE'}
      {...props}
      disabled={disabled}
      disableValue={disableValue}
    />
  );
};

export default MobileTestStatusInput;
