import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import ComponentForm from './components/relatedForms/componentForm/ComponentForm';
import SubSystemForm from './components/relatedForms/subSystemForm/SubSystemForm';
import SystemForm from './components/relatedForms/systemForm/SystemForm';
import { Moc } from './types';
import { EditPropsWithLocation } from '../common/types';
import MocForm from './MocForm';
import AttachmentFormTab from '../common/AttachmentFormTab';

const MocEdit: React.FC<EditPropsWithLocation<Moc>> = (props): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="MoC" id="moc_tab" sx={commonStyles.formTabHeader}>
      <MocForm editMode />
    </LazyFormTab>
    <LazyFormTab
      label="Related"
      id="related_tab"
      sx={commonStyles.formTabHeader}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'space-between',
        }}
      >
        <SystemForm />
        <SubSystemForm />
        <ComponentForm />
      </Box>
    </LazyFormTab>
    <LazyFormTab
      label="Attachment"
      id="attachment_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentFormTab targetId="mocId" />
    </LazyFormTab>
    <HistoryTab tableName="tbl_moc" />
  </TabbedEditForm>
);

export default MocEdit;
