import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  FunctionField,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { get, isEqual } from 'lodash';
import {
  getContext,
  updateContext,
  UserContext,
} from '../../provider/userContext';
import checkSecurityLevel from '../common/helpers/checkSecurityLevel';
import useRefreshCompanyContext from '../common/hooks/useRefreshCompanyContext';
import TabbedEditForm from '../common/TabbedEditForm';
import LazyFormTab from '../common/LazyFormTab';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import { securityLevel } from '../common/constants';
import PermissionsTab from './components/PermissionsTab';
import ProjectsInputArrayField from './components/ProjectsInputArrayField';
import UserForm from './UserForm';
import {
  getEffectivePermissionWithProjectId,
  makeRequestBody,
} from './helpers';

const UserEdit: React.FC<EditPropsWithLocation> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const resource = useResourceContext();
  const queryClient = useQueryClient();
  const record = useRecordContext();
  const { id } = useParams();
  const userId = get(record, 'id', id);
  const refresh = useRefresh();
  const refreshCompanyContext = useRefreshCompanyContext();

  const mutationOptions = useMemo(() => {
    return {
      onSuccess: async (data) => {
        const isCurrentUser = userId && ctx.id === userId;

        if (!isCurrentUser) return;

        let shouldRefresh = false;
        const currentCompanyProjects = data?.companies[ctx.company]?.projects;
        const previousCompanyProjects =
          record?.companies[ctx.company]?.projects;

        if (data.firstName && data.lastName) {
          const fullName = `${data.firstName} ${data.lastName}`;

          await updateContext({ fullName });
          shouldRefresh = true;
        }

        if (
          currentCompanyProjects &&
          !isEqual(currentCompanyProjects, previousCompanyProjects)
        ) {
          await refreshCompanyContext();

          shouldRefresh = true;
        }

        if (shouldRefresh) {
          await refresh();
        }
      },
    };
  }, [
    ctx.company,
    ctx.id,
    record?.companies,
    refresh,
    refreshCompanyContext,
    userId,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [resource, 'getOne', { id: String(userId) }],
    });
  }, [queryClient, userId, resource]);

  const transform = (data) => makeRequestBody(data, ctx?.company);

  return (
    <TabbedEditForm
      {...props}
      transform={transform}
      syncWithLocation={false}
      mutationOptions={mutationOptions}
    >
      <LazyFormTab label="User" id="user_tab" sx={commonStyles.formTabHeader}>
        <UserForm />
      </LazyFormTab>
      <LazyFormTab
        label="Permissions"
        id="permissions_tab"
        sx={commonStyles.formTabHeader}
      >
        <FunctionField
          sx={commonStyles.fullWidth}
          render={(user) => {
            const effectivePermissions = getEffectivePermissionWithProjectId(
              get(user, `companies.${ctx?.company}.projects`, []),
              ctx?.projectId
            );
            return (
              <PermissionsTab
                effectivePermissions={effectivePermissions}
                userId={user.id}
              />
            );
          }}
        />
      </LazyFormTab>
      {checkSecurityLevel(securityLevel.fieldAdmin) && (
        <LazyFormTab
          label="Projects"
          id="projects_tab"
          sx={commonStyles.formTabHeader}
        >
          <ProjectsInputArrayField />
        </LazyFormTab>
      )}
    </TabbedEditForm>
  );
};

export default UserEdit;
