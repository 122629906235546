import React, { ReactElement } from 'react';
import { MenuItemNode } from '@react-admin/ra-navigation';
import IconMenuItemList from './IconMenuItemList';
import { MenuItemProps } from './constants';

const ChildIconMenuItems: React.FC<MenuItemProps> = ({
  menuItems,
  menuName,
}): ReactElement => {
  return menuItems.map((menuItem) => {
    if (
      !menuItem.hasOwnProperty('items') &&
      !menuItem.hasOwnProperty('resource')
    ) {
      return (
        <MenuItemNode
          key={menuItem.label}
          name={menuName}
          label={menuItem.label}
          to={menuItem.to ? menuItem.to : ''}
          icon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
        />
      );
    }
    if (menuItem.hasOwnProperty('resource')) {
      return (
        <MenuItemNode
          key={menuItem.resource}
          name={menuName}
          label={menuItem.label}
          to={menuItem.to ? menuItem.to : ''}
          icon={menuItem.icon ? menuItem.icon : null}
          id={menuItem.id ? menuItem.id : null}
        />
      );
    }
    if (menuItem.hasOwnProperty('items') && !!menuItem.items.length) {
      return (
        <IconMenuItemList
          key={menuItem.name}
          menuItem={menuItem}
          menuName={menuName}
        />
      );
    }
  });
};

export default ChildIconMenuItems;
