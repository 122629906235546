import React, { useMemo } from 'react';
import AutocompleteReferenceInput, {
  AutocompleteReferenceInputProps,
} from './AutocompleteReferenceInput';
import CreateAutoCompleteCategoryDialog from './CreateAutoCompleteCategoryDialog';

interface AutoCompleteWithCreateOptionReferenceInputProps
  extends Partial<AutocompleteReferenceInputProps> {
  reference: string;
  keyValue: string;
  defaultFilter?: object;
}

const AutoCompleteWithCreateOptionReferenceInput = ({
  reference,
  keyValue,
  alwaysOn,
  defaultFilter,
  ...rest
}: AutoCompleteWithCreateOptionReferenceInputProps) => {
  const requestParams = useMemo(() => {
    return {
      filter: defaultFilter,
      sort: { field: keyValue, order: 'ASC' as const },
    };
  }, [defaultFilter, keyValue]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={reference}
      requestParams={requestParams}
      create={
        alwaysOn ? null : (
          <CreateAutoCompleteCategoryDialog
            reference={reference}
            keyValue={keyValue}
          />
        )
      }
      optionText={keyValue}
      optionValue={keyValue}
      includeFields={['id', keyValue]}
    />
  );
};

export default AutoCompleteWithCreateOptionReferenceInput;
