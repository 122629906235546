import React, { useCallback, useState, Fragment, ReactElement } from 'react';
import { useUpdate, useRecordContext } from 'react-admin';
import { EditDialog } from '@react-admin/ra-form-layout';
import { IconButton } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/functionalIcons/Edit.svg';
import { RESOURCE_ATTACHMENT_REVISION } from '../constants';
import AttachmentRevisionEditSimpleForm from './components/AttachmentRevisionEditSimpleForm';
import AttachmentRevisionEditTabbedForm from './components/AttachmentRevisionEditTabbedForm';
import { checkRevisionTabbedFormIsNeeded } from './helpers';

type AttachmentRevisionEditProps = {
  externalFormId?: string;
};

const AttachmentRevisionEdit: React.FC<AttachmentRevisionEditProps> = ({
  externalFormId,
}): ReactElement => {
  const record = useRecordContext();
  const [update] = useUpdate();
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => setShowDialog(false), []);

  const submit = useCallback(
    async (data) => {
      await update(RESOURCE_ATTACHMENT_REVISION, {
        id: data.id,
        data: {
          revision: data?.revision,
          revisionDate: data?.revisionDate || null,
          details: data?.details || null,
        },
        previousData: data,
      });

      closeDialog();
    },
    [closeDialog, update]
  );

  return (
    <Fragment>
      <IconButton
        size="small"
        color="primary"
        onClick={openDialog}
        title="Edit"
        className="editButton"
      >
        <EditIcon />
      </IconButton>
      <EditDialog
        resource={RESOURCE_ATTACHMENT_REVISION}
        fullWidth
        maxWidth="md"
        mutationMode="pessimistic"
        close={closeDialog}
        onClick={(event) => event?.stopPropagation()}
        isOpen={showDialog}
      >
        {checkRevisionTabbedFormIsNeeded(record?.attachment?.resourceName) ? (
          <AttachmentRevisionEditTabbedForm
            onClose={closeDialog}
            onSubmit={submit}
            externalFormId={externalFormId}
          />
        ) : (
          <AttachmentRevisionEditSimpleForm
            onClose={closeDialog}
            onSubmit={submit}
          />
        )}
      </EditDialog>
    </Fragment>
  );
};

export default AttachmentRevisionEdit;
