import { useCallback } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { RESOURCE_CONTACT } from '../../constants';
import { UserContext, getContext } from '../../../provider/userContext';
import useInvalidateResourceQueryCache from './useInvalidateResourceQueryCache';

const useCreateContact = ({
  category,
  changeValue,
  onCreate,
  getErrorMessage,
}) => {
  const [create] = useCreate();
  const ctx: UserContext = getContext();
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_CONTACT);
  const notify = useNotify();

  return useCallback(
    async (value) => {
      await create(
        RESOURCE_CONTACT,
        {
          data: {
            nickname: value,
            projectId: ctx?.projectId,
            category,
          },
        },
        {
          onSuccess: async (data) => {
            changeValue('');
            onCreate(data);
            await invalidateQueries();
          },
          onError: (error) => {
            notify(getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
        }
      );
    },
    [
      category,
      changeValue,
      create,
      ctx?.projectId,
      getErrorMessage,
      invalidateQueries,
      notify,
      onCreate,
    ]
  );
};

export default useCreateContact;
