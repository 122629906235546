import React, { ReactElement } from 'react';
import { FunctionField } from 'react-admin';
import { formatRoleRecord } from './helpers';

const RecordField: React.FC = (): ReactElement => (
  <FunctionField
    label="Record"
    render={(permission) => formatRoleRecord(permission.record)}
  />
);

export default RecordField;
