import React, { ReactElement } from 'react';
import { AutocompleteInput, Validator } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import useResourceChoices from '../common/hooks/useResourceChoices';
import { GroupHeader, GroupItems } from '../common/Assigned/styles';
import { customRouteResources, fieldChoices } from './constants';
import { compareResources } from './helpers';

interface ResourceInputProps {
  validate?: Validator[];
  alwaysOn?: boolean;
  multiple?: boolean;
  sx: SxProps<Theme>;
  source?: string;
  label?: string;
}

const ResourceInput: React.FC<ResourceInputProps> = ({
  validate,
  alwaysOn,
  multiple,
  sx,
  source,
  label,
}): ReactElement => {
  const resourceChoices = useResourceChoices();
  const allResourcesChoices = [...resourceChoices, ...customRouteResources];
  const groupedResourceChoices = allResourcesChoices
    .map((resourceChoice) => ({
      group: 'Resources',
      option: resourceChoice,
    }))
    .sort(compareResources);
  const groupedFieldChoices = fieldChoices
    .map((fieldChoice) => ({
      group: 'Fields',
      option: fieldChoice,
    }))
    .sort((a, b) => a.option.name.localeCompare(b.option.name));

  const groupedChoices = [...groupedResourceChoices, ...groupedFieldChoices];

  return (
    <AutocompleteInput
      choices={groupedChoices}
      source={source}
      label={label}
      alwaysOn={alwaysOn}
      multiple={multiple}
      validate={validate}
      sx={sx}
      groupBy={(option) => option.group}
      optionText="option.name"
      optionValue="option.id"
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

ResourceInput.defaultProps = {
  source: 'resourceName',
  label: 'Resource/Field',
};

export default ResourceInput;
