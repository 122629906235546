import React, { ReactElement, ReactNode } from 'react';
import { usePermissions, useResourceContext } from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import { FieldPropsWithOptionalSource } from './types';

export enum Actions {
  EDIT = 'edit',
  SHOW = 'show',
}

interface WithFieldCheckAccessProps extends FieldPropsWithOptionalSource {
  action: Actions;
  children: ReactNode;
  alwaysOn?: boolean;
  hasResourceEditAccess?: boolean;
}

const WithFieldCheckAccess: React.FC<WithFieldCheckAccessProps> = ({
  children,
  action,
  hasResourceEditAccess,
}): ReactElement | ReactElement[] | null => {
  const { permissions } = usePermissions();
  const resource = useResourceContext();
  const isEditAction = action === Actions.EDIT;

  const getResourceForField = (field: ReactElement): string | null => {
    const source = field?.props?.source;
    return source ? `${resource}.${source}` : null;
  };

  const hasFieldAccess = (
    field: ReactElement,
    actionType: Actions,
    skipFieldAccessCheck: boolean = true
  ): boolean => {
    const resourceForField = getResourceForField(field);
    const fieldPermissionExists = permissions.some(
      (permission) => permission.resource === resourceForField
    );
    // If skipFieldAccessCheck is set to false, enforce the requirement for access permissions; otherwise, return true if there are no permissions present for the field.
    if ((!resourceForField || !fieldPermissionExists) && skipFieldAccessCheck) {
      return true;
    }

    return canAccess({
      permissions,
      action: actionType,
      resource: resourceForField,
    });
  };

  const processField = (field: ReactNode): ReactElement | null => {
    if (!React.isValidElement(field)) return null;

    const canEdit = hasFieldAccess(field, Actions.EDIT, hasResourceEditAccess);
    const canShow = hasFieldAccess(field, Actions.SHOW);

    if (isEditAction) {
      if (canEdit) {
        return field;
      } else if (canShow) {
        return React.cloneElement(field, {
          ...field.props,
          key: getResourceForField(field),
          readOnly: true,
        });
      }
    } else if (canShow) {
      return field;
    }

    return null;
  };

  if (Array.isArray(children)) {
    return children.map(processField).filter(Boolean);
  } else {
    return processField(children);
  }
};

export default WithFieldCheckAccess;
