import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface ListComponentTitleProps {
  label: string;
  variant?: Variant;
}

export const ListComponentTitle: React.FC<ListComponentTitleProps> = ({
  label,
  variant = 'h5',
}): ReactElement => {
  return (
    <Typography variant={variant} color="textSecondary" align="left">
      {label}
    </Typography>
  );
};
