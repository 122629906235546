import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import {
  Datagrid,
  Identifier,
  TextField,
  useListContext,
  useRecordContext,
  useUnselectAll,
} from 'react-admin';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { ListWithPagination } from '../../common/ListWithPagination';
import { Test } from '../../test/types';
import { RESOURCE_TEST } from '../../constants';
import { TestFilters } from './TestFilters';

interface SelectTestDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (record: Test | Test[]) => void;
  bulkUpload?: boolean;
}

export const SelectTestDialog: React.FC<SelectTestDialogProps> = ({
  open,
  onClose,
  onSelect,
  bulkUpload,
}): ReactElement => (
  <Dialog maxWidth="lg" onClose={onClose} open={open}>
    <DialogTitle>Select Test</DialogTitle>
    <DialogContent>
      <ListWithPagination
        disableSyncWithLocation
        resource={RESOURCE_TEST}
        actions={false}
        filters={<TestFilters />}
      >
        <Datagrid
          bulkActionButtons={
            bulkUpload ? <AddButton onSelect={onSelect} /> : false
          }
          rowClick={false}
        >
          <TextField source="testFormNo" label="Form No" sortable={false} />
          <TextField source="testName" label="Test Name" sortable={false} />
          {!bulkUpload && <SelectButton onSelect={onSelect} />}
        </Datagrid>
      </ListWithPagination>
    </DialogContent>
  </Dialog>
);

interface ButtonProps {
  onSelect: (record: Test | Test[]) => void;
}

const SelectButton: React.FC<ButtonProps> = ({ onSelect }): ReactElement => {
  const record = useRecordContext<Test>();
  return <Button onClick={() => onSelect(record)}>Select</Button>;
};

const AddButton: React.FC<ButtonProps> = ({ onSelect }): ReactElement => {
  const { data, selectedIds } = useListContext<Test>();
  const selectedData = useRef<Record<Identifier, Test>>({});
  const dataByIdMap = useMemo<Record<Identifier, Test>>(() => {
    return data.reduce((acc, test) => {
      acc[test.id] = test;
      return acc;
    }, {});
  }, [data]);
  const unselectAll = useUnselectAll(RESOURCE_TEST);

  useEffect(() => {
    const newSelectedData = selectedIds.reduce((acc, id) => {
      acc[id] = selectedData.current[id] || dataByIdMap[id];
      return acc;
    }, {});
    selectedData.current = newSelectedData;
  }, [dataByIdMap, selectedIds]);

  useEffect(() => {
    return () => unselectAll();
  }, [unselectAll]);

  const select = () => {
    onSelect(Object.values(selectedData.current));
  };

  return (
    <Button onClick={select} startIcon={<ShoppingBasketIcon />}>
      Add
    </Button>
  );
};
