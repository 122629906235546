import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { SxProps, Theme } from '@mui/material';
import { RESOURCE_MATERIAL_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface MaterialTypeFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: SxProps<Theme>;
  sortable?: boolean;
}
const MaterialTypeField: React.FC<MaterialTypeFieldProps> = ({
  label,
  source,
  textSource,
  sx,
}): ReactElement => (
  <SmartLinkField
    source={source}
    label={label}
    resource={RESOURCE_MATERIAL_TYPE}
  >
    <TextField source={textSource} sx={sx} />
  </SmartLinkField>
);

MaterialTypeField.defaultProps = {
  label: 'Material Type',
  source: 'materialTypeId',
  textSource: 'materialType.type',
  sortBy: '`materialType.type`',
};

export default MaterialTypeField;
