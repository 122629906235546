import get from 'lodash/get';
import { ValidationErrorMessage, Validator } from 'react-admin';

export const getQtyToReleaseValidator = (qtyOnHandField: string): Validator => {
  return (value, allValues): ValidationErrorMessage => {
    const qtyOnHand = get(allValues, qtyOnHandField);
    if (value > qtyOnHand) {
      return 'Value cannot be greater than "On Hand Qty"';
    }
    return undefined;
  };
};
