import React, { ReactElement } from 'react';

const TempPasswordHint: React.FC = (): ReactElement => (
  <div>
    Requirements:
    <li>8-character minimum length</li>
    <li>Contains at least 1 number</li>
    <li>Contains at least 1 lowercase letter</li>
    <li>Contains at least 1 uppercase letter</li>
    <li>
      Contains at least 1 special character from the following set, or a
      non-leading, non-trailing space character.
      <div>
        ^ $ * . [ ] &#123; &#125; ( ) ? - &quot; ! @ # % &amp; / &#92; , &gt;
        &lt; &apos; : ; &#124; _ ~ ` + =
      </div>
    </li>
  </div>
);

export default TempPasswordHint;
