import React, { ReactElement } from 'react';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { Card, CardContent } from '@mui/material';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import TextInput from '../../common/TextInput';
import MaterialTypeInput from '../../common/MaterialTypeInput';
import DateRangeFilter from '../../common/DateRangeFilter';
import NumberRangeFilter from '../../common/NumberRangeFilter';
import { SideFilter } from '../../common/types';
import sideFilterStyles from '../../common/sideFilterStyles';
import { RESOURCE_MATERIAL } from '../../constants';

const MaterialSidebarFilter: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
    numberFrom,
    numberTo,
    handleNumberFromChange,
    handleNumberToChange,
  } = useSidebarFilters(RESOURCE_MATERIAL, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }
  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="material"
            name="material_inc"
            label={labels['material'] || 'Material'}
            fullWidth
            alwaysOn
          />
          <TextInput
            source="description"
            name="description_inc"
            label={labels['description'] || 'Description'}
            fullWidth
            alwaysOn
          />
          <MaterialTypeInput
            source="materialTypeId"
            label={labels['materialType'] || 'Material Type'}
            multiple
            fullWidth
            alwaysOn
          />
          <NumberRangeFilter
            source="orderPoint"
            label={labels['orderPoint'] || 'Order Point'}
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <NumberRangeFilter
            source="totalQty"
            label={labels['totalQty'] || 'Total Qty'}
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <NumberRangeFilter
            source="price"
            label={labels['price'] || 'Price'}
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <NumberRangeFilter
            source="defReorderQty"
            label={labels['defReorderQty'] || 'Def Reorder Qty'}
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <NumberRangeFilter
            source="minLevel"
            label={labels['minLevel'] || 'Min Level'}
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <NumberRangeFilter
            source="maxLevel"
            label={labels['maxLevel'] || 'Max Level'}
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default MaterialSidebarFilter;
