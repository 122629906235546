import React from 'react';
import { useRecordContext } from 'react-admin';
import { JsonField } from 'react-admin-json-view';

interface JsonFieldTypeSafeProps {
  source: string;
}

const JsonFieldTypeSafe: React.FC<JsonFieldTypeSafeProps> = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <JsonField
      source={source}
      jsonString={typeof record.details === 'string'}
      reactJsonOptions={{
        name: null,
        collapsed: false,
        displayDataTypes: false,
      }}
    />
  );
};

export default JsonFieldTypeSafe;
