import React, { ReactElement } from 'react';
import { Labeled, ImageInput, FileInputProps } from 'react-admin';
import Box from '@mui/material/Box';
import { useNotifyFileDropErrors } from './hooks/useNotifyFileDropErrors';
import FileField from './FileField';
import commonStyles from './commonStyles';
import { FILE_SIZE_LIMIT } from './constants';

type CustomImageInputProps = FileInputProps & {
  isHideRemoveButton?: boolean;
};

const CustomImageInput: React.FC<CustomImageInputProps> = ({
  source,
  isHideRemoveButton,
  label,
}): ReactElement => {
  const accept = {
    'image/*': ['.png'],
  };
  const handleDrop = useNotifyFileDropErrors({
    accept,
    maxSize: FILE_SIZE_LIMIT,
    multiple: false,
  });

  return (
    <Labeled label={label}>
      <Box sx={commonStyles.flexBox}>
        <ImageInput
          source={source}
          label={false}
          accept={accept}
          options={{ onDrop: handleDrop }}
          sx={{
            ...commonStyles.uploadField,
            ...commonStyles.flexBoxLongItem,
            '& .RaFileInput-removeButton button': {
              top: 2,
              right: 2,
              ...(isHideRemoveButton && { display: 'none' }),
            },
          }}
        >
          <FileField width={300} height={150} />
        </ImageInput>
      </Box>
    </Labeled>
  );
};

CustomImageInput.defaultProps = {
  isHideRemoveButton: false,
  label: 'File',
};

export default CustomImageInput;
