import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { lucidDocumentEditUrl } from '../constants';

export interface LucidDiagramSelectConfirmationProps {
  open: boolean;
  onClose: () => void;
  documentId: string;
}

const LucidDiagramSelectConfirmation: React.FC<
  LucidDiagramSelectConfirmationProps
> = ({ open, onClose, documentId }): ReactElement => {
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>Information</DialogTitle>
      <DialogContent>
        <Box>
          The selected diagram may not be visible to other users. <br />
          To make it available, consider opening the diagram in&nbsp;
          <a
            href={lucidDocumentEditUrl(documentId)}
            target="_blank"
            rel="noreferrer"
          >
            Lucid.app
          </a>
          <br />
          Then go to the menu &apos;Share&apos; &gt; &apos;Embed&apos;, click
          &apos;Activate Embed Code&apos; and &apos;Done&apos;
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LucidDiagramSelectConfirmation;
