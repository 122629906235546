import React, { ReactElement } from 'react';
import {
  Datagrid,
  NumberField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { RESOURCE_TEST_POSITION } from '../../constants';

const PositionFormShow: React.FC = (): ReactElement => (
  <ReferenceManyField target="test_id" reference={RESOURCE_TEST_POSITION}>
    <Datagrid bulkActionButtons={false} rowClick={false} sx={{ width: '50%' }}>
      <TextField label="Position" source="position.position" />
      <NumberField label="Quantity" source="posQty" />
      <NumberField label="Hours" source="posHours" />
      <TextField label="Description" source="position.description" />
    </Datagrid>
  </ReferenceManyField>
);

export default PositionFormShow;
