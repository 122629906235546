import React, { ReactElement } from 'react';
import { Button, useResourceContext, useStore } from 'react-admin';
import { Box } from '@mui/material';
import commonStyles from '../commonStyles';

const SmartTabWidthButtons: React.FC = (): ReactElement => {
  const resource = useResourceContext();
  const [smartTabWide, setSmartTabWide] = useStore(
    `preferences.${resource}.datagrid.smartTabWide`,
    true
  );

  return (
    <Box sx={{ ...commonStyles.flexBox }}>
      <Button
        variant="outlined"
        onClick={setSmartTabWide.bind(this, true)}
        label="Wide"
        disabled={smartTabWide}
      />
      <Button
        variant="outlined"
        onClick={setSmartTabWide.bind(this, false)}
        label="Narrow"
        disabled={!smartTabWide}
      />
    </Box>
  );
};

export default SmartTabWidthButtons;
