import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';
import CustomBooleanField from '../common/CustomBooleanField';
import CertTemplateCategoryField from '../common/CertTemplateCategoryField';
import AuditListTab from '../audit/AuditListTab';

const CertTemplateShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Certificate">
        <Box>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="certName" label="Certificate" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="description" label="Description" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CertTemplateCategoryField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="externalFormId" label="External Form ID" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CustomBooleanField source="isVendorCert" label="Vendor" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Revisions"
        id="revisions_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentRevisionFormTab
          targetId="certTemplateId"
          onlyShow
          withoutProjectId
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_cert_template" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default CertTemplateShow;
