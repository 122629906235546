import axios from 'axios';
import { GetListParams, GetOneParams } from 'react-admin';
import { BOOK_GEN_API, getRequestConfig } from '../../api';
import { getDefaultDataProvider } from './defaultDataProvider';

const defaultProvider = getDefaultDataProvider();

const getEmbeddedReportList = async () => {
  try {
    const response = await axios.get(
      `${BOOK_GEN_API}/api/powerbi/getall`,
      getRequestConfig()
    );
    if (Array.isArray(response.data)) {
      return response;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error getting embedded reports', e);
  }

  return { data: [] };
};

const convertToPowerBIReport = (embed, powerBIList) => {
  const powerBIRecord = powerBIList.find(
    (record) => record.reportName === embed.name
  );

  return {
    id: embed.id,
    reportName: embed.name,
    reportType: 'POWER-BI',
    description: embed.description || '',
    powerBiReportType: embed.type,
    updatedAt: powerBIRecord?.updatedAt,
    updatedBy: powerBIRecord?.updatedBy,
    updatedByName: powerBIRecord?.updatedByName,
  };
};

const getEmbeddedReport = async (id) => {
  try {
    return await axios.get(
      `${BOOK_GEN_API}/api/powerbi/getembedded?id=${id}`,
      getRequestConfig()
    );
  } catch (e) {
    return { data: {} };
  }
};

const exportTelerikReport = async (body) => {
  try {
    return await axios.post(
      `${BOOK_GEN_API}/api/report-viewer/export`,
      body,
      getRequestConfig()
    );
  } catch (e) {
    throw e;
  }
};

const reportDataProvider = {
  getPowerBIEmbedded: async (
    _resource: string,
    params: { embeddedId: string; recordId: string }
  ) => {
    const embeddedReport = await getEmbeddedReport(params.embeddedId);
    return { ...embeddedReport.data, id: params.embeddedId };
  },
  exportTelerikReport: async (
    _resource: string,
    params: {
      reportName: string;
      parameters: object;
      reportType: string;
      projectId: number;
    }
  ) => {
    await exportTelerikReport(params);
    return params.reportType.toUpperCase();
  },
  getOne: async (resource: string, params: GetOneParams) => {
    if (params.id.toString().split('-').length === 1) {
      return await defaultProvider.getOne(resource, params);
    } else {
      const { data: embed } = await getEmbeddedReport(params.id);

      return {
        data: {
          id: params.id,
          reportName: embed.reportName,
          reportType: 'POWER-BI',
          powerBiReportType: embed.type,
        },
      };
    }
  },
  getList: async (resource: string, params: GetListParams) => {
    const { data: embeds } = await getEmbeddedReportList();
    const result = await defaultProvider.getList(resource, params);
    const telerikList = result.data.filter(
      (report) => report.reportType === 'TELERIK'
    );
    const powerBIList = result.data.filter(
      (report) => report.reportType === 'POWER-BI'
    );
    const { sort } = params;
    const data = [
      ...telerikList,
      ...embeds.map((embed) => convertToPowerBIReport(embed, powerBIList)),
    ].sort((report1, report2) => {
      const fieldValue1 = report1[sort?.field] || '';
      const fieldValue2 = report2[sort?.field] || '';
      return sort?.order === 'ASC'
        ? fieldValue1.localeCompare(fieldValue2)
        : fieldValue2.localeCompare(fieldValue1);
    });

    return {
      total: data.length,
      data,
    };
  },
};

export default reportDataProvider;
