import { AuthProvider } from 'react-admin';
import {
  getContext,
  setContext,
  updateContext,
  UserContext,
} from '../../userContext';
import {
  updateDictionaryContext,
  updateLocalUISettings,
} from '../../../utils/UtilityFunctions';
import { api } from '../../api';
import {
  clearNotificationContext,
  initNotificationContext,
} from '../../notificationContext';

export type ApiKeyAuthProviderOptions = {
  smartITRUser: string;
  apiKey: string;
  companyName: string;
  projectId: string;
};

export const ApiKeyAuthProvider = (
  options: ApiKeyAuthProviderOptions
): AuthProvider => {
  const { apiKey, smartITRUser, companyName, projectId } = options;

  return {
    login: () =>
      new Promise(async (resolve, reject) => {
        try {
          if (apiKey) {
            updateContext({
              apiKey,
              smartITRUser,
              company: companyName,
              projectId: Number(projectId),
            });
          }

          const response = await api.user.getMyDetails();

          if (response.data.companies) {
            const ctx: UserContext = getContext();
            ctx.companies = response.data.companies;
            ctx.id = response.data.id;
            ctx.fullName = `${response.data.firstName} ${response.data.lastName}`;
            ctx.ablyToken = response.data.ablyToken;
            ctx.isRev1Admin = response.data.isRev1Admin;
            ctx.isTermsAndPrivacyPolicyAccepted =
              response.data.isTermsAndPrivacyPolicyAccepted;
            updateContext(ctx);
            initNotificationContext(ctx.ablyToken, ctx.company, ctx.id);

            const uiSettings = response.data.companies[companyName]?.uiSettings;

            if (uiSettings) {
              updateLocalUISettings(uiSettings);
            }
          }

          await updateDictionaryContext();

          return resolve({});
        } catch (error) {
          return reject(error);
        }
      }),
    async checkError({ status }) {
      if (status === 401 || status === 403) {
        throw new Error('Unauthorized');
      }
    },
    checkAuth: () =>
      new Promise(async (resolve, reject) => {
        const ctx: UserContext = getContext();
        if (ctx?.apiKey && ctx?.id) {
          return resolve();
        }
        setContext(undefined);
        await clearNotificationContext();
        return reject();
      }),
    logout: () =>
      new Promise(async (resolve) => {
        setContext(undefined);
        await clearNotificationContext();
        resolve();
      }),
    getIdentity: () =>
      new Promise((resolve, reject) => {
        const ctx: UserContext = getContext();
        if (ctx?.id && ctx?.fullName) {
          return resolve({ id: ctx.id, fullName: ctx.fullName });
        }
        return reject();
      }),
    getPermissions: () =>
      new Promise((resolve, reject) => {
        const ctx: UserContext = getContext();
        const companyName = ctx?.company;
        const projectId = ctx?.projectId;
        const effectivePermissions =
          ctx?.companies?.[companyName]?.projects?.[projectId]
            ?.effectivePermissions || [];

        if (ctx?.id && ctx?.apiKey) {
          return resolve(effectivePermissions);
        }
        return reject();
      }),
  };
};
