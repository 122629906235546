import React, { ReactElement } from 'react';
import { ControlProps, RankedTester, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, styled } from '@mui/material';

const StyledLabel = styled('span')(() => ({
  color: '#241f1f',
  background: '#fff',
  lineHeight: '1',
  fontSize: '1rem',
  scale: '0.75',
  fontFamily:
    'Hind, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif',
}));

const StyledImage = styled('img')(() => ({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  objectFit: 'scale-down',
}));

export const ImageControl: React.FC<ControlProps> = (props): ReactElement => (
  <Grid container>
    <Grid item xs={12}>
      <span>
        <StyledLabel>{props.label}</StyledLabel>
        <StyledImage
          src={`data:image/png;base64,${props?.schema?.default}`}
          alt={props?.label}
        />
      </span>
    </Grid>
  </Grid>
);

export const ImageControlTester: RankedTester = rankWith(22, (uischema) => {
  return uischema?.options?.format === 'photo';
});

export default withJsonFormsControlProps(ImageControl);
