import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_MOC } from '../../constants';
import SmartLinkField from '../../common/SmartLinkField';

interface ParentMocFieldProps {
  label?: string;
  source?: string;
}

const ParentMocField: React.FC<ParentMocFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_MOC}>
    <TextField source="parentMoc.mocNo" />
  </SmartLinkField>
);

ParentMocField.defaultProps = { label: 'Parent MoC', source: 'parentMocId' };

export default ParentMocField;
