import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import useRevisionDetailsJsonDownload from '../../common/hooks/useRevisionDetailsJsonDownload';

const DownloadJSONButton: React.FC = () => {
  const record = useRecordContext();
  const { downloadInProgress, downloadJson } = useRevisionDetailsJsonDownload();

  return (
    <Button
      label={downloadInProgress ? 'Downloading...' : 'Download Json'}
      disabled={downloadInProgress || !record?.details}
      onClick={() => downloadJson(record)}
      size="medium"
      variant="contained"
      sx={{ marginTop: '10px' }}
    />
  );
};

export default DownloadJSONButton;
