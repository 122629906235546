import { RESOURCE_STRUCTURE_ASSET } from '../constants';
import commonStyles from '../common/commonStyles';
import { getContext, UserContext } from '../../provider/userContext';
import AutocompleteReferenceInput from '../common/AutocompleteReferenceInput';
import React, { ReactElement, useMemo } from 'react';
import { required } from '../../utils/UtilityFunctions';
import { assetOptionRenderer } from './helper';

interface AssetInputProps {
  isRequired?: boolean;
}

const AssetInput: React.FC<AssetInputProps> = ({
  isRequired,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { division_id: ctx?.defaultDivisionId },
      sort: { field: 'asset', order: 'ASC' as const },
    };
  }, [ctx?.defaultDivisionId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_STRUCTURE_ASSET}
      requestParams={requestParams}
      source="assetId"
      label="Asset"
      name="asset_inc"
      optionText={assetOptionRenderer}
      validate={isRequired ? [required()] : []}
      sx={commonStyles.flexBoxItem}
      fullWidth
      includeFields={['id', 'asset', 'region.region']}
    />
  );
};

export default AssetInput;
