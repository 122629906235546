import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_MATERIAL_TYPE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface MaterialTypeInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  disabled?: boolean;
  source?: string;
  label?: string;
}

const MaterialTypeInput: React.FC<MaterialTypeInputProps> = ({
  isRequired,
  onChange,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'type', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_MATERIAL_TYPE}
      requestParams={requestParams}
      name="type_inc"
      optionText="type"
      validate={isRequired ? [required()] : []}
      handleOnChange={onChange}
      includeFields={['id', 'type']}
    />
  );
};

MaterialTypeInput.defaultProps = {
  source: 'materialTypeId',
  label: 'Material Type',
};

export default MaterialTypeInput;
