import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

const entitySupportDataProvider = {
  restoreEntity: (resource: string, auditIds: number[]) => {
    return axios.put(
      `${CRUD_API_URL}/${resource}/restore-entity`,
      { auditIds },
      getRequestConfig()
    );
  },
};

export default entitySupportDataProvider;
