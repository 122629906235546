import React, { ReactElement, useCallback } from 'react';
import { Button, TopToolbar, useStore } from 'react-admin';
import { BLUE, GREEN } from '../../../theme/colors';
import FilterSidebarButton from '../../common/FilterSidebarButton';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import LegendButton from '../../common/LegendButton';
import MultipleExportButton from '../../common/MultipleExportButton';
import { RESOURCE_AUDIT } from '../../constants';

interface AuditActions {
  sideFilter?: string;
  defaultFilter?: object;
  withExportButton?: boolean;
}

const AuditActions: React.FC<AuditActions> = ({
  withExportButton,
  sideFilter,
  defaultFilter,
}): ReactElement => {
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_AUDIT);
  const [listParams] = useStore(`${RESOURCE_AUDIT}.listParams`);
  const refreshAuditList = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  return (
    <TopToolbar>
      {sideFilter ? (
        <FilterSidebarButton
          sideFilter={sideFilter}
          filters={listParams?.filter || defaultFilter}
          defaultFilter={defaultFilter}
        />
      ) : null}
      <LegendButton
        legends={[
          {
            label: 'New value',
            color: GREEN,
          },
          {
            label: 'Changed value',
            color: BLUE,
          },
        ]}
      />
      <Button onClick={refreshAuditList} label="Refresh" />
      {withExportButton && <MultipleExportButton />}
    </TopToolbar>
  );
};

export default AuditActions;
