import React, { ReactElement } from 'react';
import { DateField, TextField, useStore } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import DrawingDisciplineField from '../common/DrawingDisciplineField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import BulkActionButtons from './components/BulkActionButtons';
import DrawingTypeField from '../common/DrawingTypeField';
import DrawingSideFilters from './DrawingSideFilters';
import DownloadButton from '../common/DownloadButton';
import LocationField from '../common/LocationField';
import PreviewButton from '../common/PreviewButton';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import { RESOURCE_DRAWING } from '../constants';
import GroupField from '../common/GroupField';
import DrawingEdit from './DrawingEdit';
import DrawingShow from './DrawingShow';

const DrawingList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_DRAWING);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filterDefaultValues={defaultFilter}
      filters={<FreeTextSearchFilter />}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      aside={
        showSideFilter ? (
          <DrawingSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'drawingName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<DrawingShow withoutActions />}
        EditComponent={<DrawingEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'drawingName',
          'drawingTypeId',
          'drawingDisciplineId',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="drawingName" label="Drawing Name" />
        <TextField source="description" label="Description" />
        <TextField source="sheet" label="Sheet" />
        <GroupField />
        <DrawingTypeField />
        <DrawingDisciplineField />
        <LocationField />
        <TextField source="revision" label="Revision" sortable={false} />
        <DateField
          source="revisionDate"
          label="Revision Date"
          sortable={false}
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <PreviewButton />
          <DownloadButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default DrawingList;
