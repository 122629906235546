import React, { ReactElement, Fragment } from 'react';
import CustomBulkUpdateButton from '../../common/CustomBulkUpdateButton';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_PENDING_PUNCH } from '../../constants';
import BulkEditButton from '../../common/BulkEditButton';
import PendingPunchForm from '../PendingPunchForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_PENDING_PUNCH}
        form={<PendingPunchForm bulkEditMode />}
      />
      <CustomBulkUpdateButton
        data={{ status: 'APPROVED_PROCESSED' }}
        label="Add to Master Punch"
        icon={null}
        resource={RESOURCE_PENDING_PUNCH}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
