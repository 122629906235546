import React, { ReactElement } from 'react';
import {
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FILTER_TYPES } from './constants';
import FilterTypeLegend from './FilterTypeLegend';

interface FilterTypeSelectionMenuProps {
  menuOpen: boolean;
  menuRef: React.RefObject<HTMLButtonElement>;
  handleMenuClose: (event: Event) => void;
  filterType: (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];
  handleChangeFilterType: (
    value: FilterTypeSelectionMenuProps['filterType']
  ) => void;
}

const FilterTypeSelectionMenu: React.FC<FilterTypeSelectionMenuProps> = ({
  menuOpen,
  menuRef,
  handleMenuClose,
  filterType,
  handleChangeFilterType,
}): ReactElement => (
  <Popper
    open={menuOpen}
    anchorEl={menuRef.current}
    transition
    placement="right-start"
    sx={{ zIndex: 2000, p: 1 }}
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <Paper sx={{ p: 1 }}>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Box>
              <FormControl>
                <FormLabel id="filter-types-group">Filter type:</FormLabel>
                <RadioGroup
                  aria-labelledby="filter-types-group"
                  name="controlled-filter-types-group"
                  value={filterType}
                >
                  <FormControlLabel
                    value={FILTER_TYPES.startsWith}
                    control={<Radio />}
                    label="Starts with"
                    onClick={(event) => {
                      event.preventDefault();
                      handleChangeFilterType(FILTER_TYPES.startsWith);
                    }}
                  />
                  <FormControlLabel
                    value={FILTER_TYPES.contains}
                    control={<Radio />}
                    label="Contains"
                    onClick={(event) => {
                      event.preventDefault();
                      handleChangeFilterType(FILTER_TYPES.contains);
                    }}
                  />
                  <FormControlLabel
                    value={FILTER_TYPES.endsWith}
                    control={<Radio />}
                    label="Ends with"
                    onClick={(event) => {
                      event.preventDefault();
                      handleChangeFilterType(FILTER_TYPES.endsWith);
                    }}
                  />
                  <FormControlLabel
                    value={FILTER_TYPES.custom}
                    control={<Radio />}
                    label="Custom"
                    onClick={(event) => {
                      event.preventDefault();
                      handleChangeFilterType(FILTER_TYPES.custom);
                    }}
                  />
                </RadioGroup>
              </FormControl>
              <FilterTypeLegend />
            </Box>
          </ClickAwayListener>
        </Paper>
      </Fade>
    )}
  </Popper>
);

export default FilterTypeSelectionMenu;
