import { ReactElement } from 'react';
import { BookSectionEngCode } from '../type';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import { Identifier, NumberInput, useStore } from 'react-admin';
import commonStyles from '../../common/commonStyles';
import { SelectEngCodeInput } from './SelectEngCodeInput';
import { RESOURCE_BOOK_SECTION_ENG_CODE } from '../../constants';
import { useGetMaxSortOrder } from '../useGetMaxSortOrder';
import { bookSectionIdStore } from '../constants';

export const CreateForm: React.FC = (): ReactElement => {
  const [bookSectionId] = useStore<Identifier>(bookSectionIdStore, null);
  const maxSortOrder = useGetMaxSortOrder(RESOURCE_BOOK_SECTION_ENG_CODE);
  const transformCreate = (data: BookSectionEngCode) => {
    return {
      ...data,
      bookSectionId,
      sortOrder: data.sortOrder || maxSortOrder + 1,
    };
  };

  return (
    <RowForm transform={transformCreate}>
      <SelectEngCodeInput />
      <NumberInput source="sortOrder" sx={commonStyles.flexBoxItem} />
    </RowForm>
  );
};
