import React, { ReactElement, useEffect } from 'react';
import { BooleanInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { SxProps, Theme } from '@mui/material/styles';
import commonStyles from '../../common/commonStyles';

const VerifiedField: React.FC<{ sx?: SxProps<Theme> }> = ({
  sx,
}): ReactElement => {
  const { setValue, getFieldState } = useFormContext();

  const acceptedBy = useWatch({ name: 'acceptedBy' });
  const acceptedDate = useWatch({ name: 'acceptedDate' });

  useEffect(() => {
    const acceptedByTouched = getFieldState('acceptedBy').isDirty;
    const acceptedDateTouched = getFieldState('acceptedDate').isDirty;
    if (
      !!acceptedBy &&
      !!acceptedDate &&
      (acceptedByTouched || acceptedDateTouched)
    ) {
      setValue('isVerified', true, { shouldDirty: true });
    }
  }, [acceptedBy, acceptedDate, getFieldState, setValue]);

  return (
    <BooleanInput
      source="isVerified"
      label="Verified"
      sx={sx || commonStyles.rowFormInput}
    />
  );
};

export default VerifiedField;
