import React, { ReactElement, useCallback } from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';
import useDataProvider from '../../../../provider/dataProvider/useDataProvider';
import { getErrorMessage } from '../../../../utils/UtilityFunctions';
import useInvalidateResourceQueryCache from '../../../common/hooks/useInvalidateResourceQueryCache';
import {
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
} from '../../../constants';

export interface RefillMaterialsButtonProps {
  startIcon?: React.ReactNode;
  className?: string;
}

const RefillMaterialsButton: React.FC<RefillMaterialsButtonProps> = ({
  startIcon,
  className,
}): ReactElement => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onRefillSuccess = useInvalidateResourceQueryCache(
    RESOURCE_MATERIAL_PURCHASE_ORDER,
    true
  );

  const refillMaterials = useCallback(() => {
    dataProvider
      .refillMaterials(RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL, record.id)
      .then(onRefillSuccess)
      .catch((error) => {
        notify(getErrorMessage(error), {
          type: 'error',
          undoable: false,
        });
      });
  }, [dataProvider, record, onRefillSuccess, notify]);

  return (
    <Button
      label="Refill Materials"
      type="button"
      onClick={refillMaterials}
      className={className}
      startIcon={startIcon}
    />
  );
};

export default RefillMaterialsButton;
