import { GREEN, RED, GREY } from '../../../theme/colors';
import { activityCategory, activityStatusOverall } from '../constants';
import { ActivityCategory } from '../types';

export const getGatingEnforcementLabel = (
  category: ActivityCategory
): string => {
  switch (category) {
    case activityCategory.system:
      return 'System';
    case activityCategory.subSystem:
      return 'SubSystem';
    case activityCategory.component:
    default:
      return 'Component';
  }
};

export const getGatingStatusFieldColor = (value: string): string => {
  switch (value) {
    case activityStatusOverall.ready:
      return GREEN;
    case activityStatusOverall.notReady:
      return RED;
    default:
      return GREY;
  }
};
