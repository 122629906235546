import React, { ReactElement } from 'react';
import { SimpleForm, useRedirect } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import { RESOURCE_PRESERVATION_ITEM } from '../constants';
import PreservationItemForm, {
  PreservationFormMode,
} from './PreservationItemForm';
import { PreservationItemType } from './types';

const PreservationItemCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const redirect = useRedirect();
  const transform = (data: PreservationItemType): PreservationItemType => ({
    ...data,
    projectId: ctx?.projectId,
  });

  const onSuccess = async () => {
    redirect('list', RESOURCE_PRESERVATION_ITEM);
  };

  return (
    <CreateWithTitle
      redirect="list"
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<FooterToolbar />}>
        <PreservationItemForm mode={PreservationFormMode.create} />
      </SimpleForm>
    </CreateWithTitle>
  );
};
export default PreservationItemCreate;
