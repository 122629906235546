import React, { Fragment, ReactElement, useCallback } from 'react';
import ModalDialog from '../common/ModalDialog';
import { Stack, Box } from '@mui/material';
import {
  useDataProvider,
  useNotify,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  AutocompleteInput,
  FileField,
} from 'react-admin';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import commonStyles from '../common/commonStyles';
import {
  SUPPORT_FORM_FILES_LIMIT,
  SUPPORT_FORM_FILE_SIZE_LIMIT,
} from '../common/constants';
import { CUSTOM_ROUTE_SUPPORT } from '../constants';
import { ticketTypeOptions, ticketPriorityOptions } from './constants';
import { useMutation } from '@tanstack/react-query';
import CustomFileInput from '../common/CustomFileInput';

interface ContactSupportModalProps {
  open: boolean;
  onClose: (event?, reason?: string) => void;
  withRecording: boolean;
  lastRecordTimeRange?: string;
  recordLink?: string;
}

export const ContactSupportModal: React.FC<ContactSupportModalProps> = ({
  open,
  onClose,
  withRecording,
  lastRecordTimeRange,
  recordLink,
}): ReactElement => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: sendFeedback, isPending } = useMutation({
    mutationFn: (data) => dataProvider.createTicket(CUSTOM_ROUTE_SUPPORT, data),
    onSuccess: () => {
      notify('The Feedback was submitted successfully!', {
        type: 'success',
        autoHideDuration: 5000,
      });
      onClose();
    },
    onError: (error) => {
      notify(getErrorMessage(error, 'Feedback was not created!'), {
        type: 'error',
        undoable: false,
      });
    },
  });

  const handleSubmit = useCallback(
    async (data) => {
      if (withRecording) {
        data.recordLink = recordLink;
        data.recordTimeRange = lastRecordTimeRange;
      }
      sendFeedback(data);
    },
    [recordLink, sendFeedback, withRecording, lastRecordTimeRange]
  );

  return (
    <ModalDialog title="Contact Support" open={open} onClose={onClose}>
      <SimpleForm
        mode="onBlur"
        onSubmit={handleSubmit}
        toolbar={
          <Toolbar>
            <SaveButton
              disabled={isPending}
              variant="outlined"
              label="Send"
              icon={null}
            />
          </Toolbar>
        }
      >
        <Stack alignItems="flex-start">
          <Box sx={commonStyles.flexBox}>
            <TextInput
              defaultValue={document.title}
              required
              source="page"
              sx={commonStyles.flexBoxLongItem}
              label="Page"
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <AutocompleteInput
              sx={commonStyles.flexBoxItem}
              label="Type"
              source="type"
              optionText="label"
              optionValue="value"
              choices={ticketTypeOptions}
            />
            <AutocompleteInput
              sx={commonStyles.flexBoxItem}
              label="Priority"
              source="priority"
              optionText="label"
              optionValue="value"
              choices={ticketPriorityOptions}
            />
          </Box>
          {withRecording && (
            <Box sx={commonStyles.flexBox}>
              <TextInput
                disabled
                sx={commonStyles.flexBoxLongItem}
                source="recordTimeRange"
                label="Record Time"
                InputProps={{ value: lastRecordTimeRange }}
              />
            </Box>
          )}
          <Box sx={commonStyles.flexBox}>
            <TextInput
              required
              sx={commonStyles.flexBoxLongItem}
              fullWidth
              minRows={3}
              multiline
              source="message"
              label="Message"
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <CustomFileInput
              source="attachments"
              label={false}
              options={{ maxFiles: SUPPORT_FORM_FILES_LIMIT }}
              validate={(x) =>
                x && x.length > SUPPORT_FORM_FILES_LIMIT
                  ? `The maximum number of files allowed is three`
                  : null
              }
              labelMultiple={
                'Drag and drop screenshots/files here, or click to select files'
              }
              multiple={true}
              maxSize={SUPPORT_FORM_FILE_SIZE_LIMIT}
              sx={commonStyles.uploadField}
            >
              <Fragment>
                <FileField source="src" target="_blank" title="title" />
                <Box sx={commonStyles.uploadFieldBox}></Box>
              </Fragment>
            </CustomFileInput>
          </Box>
        </Stack>
      </SimpleForm>
    </ModalDialog>
  );
};
