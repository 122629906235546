import { SxProps, Theme } from '@mui/material';
import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import SubsystemInput from 'src/components/common/SubsystemInput';
import { getCertDetailsSubsystemSource } from './helpers';

interface CertDetailsSubsystemInputProps {
  label?: string;
  disabled: boolean;
  sx: SxProps<Theme>;
}

const CertDetailsSubsystemInput: React.FC<CertDetailsSubsystemInputProps> = ({
  label,
  disabled,
  sx,
}): ReactElement => {
  const record = useRecordContext();
  const { source } = getCertDetailsSubsystemSource(record);

  return (
    <SubsystemInput source={source} label={label} disabled={disabled} sx={sx} />
  );
};

CertDetailsSubsystemInput.defaultProps = {
  label: 'Subsystem',
};

export default CertDetailsSubsystemInput;
