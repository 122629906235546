import { useDataProvider } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RESOURCE_USER } from '../../constants';
import getUISettings from '../helpers/getUISettings';
import useDebounce from './useDebounce';

const useUploadDatagridSettings = () => {
  const ctx: UserContext = getContext();
  const debounce = useDebounce(3000);
  const dataProvider = useDataProvider();

  return () => {
    debounce(async () => {
      try {
        await dataProvider.updateUISettings(RESOURCE_USER, {
          id: ctx.id,
          uiSettings: JSON.stringify(getUISettings()),
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(getErrorMessage(error, 'Upload Datagrid settings error'));
      }
    });
  };
};

export default useUploadDatagridSettings;
