import React, { Fragment, ReactElement } from 'react';
import { DateField, Labeled, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import MechanicalDisciplineField from './MechanicalDisciplineField';

const MechanicalShowTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box>
      <Labeled label="Discipline" sx={commonStyles.flexBoxItem}>
        <MechanicalDisciplineField source="mechanical.disciplineId" />
      </Labeled>
      <Labeled label="Additional Description" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.descriptionAddtl" />
      </Labeled>
      <Labeled label="Other Subsystem" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.subsystemOther" />
      </Labeled>
      <Labeled label="Weights" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.weights" />
      </Labeled>
      <Labeled label="Capacity" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.capacity" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Post Weld Heat Treatment" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.pwht" />
      </Labeled>
      <Labeled label="Insulation" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.insulation" />
      </Labeled>
      <Labeled label="Size" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.size" />
      </Labeled>
      <Labeled label="Skid No" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.skidNo" />
      </Labeled>
      <Labeled label="Current Previous" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.currentPrevious" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Updated At" sx={commonStyles.flexBoxItem}>
        <DateField source="mechanical.updatedAt" />
      </Labeled>
      <Labeled label="Updated By" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.updatedBy" />
      </Labeled>
      <Labeled label="Updated By Name" sx={commonStyles.flexBoxItem}>
        <TextField source="mechanical.updatedByName" />
      </Labeled>
    </Box>
  </Fragment>
);

export default MechanicalShowTab;
