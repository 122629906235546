import React, { ReactElement } from 'react';
import { DateField, ListProps, TextField, useStore } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import PreservationSeriesShow from './PreservationSeriesShow';
import IssueButton from './IssueButton';
import PreservationSeriesSidebarFilters from './PreservationSeriesSidebarFilters';
import ComponentField from '../common/ComponentField';
import TestField from '../common/TestField';
import LocationField from '../common/LocationField';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import { RESOURCE_PRESERVATION_SERIES } from '../constants';

const PreservationSeriesList: React.FC<ListProps> = (): ReactElement => {
  const { labels, preferenceKey } = useDictionaryLabelsWithResource();
  const sideFilter = getSideFiltersKey(RESOURCE_PRESERVATION_SERIES);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{ create: false, import: false }}
          preferenceKey={preferenceKey}
        />
      }
      aside={
        showSideFilter ? (
          <PreservationSeriesSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'dueDate', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PreservationSeriesShow withoutActions />}
        EditComponent={<PreservationSeriesShow withoutActions />}
        defaultVisibleColumns={[
          'id',
          'preservationItem.component.id',
          'preservationItem.test.id',
          'preservationItem.sequence',
          'dueDate',
          'lateDate',
        ]}
        preferenceKey={preferenceKey}
      >
        <TextField source="id" label="ID" />
        <ComponentField
          source="preservationItem.component.id"
          textSource="preservationItem.component.tag"
          sortBy="`preservationItem.component.tag`"
          label={labels['preservationItem.component.id'] || 'Tag'}
        />
        <TestField
          source="preservationItem.test.id"
          textSource="preservationItem.test.testFormNo"
          sortBy="`preservationItem.test.testFormNo`"
          label={labels['preservationItem.test.id'] || 'Test'}
        />
        <TextField
          source="preservationItem.sequence"
          label={labels['preservationItem.sequence'] || 'Sequence'}
        ></TextField>
        <DateField source="dueDate" label={labels['dueDate'] || 'Due Date'} />
        <DateField
          source="lateDate"
          label={labels['lateDate'] || 'Late Date'}
        />
        <LocationField
          source="preservationItem.location.id"
          textSource="preservationItem.location.location"
          sortBy="`preservationItem.location.location`"
          label={labels['preservationItem.location.id'] || 'Location'}
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" showActions={{ edit: false }}>
          <IssueButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PreservationSeriesList;
