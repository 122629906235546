const upperCaseText = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const convertResourceKeyToName = (key) => {
  if (key === 'subsystem') {
    return 'SubSystem';
  }

  return key
    .split('-')
    .map((val) => upperCaseText(val))
    .join(' ');
};
