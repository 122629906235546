import React, { Fragment, ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import ListWithTitle from '../common/ListWithTitle';
import DrawingPrefixShow from './DrawingPrefixShow';
import DrawingPrefixEdit from './DrawingPrefixEdit';
import TopText from './TopText';

const DrawingPrefixList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <Fragment>
      <TopText />
      <ListWithTitle
        filters={<FreeTextSearchFilter />}
        filterDefaultValues={defaultFilter}
        actions={<ListActions />}
        sort={{ field: 'prefix', order: 'ASC' }}
      >
        <DatagridConfigurableRbac
          ShowComponent={<DrawingPrefixShow withoutActions />}
          EditComponent={<DrawingPrefixEdit syncWithLocation={false} />}
        >
          <TextField source="id" label="ID" />
          <TextField source="prefix" label="Prefix" />
          <TextField source="description" label="Description" />
          <ColumnActions label="Actions" />
        </DatagridConfigurableRbac>
      </ListWithTitle>
    </Fragment>
  );
};

export default DrawingPrefixList;
