import React, { ReactElement } from 'react';
import {
  BooleanInput,
  SimpleForm,
  useRecordContext,
  SimpleFormProps,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { getContext, UserContext } from '../../provider/userContext';
import { GREEN } from '../../theme/colors';
import { required } from '../../utils/UtilityFunctions';
import FooterToolbar from '../common/FooterToolbar';
import TextInput from '../common/TextInput';
import PinCodeInput from '../common/PinCodeInput';
import commonStyles from '../common/commonStyles';
import { CUSTOM_ROUTE_USER_PROFILE } from '../constants';

interface UserProfileFormProps extends Omit<SimpleFormProps, 'children'> {
  autoSaveEnabled: boolean;
}

const UserProfileForm: React.FC<UserProfileFormProps> = ({
  autoSaveEnabled,
  ...rest
}): ReactElement => {
  const record = useRecordContext();
  const ctx: UserContext = getContext();

  return (
    <SimpleForm
      {...rest}
      defaultValues={{
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        autoSaveEnabled,
      }}
      toolbar={
        <FooterToolbar
          autoSave={autoSaveEnabled}
          cancelButtonResource={CUSTOM_ROUTE_USER_PROFILE}
        />
      }
    >
      <Box sx={commonStyles.flexBox}>
        <Typography component="h6" paragraph color={GREEN} fontWeight="bold">
          Company:{' '}
          {ctx?.companies[ctx?.company].longName ||
            ctx?.companies[ctx?.company].name}
        </Typography>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="id"
          label="Email"
          sx={commonStyles.flexBoxLongItem}
          fullWidth
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="firstName"
          label="First Name"
          validate={required()}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
        <TextInput
          source="lastName"
          label="Last Name"
          validate={required()}
          sx={commonStyles.flexBoxItem}
          fullWidth
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="jobTitle"
          name="jobTitle"
          label="Job Title"
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="externalCompany"
          name="externalCompany"
          label="Company"
          sx={commonStyles.flexBoxItem}
        />
        <PinCodeInput />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="autoSaveEnabled"
          label="Auto Save"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </SimpleForm>
  );
};

export default UserProfileForm;
