import axios from 'axios';
import { downloadFile } from '../../../utils/UtilityFunctions';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export const getUserPermissionDataProvider = () => ({
  updateUserEffectivePermission: (
    resource: string,
    ids: (string | number)[]
  ) => {
    const [userId] = ids;
    return axios.put(
      `${CRUD_API_URL}/${resource}/permissions-by-company/${userId ?? 'all'}`,
      {},
      getRequestConfig()
    );
  },
  getEffectivePermissions: (resource: string, ids: (string | number)[]) => {
    const [projectId, userId] = ids;
    return axios.get(
      `${CRUD_API_URL}/${resource}/permissions-by-project/${projectId}/${userId}`,
      getRequestConfig()
    );
  },
  addFavoriteProject: (resource: string, projectId: number) =>
    axios.put(
      `${CRUD_API_URL}/${resource}/my/details/favorite-project/${projectId}`,
      {},
      getRequestConfig()
    ),
  deleteFavoriteProject: (resource: string, projectId: number) =>
    axios.delete(
      `${CRUD_API_URL}/${resource}/my/details/favorite-project/${projectId}`,
      getRequestConfig()
    ),
  generateQRCode: (resource: string, data: object) =>
    axios
      .put(`${CRUD_API_URL}/${resource}/qr-code/generate`, data, {
        ...getRequestConfig(),
        responseType: 'blob',
      })
      .then((req) => {
        if (req['status'] === 200) {
          const filename =
            req.headers['x-suggested-filename'] || `${resource}-qr-code`;

          downloadFile(
            filename,
            req.data,
            req.headers['content-type'],
            'pdf',
            false
          );
        }
      }),
});
