export enum ATTACHMENT_TYPE_ENUMS {
  GENERAL = 'GENERAL',
  LOGO = 'LOGO',
  IMPORT = 'IMPORT',
  SMART_ITR = 'SMART_ITR',
  IMAGE = 'IMAGE',
}

export enum AssignedType {
  CONTACT = 'CONTACT',
  // GROUP = 'GROUP',
  USER_GROUP = 'USER_GROUP',
  EMAIL = 'EMAIL',
}

export enum NotificationType {
  SIMPLE_MESSAGE = 'SIMPLE_MESSAGE',
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  ERROR = 'ERROR',
  GENERATE_PDF = 'GENERATE_PDF',
  ITR_CLOSEOUT = 'ITR_CLOSEOUT',
  ITR_COPY_TEMPLATE = 'ITR_COPY_TEMPLATE',
  CERTIFICATE_CLOSEOUT = 'CERTIFICATE_CLOSEOUT',
  PRESERVATION_CLOSEOUT = 'PRESERVATION_CLOSEOUT',
  POPULATE = 'POPULATE',
  ITR_SEND_REASSIGN_EMAIL = 'ITR_SEND_REASSIGN_EMAIL',
  CERT_SEND_REASSIGN_EMAIL = 'CERT_SEND_REASSIGN_EMAIL',
  PRES_SEND_REASSIGN_EMAIL = 'PRES_SEND_REASSIGN_EMAIL',
  UI_SETTINGS_RESET = 'UI_SETTINGS_RESET',
  EXPORT_REPORT = 'EXPORT_REPORT',
  MATERIAL_INFO = 'MATERIAL_INFO',
  CONVERT_TO_PDF_AND_SAVE_ATTACHMENTS = 'CONVERT_TO_PDF_AND_SAVE_ATTACHMENTS',
  COPY_BOOK_TO_PROJECT = 'COPY_BOOK_TO_PROJECT',
}
