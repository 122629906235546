import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

interface ImportResultsRowProps {
  label: string;
  value: string | number;
}

const ImportResultsRow: React.FC<ImportResultsRowProps> = ({
  label,
  value,
}) => {
  return (
    <Stack direction="row" spacing={10}>
      <Box width={160}>{label}</Box>
      <Box>{value}</Box>
    </Stack>
  );
};

export default ImportResultsRow;
