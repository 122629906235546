import React, { ReactElement } from 'react';
import { maxLength, SelectInput, SimpleForm, useStore } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { Group } from './types';
import { categoryChoices } from './constants';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const GroupCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const [groupFilters] = useStore('group.listParams');
  const categoryFilter = groupFilters?.filter?.category;

  const transform = (data: Group): Group => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="groupName"
            label="Group"
            validate={[required(), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={maxLength(255)}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <SelectInput
            source="category"
            label="Category"
            choices={categoryChoices}
            validate={required()}
            sx={commonStyles.flexBoxItem}
            defaultValue={categoryFilter ? categoryFilter : ''}
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default GroupCreate;
