import React, { ReactElement, useMemo } from 'react';
import { required } from '../../utils/UtilityFunctions';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_DRAWING_TYPE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface DrawingTypeInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
  label?: string;
}

const DrawingTypeInput: React.FC<DrawingTypeInputProps> = ({
  isRequired,
  multiple,
  sx,
  source,
  label,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'drawingType', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_DRAWING_TYPE}
      requestParams={requestParams}
      source={source}
      label={label}
      name="drawingType_inc"
      optionText="drawingType"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'drawingType']}
    />
  );
};

DrawingTypeInput.defaultProps = {
  source: 'drawingTypeId',
  label: 'Drawing Type',
};

export default DrawingTypeInput;
