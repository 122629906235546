import React, { ReactElement } from 'react';
import { ListProps, TextField, useStore } from 'react-admin';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import SystemSideFilters from './SystemSideFilters';
import BulkActionButtons from './BulkActionButtons';
import ListActions from '../common/ListActions';
import { RESOURCE_SYSTEM } from '../constants';
import SystemEdit from './SystemEdit';
import SystemShow from './SystemShow';

const SystemList: React.FC<ListProps> = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_SYSTEM);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      aside={
        showSideFilter ? (
          <SystemSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'systemName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<SystemShow withoutActions />}
        EditComponent={<SystemEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'systemName',
          'description',
          'priority',
          'sequence',
          'updatedAt',
        ]}
        sx={{
          '& .column-actions': { textAlign: 'center' },
          '& .column-priority': { minWidth: '120px' },
        }}
      >
        <TextField source="id" label="ID" />
        <TextField source="systemName" label="System" />
        <TextField source="description" label="Description" />
        <TextField source="priority" label="Priority" />
        <TextField
          source="commissioningProcedureNo"
          label="Commissioning Procedure No"
        />
        <TextField
          source="commissioningEngineer"
          label="Commissioning Engineer"
        />
        <TextField
          source="clientRepresentative"
          label="Client Representative"
        />
        <TextField source="sequence" label="Sequence" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SystemList;
