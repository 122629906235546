import { useGetList } from 'react-admin';
import { RESOURCE_SECURITY_ROLE } from '../../constants';
import { useCallback, useMemo } from 'react';
import { isEmpty } from '../../../utils/UtilityFunctions';
import checkSecurityLevel from '../helpers/checkSecurityLevel';

const useRolesSecurityLevelValidator = () => {
  const { data: roles } = useGetList(RESOURCE_SECURITY_ROLE, {
    filter: { isDeleted: false },
  });

  const restrictedRoles = useMemo(() => {
    return roles?.filter((role) => !checkSecurityLevel(role.securityLevel));
  }, [roles]);

  return useCallback(
    async (value: number[]) => {
      if (isEmpty(value)) return undefined;

      const selectedRestrictedRoles = (restrictedRoles || [])
        .filter((role) => value.includes(role.id))
        .map((role) => role.role);

      if (isEmpty(selectedRestrictedRoles)) return undefined;

      return `Current user is not allowed to select roles: ${selectedRestrictedRoles}`;
    },
    [restrictedRoles]
  );
};

export default useRolesSecurityLevelValidator;
