import React, { ReactElement } from 'react';
import { AutocompleteInput } from 'react-admin';
import { ACTIVITY_STATUS_OVERALL_CHOICES } from './constants';
import { SelectInputMultipleProps } from './types';

const ActivityCertGatingStatusOverallInput: React.FC<
  SelectInputMultipleProps
> = ({ source, label, ...rest }): ReactElement => (
  <AutocompleteInput
    {...rest}
    source={source}
    label={label}
    choices={ACTIVITY_STATUS_OVERALL_CHOICES}
  />
);

ActivityCertGatingStatusOverallInput.defaultProps = {
  label: 'Cert Gating Status',
  source: 'certGatingStatus->cert_status',
};

export default ActivityCertGatingStatusOverallInput;
