import React, { Fragment, ReactElement } from 'react';
import { maxLength, NumberInput } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import TextInput from '../../common/TextInput';

const ProjectFormDetailsTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="documentNumber"
        label="Document Number"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="owner"
        label="Owner"
        validate={[maxLength(55)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <NumberInput
        source="workWeek"
        label="Work Week"
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="gsap"
        label="GSAP"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
  </Fragment>
);

export default ProjectFormDetailsTab;
