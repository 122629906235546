import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { Punch } from './types';
import FooterToolbar from '../common/FooterToolbar';
import PunchForm from './PunchForm';
import CreateWithTitle from '../common/CreateWithTitle';

const PunchCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: Punch): Punch => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <PunchForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default PunchCreate;
