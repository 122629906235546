import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import { Button } from 'react-admin';
import {
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_RELEASED,
} from '../../../constants';
import MaterialReleasedForm from '../../materialReleased/components/MaterialReleasedForm';
import PurchaseOrderRequestForm from '../../purchaseOrder/components/PurchaseOrderRequestForm';
import CreateForSelectedMaterialsDialog from './CreateForSelectedMaterialsDialog';

const BulkActionButtons: React.FC = (): ReactElement => {
  const [isPOCreateVisible, setPOCreateVisible] = useState(false);
  const [isRMCreateVisible, setRMCreateVisible] = useState(false);

  const showPOCreateDialog = useCallback(() => {
    setPOCreateVisible(true);
  }, [setPOCreateVisible]);

  const hidePOCreateDialog = useCallback(() => {
    setPOCreateVisible(false);
  }, [setPOCreateVisible]);

  const showRMCreateDialog = useCallback(() => {
    setRMCreateVisible(true);
  }, [setRMCreateVisible]);

  const hideRMCreateDialog = useCallback(() => {
    setRMCreateVisible(false);
  }, [setRMCreateVisible]);

  return (
    <Fragment>
      <Button label="Release Materials" onClick={showRMCreateDialog} />
      <Button label="Purchase Order" onClick={showPOCreateDialog} />

      <CreateForSelectedMaterialsDialog
        title="Purchase Order"
        createResource={RESOURCE_MATERIAL_PURCHASE_ORDER}
        redirectTab="1"
        isOpen={isPOCreateVisible}
        onClose={hidePOCreateDialog}
      >
        <PurchaseOrderRequestForm />
      </CreateForSelectedMaterialsDialog>
      <CreateForSelectedMaterialsDialog
        title="Released Material"
        createResource={RESOURCE_MATERIAL_RELEASED}
        redirectTab="1"
        isOpen={isRMCreateVisible}
        onClose={hideRMCreateDialog}
      >
        <MaterialReleasedForm />
      </CreateForSelectedMaterialsDialog>
    </Fragment>
  );
};

export default BulkActionButtons;
