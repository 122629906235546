import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import UserGroupEdit from './UserGroupEdit';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import UserGroupShow from './UserGroupShow';
import ListNotDeleted from '../common/ListNotDeleted';
import ProjectField from './components/ProjectField';

const UserGroupList: React.FC = (): ReactElement => (
  <ListNotDeleted
    filters={<FreeTextSearchFilter />}
    actions={<ListActions />}
    sort={{ field: 'userGroup', order: 'ASC' }}
  >
    <DatagridConfigurableRbac
      ShowComponent={<UserGroupShow withoutActions />}
      EditComponent={<UserGroupEdit syncWithLocation={false} />}
      defaultVisibleColumns={['id', 'userGroup', 'description', 'projectId']}
    >
      <TextField source="id" label="ID" />
      <TextField source="userGroup" label="User Group" />
      <TextField source="description" label="Description" />
      <ProjectField />
      {renderMetaDataFields()}
      <ColumnActions label="Actions" />
    </DatagridConfigurableRbac>
  </ListNotDeleted>
);

export default UserGroupList;
