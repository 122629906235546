import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import TabbedEditForm from '../common/TabbedEditForm';
import MetaDataInputs from '../common/MetaDataInputs';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import ResponsibilityInput from './ResponsibilityInput';
import { Vendor } from './types';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const VendorEdit: React.FC<EditPropsWithLocation<Vendor>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="Vendor" id="vendor_tab" sx={commonStyles.formTabHeader}>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="vendor"
          label="Vendor"
          validate={[required(), maxLength(100)]}
          sx={commonStyles.flexBoxItem}
        />
        <ResponsibilityInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="description"
          label="Description"
          validate={maxLength(255)}
          sx={commonStyles.flexBoxItem}
          fullWidth
          multiline
        />
      </Box>
      <MetaDataInputs />
    </LazyFormTab>
    <HistoryTab tableName="tbl_vendor" />
  </TabbedEditForm>
);

export default VendorEdit;
