import React, { ReactElement } from 'react';
import { ListProps, TextField } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import DisciplineField from '../common/DisciplineField';
import ColumnActions from '../common/ColumnActions';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import PositionEdit from './PositionEdit';
import PositionShow from './PositionShow';

const PositionList: React.FC<ListProps> = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={<ListActions />}
      sort={{ field: 'position', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PositionShow withoutActions />}
        EditComponent={<PositionEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'position', 'description']}
      >
        <TextField source="id" label="ID" />
        <TextField source="position" label="Position" />
        <TextField source="description" label="Description" />
        <DisciplineField source="disciplineId" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PositionList;
