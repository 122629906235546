import { useCallback } from 'react';
import { Identifier, useStore } from 'react-admin';

const useUpdateSelectedIds = (resourceName: string) => {
  const [selectedIds, setSelectedIds] = useStore(`${resourceName}.selectedIds`);

  return useCallback(
    (recordId: Identifier | number) => {
      if (selectedIds) {
        setSelectedIds(selectedIds.filter((id) => id !== recordId));
      }
    },
    [selectedIds, setSelectedIds]
  );
};

export default useUpdateSelectedIds;
