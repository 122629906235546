import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_CERT_TEMPLATE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { ActivityCategory } from './types';
import { required } from '../../utils/UtilityFunctions';

interface CertTemplateInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  category?: ActivityCategory;
  label?: string;
  source?: string;
  isRequired?: boolean;
}

const CertTemplateInput: React.FC<CertTemplateInputProps> = ({
  category,
  label,
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        division_id: ctx?.defaultDivisionId,
        category,
      },
      sort: { field: 'certName', order: 'ASC' as const },
    };
  }, [category, ctx?.defaultDivisionId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_CERT_TEMPLATE}
      requestParams={requestParams}
      label={label}
      name="certName_inc"
      optionText="certName"
      validate={isRequired ? required() : undefined}
      includeFields={['id', 'certName']}
      additionalParams={{ skipListAdditionalData: true }}
    />
  );
};

CertTemplateInput.defaultProps = {
  label: 'Certificate Template',
  source: 'certTemplateId',
};

export default CertTemplateInput;
