import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../../common/AutocompleteReferenceInput';
import { RESOURCE_CONTACT } from '../../../constants';
import { UserContext, getContext } from '../../../../provider/userContext';
import { required } from '../../../../utils/UtilityFunctions';

interface ContactInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  sx?: object;
}

const ContactInput: React.FC<ContactInputProps> = ({
  isRequired,
  alwaysOn,
  multiple,
  fullWidth,
  sx,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'nickname', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);
  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_CONTACT}
      requestParams={requestParams}
      source="contactId"
      label="Contact"
      name="nickname_inc"
      optionText="nickname"
      validate={isRequired ? [required()] : []}
      sx={sx}
      multiple={multiple}
      alwaysOn={alwaysOn}
      fullWidth={fullWidth}
      includeFields={['id', 'nickname']}
    />
  );
};

export default ContactInput;
