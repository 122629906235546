import React, { ReactElement } from 'react';
import useCanAccess from '../common/hooks/useCanAccess';
import BulkEditButton from '../common/BulkEditButton';
import { RESOURCE_SUBSYSTEM } from '../constants';
import SubSystemForm from './SubSystemForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <BulkEditButton
      resource={RESOURCE_SUBSYSTEM}
      form={<SubSystemForm bulkEditMode />}
    />
  );
};

export default BulkActionButtons;
