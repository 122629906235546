import React, { Fragment, ReactElement } from 'react';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import YesNoInput from '../../common/YesNoInput';
import InheritCertSignOffsLegend from './InheritCertSignOffsLegend';

const ProjectFormInheritCertSignOffsTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <YesNoInput
        source="inheritCertSignoffsSys"
        label="Inherit Cert Sign Offs System"
        sx={commonStyles.flexBoxSelectItem}
      />
      <YesNoInput
        source="inheritCertSignOffsSs"
        label="Inherit Cert Sign Offs SubSystem"
        sx={commonStyles.flexBoxSelectItem}
      />
      <YesNoInput
        source="inheritCertSignoffsComponent"
        label="Inherit Cert Sign Offs Component"
        sx={commonStyles.flexBoxSelectItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <InheritCertSignOffsLegend />
    </Box>
  </Fragment>
);

export default ProjectFormInheritCertSignOffsTab;
