import BookIcon from '@mui/icons-material/Book';
import { Button as ButtonMaterial } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link } from 'react-admin';
import { RESOURCE_BOOK, RESOURCE_BOOK_SECTION } from '../../constants';

interface LinkToTocProps {
  bookId: string;
}

const LinkToToc: React.FC<LinkToTocProps> = ({ bookId }): ReactElement => (
  <Link to={`/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}`} key="back">
    <ButtonMaterial startIcon={<BookIcon />} sx={{ mt: 2 }}>
      Back to TOC
    </ButtonMaterial>
  </Link>
);

export default LinkToToc;
