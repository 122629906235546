import { useEffect, useRef, useState } from 'react';

export function useTimeout(callback, delay) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const savedCallback = useRef<typeof callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay != null) {
      const id = setInterval(() => {
        savedCallback.current();
      }, delay);
      setTimeoutId(id);
      return () => clearTimeout(id);
    }
  }, [delay, setTimeoutId]);
  return () => clearTimeout(timeoutId);
}
