import AddIcon from '@mui/icons-material/Add';
import { useEditableDatagridContext } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Button } from 'react-admin';

interface EditableDatagridCreateButtonProps {
  label: string;
}

export const EditableDatagridCreateButton: React.FC<
  EditableDatagridCreateButtonProps
> = ({ label }): ReactElement => {
  const { openStandaloneCreateForm } = useEditableDatagridContext();

  return (
    <Button
      label={label}
      onClick={openStandaloneCreateForm}
      startIcon={<AddIcon />}
    />
  );
};
