import { useMemo } from 'react';
import {
  addRefreshAuthToDataProvider,
  combineDataProviders,
} from 'react-admin';
import { getCopyBookToProjectDataProvider } from './providers/copyBookToProjectDataProvider';
import importDataProvider from './providers/importDataProvider';
import userDataProvider from './providers/userDataProvider';
import reportDataProvider from './providers/reportDataProvider';
import dashDataProvider from './providers/dashDataProvider';
import companyDataProvider from './providers/companyDataProvider';
import companySubscriptionDataProvider from './providers/companySubscriptionDataProvider';
import purchaseOrderMaterialsDataProvider from './providers/purchaseOrderMaterialsDataProvider';
import auditDataProvider from './providers/auditDataProvider';
import { getGeneratePdfDataProvider } from './providers/generatePdfDataProvider';
import { getBookGenerationDataProvider } from './providers/bookGenerationDataProvider';
import { getAttachmentDataProvider } from './providers/attachmentDataProvider';
import { getQrScannerDataProvider } from './providers/qrScannerDataProvider';
import { getUserPermissionDataProvider } from './providers/userPermissionDataProvider';
import { getPreservationDataProvider } from './providers/preservationDataProvider';
import { getTemplateDataProvider } from './providers/templateDataProvider';
import { getItrDataProvider } from './providers/itrDataProvider';
import { getConfigurationDataProvider } from './providers/configurationDataProvider';
import { getAttachmentRevisionDataProvider } from './providers/attachmentRevisionDataProvider';
import { supportDataProvider } from './providers/supportDataProvider';
import { getDefaultDataProvider } from './providers/defaultDataProvider';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_CONFIGURATION,
  RESOURCE_IMPORT,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_USER,
  RESOURCE_SUBSYSTEM,
  RESOURCE_COMPONENT_ACTIVITY,
  RESOURCE_SUBSYSTEM_ACTIVITY,
  RESOURCE_SYSTEM_ACTIVITY,
  CUSTOM_ROUTE_BOOK_GENERATION,
  RESOURCE_TEMPLATE,
  RESOURCE_PUNCH,
  RESOURCE_QR_SCANNER,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_REPORT,
  RESOURCE_DASHBOARD,
  CUSTOM_ROUTE_SUPPORT,
  RESOURCE_CERT_DETAIL,
  RESOURCE_SMART_CERT_DETAIL,
  RESOURCE_COMPANY,
  RESOURCE_TEST,
  RESOURCE_COMPANY_SUBSCRIPTION,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_ATTACHMENT_REVISION,
  RESOURCE_BOOK,
  RESOURCE_AUDIT,
} from '../../components/constants';
import useAddDataProviderActionCatch from '../../components/DataProviderErrors/useAddDataProviderActionCatch';
import { getAuthProvider } from '../authProvider/authProvider';
import { AuthProviderExt } from '../authProvider/providers/cognitoAuthProvider';

const defaultDataProvider = getDefaultDataProvider();
const attachmentDataProvider = getAttachmentDataProvider();
const attachmentRevisionDataProvider = getAttachmentRevisionDataProvider();
const configurationDataProvider = getConfigurationDataProvider();
const userPermissionDataProvider = getUserPermissionDataProvider();
const preservationDataProvider = getPreservationDataProvider();
const itrDataProvider = getItrDataProvider();
const generatePdfDataProvider = getGeneratePdfDataProvider();
const bookGenerationDataProvider = getBookGenerationDataProvider();
const templateDataProvider = getTemplateDataProvider();
const qrScannerDataProvider = getQrScannerDataProvider();
const copyBookToProjectProvide = getCopyBookToProjectDataProvider();

const getDataProviderMatcher = (addDataProviderActionCatch) => (resource) => {
  const { getList, getOne } = defaultDataProvider;

  switch (resource) {
    case RESOURCE_AUDIT:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...auditDataProvider,
      });
    case RESOURCE_ATTACHMENT:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...attachmentDataProvider,
      });
    case RESOURCE_ATTACHMENT_REVISION:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...attachmentRevisionDataProvider,
      });
    case RESOURCE_USER:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...userDataProvider,
        ...userPermissionDataProvider,
      });
    case RESOURCE_CONFIGURATION:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...configurationDataProvider,
      });
    case RESOURCE_IMPORT:
      return addDataProviderActionCatch(importDataProvider);
    case RESOURCE_PRESERVATION_ITEM_ACTIVITY:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...preservationDataProvider,
        bulkGeneratePdf: generatePdfDataProvider.bulkGeneratePdf,
      });
    case RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        bulkGeneratePdf: generatePdfDataProvider.bulkGeneratePdf,
      });
    case RESOURCE_SUBSYSTEM:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...itrDataProvider,
      });
    case RESOURCE_COMPONENT_ACTIVITY:
    case RESOURCE_SUBSYSTEM_ACTIVITY:
    case RESOURCE_SYSTEM_ACTIVITY:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        generatePdf: generatePdfDataProvider.generatePdf,
      });
    case RESOURCE_PUNCH:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        bulkGeneratePdf: generatePdfDataProvider.bulkGeneratePdf,
      });
    case CUSTOM_ROUTE_BOOK_GENERATION:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...bookGenerationDataProvider,
      });
    case RESOURCE_TEMPLATE:
      return addDataProviderActionCatch({
        getList,
        getOne,
        ...templateDataProvider,
      });
    case RESOURCE_QR_SCANNER:
      const { hardDelete } = attachmentDataProvider;
      return addDataProviderActionCatch({
        getList,
        getOne,
        hardDelete,
        ...qrScannerDataProvider,
      });
    case RESOURCE_COMPONENT_TEST:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        bulkGeneratePdf: generatePdfDataProvider.bulkGeneratePdf,
      });
    case RESOURCE_CERT_DETAIL:
    case RESOURCE_SMART_CERT_DETAIL:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        bulkGeneratePdf: generatePdfDataProvider.bulkGeneratePdf,
      });
    case RESOURCE_REPORT:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...reportDataProvider,
      });
    case RESOURCE_DASHBOARD:
      return addDataProviderActionCatch(dashDataProvider);
    case CUSTOM_ROUTE_SUPPORT:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...supportDataProvider,
      });
    case RESOURCE_COMPANY:
      return addDataProviderActionCatch(companyDataProvider);
    case RESOURCE_COMPANY_SUBSCRIPTION:
      return addDataProviderActionCatch(companySubscriptionDataProvider);
    case RESOURCE_TEST:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...templateDataProvider,
      });
    case RESOURCE_MATERIAL_RELEASED:
    case RESOURCE_MATERIAL_PURCHASE_ORDER: {
      return {
        ...defaultDataProvider,
        bulkGeneratePdf: generatePdfDataProvider.bulkGeneratePdf,
      };
    }
    case RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL:
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        ...purchaseOrderMaterialsDataProvider,
      });
    case RESOURCE_BOOK: {
      return addDataProviderActionCatch({
        ...defaultDataProvider,
        copyBookToProject: copyBookToProjectProvide.copyBookToProject,
      });
    }
    default:
      return addDataProviderActionCatch(defaultDataProvider);
  }
};

const refreshTokens = (): Promise<void> => {
  const authProvider = getAuthProvider() as AuthProviderExt;
  return authProvider.refreshUserSession();
};

const useDataProvider = () => {
  const addDataProviderActionCatch = useAddDataProviderActionCatch();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useMemo(
    () =>
      addRefreshAuthToDataProvider(
        combineDataProviders(
          getDataProviderMatcher(addDataProviderActionCatch)
        ),
        refreshTokens
      ),
    [addDataProviderActionCatch]
  );
};

export default useDataProvider;
