import React, { ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import ActivityConstraintField from './ActivityConstraintField';
import ActivityField from './ActivityField';
import ActivityPhaseField from './ActivityPhaseField';
import ActivityTypeField from './ActivityTypeField';
import CertTemplateField from './CertTemplateField';
import DisciplineField from './DisciplineField';
import GenerateCertificateButton from './GenerateCertificateButton';
import {
  getActivityFieldSource,
  getActivityFieldTargetId,
  getActivityResource,
} from './helpers/activity';
import { ActivityCategory } from './types';
import CertStatusWithColorDotField from './CertStatusWithColorDotField';

interface ActivitiesTabProps {
  category: ActivityCategory;
  onlyShow?: boolean;
}

const ActivitiesTab: React.FC<ActivitiesTabProps> = ({
  category,
  onlyShow,
}): ReactElement => {
  const activitySource = getActivityFieldSource(category);
  const targetId = getActivityFieldTargetId(category);
  const resource = getActivityResource(category);

  return (
    <ReferenceManyField target={targetId} reference={resource}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <CertStatusWithColorDotField />
        <ActivityField activitySource={activitySource} resource={resource} />
        <ActivityPhaseField />
        <DisciplineField />
        <ActivityTypeField />
        <TextField source="description" label="Description" />
        <ActivityConstraintField />
        <CertTemplateField />
        {!onlyShow ? (
          <GenerateCertificateButton activityCategory={category} />
        ) : null}
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ActivitiesTab;
