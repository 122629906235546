import React, { Fragment, ReactElement } from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import Box from '@mui/material/Box';
import PurchaseOrderField from '../../../common/PurchaseOrderField';
import commonStyles from '../../../common/commonStyles';
import { MaterialType } from '../types';

interface ActivePoListFieldProps {
  label?: string;
  source?: string;
  materialRecordPath?: string;
  sortable?: boolean;
}

const ActivePoListField: React.FC<ActivePoListFieldProps> = ({
  materialRecordPath,
}): ReactElement => {
  const record = useRecordContext() as MaterialType;
  const materialPurchaseOrderMaterials = get(record, materialRecordPath, []);

  return (
    <Fragment>
      {materialPurchaseOrderMaterials.map((item) => (
        <Box key={item.id} sx={commonStyles.flexBox}>
          <RecordContextProvider value={item}>
            <PurchaseOrderField
              source="materialPurchaseOrder.id"
              textSource="materialPurchaseOrder.orderNo"
            />
          </RecordContextProvider>
        </Box>
      ))}
    </Fragment>
  );
};

ActivePoListField.defaultProps = {
  label: 'Active PO',
  source: 'materialPurchaseOrderMaterials',
  materialRecordPath: 'materialPurchaseOrderMaterials',
};

export default ActivePoListField;
