import React, { ReactElement } from 'react';
import {
  SelectInput,
  SelectInputProps,
  Validator,
  AutocompleteArrayInput,
} from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { completedChoices } from '../constants';

interface CompletedInputProps
  extends Omit<
    SelectInputProps,
    'field' | 'name' | 'formState' | 'fieldState'
  > {
  source: string;
  label?: string;
  sx?: SxProps<Theme>;
  validate?: Validator[];
  multiple?: boolean;
  disableValue?: string;
}

const CompletedInput: React.FC<CompletedInputProps> = ({
  source,
  label,
  sx,
  validate,
  disabled,
  disableValue,
  multiple = false,
}): ReactElement => {
  const props = {
    source,
    label,
    validate,
    sx,
    choices: completedChoices,
  };

  if (multiple) {
    return (
      <AutocompleteArrayInput defaultValue={['ISSUED']} multiple {...props} />
    );
  }

  // We don't need empty value for this field, we can use default value instead
  const defaultValue = completedChoices[0];
  return (
    <SelectInput
      emptyText={defaultValue.name}
      emptyValue={defaultValue.id}
      defaultValue={defaultValue.id}
      {...props}
      choices={completedChoices.slice(1)}
      disabled={disabled}
      disableValue={disableValue}
    />
  );
};

export default CompletedInput;
