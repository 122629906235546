import { useCallback, useEffect, useRef } from 'react';

const useDebounce = (delay = 300) => {
  const timeoutRef = useRef<number>(0);

  const clrTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => clrTimeout, [clrTimeout]);

  return useCallback(
    (fn: () => void) => {
      clrTimeout();
      timeoutRef.current = setTimeout(fn, delay) as unknown as number; // In Node setTimeout returns Timer object. In browser-specific environment setTimeout returns number
    },
    [clrTimeout, delay]
  );
};

export default useDebounce;
