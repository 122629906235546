import React, { ReactElement } from 'react';
import { Button, Filter } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import DateRangeFilter from '../../../common/DateRangeFilter';
import useSidebarFilters from '../../../common/hooks/useSidebarFilters';
import NumberRangeFilter from '../../../common/NumberRangeFilter';
import sideFilterStyles from '../../../common/sideFilterStyles';
import TextInput from '../../../common/TextInput';
import { SideFilter } from '../../../common/types';
import { RESOURCE_MATERIAL_RELEASED } from '../../../constants';
import StatusInput from './StatusInput';

const MaterialReleasedSideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
    numberFrom,
    numberTo,
    handleNumberFromChange,
    handleNumberToChange,
  } = useSidebarFilters(RESOURCE_MATERIAL_RELEASED, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="Released Order No"
            name="orderNo_inc"
            source="orderNo"
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            source="generateDate"
            label="Date Generated"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="releasedDate"
            label="Date Released"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <NumberRangeFilter
            source="totalQty"
            label="Total Qty to Release"
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <StatusInput fullWidth alwaysOn />
          <TextInput
            label="Released To"
            name="releasedTo_inc"
            source="releasedTo"
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default MaterialReleasedSideFilters;
