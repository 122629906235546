import React, { ReactElement } from 'react';
import { email, maxLength } from 'react-admin';
import { required } from '../../utils/UtilityFunctions';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';

const EmailInput: React.FC = (): ReactElement => (
  <TextInput
    source="email"
    label="Email"
    name="email"
    validate={[required(), maxLength(150), email()]}
    sx={commonStyles.flexBoxItem}
  />
);

export default EmailInput;
