import React, { Fragment, ReactElement, useCallback } from 'react';
import CreateWithTitle from '../common/CreateWithTitle';
import {
  BooleanInput,
  SimpleForm,
  maxLength,
  regex,
  useGetList,
  useNotify,
  useRedirect,
  useStore,
} from 'react-admin';
import FooterToolbar from '../common/FooterToolbar';
import { RESOURCE_BOOK, RESOURCE_BOOK_SECTION } from '../constants';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { useParams } from 'react-router-dom';
import LinkToToc from './components/LinkToToc';
import { BookSection } from './type';
import { SectionTypeInput } from './components/SectionTypeInput';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';

const BookSectionCreate: React.FC = (): ReactElement => {
  const { bookId } = useParams();
  const [bookSectionNext] = useStore<string>('bookSectionNext', undefined);
  const [bookSectionSub] = useStore<string>('bookSectionSub', undefined);
  const redirect = useRedirect();
  const notify = useNotify();

  const { data: bookSections } = useGetList<BookSection>(
    RESOURCE_BOOK_SECTION,
    {
      pagination: {
        page: 1,
        perPage: MAX_RECORD_PER_PAGE,
      },
      sort: { field: 'section', order: 'ASC' },
      filter: {
        bookId: bookId,
      },
    }
  );

  const validateSection = useCallback(
    (value) => {
      if (!value) return undefined;

      if (bookSections) {
        //Check same section exist
        const foundSection = bookSections.find((s) => s.section === value);
        if (foundSection) {
          return `Section ${value} is already exist!`;
        }

        {
          //Check Parent section exist
          const parts = value.split('.');
          if (parts.length > 1) {
            parts.pop();
            const parentSection = parts.join('.');

            const foundParentSection = bookSections.find(
              (s) => s.section === parentSection
            );
            if (!foundParentSection) {
              return `Couldn't find parent section ${parts.join(
                '.'
              )} Create it first!`;
            }
          }
        }

        {
          //Check Previous sibling
          const parts = value.split('.');
          const lastPart = parts[parts.length - 1];
          try {
            if (parseInt(lastPart) > 1) {
              let prevSibling = '0' + (parseInt(lastPart) - 1);
              prevSibling = prevSibling.substring(prevSibling.length - 2);
              parts[parts.length - 1] = prevSibling;

              const foundPrevSibling = bookSections.find(
                (s) => s.section === parts.join('.')
              );
              if (!foundPrevSibling) {
                return `Couldn't find previous section ${parts.join(
                  '.'
                )} Create it first!`;
              }
            }
          } catch (e) {}
        }
      } else {
        //There is no section yet, It has to be section 01
        if (value !== '01') {
          return 'You should start with Section 01';
        }
      }
      return undefined;
    },
    [bookSections]
  );

  const transform = (data: BookSection) => {
    const level = data.section.split('.').length - 1;
    return {
      ...data,
      bookId: +bookId,
      level: level,
    };
  };

  const onSuccess = useCallback(
    ({ id }) => {
      redirect(`/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}/${id}`);
      notify('Element created');
    },
    [bookId, notify, redirect]
  );

  return (
    <Fragment>
      <LinkToToc bookId={bookId} />
      <CreateWithTitle
        resource={RESOURCE_BOOK_SECTION}
        transform={transform}
        redirect={`/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}`}
        mutationOptions={{
          onSuccess,
        }}
      >
        <SimpleForm
          toolbar={
            <FooterToolbar
              cancelButtonProps={{
                to: `/${RESOURCE_BOOK}/${bookId}/${RESOURCE_BOOK_SECTION}`,
              }}
            />
          }
          defaultValues={{ section: bookSectionNext || bookSectionSub }}
        >
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="section"
              label="Section"
              validate={[
                required(),
                regex(/^(\d\d)(.\d\d)*$/, 'Must be in format like: 02.01'),
                validateSection,
                maxLength(255),
              ]}
              sx={commonStyles.flexBoxItem}
            />
            <SectionTypeInput />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="title"
              label="Title"
              validate={[required(), maxLength(255)]}
              sx={commonStyles.flexBoxItem}
            />
            <TextInput
              fullWidth
              multiline
              source="description"
              label="Description"
              sx={commonStyles.flexBoxLongItem}
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <BooleanInput
              label="Show section summary"
              source="showSectionSummary"
              sx={commonStyles.flexBoxSelectItem}
            />
          </Box>
        </SimpleForm>
      </CreateWithTitle>
    </Fragment>
  );
};

export default BookSectionCreate;
