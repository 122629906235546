import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import { Button } from 'react-admin';
import useCanAccess from '../hooks/useCanAccess';
import ModalDialog from '../ModalDialog';
import Tooltip from '../Tooltip';
import { TemplateType } from '../types';
import CopyTemplatesDatagrid from './CopyTemplatesDatagrid';
import { BUTTON_LABEL, BUTTON_TOOLTIP } from './constants';

interface CopyTemplatesButtonProps {
  templateType: TemplateType;
  resource?: string;
  lambdaMode?: boolean;
}

const CopyTemplatesButton: React.FC<CopyTemplatesButtonProps> = ({
  templateType,
  resource,
  lambdaMode,
}): ReactElement => {
  const canAccessList = useCanAccess();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <Tooltip title={BUTTON_TOOLTIP} placement="bottom">
        <Button
          label={BUTTON_LABEL}
          onClick={handleOpenModal}
          className="copyTemplatesButton"
        />
      </Tooltip>
      <ModalDialog
        title={BUTTON_LABEL}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <CopyTemplatesDatagrid
          templateType={templateType}
          onSuccess={handleCloseModal}
          resource={resource}
          lambdaMode={lambdaMode}
        />
      </ModalDialog>
    </Fragment>
  );
};

export default CopyTemplatesButton;
