import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_MATERIAL_PURCHASE_ORDER } from '../constants';
import SmartLinkField from './SmartLinkField';

interface PurchaseOrderFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}

const PurchaseOrderField: React.FC<PurchaseOrderFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_MATERIAL_PURCHASE_ORDER}
  >
    <TextField source={textSource} />
  </SmartLinkField>
);

PurchaseOrderField.defaultProps = {
  label: 'Purchase Order Request No',
  source: 'purchaseOrderId',
  textSource: 'purchaseOrder.orderNo',
  sortBy: '`purchaseOrder.generateDate`',
};

export default PurchaseOrderField;
