import React, { ReactElement } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps, Theme } from '@mui/material/styles';

interface ModalDialogProps {
  title: string;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  sx?: SxProps<Theme>;
}

const ModalDialog: React.FC<ModalDialogProps> = ({
  title,
  children,
  open,
  onClose,
  sx,
}): ReactElement => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{ sx: sx ?? { maxWidth: '920px' } }}
  >
    <DialogTitle sx={{ padding: '32px 24px 0' }}>{title}</DialogTitle>
    <IconButton
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 4,
        top: 4,
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent sx={{ paddingTop: 0, marginTop: '20px' }}>
      {children}
    </DialogContent>
  </Dialog>
);

export default ModalDialog;
