import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import {
  Button,
  SimpleForm,
  useCreate,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import Add from '@mui/icons-material/Add';
import { getErrorMessage } from '../../../../../utils/UtilityFunctions';
import useInvalidateRelatedResourceQueries from '../../../../common/hooks/useInvalidateRelatedResourceQueries';
import SaveOnlyToolbar from '../../../../common/SaveOnlyToolbar';
import ComponentInput from '../../../../common/ComponentInput';
import {
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_COMPONENT,
} from '../../../../constants';

const ComponentTabAdd: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const invalidateQueries =
    useInvalidateRelatedResourceQueries(RESOURCE_MATERIAL);
  const [create] = useCreate(
    RESOURCE_MATERIAL_COMPONENT,
    {},
    {
      onSuccess: () => {
        invalidateQueries().then();
      },
    }
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const optionRenderer = useCallback(
    (choice) => `${choice.tag}::${choice.tagDescription}`,
    []
  );

  const onSubmit = useCallback(
    async (data) => {
      await create(
        RESOURCE_MATERIAL_COMPONENT,
        {
          data: {
            componentId: data.componentId,
            materialId: record.id,
          },
        },
        {
          onError: (error) => {
            notify('Error: ' + getErrorMessage(error), {
              type: 'error',
              undoable: false,
            });
          },
          onSuccess: () => {
            notify('Tag was added to material', {
              type: 'success',
              undoable: false,
            });
          },
        }
      );

      handleClose();
    },
    [create, notify, handleClose, record]
  );

  return (
    <Fragment>
      <Button label="Add TAG" onClick={handleOpen}>
        <Add />
      </Button>
      <CreateDialog
        title={<span>Add Tag</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <SimpleForm
          id="material_add_tag"
          onSubmit={onSubmit}
          toolbar={<SaveOnlyToolbar />}
        >
          <ComponentInput fullWidth isRequired optionText={optionRenderer} />
        </SimpleForm>
      </CreateDialog>
    </Fragment>
  );
};

export default ComponentTabAdd;
