import React, { ReactElement, useMemo } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../utils/UtilityFunctions';
import AutocompleteReferenceInput from '../common/AutocompleteReferenceInput';
import { RESOURCE_SECURITY_ROLE } from '../constants';

interface SecurityRoleInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx: SxProps<Theme>;
  source?: string;
}

const SecurityRoleInput: React.FC<SecurityRoleInputProps> = ({
  isRequired,
  multiple,
  sx,
  source,
}): ReactElement => {
  const requestParams = useMemo(() => {
    return {
      sort: { field: 'role', order: 'ASC' as const },
      filter: { isDeleted: false },
    };
  }, []);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_SECURITY_ROLE}
      requestParams={requestParams}
      label="Role"
      name="role_inc"
      optionText="role"
      source={source}
      validate={isRequired ? [required()] : []}
      multiple={multiple}
      sx={sx}
      includeFields={['id', 'role']}
    />
  );
};

SecurityRoleInput.defaultProps = { source: 'roleId' };

export default SecurityRoleInput;
