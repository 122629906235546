import { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { getErrorMessage } from '../../../utils/UtilityFunctions';

const useExport = () => {
  const [exportInProgress, setExportInProgress] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const exportFile = async (resource, exportParams, fileName = null) => {
    try {
      setExportInProgress(true);
      notify(
        'The export has been started. You will receive a notification once the export process is complete.',
        {
          type: 'success',
          undoable: false,
        }
      );

      return await dataProvider.export(
        `${resource}/export?${exportParams}`,
        fileName ? fileName : resource
      );
    } catch (error) {
      let requestError = error;
      if (error?.response?.data instanceof Blob) {
        requestError = await error?.response?.data.text();
      }
      notify(getErrorMessage(requestError), {
        type: 'error',
        undoable: false,
      });
    } finally {
      setExportInProgress(false);
    }
  };
  return { exportInProgress, exportFile };
};

export default useExport;
