import React, { ReactElement } from 'react';
import {
  DatagridConfigurable,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import ColumnActions from '../../../../common/ColumnActions';
import ComponentField from '../../../../common/ComponentField';
import { RESOURCE_MATERIAL_COMPONENT } from '../../../../constants';

interface ComponentTabListProps {
  showAction?: boolean;
}

const ComponentTabList: React.FC<ComponentTabListProps> = ({
  showAction,
}): ReactElement => {
  return (
    <ReferenceManyField
      reference={RESOURCE_MATERIAL_COMPONENT}
      target="materialId"
      perPage={100}
      fullWidth={true}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
        <TextField source="id" label="ID" />
        <ComponentField />
        <TextField
          source="component.tagDescription"
          label="Tag Description"
          sortable={false}
        />
        <TextField
          source="component.subsystem.subsystem"
          label="SubSystem"
          sortable={false}
        />
        <TextField
          source="component.discipline.discipline"
          label="Discipline"
          sortable={false}
        />
        {showAction && (
          <ColumnActions
            label="Actions"
            showActions={{ show: false, edit: false, delete: true }}
          />
        )}
      </DatagridConfigurable>
    </ReferenceManyField>
  );
};

ComponentTabList.defaultProps = {
  showAction: true,
};

export default ComponentTabList;
