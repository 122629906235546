import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_SUBSYSTEM } from '../constants';
import SmartLinkField from './SmartLinkField';

interface SubsystemFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: object;
}
const SubsystemField: React.FC<SubsystemFieldProps> = ({
  label,
  source,
  textSource,
  sx,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_SUBSYSTEM}>
    <TextField source={textSource} sx={sx} />
  </SmartLinkField>
);

SubsystemField.defaultProps = {
  label: 'SubSystem',
  source: 'subsystemId',
  textSource: 'subsystem.subsystem',
  sortBy: '`subsystem.subsystem`',
};

export default SubsystemField;
