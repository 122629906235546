import { RED } from '../../theme/colors';

export const styles = {
  fileProgressListItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  fileNameAndStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  fileUploadStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actions: { display: 'flex', justifyContent: 'space-between' },
  circularProgressWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  deleteButton: {
    color: RED,
    minWidth: '30px',
    width: '30px',
    padding: '5px',
    '& > span': { margin: 0 },
  },
};
