import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_DISCIPLINE_TEST } from '../constants';
import SmartLinkField from './SmartLinkField';

interface DisciplineTestFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
}

const DisciplineTestField: React.FC<DisciplineTestFieldProps> = ({
  label,
  source,
  textSource,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_DISCIPLINE_TEST}
  >
    <TextField source={textSource} />
  </SmartLinkField>
);

DisciplineTestField.defaultProps = {
  label: 'Discipline Test',
  source: 'disciplineTestId',
  textSource: 'disciplineTest.disciplineTest',
  sortBy: '`disciplineTest.disciplineTest`',
};

export default DisciplineTestField;
