import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { Identifier, NumberInput, minValue } from 'react-admin';
import PositionInput from '../../../components/common/PositionInput';

interface TestPositionRowFormProps {
  recordId: Identifier;
}

const TestPositionRowForm: React.FC<TestPositionRowFormProps> = ({
  recordId,
}): ReactElement => (
  <RowForm
    transform={(data) => ({
      ...data,
      testId: recordId,
    })}
  >
    <PositionInput isRequired={true} />
    <NumberInput
      source="posQty"
      label="Quantity"
      validate={[minValue(0, "Quantity can't be negative.")]}
    />
    <NumberInput
      source="posHours"
      label="Hours"
      validate={[minValue(0, "Hours can't be negative.")]}
    />
  </RowForm>
);

export default TestPositionRowForm;
