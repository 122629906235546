import { Button } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import { useListContext, useUnselectAll } from 'react-admin';
import { RESOURCE_SUBSYSTEM } from '../../../constants';
import { Subsystem } from '../../types';

interface SubsystemSelectButtonProps {
  onSelect: (subsystems: Subsystem[]) => void;
}

const SubsystemSelectButton: React.FC<SubsystemSelectButtonProps> = ({
  onSelect,
}): ReactElement => {
  const { data, selectedIds } = useListContext<Subsystem>();
  const unselectAll = useUnselectAll(RESOURCE_SUBSYSTEM);

  const select = () => {
    onSelect(
      data
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            subsystem: `${item.subsystem} - ${item.description}`,
          };
        })
    );
    unselectAll();
  };

  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Button onClick={select}>Select</Button>;
};

export default SubsystemSelectButton;
