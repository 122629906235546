import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_MOC_TYPE } from '../../constants';
import SmartLinkField from '../../common/SmartLinkField';

interface MocTypeFieldProps {
  label?: string;
  source?: string;
}

const MocTypeField: React.FC<MocTypeFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_MOC_TYPE}>
    <TextField source="mocType.mocType" />
  </SmartLinkField>
);

MocTypeField.defaultProps = { label: 'MoC Type', source: 'mocTypeId' };

export default MocTypeField;
