import Autocomplete from '@mui/material/Autocomplete';
import { TextInput, TextInputProps, useInput } from 'react-admin';

interface SelectInputWithFreeTextProps
  extends Pick<TextInputProps, 'source' | 'fullWidth' | 'validate' | 'sx'> {
  label: string;
  options: string[];
}

const SelectInputWithFreeText = ({
  label,
  source,
  options,
  fullWidth,
  validate,
  sx,
}: SelectInputWithFreeTextProps) => {
  const input = useInput({ source });
  return (
    <Autocomplete
      id={label}
      freeSolo
      options={options}
      onChange={(event) => {
        const { textContent } = event.target as HTMLInputElement;
        input.field.onChange(textContent);
      }}
      sx={sx}
      value={input.field.value}
      renderInput={(params) => (
        <TextInput
          {...params}
          source={source}
          label={label}
          validate={validate}
        />
      )}
      fullWidth={fullWidth}
    />
  );
};

export default SelectInputWithFreeText;
