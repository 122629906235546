import React, { Fragment, ReactElement } from 'react';
import ItrAutomationInput from './ItrAutomationInput';
import ItrAutomationLegend from './ItrAutomationLegend';

const ProjectFormItrAutomationTab: React.FC = (): ReactElement => (
  <Fragment>
    <ItrAutomationInput />
    <ItrAutomationLegend />
  </Fragment>
);

export default ProjectFormItrAutomationTab;
