import React, { ReactElement, useMemo } from 'react';
import { Edit, useRefresh } from 'react-admin';
import {
  UserContext,
  getContext,
  updateContext,
} from '../../provider/userContext';
import getUISettings from '../common/helpers/getUISettings';
import { setPageTitle } from '../common/helpers/setPageTitle';
import useAutoSaveEnabled from '../common/hooks/useAutoSaveEnabled';
import UserProfileForm from './UserProfileForm';
import { UserProfileFormData } from './types';
import { RESOURCE_USER } from '../constants';

const UserProfileEdit: React.FC = (): ReactElement => {
  const [autoSaveEnabled, setAutoSaveEnabled] = useAutoSaveEnabled();
  const ctx: UserContext = getContext();
  const title = 'User Profile';
  setPageTitle(title);
  const refresh = useRefresh();
  const mutationOptions = useMemo(() => {
    return {
      onSuccess: async (data) => {
        if (data.firstName && data.lastName) {
          const fullName = `${data.firstName} ${data.lastName}`;

          await updateContext({ fullName });
          refresh();
        }
      },
    };
  }, [refresh]);

  const transform = (data: UserProfileFormData) => {
    const uiSettings = getUISettings();
    setAutoSaveEnabled(data.autoSaveEnabled);

    return {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: data.jobTitle ?? '',
      pinCode: data.pinCode ?? '',
      externalCompany: data.externalCompany ?? '',
      uiSettings: JSON.stringify({
        ...uiSettings,
        'RaStore.autoSaveEnabled': data.autoSaveEnabled,
      }),
    };
  };

  return (
    <Edit
      id={ctx?.id}
      resource={RESOURCE_USER}
      transform={transform}
      title={title}
      mutationMode="pessimistic"
      actions={false}
      redirect={false}
      mutationOptions={mutationOptions}
    >
      <UserProfileForm autoSaveEnabled={autoSaveEnabled} />
    </Edit>
  );
};

export default UserProfileEdit;
