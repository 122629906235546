const auditStyles = {
  diffDataString: (color) => ({
    borderLeftColor: color,
    borderLeftWidth: 6,
    borderLeftStyle: 'solid' as const,
    paddingLeft: '5px',
  }),
  diffDataStringKey: (color) => ({
    color: color,
    fontWeight: 600,
  }),
};

export default auditStyles;
