import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { RESOURCE_ACTIVITY_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface ActivityTypeFieldProps {
  label?: string;
  source?: string;
  sx?: SxProps<Theme>;
  sortBy?: string;
}

const ActivityTypeField: React.FC<ActivityTypeFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_ACTIVITY_TYPE}
  >
    <TextField source="activityType.activityType" sx={sx} />
  </SmartLinkField>
);

ActivityTypeField.defaultProps = {
  label: 'Type',
  source: 'activityTypeId',
  sortBy: '`activityType.activityType`',
};

export default ActivityTypeField;
