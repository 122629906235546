import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_STRUCTURE_ASSET } from '../constants';
import SmartLinkField from './SmartLinkField';

interface AssetFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}

const AssetField: React.FC<AssetFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_STRUCTURE_ASSET}
  >
    <TextField source="asset.asset" />
  </SmartLinkField>
);

AssetField.defaultProps = {
  label: 'Asset',
  source: 'assetId',
  sortBy: '`asset.asset`',
};

export default AssetField;
