import React, { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import commonStyles from '../common/commonStyles';
import { DisciplineTest } from './types';

const DisciplineTestCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: DisciplineTest): DisciplineTest => ({
    ...data,
    divisionId: ctx?.defaultDivisionId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <Box sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="disciplineTest"
            label="Discipline Test"
            validate={[required(), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
          <TextInput
            source="disciplineTestCode"
            label="Discipline Test Code"
            validate={[required(), maxLength(35)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
          <TextInput
            source="resource"
            label="Resource"
            validate={[maxLength(85)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
        </Box>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default DisciplineTestCreate;
