import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_STORAGE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface StorageFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sx?: object;
  sortBy?: string;
}

const StorageField: React.FC<StorageFieldProps> = ({
  label,
  source,
  textSource,
  sx,
}): ReactElement => (
  <SmartLinkField label={label} source={source} resource={RESOURCE_STORAGE}>
    <TextField source={textSource} sx={sx} />
  </SmartLinkField>
);

StorageField.defaultProps = {
  label: 'Storage',
  source: 'storageId',
  textSource: 'tblStorage.storage',
  sortBy: '`tblStorage.storage`',
};

export default StorageField;
