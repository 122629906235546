import React, { ReactElement } from 'react';
import { minValue, SimpleForm } from 'react-admin';
import { required } from 'ra-core/dist/cjs/form/validate';
import Box from '@mui/material/Box';
import {
  useDictionaryLabelsWithResource,
  integer,
} from '../../../../../utils/UtilityFunctions';
import StorageInput from '../../../../common/StorageInput/StorageInput';
import SaveOnlyToolbar from '../../../../common/SaveOnlyToolbar';
import CustomNumberInput from '../../../../common/CustomNumberInput';
import commonStyles from '../../../../common/commonStyles';

interface StorageTabFormProps {
  onSubmit: (data: Record<string, string>) => void;
  record?: Record<string, string>;
}

const StorageTabForm: React.FC<StorageTabFormProps> = ({
  onSubmit,
  record,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <SimpleForm
      id="material_storage_form"
      onSubmit={onSubmit}
      toolbar={<SaveOnlyToolbar />}
    >
      <Box sx={{ ...commonStyles.flexBox, ...commonStyles.fullWidth }}>
        <StorageInput
          canCreate
          sx={commonStyles.halfWidth}
          defaultValue={record?.storageId || ''}
        />
        <CustomNumberInput
          source="qtyOnHand"
          name="qtyOnHand"
          label={labels['qtyOnHand'] || 'Qty On Hand'}
          sx={commonStyles.halfWidth}
          validate={[required(), minValue(0), integer()]}
          defaultValue={Number(record?.qtyOnHand || 0)}
        />
      </Box>
    </SimpleForm>
  );
};

export default StorageTabForm;
