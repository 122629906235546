import React, { ReactElement } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { FieldPropsWithOptionalSource } from '../../common/types';
import SmartProjectField from '../../common/ProjectField';

interface ProjectFieldProps extends FieldPropsWithOptionalSource {
  label?: string;
  textSource?: string;
}
const ProjectField: React.FC<ProjectFieldProps> = ({
  textSource,
  label,
  ...rest
}): ReactElement => {
  const record = useRecordContext();
  const isActiveProject = record?.project?.isActive;

  return isActiveProject ? (
    <SmartProjectField {...rest} label={label} />
  ) : (
    <TextField source={textSource} label={label} />
  );
};

ProjectField.defaultProps = {
  label: 'Project',
  source: 'projectId',
  textSource: 'project.project',
  sortBy: '`project.project`',
};

export default ProjectField;
