import React, { ReactElement, useCallback, useState, Fragment } from 'react';
import { Identifier } from 'react-admin';
import ImportResults from '../../common/ImportButton/components/ImportResults';
import { titleFromResource } from '../../common/hooks/useResourceTitle';
import { ImportResult } from '../../common/ImportButton/types';
import ModalDialog from '../../common/ModalDialog';
import NotificationItemCard from './NotificationItemCard';
import { Notification } from '../type';

interface ImportNotificationProps {
  notification: Notification;
  readNotification: (id: Identifier) => void;
}

const ImportNotification: React.FC<ImportNotificationProps> = ({
  notification,
  readNotification,
}): ReactElement => {
  const resource =
    titleFromResource?.[notification?.details?.resourceName]?.[0] || '';
  const notificationTitle = `${resource} Import Result`;
  const importResult: ImportResult = notification?.details;
  const [showDialog, setShowDialog] = useState(false);
  const handleOpenNotification = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  return (
    <Fragment>
      <NotificationItemCard
        notification={notification}
        readNotification={readNotification}
        handleOpenNotification={handleOpenNotification}
      />
      <ModalDialog
        title={notificationTitle}
        open={!!importResult && showDialog}
        onClose={closeDialog}
      >
        <ImportResults importResult={importResult} />
      </ModalDialog>
    </Fragment>
  );
};

export default ImportNotification;
