import React, { ReactElement } from 'react';
import { CreateProps, TabbedForm, FormTab } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import checkSecurityLevel from '../common/helpers/checkSecurityLevel';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import commonStyles from '../common/commonStyles';
import { securityLevel } from '../common/constants';
import ProjectsInputArrayField from './components/ProjectsInputArrayField';
import UserForm from './UserForm';
import { makeRequestBody } from './helpers';

const UserCreate: React.FC<CreateProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data) => makeRequestBody(data, ctx?.company, true);

  return (
    <CreateWithTitle {...props} redirect="list" transform={transform}>
      <TabbedForm
        toolbar={<FooterToolbar />}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <FormTab label="User" id="user_tab" sx={commonStyles.formTabHeader}>
          <UserForm createMode />
        </FormTab>
        {checkSecurityLevel(securityLevel.fieldAdmin) && (
          <FormTab
            label="Projects"
            id="projects_tab"
            sx={commonStyles.formTabHeader}
          >
            <ProjectsInputArrayField />
          </FormTab>
        )}
      </TabbedForm>
    </CreateWithTitle>
  );
};

export default UserCreate;
