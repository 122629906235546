import { AvailableColumn } from '../types';

const getColumnsToExport = (
  availableColumns: AvailableColumn[],
  selectedColumns: string[]
) => {
  const columns = [];
  if (Array.isArray(selectedColumns)) {
    availableColumns.forEach((item) => {
      if (
        item.source &&
        selectedColumns.find(
          (selectedColumn) =>
            selectedColumn === item.index || selectedColumn === item.source
        )
      ) {
        columns.push(item.source);
      }
    });
  } else {
    availableColumns.forEach((item) => {
      if (item.source) {
        columns.push(item.source);
      }
    });
  }
  return columns;
};

export default getColumnsToExport;
