import { SimpleShowLayout } from 'react-admin';
import React, { ReactElement } from 'react';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import commonStyles from '../common/commonStyles';
import LucidEmbedView from './components/LucidEmbedView';

const LucidDiagramShow: React.FC<ShowComponentProps> = (
  props
): ReactElement => {
  return (
    <ShowComponent {...props}>
      <SimpleShowLayout sx={commonStyles.bottomMargin}>
        <LucidEmbedView className="lucid-embed--show" />
      </SimpleShowLayout>
    </ShowComponent>
  );
};

export default LucidDiagramShow;
