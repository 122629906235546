import * as React from 'react';
import { SelectField, SelectFieldProps } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { YES_NO_CHOICES } from './constants';

interface YesNoFieldProps extends SelectFieldProps {
  source: string;
  label: string;
  sx: SxProps<Theme>;
}

const YesNoField: React.FC<YesNoFieldProps> = ({ source, label, sx }) => (
  <SelectField source={source} label={label} sx={sx} choices={YES_NO_CHOICES} />
);

export default YesNoField;
