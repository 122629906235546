export const checkJson = (data) => {
  try {
    JSON.parse(data);
  } catch (_) {
    return false;
  }

  return true;
};

export const getJsonValue = (data) => {
  try {
    return JSON.parse(data);
  } catch (_) {
    return data;
  }
};
