import React, { ReactElement } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import SystemInput from '../common/SystemInput';
import TextInput from '../common/TextInput';
import SubsystemInput from '../common/SubsystemInput';
import DateRangeFilter from '../common/DateRangeFilter';
import sideFilterStyles from '../common/sideFilterStyles';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import { SideFilter } from '../common/types';
import { RESOURCE_SUBSYSTEM } from '../constants';

const SubSystemSideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_SUBSYSTEM, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard} id="subsystemSidebarFilter">
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <SubsystemInput source="id" multiple fullWidth alwaysOn />
          <SystemInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <TextInput
            label="Phase"
            name="phase_inc"
            source="phase"
            alwaysOn
            fullWidth
          />
          <TextInput
            label="Description"
            name="description_inc"
            source="description"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Scoped"
            name="isScoped"
            source="isScoped"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Verified"
            name="isVerified"
            source="isVerified"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Sequence"
            name="sequence_inc"
            source="sequence"
            fullWidth
            alwaysOn
          />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default SubSystemSideFilters;
