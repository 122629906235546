import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export const getItrDataProvider = () => ({
  populateITR: (resource: string, projectId: number) => {
    return axios.get(
      `${CRUD_API_URL}/${resource}/populate/${projectId}`,
      getRequestConfig()
    );
  },
});
