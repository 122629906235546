import React, { ReactElement } from 'react';
import { FunctionField, FieldProps } from 'react-admin';
import get from 'lodash/get';
import commonStyles from './commonStyles';

interface ColorDotFieldProps extends FieldProps {
  label: string;
  source: string;
  getDotColor: (value: string) => string;
}

const ColorDotField: React.FC<ColorDotFieldProps> = ({
  label,
  source,
  getDotColor,
  ...rest
}): ReactElement => (
  <FunctionField
    {...rest}
    source={source}
    label={label}
    render={(record) => (
      <span
        style={{
          ...commonStyles.statusColorDot,
          backgroundColor: getDotColor(get(record, source, '')),
        }}
      />
    )}
  />
);

export default ColorDotField;
