import { Identifier, RaRecord } from 'react-admin';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';

export interface Attachment extends RaRecord {
  id: Identifier;
  resourceName: string;
  attachmentType?: string;
  fileName: string;
  description?: string;
  rawFile?: Blob;
  projectId: number;
  details?: string;
  isDeleted?: boolean;
  downloadUrl?: string;
  revisions?: object;
  systemId?: number;
  subsystemId?: number;
  componentId?: number;
  drawingId?: number;
  testId?: number;
  refProjectId?: number;
  bookId?: number;
  punchId?: number;
  updatedAt: Date;
  updatedBy: string;
  updatedByName: string;
}

export type RawAttachment = { rawFile: File };

export type FormDataWithFiles = {
  files?: File[];
  fileName?: string;
  description?: string;
  resourceId?: string;
  resource?: string;
  isActive?: string;
  attachmentType?: ATTACHMENT_TYPE_ENUMS;
  projectId?: string;
  refProjectId?: string;
  resourceName?: string;
};

export enum AttachmentFileType {
  pdf = 'pdf',
  image = 'image',
  other = 'other',
}
