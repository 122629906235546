import { PRICE_FIELDS } from '../constants';

export const makeNumberFilter = (
  numberFrom: number | null,
  numberTo: number | null,
  fieldName: string
) => {
  let ratio = 1;
  if (PRICE_FIELDS.includes(fieldName)) {
    ratio = 100;
  }

  if (numberFrom === null && numberTo === null) {
    return {};
  }
  if (numberFrom && numberTo) {
    return {
      [`${fieldName}_between`]: [
        String(numberFrom * ratio),
        String(numberTo * ratio),
      ],
    };
  } else {
    if (numberFrom) {
      return { [`${fieldName}_gte`]: String(numberFrom * ratio) };
    }
    if (numberTo) {
      return { [`${fieldName}_lte`]: String(numberTo * ratio) };
    }
    return {};
  }
};

export const isNumericString = (value: string): boolean => {
  // check strings
  if (typeof value != 'string') {
    return false;
  }

  return !isNaN(Number(value)) && !isNaN(parseFloat(value));
};
