import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import { ImageField, SimpleShowLayout, useRecordContext } from 'react-admin';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import { IconButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { ReactComponent as PDFIcon } from '../../assets/icons/functionalIcons/PDF.svg';
import { getAttachmentFileType } from '../attachment/helpers';
import { AttachmentFileType } from '../attachment/types';
import CustomDialog from './CustomDialog/CustomDialog';
import EmptyIconButtonPlaceholder from './EmptyIconButtonPlaceholder';
import { getFileName } from './helpers/getFileName';
import commonStyles from './commonStyles';

type PreviewButtonProps = {
  showEmptyIcon?: boolean;
};

const PreviewButton: React.FC<PreviewButtonProps> = ({
  showEmptyIcon = true,
}): ReactElement => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => setShowDialog(false), []);
  const fileName = getFileName(
    record?.fileName || record.s3FileKey || record.downloadUrl
  );
  const attachmentFileType = getAttachmentFileType(fileName);

  return attachmentFileType !== AttachmentFileType.other ? (
    <Fragment>
      <IconButton
        size="small"
        title="Preview"
        className="previewButton"
        onClick={openDialog}
        style={{ color: '#212121' }}
      >
        {attachmentFileType === AttachmentFileType.image ? (
          <ImageIcon />
        ) : (
          <PDFIcon />
        )}
      </IconButton>

      <CustomDialog title={fileName} open={showDialog} onClose={closeDialog}>
        {attachmentFileType === AttachmentFileType.image ? (
          <SimpleShowLayout record={record}>
            <ImageField
              source="downloadUrl"
              label={false}
              sx={commonStyles.previewImageField}
            />
          </SimpleShowLayout>
        ) : (
          <PDFViewer
            url={record.downloadUrl}
            tools={[
              'pager',
              'spacer',
              'zoomInOut',
              'zoom',
              'selection',
              'spacer',
              'search',
            ]}
            defaultZoom={1}
          />
        )}
      </CustomDialog>
    </Fragment>
  ) : showEmptyIcon ? (
    <EmptyIconButtonPlaceholder />
  ) : null;
};

export default PreviewButton;
