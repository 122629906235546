import React, { ReactElement } from 'react';
import { DateField, TextField, useStore } from 'react-admin';
import SubSystemActivitySideFilters from './components/SubSystemActivitySideFilters';
import CertStatusWithColorDotField from '../common/CertStatusWithColorDotField';
import ActivityDurationUnitField from '../common/ActivityDurationUnitField';
import GenerateCertificateButton from '../common/GenerateCertificateButton';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ActivityConstraintField from '../common/ActivityConstraintField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import BulkActionButtons from './components/BulkActionButtons';
import ActivityPhaseField from '../common/ActivityPhaseField';
import AssignedField from '../common/Assigned/AssignedField';
import ActivityTypeField from '../common/ActivityTypeField';
import CertTemplateField from '../common/CertTemplateField';
import SubSystemActivityEdit from './SubSystemActivityEdit';
import SubSystemActivityShow from './SubSystemActivityShow';
import { RESOURCE_SUBSYSTEM_ACTIVITY } from '../constants';
import DisciplineField from '../common/DisciplineField';
import { activityCategory } from '../common/constants';
import SubsystemField from '../common/SubsystemField';
import DownloadButton from '../common/DownloadButton';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import PreviewButton from '../common/PreviewButton';
import ListActions from '../common/ListActions';
import SystemField from '../common/SystemField';

const SubSystemActivityList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_SUBSYSTEM_ACTIVITY);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      aside={
        showSideFilter ? (
          <SubSystemActivitySideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'subsystemActivity', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<SubSystemActivityShow withoutActions />}
        EditComponent={<SubSystemActivityEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'subsystemActivity',
          'subsystemId',
          'sequence',
          'activityPhaseId',
          'activityTypeId',
          'certTemplateId',
          'certGatingStatus.certStatus',
        ]}
      >
        <TextField source="id" label="ID" />
        <CertStatusWithColorDotField />
        <TextField source="subsystemActivity" label="Activity" />
        <SubsystemField />
        <TextField
          source="subsystem.description"
          label="SubSystem Description"
        />
        <SystemField
          source="subsystem.systemId"
          label="System"
          textSource="subsystem.system.systemName"
          sortBy="`subsystem.system.systemName`"
        />
        <TextField source="sequence" label="Sequence" />
        <ActivityPhaseField />
        <DisciplineField />
        <ActivityTypeField />
        <TextField source="description" label="Description" />
        <ActivityConstraintField />
        <TextField source="duration" label="Duration" />
        <TextField source="actualDuration" label="Actual Duration" />
        <ActivityDurationUnitField />
        <DateField source="plannedDate" label="Planned Date" />
        <DateField source="actualDate" label="Actual Date" />
        <CertTemplateField />
        <TextField
          source="certDetails.certTotalSignatures"
          label="Certificate Total Signatures"
          sortable={false}
        />
        <TextField
          source="certDetails.certSigned"
          label="Certificate Signed"
          sortable={false}
        />
        <TextField
          source="certDetails.certSignedBy"
          label="Certificate Signed By"
          sortable={false}
        />
        <AssignedField
          sourcePrefix="certDetails"
          label="Certificate Responsible"
          sortable={false}
        />
        <TextField
          source="certGatingStatus.itrStatus"
          label="ITR Status"
          sortBy="certGatingStatus.itr_status"
        />
        <TextField
          source="certGatingStatus.punchStatus"
          label="Punch Status"
          sortBy="certGatingStatus.punch_status"
        />
        <TextField
          source="certGatingStatus.certificateStatus"
          label="Predecessor Certificate Status"
          sortBy="certGatingStatus.certificate_status"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <GenerateCertificateButton
            activityCategory={activityCategory.subSystem}
          />
          <PreviewButton />
          <DownloadButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default SubSystemActivityList;
