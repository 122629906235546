import React, { ReactElement } from 'react';
import { CreateButton, SelectColumnsButton, TopToolbar } from 'react-admin';
import commonStyles from '../../common/commonStyles';

export const BookSectionActions: React.FC = (): ReactElement => {
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <CreateButton
        className="createButton"
        sx={commonStyles.mdResponsiveButton}
        to="create"
      />
    </TopToolbar>
  );
};

export default BookSectionActions;
