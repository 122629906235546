import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export const getAttachmentDataProvider = () => ({
  hardDelete: (resource: string, params: { id: number }) => {
    return axios.delete(
      `${CRUD_API_URL}/${resource}/${params.id}/hard-delete`,
      getRequestConfig()
    );
  },
  addAttachment: (resource: string, data: Record<string, never>) => {
    return axios.post(
      `${CRUD_API_URL}/${resource}/create`,
      data,
      getRequestConfig()
    );
  },
});
