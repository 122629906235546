import React, { Fragment, ReactElement } from 'react';
import { Link } from 'react-admin';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/functionalIcons/Download.svg';
import { IconButton, Typography } from '@mui/material';
import { getFileName } from '../helpers';
import { styles } from '../styles';

interface DownloadButtonProps {
  subsystem: string;
  downloadUrl: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  subsystem,
  downloadUrl,
}): ReactElement =>
  downloadUrl && subsystem ? (
    <Link
      to={downloadUrl}
      download
      title={getFileName(subsystem)}
      target="_blank"
    >
      <IconButton
        size="small"
        sx={styles.downloadButton}
        color="primary"
        title="Download"
      >
        <DownloadIcon />
        <Typography sx={styles.downloadButtonText}>
          {getFileName(subsystem)}
        </Typography>
      </IconButton>
    </Link>
  ) : (
    <Fragment />
  );

export default DownloadButton;
