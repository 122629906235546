import { FunctionField } from 'react-admin';

import ContactField from '../ContactField';
// import GroupField from '../GroupField';
import UserGroupField from '../UserGroupField';
import useAssignedField from '../hooks/useAssignedField';
import { AssignedType } from '../enums';
import { ReactElement } from 'react';
import { FieldPropsWithOptionalSource } from '../types';

interface AssignedFieldProps extends FieldPropsWithOptionalSource {
  label?: string;
  sourcePrefix?: string;
}

const AssignedField: React.FC<AssignedFieldProps> = ({
  label,
  sourcePrefix,
}): ReactElement => {
  const assignedField = useAssignedField(sourcePrefix);

  if (!assignedField) {
    return null;
  }

  const { assignedType, source, textFieldSource } = assignedField;

  if (assignedType === AssignedType.CONTACT) {
    return (
      <ContactField
        source={source}
        textFieldSource={textFieldSource}
        label={label}
      />
    );
  }

  // if (assignedType === AssignedType.GROUP) {
  //   return (
  //     <GroupField
  //       source={source}
  //       textFieldSource={textFieldSource}
  //       label={label}
  //     />
  //   );
  // }

  if (assignedType === AssignedType.USER_GROUP) {
    return (
      <UserGroupField
        source={source}
        textFieldSource={textFieldSource}
        label={label}
      />
    );
  }

  return (
    <FunctionField
      label="File Name"
      render={(record) => {
        record = sourcePrefix ? record?.[sourcePrefix] || {} : record;
        return `${record.assignedEmail?.firstName || ''} ${
          record.assignedEmail?.lastName || ''
        } ${record.assignedEmail?.id || ''}`;
      }}
    />
  );
};

AssignedField.defaultProps = {
  label: 'Assigned',
  source: 'assigned',
};

export default AssignedField;
