import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import {
  DateField,
  Labeled,
  SelectField,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import renderShowMetaDataFields from '../../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../../common/ShowComponent';
import AttachmentList from '../../attachment/AttachmentList';
import POMaterialsForm from './components/POMaterialsForm';
import commonStyles from '../../common/commonStyles';
import AuditListTab from '../../audit/AuditListTab';
import { statusChoices } from './constants';

const PurchaseOrderRequestShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Purchase Order" id="purchase_order_tab">
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="orderNo" label="PO Request No" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <SelectField
              source="status"
              choices={statusChoices}
              label="Status"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="generateDate" label="Date Generated" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="clientOrderNo" label="Client Order No" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="receivedDate" label="Date Received" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxDescriptionItem}>
            <TextField source="comment" label="PO Comment" />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="PO Form"
        id="po_form_tab"
        contentClassName="materials-table"
      >
        <POMaterialsForm isShowMode />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Attachment" id="attachment_tab">
        <AttachmentList targetId="materialPurchaseOrderId" onlyShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_material_purchase_order" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default PurchaseOrderRequestShow;
