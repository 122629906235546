export enum SortOrder {
  TAG = 'TAG',
  ITR = 'ITR',
}

export enum BookType {
  TABS = 'TABS',
  TAGS = 'TAGS',
  SCANS = 'SCANS',
}

export enum GenerateType {
  ZIP = 'ZIP',
  PDF = 'PDF',
  BOTH = 'BOTH',
}
