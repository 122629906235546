import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_USER_GROUP } from '../constants';
import SmartLinkField from './SmartLinkField';

interface UserGroupFieldProps {
  label?: string;
  source?: string;
  textFieldSource?: string;
}

const UserGroupField: React.FC<UserGroupFieldProps> = ({
  label,
  source,
  textFieldSource,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_USER_GROUP}
    sortBy={textFieldSource}
  >
    <TextField source={textFieldSource} />
  </SmartLinkField>
);

UserGroupField.defaultProps = {
  label: 'User Group',
  source: 'id',
  textFieldSource: 'userGroup',
};

export default UserGroupField;
