import React, { Fragment, isValidElement, ReactElement } from 'react';
import { useTranslate, ValidationError, ValidationErrorMessage } from 'ra-core';

export const InputHelperText: React.FC<InputHelperTextProps> = ({
  helperText,
  error,
}): ReactElement => {
  const translate = useTranslate();

  if (error) {
    return <ValidationError error={error} />;
  }

  if (helperText === false) {
    return null;
  }

  if (isValidElement(helperText)) {
    return helperText;
  }

  if (typeof helperText === 'string') {
    return <Fragment>{translate(helperText, { _: helperText })}</Fragment>;
  }

  return <span dangerouslySetInnerHTML={defaultInnerHTML} />;
};

const defaultInnerHTML = { __html: '&#8203;' };

export interface InputHelperTextProps {
  helperText?: string | ReactElement | boolean;
  error?: ValidationErrorMessage;
}
