import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import React, { ReactElement } from 'react';
import { Button, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { styles } from '../../styles';

interface ActionsProps {
  filesInProgress: number;
  handleClear: () => void;
  isLoading: boolean;
}

const Actions: React.FC<ActionsProps> = ({
  filesInProgress,
  handleClear,
  isLoading,
  ...rest
}): ReactElement => {
  const { reset, formState } = useFormContext();
  const { isValid } = formState;

  return (
    <Toolbar {...rest} sx={styles.actions}>
      <Button
        name="upload-button"
        label="Upload and Process Document(s)"
        variant="text"
        type="submit"
        disabled={!isValid || filesInProgress > 0}
        sx={{ minWidth: '200px' }}
      >
        <CloudUploadIcon />
      </Button>
      <Button
        name="clear-button"
        label="Clear All"
        variant="text"
        type="button"
        disabled={isLoading}
        onClick={() => {
          handleClear();
          reset();
        }}
      >
        <RestartAltIcon />
      </Button>
    </Toolbar>
  );
};

export default Actions;
