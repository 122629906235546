import React, { ReactElement, Fragment } from 'react';
import { maxLength, BooleanInput } from 'react-admin';
import Box from '@mui/material/Box';
import CertTemplateCategoryInput from '../common/CertTemplateCategoryInput';
import AssignedWithCreateInput from '../common/Assigned/AssignedWithCreateInput';
import { ContactCategories } from '../common/contactInput/constants';
import ActivityTextInput from './components/ActivityTextInput';
import ContactInput from '../common/contactInput/ContactInput';
import ActivityPhaseInput from '../common/ActivityPhaseInput';
import DependentTextInput from '../common/DependentTextInput';
import CertTemplateInput from '../common/CertTemplateInput';
import { validateStatusFields } from '../common/validators';
import DisciplineInput from '../common/DisciplineInput';
import CustomDateInput from '../common/CustomDateInput';
import VerifiedField from './components/VerifiedField';
import ActivityField from './components/ActivityField';
import ComponentInput from '../common/ComponentInput';
import { RESOURCE_ACTIVITY_TYPE } from '../constants';
import commonStyles from '../common/commonStyles';
import AutocompleteInput from '../common/AutocompleteInput';
import TextInput from '../common/TextInput';
import { statuses } from './constants';
import CertDetailsSystemInput from './components/CertDetailsSystemInput';
import CertDetailsSubsystemInput from './components/CertDetailsSubsystemInput';

const CertDetailsForm: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <CertTemplateInput disabled sx={commonStyles.flexBoxItem} />
        <TextInput
          source="certTemplate.description"
          label="Description"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <CertTemplateCategoryInput
          source="certTemplate.category"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <AutocompleteInput
          label="Status"
          source="status"
          disabled
          sx={commonStyles.flexBoxItem}
          choices={statuses}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ActivityField
          label="Activity"
          disabled
          isInput
          sx={commonStyles.flexBoxItem}
        />
        <ActivityTextInput
          resource={RESOURCE_ACTIVITY_TYPE}
          source="activityTypeId"
          sortBy="activityType"
          label="Activity Type"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <ActivityTextInput
          source="activityConstraint"
          label="Activity Constraint"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <ActivityTextInput
          source="sequence"
          label="Activity Sequence"
          disabled
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ActivityTextInput
          source="plannedDate"
          label="Activity Planned Date"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <ActivityTextInput
          source="actualDate"
          label="Activity Actual Date"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <ActivityTextInput
          source="duration"
          label="Activity Duration"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <ActivityTextInput
          source="actualDuration"
          label="Activity Actual Duration"
          disabled
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput disabled sx={commonStyles.flexBoxItem} />
        <CertDetailsSystemInput disabled sx={commonStyles.flexBoxItem} />
        <CertDetailsSubsystemInput disabled sx={commonStyles.flexBoxItem} />
        <ActivityTextInput
          source="durationUnit"
          label="Activity Duration Unit"
          disabled
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ActivityPhaseInput
          disabled
          category="SYSTEM"
          sx={commonStyles.flexBoxItem}
        />
        <DisciplineInput disabled sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="isSmart"
          label="Smart"
          sx={commonStyles.flexBoxItem}
        />
        <AssignedWithCreateInput
          label="Responsible"
          sx={commonStyles.flexBoxLongItem}
          category={ContactCategories.certSignOff}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <VerifiedField sx={commonStyles.flexBoxItem} />
        <CustomDateInput
          label="Accepted Date"
          source="acceptedDate"
          validate={validateStatusFields('accepted')}
          sx={commonStyles.flexBoxItem}
        />
        <ContactInput
          category={ContactCategories.certSignOff}
          source="acceptedBy"
          label="Accepted By"
          validate={[validateStatusFields('accepted'), maxLength(75)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="isPrinted"
          label="Printed"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="printedDate"
          label="Printed Date"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <DependentTextInput
          source="printedBy"
          label="Printed By"
          validate={[maxLength(75)]}
          mainField="isPrinted"
          sx={commonStyles.flexBoxLongItem}
          fullWidth
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="certTotalSignatures"
          label="Certificate Total Signatures"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          source="certSigned"
          label="Certificate Signed"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="certSignedBy"
          label="Certificate Signed By"
          sx={commonStyles.flexBoxLongItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="generatedDate"
          label="Generated Date"
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="generatedBy"
          label="Generated By"
          disabled
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
    </Fragment>
  );
};

export default CertDetailsForm;
