import { useCallback, useState } from 'react';
import { Identifier, useDataProvider } from 'react-admin';

const useForceDelete = () => {
  const [deletingProgress, setDeletingProgress] = useState(false);
  const dataProvider = useDataProvider();
  const forceDelete = useCallback(
    async (resource: string, id: Identifier) => {
      setDeletingProgress(true);

      const result = await dataProvider.forceDeletePreservationItemActivity(
        resource,
        id
      );

      setDeletingProgress(false);

      return result;
    },
    [dataProvider]
  );

  return { deletingProgress, forceDelete };
};

export default useForceDelete;
