import React, { ReactElement } from 'react';
import { SelectField, SelectFieldProps } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { STRICT_CHOICES } from './constants';

interface StrictOptionFieldProps extends SelectFieldProps {
  source: string;
  label: string;
  sx?: SxProps<Theme>;
}

const StrictOptionField: React.FC<StrictOptionFieldProps> = ({
  source,
  label,
  sx,
}): ReactElement => (
  <SelectField source={source} label={label} sx={sx} choices={STRICT_CHOICES} />
);

export default StrictOptionField;
