import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

import { useRecordContext } from 'react-admin';
import commonStyles from '../../common/commonStyles';
import { isUserAdminLevel } from '../../common/helpers/checkSecurityLevel';
import { Audit } from '../types';
import RestoreButton from './RestoreButton';

const ColumnActions: React.FC = (): ReactElement => {
  const audit = useRecordContext<Audit>();
  return (
    <Box sx={commonStyles.columnActionsBox}>
      {isUserAdminLevel() && <RestoreButton audit={audit} />}
    </Box>
  );
};

export default ColumnActions;
