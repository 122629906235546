export const statuses = [
  { id: 'NONE', name: 'None' },
  { id: 'SUBMITTED', name: 'Submitted' },
  { id: 'APPROVED', name: 'Approved' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'APPROVED_PROCESSED', name: 'Approved Processed' },
];

export const editStatuses = [
  { id: 'NONE', name: 'None', disabled: true },
  { id: 'SUBMITTED', name: 'Submitted' },
  { id: 'APPROVED', name: 'Approved' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'APPROVED_PROCESSED', name: 'Approved Processed', disabled: true },
];

export const getFilterStatuses = (defaultValue: string[]) => [
  { id: defaultValue, name: 'All' },
  { id: 'SUBMITTED', name: 'Submitted' },
  { id: 'APPROVED', name: 'Approved' },
];
