import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_TEST_TYPE } from '../constants';
import { required } from '../../utils/UtilityFunctions';

interface TestTypeInputProps {
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  source?: string;
  label?: string;
  isRequired?: boolean;
  additionalFilters?: object;
  disabled?: boolean;
}

const TestTypeInput: React.FC<TestTypeInputProps> = ({
  multiple,
  label,
  sx,
  isRequired,
  additionalFilters,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { divisionId: ctx?.defaultDivisionId, ...additionalFilters },
      sort: { field: 'testType', order: 'ASC' as const },
    };
  }, [additionalFilters, ctx?.defaultDivisionId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_TEST_TYPE}
      requestParams={requestParams}
      label={label}
      name="testType_inc"
      optionText="testType"
      multiple={multiple}
      sx={sx}
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'testType']}
    />
  );
};

TestTypeInput.defaultProps = { source: 'testTypeId', label: 'Test Type' };

export default TestTypeInput;
