import React, { ReactElement } from 'react';
import { SelectField, SelectFieldProps } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { mobileTestStatuses } from '../constants';

interface MobileTestStatusFieldProps extends SelectFieldProps {
  source: string;
  label?: string;
  sx?: SxProps<Theme>;
}

const MobileTestStatusField: React.FC<MobileTestStatusFieldProps> = ({
  source,
  label,
  sx,
}): ReactElement => (
  <SelectField
    source={source}
    label={label}
    sx={sx}
    choices={mobileTestStatuses}
  />
);

export default MobileTestStatusField;
