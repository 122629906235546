import React, { ReactElement } from 'react';
import { SelectInput } from 'react-admin';
import { categoryChoices } from '../certTemplate/constants';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../utils/UtilityFunctions';

interface CertTemplateCategoryInputProps {
  isRequired?: boolean;
  fullWidth?: boolean;
  alwaysOn?: boolean;
  sx?: SxProps<Theme>;
  source?: string;
  disabled?: boolean;
  label?: string;
}

const CertTemplateCategoryInput: React.FC<CertTemplateCategoryInputProps> = ({
  isRequired,
  fullWidth,
  alwaysOn,
  disabled,
  source,
  label,
  sx,
}): ReactElement => (
  <SelectInput
    source={source}
    label={label}
    choices={categoryChoices}
    validate={isRequired ? [required()] : []}
    sx={sx}
    alwaysOn={alwaysOn}
    fullWidth={fullWidth}
    disabled={disabled}
  />
);

CertTemplateCategoryInput.defaultProps = {
  source: 'category',
  label: 'Category',
};

export default CertTemplateCategoryInput;
