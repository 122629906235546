import React, { ReactElement } from 'react';
import { AutocompleteInput } from 'react-admin';
import { ACTIVITY_PUNCH_STATUS_CHOICES } from './constants';
import { SelectInputMultipleProps } from './types';

const ActivityCertGatingPunchStatusInput: React.FC<
  SelectInputMultipleProps
> = ({ source, label, ...rest }): ReactElement => (
  <AutocompleteInput
    {...rest}
    source={source}
    label={label}
    choices={ACTIVITY_PUNCH_STATUS_CHOICES}
  />
);

ActivityCertGatingPunchStatusInput.defaultProps = {
  label: 'Punch Status',
  source: 'certGatingStatus->punch_status',
};

export default ActivityCertGatingPunchStatusInput;
