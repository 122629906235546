import React, { ReactElement } from 'react';
import { RESOURCE_PUNCH_PRIORITY } from '../../../constants';
import GatingArrayField from './GatingArrayField';

interface GatingPunchPrioritiesFieldProps {
  label?: string;
}

const GatingPunchPrioritiesField: React.FC<
  GatingPunchPrioritiesFieldProps
> = (): ReactElement => (
  <GatingArrayField
    source="tblCertGatingPunches"
    resource={RESOURCE_PUNCH_PRIORITY}
    recordName="punchPriority"
    itemNameResource="punchPriority.priority"
  />
);

GatingPunchPrioritiesField.defaultProps = { label: 'Punch Priorities' };

export default GatingPunchPrioritiesField;
