import {
  ValidationErrorMessageWithArgs,
  Validator,
} from 'ra-core/src/form/validate';

const isFunction = (value) => typeof value === 'function';

function isValidationErrorMessageWithArgs(
  error: ReturnType<Validator>
): error is ValidationErrorMessageWithArgs {
  return error ? error.hasOwnProperty('message') : false;
}

const combine2Validators = (
  validator1: Validator,
  validator2: Validator
): Validator => {
  return (value, values, meta) => {
    const result1 = validator1(value, values, meta);
    if (!result1) {
      return validator2(value, values, meta);
    }
    if (
      typeof result1 === 'string' ||
      isValidationErrorMessageWithArgs(result1)
    ) {
      return result1;
    }

    return result1.then((resolvedResult1) => {
      if (!resolvedResult1) {
        return validator2(value, values, meta);
      }
      return resolvedResult1;
    });
  };
};

export const composeValidators = (...validators) => {
  const allValidators = (
    Array.isArray(validators[0]) ? validators[0] : validators
  ).filter(isFunction) as Validator[];
  return allValidators.reduce(combine2Validators, () => null);
};
