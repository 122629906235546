import React, { Fragment, ReactElement } from 'react';
import { FunctionField } from 'react-admin';
import { Chip } from '@mui/material';
import { getJsonValue } from '../common/helpers/json';
import commonStyles from '../common/commonStyles';
import { actionChoices } from './constants';

interface ActionFieldProps {
  label?: string;
  isShowPage?: boolean;
}

const ActionField: React.FC<ActionFieldProps> = ({
  label,
  isShowPage,
}): ReactElement => (
  <FunctionField
    label={label ?? ''}
    render={(permission) => {
      let actions = getJsonValue(permission.action);
      actions =
        actions === '*'
          ? isShowPage
            ? actionChoices
                .filter((choice) => choice.id !== '*')
                .map((choice) => choice.name)
            : ['*']
          : actions;

      return (
        <Fragment>
          {actions.map((action, index) => (
            <Chip key={index} label={action} sx={commonStyles.chipMargin} />
          ))}
        </Fragment>
      );
    }}
  />
);

export default ActionField;
