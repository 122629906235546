import {
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_TEST,
} from '../constants';

export const checkRevisionTabbedFormIsNeeded = (resource: string): boolean =>
  [RESOURCE_TEST, RESOURCE_CERT_TEMPLATE, RESOURCE_PUNCH_TEMPLATE].includes(
    resource
  );
