import { useGetList } from 'react-admin';
import { RESOURCE_USER } from '../../constants';
import { getContext, UserContext } from '../../../provider/userContext';

const useUserList = () => {
  const ctx: UserContext = getContext();

  return useGetList(RESOURCE_USER, {
    filter: { projectId: ctx?.projectId },
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 1000 },
  });
};

export default useUserList;
