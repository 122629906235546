import React, { ReactElement, useCallback, useState } from 'react';
import {
  Button,
  FileField,
  SimpleForm,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import ImportToolbar from '../../common/ImportButton/components/ImportToolbar';
import useUpdateRevisionFile from '../../common/hooks/useUpdateRevisionFile';
import CustomFileInput from '../../common/CustomFileInput';
import { ATTACHMENT_TYPE_ENUMS } from '../../common/enums';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';

const UpdateRevisionFileButton: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(false);
  const { updateInProgress, updateRevisionFile } = useUpdateRevisionFile();
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        const formData = {
          id: record.id,
          attachmentType: ATTACHMENT_TYPE_ENUMS.GENERAL,
          file: data.file.rawFile,
        };
        await updateRevisionFile(RESOURCE_ATTACHMENT_REVISION, formData);
        await queryClient.invalidateQueries({
          queryKey: [RESOURCE_ATTACHMENT_REVISION],
        });
        notify('Successfully Updated Revision File.', {
          type: 'success',
          undoable: false,
        });

        closeModal();
      } catch (error) {
        notify('Update Revision File Error: ' + getErrorMessage(error), {
          type: 'error',
          undoable: false,
        });
      }
    },
    [record.id, updateRevisionFile, queryClient, notify, closeModal]
  );

  return (
    <div>
      <Button
        label={updateInProgress ? 'Updating...' : 'Update Revision File'}
        disabled={updateInProgress}
        onClick={openModal}
        size="medium"
        variant="contained"
        sx={{ marginTop: '10px' }}
      />
      <CreateDialog
        title={<span>Update Revision File</span>}
        fullWidth
        maxWidth="md"
        isOpen={isOpen}
        open={openModal}
        close={closeModal}
      >
        <SimpleForm
          title=""
          toolbar={<ImportToolbar onClose={closeModal} />}
          onSubmit={onSubmit}
        >
          <CustomFileInput source="import" name="file" label={false}>
            <FileField source="src" title="title" target="_blank" />
          </CustomFileInput>
        </SimpleForm>
      </CreateDialog>
    </div>
  );
};

export default UpdateRevisionFileButton;
