import React, { ReactElement } from 'react';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { CertTemplate } from './types';
import { EditPropsWithLocation } from '../common/types';
import MetaDataInputs from '../common/MetaDataInputs';
import CertTemplateForm from './components/CertTemplateForm';
import AttachmentRevisionFormTab from '../attachmentRevision/AttachmentRevisionFormTab';

const CertTemplateEdit: React.FC<EditPropsWithLocation<CertTemplate>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Certificate"
      id="cert_template_tab"
      sx={commonStyles.formTabHeader}
    >
      <CertTemplateForm />
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab
      label="Revisions"
      id="attachment_revision_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentRevisionFormTab targetId="certTemplateId" withoutProjectId />
    </LazyFormTab>
    <HistoryTab tableName="tbl_cert_template" />
  </TabbedEditForm>
);

export default CertTemplateEdit;
