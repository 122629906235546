import React, { ReactElement, useMemo } from 'react';
import commonStyles from '../commonStyles';
import { RESOURCE_VENDOR } from '../../constants';
import sideFilterStyles from '../sideFilterStyles';
import { getContext, UserContext } from '../../../provider/userContext';
import AutocompleteReferenceInput from '../AutocompleteReferenceInput';
import VendorCreateDialog from './VendorCreateDialog';
import { SxProps, Theme } from '@mui/material/styles';
import { Validator } from 'react-admin';

interface VendorInputProps {
  alwaysOn?: boolean;
  multiple?: boolean;
  source?: string;
  label?: string;
  defaultValue?: string;
  sx?: SxProps<Theme>;
  canCreate?: boolean;
  validate?: Validator | Validator[];
  disabled?: boolean;
}

const VendorInput: React.FC<VendorInputProps> = ({
  alwaysOn,
  multiple,
  canCreate,
  sx,
  validate,
  label,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'vendor', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_VENDOR}
      requestParams={requestParams}
      optionText="vendor"
      name="vendor_inc"
      label={label}
      create={canCreate && <VendorCreateDialog />}
      sx={
        sx ||
        (alwaysOn
          ? sideFilterStyles.sideFiltersSelectInput
          : commonStyles.flexBoxItem)
      }
      multiple={multiple}
      validate={validate}
      includeFields={['id', 'vendor']}
    />
  );
};

VendorInput.defaultProps = {
  source: 'vendorId',
  label: 'Responsible Vendor',
  canCreate: false,
};

export default VendorInput;
