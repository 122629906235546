import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { ReferenceManyField, TextField, NumberField } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_BOOK_SECTION_ENG_CODE } from '../../constants';
import { CreateForm } from './CreateForm';
import { EditForm } from './EditForm';
import { Box } from '@mui/material';
import commonStyles from '../../common/commonStyles';
import { ListComponentTitle } from '../components/ListComponentTitle';
import { EditableDatagridCreateButton } from '../components/EditableDatagridCreateButton';

export const BookSectionEngCodeListEdit: React.FC = (): ReactElement => {
  return (
    <Box sx={commonStyles.editableDataGrid}>
      <ListComponentTitle label="Engineering List" />
      <ReferenceManyField
        reference={RESOURCE_BOOK_SECTION_ENG_CODE}
        target="bookSectionId"
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'sortOrder', order: 'ASC' }}
      >
        <EditableDatagrid
          disableAutofocus
          bulkActionButtons={false}
          editForm={<EditForm />}
          createForm={<CreateForm />}
          empty={
            <EditableDatagridCreateButton label="Create Book Section Engineering Code" />
          }
        >
          <TextField
            source="engineeringCode"
            label="Eng. Code"
            sortable={false}
          />
          <NumberField source="sortOrder" sortable={false} />
        </EditableDatagrid>
      </ReferenceManyField>
    </Box>
  );
};
