import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import EquipmentTypeForm from './EquipmentTypeForm';
import { EquipmentType } from './types';

const EquipmentTypeCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: EquipmentType): EquipmentType => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm
        toolbar={<FooterToolbar />}
        defaultValues={{ hardSoft: 'Hard' }}
      >
        <EquipmentTypeForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default EquipmentTypeCreate;
