import React, { ReactElement, useCallback, useState } from 'react';
import {
  Button,
  FileField,
  SimpleForm,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RawAttachment } from '../../attachment/types';
import ImportToolbar from '../../common/ImportButton/components/ImportToolbar';
import CustomFileInput from '../../common/CustomFileInput';
import { ACCEPTED_FORMATS_JSON_ALL } from '../../common/constants';
import { RESOURCE_ATTACHMENT_REVISION } from '../../constants';

type RevisionDetailsJSONData = {
  file: RawAttachment;
};

const ImportJSONButton: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const { mutate: importRevisionDetailsJson, isPending: importInProgress } =
    useMutation({
      mutationFn: async (data: RevisionDetailsJSONData) => {
        return await dataProvider.importJsonForRevisionDetails(
          RESOURCE_ATTACHMENT_REVISION,
          {
            id: record.id,
            file: data.file.rawFile,
          }
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [RESOURCE_ATTACHMENT_REVISION],
        });
        notify('Successfully Imported Revision Details JSON.', {
          type: 'success',
          undoable: false,
        });

        closeModal();
      },
      onError: (error) => {
        notify(
          'Revision Details JSON Import Error: ' + getErrorMessage(error),
          {
            type: 'error',
            undoable: false,
          }
        );
      },
    });

  const onSubmit = useCallback(
    (data) => {
      importRevisionDetailsJson(data);
    },
    [importRevisionDetailsJson]
  );

  return (
    <div>
      <Button
        label={importInProgress ? 'Importing...' : 'Import Json'}
        disabled={importInProgress}
        onClick={openModal}
        size="medium"
        variant="contained"
        sx={{ marginTop: '10px' }}
      />
      <CreateDialog
        title={<span>Import JSON</span>}
        fullWidth
        maxWidth="md"
        isOpen={isOpen}
        open={openModal}
        close={closeModal}
      >
        <SimpleForm
          title=""
          toolbar={<ImportToolbar onClose={closeModal} />}
          onSubmit={onSubmit}
        >
          <CustomFileInput
            source="import"
            name="file"
            accept={ACCEPTED_FORMATS_JSON_ALL}
            label={false}
          >
            <FileField source="src" title="title" target="_blank" />
          </CustomFileInput>
        </SimpleForm>
      </CreateDialog>
    </div>
  );
};

export default ImportJSONButton;
