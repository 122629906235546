import React, { ReactElement } from 'react';
import { ChipField, ReferenceArrayField } from 'react-admin';
import { RESOURCE_DRAWING_TYPE } from '../constants';
import SmartSingleFieldList from './SmartSingleFieldList';

interface DrawingTypesFieldProps {
  label?: string;
  source?: string;
}

const DrawingTypesField: React.FC<DrawingTypesFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <ReferenceArrayField
    label={label}
    source={source}
    reference={RESOURCE_DRAWING_TYPE}
  >
    <SmartSingleFieldList>
      <ChipField source="drawingType" />
    </SmartSingleFieldList>
  </ReferenceArrayField>
);

DrawingTypesField.defaultProps = {
  label: 'Drawing Types',
  source: 'drawingTypes',
};

export default DrawingTypesField;
