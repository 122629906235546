import { GREEN, GREY, WHITE } from '../../../theme/colors';
import { MaterialReleasedStatus } from './types';
import { RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE } from '../../constants';

export const materialReleasedStatusLabels = {
  [MaterialReleasedStatus.GENERATED]: 'Generated',
  [MaterialReleasedStatus.RELEASED]: 'Released',
  [MaterialReleasedStatus.CANCELLED]: 'Cancelled',
};

export const statusChoices = [
  {
    id: MaterialReleasedStatus.GENERATED,
    name: materialReleasedStatusLabels[MaterialReleasedStatus.GENERATED],
  },
  {
    id: MaterialReleasedStatus.RELEASED,
    name: materialReleasedStatusLabels[MaterialReleasedStatus.RELEASED],
  },
  {
    id: MaterialReleasedStatus.CANCELLED,
    name: materialReleasedStatusLabels[MaterialReleasedStatus.CANCELLED],
  },
];

export const MATERIAL_RELEASED_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'material_released',
  },
  {
    label: 'Export All',
    resource: RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE,
    fileName: 'material_released_all',
    columns: [
      { source: 'id', label: 'ID' },
      { source: 'releasedOrderNo', label: 'Released Order No' },
      { source: 'status', label: 'Status' },
      { source: 'generatedDate', label: 'Generated Date' },
      { source: 'releasedDate', label: 'Released Date' },
      { source: 'material', label: 'Material' },
      { source: 'description', label: 'Description' },
      { source: 'type', label: 'Type' },
      { source: 'storageLocation', label: 'Storage Location' },
      { source: 'onHandQty', label: 'On Hand Qty' },
      { source: 'qtyToRelease', label: 'Qty to Release' },
      { source: 'releasedTo', label: 'Released To' },
      { source: 'comment', label: 'Comment' },
    ],
    includeAllColumns: true,
  },
];

export const legends = [
  {
    label: 'Status GENERATED',
    color: WHITE,
  },
  {
    label: 'Status RELEASED',
    color: GREEN,
  },
  {
    label: 'Status CANCELLED',
    color: GREY,
  },
];
