import React, { ReactElement } from 'react';
import { useGetIdentity } from 'ra-core';
import { TextField, TopToolbar } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import DeleteQrListButton from './components/DeleteQrListButton';
import QrScannerEdit from './QrScannerEdit';
import QrScannerShow from './QrScannerShow';
import QrUploadButton from './components/qrUpload/QrUploadButton';

const QrScannerList: React.FC = (): ReactElement => {
  const { identity } = useGetIdentity();
  const defaultFilter = useDefaultFilter('project', {
    updatedBy: identity?.email,
  });

  return (
    <ListWithTitle
      actions={
        <TopToolbar>
          <QrUploadButton />
        </TopToolbar>
      }
      filterDefaultValues={defaultFilter}
      sort={{ field: 'fileName', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<QrScannerShow withoutActions />}
        EditComponent={<QrScannerEdit withoutActions />}
        defaultVisibleColumns={[
          'id',
          'fileName',
          'qrs.itrs.length',
          'qrs.preservations.length',
          'qrs.certificates.length',
          'qrs.punches.length',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="fileName" label="File Name" />
        <TextField
          source="qrs.itrs.length"
          label="Tag ITRS Count"
          sortable={false}
        />
        <TextField
          source="qrs.preservations.length"
          label="Preservation Count"
          sortable={false}
        />
        <TextField
          source="qrs.certificates.length"
          label="Certificate Count"
          sortable={false}
        />
        <TextField
          source="qrs.punches.length"
          label="Punch Count"
          sortable={false}
        />
        <TextField source="userName" label="User Name" />
        <ColumnActions
          label="Actions"
          customDeleteButton={<DeleteQrListButton />}
        />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default QrScannerList;
