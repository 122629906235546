import axios from 'axios';
import { CRUD_API_URL, getRequestConfig } from '../../api';

export interface CompanyInfo {
  name?: string;
  projectLimit?: number;
  isActive?: boolean;
  userLimit?: number;
  longName?: string;
  subscriptions?: Array<CompanySubscriptionInfo>;
}

interface CompanySubscriptionInfo {
  subscriptionStartedAt?: string;
  subscriptionStatus?: string;
  subscriptionTrialEnd?: string;
}

const companyDataProvider = {
  getCompanyInfo: () =>
    axios.get(`${CRUD_API_URL}/company/info`, getRequestConfig()),
};

export default companyDataProvider;
