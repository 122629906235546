import React, { ReactElement } from 'react';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { Report } from './type';
import { EditPropsWithLocation } from '../common/types';
import ReportForm from './ReportForm';

const ReportEdit: React.FC<EditPropsWithLocation<Report>> = (
  props
): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab label="Report" id="report_tab" sx={commonStyles.formTabHeader}>
      <ReportForm editMode />
    </LazyFormTab>
    <HistoryTab tableName="tbl_report" />
  </TabbedEditForm>
);

export default ReportEdit;
