import RootMenuItem from './RootMenuItem';
import { MenuItemClasses } from '@react-admin/ra-navigation';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { cloneElement, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItemProps } from './constants';
import ChildIconMenuItems from './ChildIconMenuItems';

const IconMenuItemList: React.FC<MenuItemProps> = ({
  menuItem,
  menuName,
}): ReactElement => {
  return (
    <RootMenuItem key={menuItem.label}>
      <ListItem className={MenuItemClasses.container}>
        <ListItemButton
          className={MenuItemClasses.itemButton}
          component={menuItem.to ? NavLink : 'div'}
          to={menuItem.to ? menuItem.to : ''}
        >
          <span className={MenuItemClasses.link}>
            {menuItem.icon && (
              <ListItemIcon className={MenuItemClasses.menuIcon}>
                {cloneElement(menuItem.icon, {
                  titleAccess: menuItem.label,
                })}
              </ListItemIcon>
            )}
            <ListItemText>{menuItem.label}</ListItemText>
          </span>
        </ListItemButton>
      </ListItem>
      <List className={MenuItemClasses.nestedList}>
        <ChildIconMenuItems menuItems={menuItem.items} menuName={menuName} />
      </List>
    </RootMenuItem>
  );
};

export default IconMenuItemList;
