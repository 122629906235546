import React from 'react';
import { ImportValidation } from '../types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

interface ImportResultsErrorsProps {
  importValidation: Array<ImportValidation>;
}

const ImportResultsErrors: React.FC<ImportResultsErrorsProps> = ({
  importValidation,
}) => {
  if (importValidation.length === 0) {
    return null;
  }

  return (
    <Box paddingTop={2}>
      <Box>Errors during import:</Box>
      <Stack spacing={1} paddingTop={2}>
        {importValidation.map((error, index) => (
          <Stack
            key={`${error.cellAddress}_${index}`}
            direction="row"
            spacing={1}
          >
            {error.cellAddress && (
              <Box width={60}>Cell {error.cellAddress}:</Box>
            )}
            <Box>{error.message}</Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default ImportResultsErrors;
