import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import { EditRowButton } from '@react-admin/ra-editable-datagrid';
import Box from '@mui/material/Box';
import commonStyles from '../common/commonStyles';
import DeleteWithConfirmIconButton from '../common/DeleteWithConfirmIconButton';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import { checkDeleteIsAllowed } from './helpers';
import { CertificateDetails } from './types';

const CertDetailsActions: React.FC = (): ReactElement => {
  const record = useRecordContext() as CertificateDetails;

  return (
    <Box sx={commonStyles.columnActionsBox}>
      <PreviewButton />
      <DownloadButton />
      <EditRowButton />
      {checkDeleteIsAllowed(record) ? <DeleteWithConfirmIconButton /> : null}
    </Box>
  );
};

export default CertDetailsActions;
