import React, { ReactElement, useMemo } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import TextInput from '../common/TextInput';
import ProjectInputWithGroup from '../common/ProjectInputWithGroup';
import { EditPropsWithLocation } from '../common/types';
import commonStyles from '../common/commonStyles';
import GroupUsersTab from './components/GroupUsersTab';
import { UserGroup } from './types';

const UserGroupEdit: React.FC<EditPropsWithLocation<UserGroup>> = (
  props
): ReactElement => {
  const ctx: UserContext = getContext();
  const projectInputRequestParams = useMemo(() => {
    return {
      filter: { divisionId: ctx?.defaultDivisionId },
      sort: { field: 'project', order: 'ASC' as const },
    };
  }, [ctx?.defaultDivisionId]);

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="User Group"
        id="user_group_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="userGroup"
            label="User Group"
            validate={[required(), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={maxLength(255)}
            sx={commonStyles.flexBoxItem}
            fullWidth
          />
          <ProjectInputWithGroup
            sx={commonStyles.flexBoxItem}
            isRequired
            requestParams={projectInputRequestParams}
            includeFields={['id', 'project', 'isActive']}
            getOptionDisabled={(option) => !option.isActive}
          />
        </Box>
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab label="Users" id="users_tab" sx={commonStyles.formTabHeader}>
        <GroupUsersTab />
      </LazyFormTab>
      <HistoryTab tableName="tbl_user_group" />
    </TabbedEditForm>
  );
};

export default UserGroupEdit;
