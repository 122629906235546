export const LUCID_APP_URL = 'https://lucid.app';

export const lucidDocumentEditUrl = (documentId: string) =>
  `${LUCID_APP_URL}/lucidchart/${documentId}/edit`;

export const lucidDocumentViewUrl = (documentId: string) =>
  `${LUCID_APP_URL}/documents/embedded/${documentId}`;

export const lucidDocumentPickerUrl = (embedToken: string) =>
  `${LUCID_APP_URL}/embeds?token=${embedToken}`;

export const lucidGrandAccessUrl = (clientId: string, redirectUrl: string) =>
  `${LUCID_APP_URL}/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=lucidchart.document.app.picker.share.embed`;
