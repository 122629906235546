import React, { ReactElement } from 'react';
import { Identifier, NumberInput, useStore } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_BOOK_SECTION_TEST } from '../../constants';
import { useGetMaxSortOrder } from '../useGetMaxSortOrder';
import { BookSectionTest } from '../type';
import { bookSectionIdStore } from '../constants';
import { SelectTestInput } from './SelectTestInput';

export const CreateForm: React.FC = (): ReactElement => {
  const [bookSectionId] = useStore<Identifier>(bookSectionIdStore, null);
  const maxSortOrder = useGetMaxSortOrder(RESOURCE_BOOK_SECTION_TEST);

  const transformCreate = (
    data: BookSectionTest
  ): Omit<BookSectionTest, 'id'> => {
    return {
      ...data,
      bookSectionId,
      sortOrder: data.sortOrder || maxSortOrder + 1,
    };
  };

  return (
    <RowForm transform={transformCreate}>
      <SelectTestInput />
      <NumberInput source="sortOrder" sx={commonStyles.flexBoxItem} />
    </RowForm>
  );
};
