import React, { ReactElement, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

interface SideFilterBoxProps extends BoxProps {
  alwaysOn?: boolean;
  source?: string;
  sx?: SxProps<Theme>;
  children: ReactNode;
}

const SideFilterBox: React.FC<SideFilterBoxProps> = ({
  children,
  sx,
}): ReactElement => <Box sx={sx}>{children}</Box>;

export default SideFilterBox;
