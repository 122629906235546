const DICTIONARY_CONTEXT_KEY = 'dictionaryContextV1';

interface DictionaryField {
  label: string;
  helperText?: string;
}

export type DictionaryContext = Record<string, Record<string, DictionaryField>>;

let cacheContext: DictionaryContext = undefined;

export const getDictionaryContext = (): DictionaryContext | undefined => {
  if (cacheContext) return cacheContext;

  try {
    const contextStr = localStorage.getItem(DICTIONARY_CONTEXT_KEY);
    const ctx = contextStr
      ? JSON.parse(localStorage.getItem(DICTIONARY_CONTEXT_KEY))
      : undefined;

    if (ctx) {
      cacheContext = ctx as DictionaryContext;
      return ctx;
    }
    // eslint-disable-next-line no-empty
  } catch (_) {
    cacheContext = undefined;
  }

  return undefined;
};

export const setDictionaryContext = (ctx: DictionaryContext) => {
  if (ctx) {
    localStorage.setItem(DICTIONARY_CONTEXT_KEY, JSON.stringify(ctx));
  } else {
    localStorage.removeItem(DICTIONARY_CONTEXT_KEY);
  }

  cacheContext = undefined;
};
