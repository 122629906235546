import { PreservationItemType } from './types';

export const validateEndDate = (
  endDateValue: string,
  allValues: PreservationItemType
): string | null => {
  const startDate = new Date(allValues.startDate);
  const endDate = new Date(endDateValue);

  if (endDateValue && endDate < startDate) {
    return 'End Date should not be earlier than Start Date';
  }

  return null;
};

export const validatePlusDate = (
  plusDateValue: number,
  allValues: PreservationItemType
): string | null => {
  if (
    plusDateValue &&
    allValues.frequency &&
    plusDateValue > allValues.frequency
  ) {
    return 'Plus Date should not be greater than Frequency';
  }

  return null;
};
