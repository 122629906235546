import React, { ReactElement } from 'react';
import { SimpleForm, CreateProps } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import ComponentActivityForm from './components/ComponentActivityForm';

const ComponentActivityCreate: React.FC<CreateProps> = (
  props
): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data) => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle {...props} redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <ComponentActivityForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default ComponentActivityCreate;
