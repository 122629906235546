import React, { ReactElement } from 'react';
import { useRecordContext, TextFieldProps } from 'react-admin';
import StyledDiffDataString from './StyledDiffDataString';

const DifferenceField: React.FC<TextFieldProps> = (props): ReactElement => {
  const record = useRecordContext(props);
  const hiddenProperties = [
    'stepActions',
    'updatedAt',
    'updatedBy',
    'updatedByName',
  ];

  const parseDifferenceData = (data) => {
    if (Array.isArray(data)) {
      return data.map((item) => {
        if (
          hiddenProperties.find(
            (property) =>
              property === item.path[0] || property === item.path?.[1]
          )
        ) {
          return;
        }

        if (item?.value && typeof item.value === 'object') {
          return Object.keys(item.value).map((path) => {
            if (hiddenProperties.find((property) => property === path)) {
              return;
            }
            return StyledDiffDataString(
              item.op,
              item.path[0] + ` ${path}`,
              item.value[path]
            );
          });
        }

        return StyledDiffDataString(
          item.op,
          item.path[0] + (item.path?.[1] ? ` ${item.path[1]}` : ''),
          item.value
        );
      });
    }

    return null;
  };

  return record ? <>{parseDifferenceData(record.diff)}</> : null;
};

export default DifferenceField;
