import React, { Fragment, useState, useCallback, ReactElement } from 'react';
import { ImageField, useRecordContext } from 'react-admin';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import commonStyles from './commonStyles';
import { FieldPropsWithOptionalSource } from './types';

interface FileFieldProps extends FieldPropsWithOptionalSource {
  width?: number;
  height?: number;
}

const FileField: React.FC<FileFieldProps> = ({
  source,
  width,
  height,
}): ReactElement => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = useCallback(() => setShowDialog(true), []);
  const closeDialog = useCallback(() => setShowDialog(false), []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let src: any;

  if (source) {
    src = record?.[source]?.downloadUrl ? `[${source}].downloadUrl` : null;
  } else {
    src = record?.downloadUrl && !record?.src ? '[downloadUrl]' : '[src]';
  }

  return (
    <Fragment>
      {src && (
        <Fragment>
          <span onClick={openDialog}>
            <ImageField
              record={record}
              source={src}
              sx={{
                '& img.RaImageField-image': {
                  width: width,
                  height: height,
                  objectFit: 'contain',
                },
              }}
            />
          </span>

          <Dialog
            open={showDialog}
            onClose={closeDialog}
            PaperProps={{ sx: { maxWidth: '920px', padding: '10px' } }}
          >
            <IconButton
              onClick={closeDialog}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <ImageField
                record={record}
                source={src}
                label={false}
                sx={commonStyles.previewImageField}
              />
            </DialogContent>
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
};

FileField.defaultProps = {
  width: 140,
  height: 70,
};

export default FileField;
