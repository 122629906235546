import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import sideFilterStyles from '../../../../common/sideFilterStyles';

interface PdfViewProps {
  url: string;
}

const PdfView: React.FC<PdfViewProps> = ({ url }): ReactElement => {
  return (
    <Box sx={sideFilterStyles.sideEditCard}>
      <PDFViewer
        url={url}
        tools={[
          'pager',
          'spacer',
          'zoomInOut',
          'zoom',
          'selection',
          'spacer',
          'search',
        ]}
        defaultZoom={1}
        style={{ width: 750, height: '75vh' }}
      />
    </Box>
  );
};

export default PdfView;
