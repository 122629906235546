import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../../../common/AutocompleteReferenceInput';
import { getContext, UserContext } from '../../../../../provider/userContext';
import { RESOURCE_CERT_TEMPLATE } from '../../../../constants';
import { required } from '../../../../../utils/UtilityFunctions';

interface CertTemplateInputProps {
  isRequired?: boolean;
  sx?: object;
  label?: string;
  source?: string;
  additionalFilters?: object;
}

const CertTemplateInput: React.FC<CertTemplateInputProps> = ({
  isRequired,
  sx,
  label,
  source,
  additionalFilters,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        division_id: ctx?.defaultDivisionId,
        ...additionalFilters,
      },
      sort: { field: 'certName', order: 'ASC' as const },
    };
  }, [additionalFilters, ctx?.defaultDivisionId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_CERT_TEMPLATE}
      requestParams={requestParams}
      source={source}
      label={label}
      name="certName_inc"
      optionText="certName"
      validate={isRequired ? [required()] : []}
      sx={sx}
      includeFields={['id', 'certName']}
      additionalParams={{ skipListAdditionalData: true }}
    />
  );
};

CertTemplateInput.defaultProps = {
  label: 'Certificate',
  source: 'predCertTemplateId',
};

export default CertTemplateInput;
