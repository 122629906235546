import React, { ReactElement } from 'react';
import { checkJson } from '../common/helpers/json';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { EditPropsWithLocation } from '../common/types';
import SecurityRolePermissionForm from './SecurityRolePermissionForm';
import { convertRoleAction } from './helpers';

const SecurityRolePermissionEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  const transform = (data) => ({
    ...data,
    action: checkJson(data.action)
      ? data.action
      : convertRoleAction(data.action),
    record: checkJson(data.record) ? data.record : JSON.stringify(data.record),
  });

  return (
    <TabbedEditForm {...props} transform={transform}>
      <LazyFormTab
        label="Role Permission"
        id="security_role_permission_tab"
        sx={commonStyles.formTabHeader}
      >
        <SecurityRolePermissionForm />
        <MetaDataInputs />
      </LazyFormTab>
      <HistoryTab tableName="tbl_security_role_permission" />
    </TabbedEditForm>
  );
};

export default SecurityRolePermissionEdit;
