import React, {
  Fragment,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useListContext, useStore } from 'react-admin';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getExportParams from './helpers/getExportParams';
import useColumnsToShow from './hooks/useColumnsToShow';
import useGetLookupFilter from './hooks/useGetLookupFilter';
import useExport from './hooks/useExport';
import { AvailableColumn, MultipleExportButtonInfo } from './types';
import {
  DEFAULT_MULTIPLE_EXPORT_BUTTONS,
  META_DATA_FIELDS_COUNT,
} from './constants';

interface MultipleExportButtonProps {
  buttonsInfo?: MultipleExportButtonInfo[];
  preferenceKey?: string;
}

export const MultipleExportButton: React.FC<MultipleExportButtonProps> = ({
  buttonsInfo = DEFAULT_MULTIPLE_EXPORT_BUTTONS,
  preferenceKey,
}): ReactElement => {
  const { exportInProgress, exportFile } = useExport();
  const { resource, sort, filterValues } = useListContext();
  const resourceKey = preferenceKey ?? resource + '.datagrid';
  const [availableColumns] = useStore<AvailableColumn[]>(
    `preferences.${resourceKey}.availableColumns`,
    []
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const lookupFilter = useGetLookupFilter();
  const selectedColumns = useColumnsToShow(resourceKey);

  const handleExportButtonClick = useCallback(() => {
    // This condition is to check for export additional columns.
    // If selectedMenuIndex is zero, this means default export.
    if (buttonsInfo && selectedMenuIndex) {
      // Get last three meta columns from export available columns.
      const metaDataColumns = availableColumns.slice(
        availableColumns.length - (META_DATA_FIELDS_COUNT + 1),
        availableColumns.length
      );
      // Splice those meta columns from available columns.
      availableColumns.splice(
        availableColumns.length - (META_DATA_FIELDS_COUNT + 1),
        META_DATA_FIELDS_COUNT + 1
      );
      // This condition is to check for export all.
      // If selectedMenuIndex is buttonsInfo.length - 1, this means export all.
      // Add additional columns to export available columns with this condition.
      if (selectedMenuIndex < buttonsInfo.length - 1) {
        if (buttonsInfo[selectedMenuIndex].columns) {
          availableColumns.push(...buttonsInfo[selectedMenuIndex].columns);
          selectedColumns.push(
            ...buttonsInfo[selectedMenuIndex].columns.map((col) => col.source)
          );
        }
      } else {
        buttonsInfo.forEach((column) => {
          if (column.columns) {
            availableColumns.push(...column.columns);
            selectedColumns.push(
              ...column.columns.map((col) => col.source || '')
            );
          }
        });
      }
      // Attach meta columns to export columns.
      availableColumns.push(...metaDataColumns);
      // Ordering export columns.
      availableColumns.forEach((column, idx) => {
        column.index = idx.toString();
      });
    }

    exportFile(
      buttonsInfo[selectedMenuIndex]?.resource || resource,
      getExportParams(
        availableColumns,
        buttonsInfo[selectedMenuIndex]?.includeAllColumns
          ? availableColumns.map((x) => x.source)
          : selectedColumns,
        filterValues,
        sort,
        lookupFilter
      ),
      buttonsInfo[selectedMenuIndex].fileName
    );
  }, [
    buttonsInfo,
    selectedMenuIndex,
    exportFile,
    resource,
    availableColumns,
    selectedColumns,
    filterValues,
    sort,
    lookupFilter,
  ]);

  const handleExportMenuItemClick = useCallback(
    (_: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedMenuIndex(index);
      setMenuOpen(false);
    },
    []
  );

  const handleMenuToggle = useCallback(() => {
    setMenuOpen((prevOpen) => !prevOpen);
  }, []);

  const handleMenuClose = useCallback((event: Event) => {
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  }, []);

  return (
    <Fragment>
      <ButtonGroup
        variant="outlined"
        ref={menuAnchorRef}
        aria-label="export buttons"
      >
        <Button
          style={{ borderWidth: 2 }}
          disabled={exportInProgress}
          onClick={handleExportButtonClick}
          className="exportButton"
        >
          {exportInProgress
            ? 'Exporting...'
            : `${buttonsInfo[selectedMenuIndex].label}`}
        </Button>
        {!buttonsInfo[selectedMenuIndex]?.singleButton && (
          <Button
            style={{ borderWidth: 2, marginLeft: -2 }}
            size="small"
            aria-controls={menuOpen ? 'export-button-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-label="select export method"
            aria-haspopup="menu"
            onClick={handleMenuToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1000,
        }}
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="export-button-menu" autoFocusItem>
                  {buttonsInfo.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedMenuIndex}
                      onClick={(event) =>
                        handleExportMenuItemClick(event, index)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default MultipleExportButton;
