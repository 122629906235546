import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import sideFilterStyles from '../common/sideFilterStyles';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import { SideFilter } from '../common/types';
import { RESOURCE_PRESERVATION_SERIES } from '../constants';
import DateRangeFilter from '../common/DateRangeFilter';
import ComponentInput from '../common/ComponentInput';
import TestInput from '../common/TestInput';
import LocationInput from '../common/LocationInput';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import TextInput from '../common/TextInput';
import { getContext, UserContext } from '../../provider/userContext';

const PreservationSeriesSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_PRESERVATION_SERIES, defaultFilter);
  const { labels } = useDictionaryLabelsWithResource();
  const ctx: UserContext = getContext();

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <ComponentInput
            source="preservationItem->component->id"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['componentId'] || 'Tag'}
          />
          <TestInput
            source="preservationItem->test->id"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['testId'] || 'Test'}
            name="testFormNo_inc"
            optionText="testFormNo"
            requestParams={{
              filter: {
                is_preservation: true,
                divisionId: ctx?.defaultDivisionId,
              },
              sort: { field: 'testFormNo', order: 'ASC' },
            }}
          />
          <LocationInput
            source="preservationItem->location->id"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
            label={labels['locationId'] || 'Location'}
          />
          <DateRangeFilter
            source="dueDate"
            label={labels['dueDate'] || 'Due Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="lateDate"
            label={labels['lateDate'] || 'Late Date'}
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <DateRangeFilter
            source="updatedAt"
            label="Updated"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            source="updatedBy"
            label="Updated By"
            name="updatedBy_inc"
            fullWidth
            alwaysOn
          />
          <TextInput
            source="updatedByName"
            label="Updated By Name"
            name="updatedByName_inc"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default PreservationSeriesSidebarFilters;
