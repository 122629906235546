import { MarkdownInput, MarkdownInputProps } from '@react-admin/ra-markdown';
import React, { ReactElement } from 'react';

const CustomMarkdownInput: React.FC<MarkdownInputProps> = (
  props
): ReactElement => {
  return (
    <MarkdownInput
      {...props}
      hideModeSwitch={true}
      toolbarItems={[
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['table', 'link'],
        ['code', 'codeblock'],
        ['scrollSync'],
      ]}
      height="300px"
    />
  );
};

export default CustomMarkdownInput;
