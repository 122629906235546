import { useCallback, useEffect, useState } from 'react';
import { useListContext, useStore } from 'react-admin';
import dayjs from 'dayjs';
import { PRICE_FIELDS } from '../constants';
import { getFiltersWithoutRange, makeDateFilter } from '../helpers/dateFilter';
import { isNumericString, makeNumberFilter } from '../helpers/numberFilter';

const useSidebarFilterEffect = (
  setFilters,
  displayedFilters,
  setDateFrom,
  setDateTo,
  filters,
  dateFrom,
  dateTo,
  setNumberFrom,
  setNumberTo,
  numberFrom,
  numberTo
) => {
  useEffect(() => {
    if (filters) {
      const filterFromURL = filters.filter || {};
      setFilters(filterFromURL, displayedFilters);

      const parsedDateFrom = { ...dateFrom };
      const parsedDateTo = { ...dateTo };

      const parsedNumberFrom = { ...numberFrom };
      const parsedNumberTo = { ...numberTo };

      if (Object.keys(filterFromURL).length === 0) {
        setDateFrom({});
        setDateTo({});
      } else {
        for (const key in filterFromURL) {
          let ratio = 1;
          PRICE_FIELDS.forEach((field) => {
            if (key.startsWith(field)) {
              ratio = 100;
            }
          });

          if (key.endsWith('_between')) {
            const valueFrom = filterFromURL[key][0].slice(0, 10);
            const valueTo = filterFromURL[key][1].slice(0, 10);

            // check if this is number or date range filter
            if (isNumericString(valueFrom) && isNumericString(valueTo)) {
              parsedNumberFrom[key.replace('_between', '')] = valueFrom / ratio;
              parsedNumberTo[key.replace('_between', '')] = valueTo / ratio;
            } else {
              parsedDateFrom[key.replace('_between', '')] = dayjs(valueFrom);
              parsedDateTo[key.replace('_between', '')] = dayjs(valueTo);
            }
          } else {
            if (key.endsWith('_gte')) {
              const gteValue = filterFromURL[key].slice(0, 10);
              if (isNumericString(gteValue)) {
                parsedNumberFrom[key.replace('_gte', '')] = gteValue / ratio;
              } else {
                parsedDateFrom[key.replace('_gte', '')] = dayjs(gteValue);
              }
            }
            if (key.endsWith('_lte')) {
              const lteValue = filterFromURL[key].slice(0, 10);
              if (isNumericString(lteValue)) {
                parsedNumberTo[key.replace('_lte', '')] = lteValue / ratio;
              } else {
                parsedDateTo[key.replace('_lte', '')] = dayjs(lteValue);
              }
            }
          }
        }

        setDateFrom(parsedDateFrom);
        setDateTo(parsedDateTo);

        setNumberFrom(parsedNumberFrom);
        setNumberTo(parsedNumberTo);
      }
    } else {
      setDateFrom({});
      setDateTo({});

      setNumberFrom({});
      setNumberTo({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useSetSidebarDateFilter = (
  filterValues,
  setFilters,
  displayedFilters
) => {
  return useCallback(
    (dateFromValue, dateToValue, type) => {
      const filtersObj = getFiltersWithoutRange(filterValues, type);
      if (
        (dateFromValue && !dateFromValue.isValid()) ||
        (dateToValue && !dateToValue.isValid()) ||
        (dateFromValue &&
          dateFromValue.isValid() &&
          dateToValue &&
          dateToValue.isValid() &&
          dateFromValue > dateToValue)
      ) {
        return;
      } else {
        setFilters(
          {
            ...filtersObj,
            ...makeDateFilter(dateFromValue, dateToValue, type),
          },
          displayedFilters
        );
      }
    },
    [displayedFilters, filterValues, setFilters]
  );
};

const useRangeFromChange = (from, to, setFrom, setRangeFilters) => {
  return useCallback(
    (value, type) => {
      setFrom({ ...from, [type]: value });
      setRangeFilters(value, to[type], type);
    },
    [from, to, setFrom, setRangeFilters]
  );
};

const useRangeToChange = (from, to, setTo, setRangeFilters) => {
  return useCallback(
    (value, type) => {
      setTo({ ...to, [type]: value });
      setRangeFilters(from[type], value, type);
    },
    [from, to, setTo, setRangeFilters]
  );
};

const useSetSidebarNumberFilter = (
  filterValues,
  setFilters,
  displayedFilters
) => {
  return useCallback(
    (numberFromValue, numberToValue, type) => {
      const filtersObj = getFiltersWithoutRange(filterValues, type);
      if (
        (numberFromValue && isNaN(numberFromValue)) ||
        (numberToValue && isNaN(numberToValue)) ||
        (numberFromValue &&
          isNaN(numberFromValue) &&
          numberToValue &&
          isNaN(numberToValue) &&
          numberFromValue > numberToValue)
      ) {
        return;
      } else {
        setFilters(
          {
            ...filtersObj,
            ...makeNumberFilter(numberFromValue, numberToValue, type),
          },
          displayedFilters
        );
      }
    },
    [displayedFilters, filterValues, setFilters]
  );
};

const useClearFilters = ({
  setDateFrom,
  setDateTo,
  setNumberFrom,
  setNumberTo,
  setFilters,
  displayedFilters,
  defaultFilter,
}) => {
  return useCallback(() => {
    setDateFrom({});
    setDateTo({});
    setNumberFrom({});
    setNumberTo({});
    setFilters(defaultFilter, displayedFilters);
  }, [
    setDateFrom,
    setDateTo,
    setNumberFrom,
    setNumberTo,
    setFilters,
    displayedFilters,
    defaultFilter,
  ]);
};

const useSidebarFilters = (resource: string, defaultFilter: object) => {
  const [filters] = useStore(`${resource}.listParams`);
  const { data, isLoading, filterValues, setFilters, displayedFilters } =
    useListContext();
  const [dateFrom, setDateFrom] = useState({});
  const [dateTo, setDateTo] = useState({});
  const [numberFrom, setNumberFrom] = useState({});
  const [numberTo, setNumberTo] = useState({});

  useSidebarFilterEffect(
    setFilters,
    displayedFilters,
    setDateFrom,
    setDateTo,
    filters,
    dateFrom,
    dateTo,
    setNumberFrom,
    setNumberTo,
    numberFrom,
    numberTo
  );

  const setDateFilters = useSetSidebarDateFilter(
    filterValues,
    setFilters,
    displayedFilters
  );

  const handleDateFromChange = useRangeFromChange(
    dateFrom,
    dateTo,
    setDateFrom,
    setDateFilters
  );

  const handleDateToChange = useRangeToChange(
    dateFrom,
    dateTo,
    setDateTo,
    setDateFilters
  );

  const setNumberFilters = useSetSidebarNumberFilter(
    filterValues,
    setFilters,
    displayedFilters
  );

  const handleNumberFromChange = useRangeFromChange(
    numberFrom,
    numberTo,
    setNumberFrom,
    setNumberFilters
  );

  const handleNumberToChange = useRangeToChange(
    numberFrom,
    numberTo,
    setNumberTo,
    setNumberFilters
  );

  const handleClearFilters = useClearFilters({
    setDateFrom,
    setDateTo,
    setNumberFrom,
    setNumberTo,
    setFilters,
    displayedFilters,
    defaultFilter,
  });

  return {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    setFilters,
    displayedFilters,
    handleClearFilters,
    numberFrom,
    numberTo,
    handleNumberFromChange,
    handleNumberToChange,
  };
};

export default useSidebarFilters;
