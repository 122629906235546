import { useMemo } from 'react';
import { useStore } from 'react-admin';

const useColumnsToShow = (resourceKey) => {
  const [availableColumns] = useStore(
    `preferences.${resourceKey}.availableColumns`,
    []
  );
  const [selectedColumns] = useStore(`preferences.${resourceKey}.columns`, []);
  const [omitColumns] = useStore(`preferences.${resourceKey}.omit`, []);

  return useMemo(() => {
    return availableColumns
      .filter((column) => {
        if (selectedColumns.length) {
          return (
            selectedColumns.includes(column?.index) ||
            selectedColumns.includes(column.source)
          );
        }

        return !(
          omitColumns.includes(column?.index) ||
          omitColumns.includes(column.source)
        );
      })
      .map((column) => column.source || column.index);
  }, [availableColumns, selectedColumns, omitColumns]);
};

export default useColumnsToShow;
