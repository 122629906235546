export const styles = {
  iframeWrapper: {
    position: 'relative',
    flex: 1,
    iframe: {
      width: '100%',
      height: '480px',
      border: 'none',
    },
  },
  iframeLoader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
  modalFormWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    flexWrap: 'wrap',
  },
  modalButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: 2,
  },
};
