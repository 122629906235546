import React, { useCallback, useState } from 'react';
import {
  Button,
  FileField,
  SimpleForm,
  useNotify,
  useResourceContext,
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { getContext, UserContext } from '../../../provider/userContext';
import { getErrorMessage, getTimeZone } from '../../../utils/UtilityFunctions';
import useImport from '../hooks/useImport';
import useResourceTitle from '../hooks/useResourceTitle';
import CustomFileInput from '../CustomFileInput';
import commonStyles from '../commonStyles';
import ImportToolbar from './components/ImportToolbar';

interface ImportButtonProps {
  source?: string;
  label?: string;
}

const ImportButton: React.FC<ImportButtonProps> = ({
  source,
  label = 'Import',
}) => {
  const title = useResourceTitle(true);
  const context: UserContext = getContext();
  const resourceFromContext = useResourceContext();
  const resource = source ? source : resourceFromContext;
  const { importFile, importInProgress } = useImport();
  const [isOpen, setIsOpen] = useState(false);
  const notify = useNotify();

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await importFile(resource, {
          projectId: context.projectId,
          divisionId: context.defaultDivisionId,
          timeZone: getTimeZone(),
          file: data.file.rawFile,
        });

        notify(
          'The import file has been uploaded. You will receive a notification once the import process is complete.',
          {
            type: 'success',
            undoable: false,
          }
        );

        closeModal();
      } catch (error) {
        notify('Import error: ' + getErrorMessage(error), {
          type: 'error',
          undoable: false,
        });
      }
    },
    [
      importFile,
      resource,
      context.projectId,
      context.defaultDivisionId,
      notify,
      closeModal,
    ]
  );

  return (
    <div>
      <Button
        label={importInProgress ? 'Importing...' : label}
        disabled={importInProgress}
        onClick={openModal}
        className="importButton"
        sx={commonStyles.smResponsiveButton}
      >
        <span>{importInProgress ? 'Importing...' : label}</span>
      </Button>
      <CreateDialog
        title={<span>Import {title}</span>}
        fullWidth
        maxWidth="md"
        isOpen={isOpen}
        open={openModal}
        close={closeModal}
      >
        <SimpleForm
          title=""
          toolbar={<ImportToolbar onClose={closeModal} />}
          onSubmit={onSubmit}
        >
          <CustomFileInput source="import" name="file" label={false}>
            <FileField source="src" title="title" target="_blank" />
          </CustomFileInput>
        </SimpleForm>
      </CreateDialog>
    </div>
  );
};

export default ImportButton;
