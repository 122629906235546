import React, { ReactElement, useCallback } from 'react';
import { TopToolbar, useDataProvider, useNotify } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Box, Button } from '@mui/material';
import { getContext, UserContext } from '../../../provider/userContext';
import TopPaginationBar from '../../common/TopPaginationBar';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_SUBSYSTEM } from '../../constants';

export const ListActions: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const ctx: UserContext = getContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: populate, isPending: isPopulateLoading } = useMutation({
    mutationFn: () =>
      dataProvider.populateITR(RESOURCE_SUBSYSTEM, ctx?.projectId),
    onSuccess: async () => {
      notify(
        'The Populate process has been started. You will receive a notification once the process is complete.',
        {
          type: 'success',
          undoable: false,
        }
      );
    },
    onError: (error) => {
      notify(getErrorMessage(error, 'Update Tag ITRs failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  const handlePopulateClick = useCallback(async () => {
    populate();
  }, [populate]);

  if (!canAccessList.edit) return;

  return (
    <TopToolbar sx={{ flex: '1 0 auto' }}>
      <TopPaginationBar />
      <Box sx={{ flex: '1 0 auto' }} />
      <Button
        onClick={handlePopulateClick}
        disabled={isPopulateLoading}
        className="populateButton"
      >
        Populate
      </Button>
    </TopToolbar>
  );
};

export default ListActions;
