import React, { ReactElement } from 'react';
import { Labeled, SimpleShowLayout, TextField } from 'react-admin';
import { ShowInDialogButton } from '@react-admin/ra-form-layout';
import Box from '@mui/material/Box';
import JsonFieldTypeSafe from '../common/JsonFieldTypeSafe';
import DownloadButton from '../common/DownloadButton';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import commonStyles from '../common/commonStyles';

const ImportShow: React.FC = (): ReactElement => {
  return (
    <ShowInDialogButton inline fullWidth maxWidth="md">
      <SimpleShowLayout sx={commonStyles.bottomMargin}>
        <Box>
          <Labeled label="File name" sx={commonStyles.flexBoxItem} fullWidth>
            <TextField source="fileName" />
          </Labeled>
          <Labeled label="Details" sx={commonStyles.flexBoxItem} fullWidth>
            <JsonFieldTypeSafe source="details" />
          </Labeled>
        </Box>
        <Box>
          {renderShowMetaDataFields()}
          <DownloadButton />
        </Box>
      </SimpleShowLayout>
    </ShowInDialogButton>
  );
};

export default ImportShow;
