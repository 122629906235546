import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import ActivitiesTab from '../common/ActivitiesTab';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import { RESOURCE_COMMENT } from '../constants';
import CommentList from '../comment/CommentList';
import AttachmentList from '../attachment/AttachmentList';
import { activityCategory, commentType } from '../common/constants';
import AuditListTab from '../audit/AuditListTab';

const SystemShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="System">
        <Box>
          <Labeled>
            <TextField
              source="systemName"
              label="System"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="priority"
              label="Priority"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <TextField
              source="commissioningProcedureNo"
              label="Commissioning Procedure No"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="commissioningEngineer"
              label="Commissioning Engineer"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="clientRepresentative"
              label="Client Representative"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled>
            <TextField
              source="sequence"
              label="Sequence"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab id="comments_tab" label="Comment" path="comments">
        <CommentList
          resource={RESOURCE_COMMENT}
          targetId="system_id"
          commentType={commentType.system}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        id="attachments_tab"
        label="Attachment"
        path="attachment"
      >
        <AttachmentList targetId="system_id" onlyShow={true} />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Activities" id="activity_tab">
        <ActivitiesTab category={activityCategory.system} onlyShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History" id="activity_tab">
        <AuditListTab tableName="tbl_system" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default SystemShow;
