import React, { ReactElement } from 'react';
import { TabbedShowLayout } from 'react-admin';
import Box from '@mui/material/Box';
import ShowComponent from '../common/ShowComponent';
import { EditPropsWithLocation } from '../common/types';
import commonStyles from '../common/commonStyles';
import CertTemplatesForm from './components/gatingForms/certTemplatesForm/CertTemplatesForm';
import TestTypesForm from './components/gatingForms/testTypesForm/TestTypesForm';
import PunchPrioritiesForm from './components/gatingForms/punchPrioritiesForm/PunchPrioritiesForm';

const CertGatingEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => (
  <ShowComponent {...props}>
    <TabbedShowLayout sx={commonStyles.bottomMargin} syncWithLocation={false}>
      <TabbedShowLayout.Tab label="Certificate" syncWithLocation={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <TestTypesForm />
          <PunchPrioritiesForm />
          <CertTemplatesForm />
        </Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default CertGatingEdit;
