import React, { ReactElement, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import moment from 'moment/moment';
import { CompanyInfo } from '../../provider/dataProvider/providers/companyDataProvider';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_COMPANY } from '../constants';
import styles from './styles';

const trialTimeLeft = (companyInfo: CompanyInfo) => {
  const subscription = (companyInfo?.subscriptions || [])
    .filter((sub) => sub.subscriptionStatus === 'in_trial')
    .sort(
      (a, b) =>
        new Date(a.subscriptionTrialEnd).getTime() -
        new Date(b.subscriptionTrialEnd).getTime()
    )?.[0];

  if (subscription?.subscriptionTrialEnd) {
    const diffSeconds = moment(subscription.subscriptionTrialEnd).diff(
      moment(),
      'second'
    );

    if (diffSeconds > 0) {
      return diffSeconds;
    }
  }

  return false;
};

const makeDiffString = (timeLeft) =>
  moment.duration(Number(timeLeft), 'seconds').humanize(true, { ss: 0, s: 60 });

const SubscriptionInfo: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const dataProvider = useDataProvider();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({});
  const timeLeft = trialTimeLeft(companyInfo);

  useEffect(() => {
    const getInfo = async () => {
      if (ctx?.company) {
        try {
          const result = await dataProvider.getCompanyInfo(RESOURCE_COMPANY);
          setCompanyInfo(result.data);
        } catch (e) {}
      }
    };
    getInfo().then();
    // eslint-disable-next-line
  }, [ctx?.company]);

  if (!timeLeft) {
    return null;
  }

  return (
    <div style={styles.container}>
      Trial ends
      <div>{makeDiffString(timeLeft)}</div>
    </div>
  );
};

export default SubscriptionInfo;
