import React, { ReactElement, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { useGetOne } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { RESOURCE_COMPONENT } from '../../constants';
import TextInput from '../../common/TextInput';

const EquipmentTypeInput: React.FC = (): ReactElement => {
  const queryClient = useQueryClient();
  const formContext = useFormContext();
  const componentId = useWatch({ name: 'componentId' });
  const { data: component } = useGetOne(
    RESOURCE_COMPONENT,
    { id: componentId },
    { enabled: !!componentId }
  );

  useEffect(() => {
    if (component?.equipTypeId) {
      formContext.setValue('equipTypeId', component.equipTypeId);
    }
  }, [component, formContext]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [RESOURCE_COMPONENT] });
  }, [queryClient]);

  return <TextInput source="equipTypeId" label="" hidden />;
};

export default EquipmentTypeInput;
