import React, { ReactElement } from 'react';
import { SimpleForm } from 'react-admin';
import FooterToolbar from '../common/FooterToolbar';
import CreateWithTitle from '../common/CreateWithTitle';
import SecurityRolePermissionForm from './SecurityRolePermissionForm';
import { convertRoleAction } from './helpers';

const SecurityRolePermissionCreate: React.FC = (): ReactElement => {
  const transform = (data) => ({
    ...data,
    action: convertRoleAction(data.action),
    record: JSON.stringify(data.record),
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <SecurityRolePermissionForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default SecurityRolePermissionCreate;
