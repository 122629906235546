import { useCallback } from 'react';
import { useResourceContext } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import { DEPENDENT_RESOURCE_MATRIX } from './useInvalidateRelatedResourceQueries';

const useInvalidateResourceQueryCache = (
  resourceName?: string,
  isInvalidateRelatedResources?: boolean
) => {
  const resourceContext = useResourceContext();
  const resource = resourceName ? resourceName : resourceContext;

  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: [resource], exact: false });
    if (isInvalidateRelatedResources) {
      const dependentResources = DEPENDENT_RESOURCE_MATRIX[resource] ?? [];

      const dependentQueries = async (dependentResource: string) => {
        //Simply invalidate all queries for the dependent resource
        await queryClient.removeQueries({
          queryKey: [dependentResource],
          exact: false,
        });
      };

      await Promise.all(dependentResources.map(dependentQueries));
    }
  }, [isInvalidateRelatedResources, queryClient, resource]);
};

export default useInvalidateResourceQueryCache;
