import React, { ReactElement } from 'react';
import {
  AutocompleteInput as RAAutocompleteInput,
  Validator,
} from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';

interface AutocompleteInputProps
  extends React.ComponentProps<typeof RAAutocompleteInput> {
  source: string;
  label: string;
  sx: SxProps<Theme>;
  validate?: Validator[];
  choices: object[];
  multiple?: boolean;
  disabled?: boolean;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  source,
  label,
  sx,
  validate = [],
  choices,
  id,
  ...rest
}): ReactElement => (
  <RAAutocompleteInput
    choices={choices}
    source={source}
    id={id || source}
    label={label}
    sx={sx}
    validate={validate}
    optionText="name"
    {...rest}
  />
);

export default AutocompleteInput;
