import React, { useCallback } from 'react';
import { useGetRecordId, useDataProvider, useNotify } from 'react-admin';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { updateContext } from '../../../provider/userContext';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { TextFieldPropsWithOptionalSource } from '../../common/types';
import { RESOURCE_PROJECT, RESOURCE_USER } from '../../constants';

const FavoriteField: React.FC<
  TextFieldPropsWithOptionalSource & {
    favoriteProjects: number[];
    setCompanies: (companies) => void;
  }
> = ({ favoriteProjects, setCompanies }) => {
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const recordId = Number(useGetRecordId());
  const isFavorite = favoriteProjects.includes(recordId);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return isFavorite
        ? dataProvider.deleteFavoriteProject(RESOURCE_USER, recordId)
        : dataProvider.addFavoriteProject(RESOURCE_USER, recordId);
    },
    onSuccess: async ({ data }) => {
      if (data?.companies) {
        updateContext({ companies: data.companies });
        queryClient.invalidateQueries({
          queryKey: [RESOURCE_PROJECT],
          exact: false,
        });
        setCompanies(data.companies);
      }
    },
    onError: (error) => {
      notify(getErrorMessage(error, 'Update Favorite field failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  const handleClick = useCallback(async () => {
    mutate();
  }, [mutate]);

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading}
      startIcon={isFavorite ? <StarIcon /> : <StarBorderIcon />}
    />
  );
};

export default FavoriteField;
