import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import TextInput from '../../common/TextInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import { SideFilter } from '../../common/types';
import { RESOURCE_CERT_TEMPLATE } from '../../constants';
import DateRangeFilter from '../../common/DateRangeFilter';
import CertTemplateCategoryInput from '../../common/CertTemplateCategoryInput';

const CertTemplateSideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_CERT_TEMPLATE, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="Certificate"
            name="certName_inc"
            source="certName"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Description"
            name="description_inc"
            source="escription"
            fullWidth
            alwaysOn
          />
          <NullableBooleanInput
            label="Vendor"
            name="isVendorCert"
            source="isVendorCert"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            fullWidth
            alwaysOn
          />
          <CertTemplateCategoryInput fullWidth alwaysOn />
          <DateRangeFilter
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default CertTemplateSideFilters;
