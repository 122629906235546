import React, { ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { RESOURCE_STRUCTURE_ASSET_GROUP } from '../../constants';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../../utils/UtilityFunctions';

interface AssetGroupInputProps {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx: SxProps<Theme>;
  source?: string;
}

const AssetGroupInput: React.FC<AssetGroupInputProps> = ({
  isRequired,
  multiple,
  sx,
  ...rest
}): ReactElement => {
  const assetId = useWatch({ name: 'assetId' });

  const requestParams = useMemo(() => {
    return assetId
      ? {
          filter: {
            assetId,
          },
        }
      : {};
  }, [assetId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_STRUCTURE_ASSET_GROUP}
      requestParams={requestParams}
      label="Asset Group"
      name="assetGroup_inc"
      optionText="assetGroup"
      multiple={multiple}
      validate={isRequired ? [required()] : []}
      sx={sx}
      fullWidth
      includeFields={['id', 'assetGroup']}
    />
  );
};

AssetGroupInput.defaultProps = { source: 'assetGroupId' };

export default AssetGroupInput;
