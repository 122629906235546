import React, { ReactElement } from 'react';
import { useInput } from 'react-admin';
import { required } from '../../../utils/UtilityFunctions';
import Box from '@mui/material/Box';
import './styles.css';

const LucidDiagramRequiredFieldMessage: React.FC = (): ReactElement => {
  const { fieldState } = useInput({
    source: 'documentId',
    validate: [required()],
  });

  return (
    fieldState.invalid && (
      <Box>
        <p className="lucid-diagram-required">
          Diagram is required to be selected
        </p>
      </Box>
    )
  );
};

export default LucidDiagramRequiredFieldMessage;
