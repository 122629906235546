import { processFilter } from '../../../provider/helpers';
import getColumnsToExport from './getColumnsToExport';
import { getLocale, getTimeZone } from '../../../utils/UtilityFunctions';
import { EXPORT_RECORD_LIMIT } from '../../constants';
import { AvailableColumn } from '../types';

const getExportParams = (
  availableColumns: AvailableColumn[],
  selectedColumns: string[],
  filterValues,
  sort,
  lookupFilter
) => {
  return new URLSearchParams({
    columnsToExport: JSON.stringify(
      getColumnsToExport(availableColumns, selectedColumns)
    ),
    filter: JSON.stringify(processFilter(filterValues)),
    limit: EXPORT_RECORD_LIMIT,
    locale: getLocale(),
    timeZone: getTimeZone(),
    sort: JSON.stringify([sort.field, sort.order]),
    ...(lookupFilter && { lookupFilter: JSON.stringify(lookupFilter) }),
  });
};

export default getExportParams;
