import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import PunchPriorityEdit from './PunchPriorityEdit';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import PunchPriorityShow from './PunchPriorityShow';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';

const PunchPriorityList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filterDefaultValues={defaultFilter}
      filters={<FreeTextSearchFilter />}
      actions={<ListActions />}
      sort={{ field: 'priority', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PunchPriorityShow withoutActions />}
        EditComponent={<PunchPriorityEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'priority', 'definition']}
      >
        <TextField source="id" label="ID" />
        <TextField source="priority" label="Priority" />
        <TextField source="definition" label="Definition" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PunchPriorityList;
