import React, { Fragment } from 'react';
import CommentList from '../comment/CommentList';
import CommentCreate from '../comment/CommentCreate';
import { RESOURCE_COMMENT } from '../constants';

const CommentFormTab = (props) => {
  return (
    <Fragment>
      <CommentList
        resource={RESOURCE_COMMENT}
        targetId={props.targetId}
        commentType={props.commentType}
        withActions
      />
      <CommentCreate
        resource={RESOURCE_COMMENT}
        transformFunc={props.transformFunc}
      />
    </Fragment>
  );
};

export default CommentFormTab;
