import React, { ReactElement } from 'react';
import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../../common/FreeTextSearchInput';
import TestTypeInput from '../../common/TestTypeInput';

export const TestFilters: React.FC<Omit<FilterProps, 'children'>> = (
  props
): ReactElement => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput source="q" label="Search" name="Search" alwaysOn />
      <TestTypeInput sx={{ marginBottom: '5px' }} multiple alwaysOn />
    </Filter>
  );
};
