export const ITR_AUTOMATION_CHOICES = [
  { id: 'MANUAL', name: 'Manual' },
  { id: 'SEMI_AUTO', name: 'Semi-Auto' },
  { id: 'AUTO', name: 'Auto' },
];

export const YES_NO_CHOICES = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const PRES_AUTOMATION_CHOICES = [
  { id: 'NO', name: 'No' },
  { id: 'YES', name: 'Yes' },
];
