import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import CustomNumberInput from '../../common/CustomNumberInput';
import TextInput from '../../common/TextInput';

const PipingForm: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <CustomNumberInput
        source="piping.testPressure"
        label="Test Pressure"
        isPositive
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.testUnits"
        label="Test Units"
        validate={[maxLength(10)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.testMedium"
        label="Test Medium"
        validate={[maxLength(10)]}
        sx={commonStyles.flexBoxItem}
      />
      <CustomNumberInput
        source="piping.testDurationMinutes"
        label="Test Duration Minutes"
        isPositive
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.cleaning"
        label="Cleaning"
        validate={[maxLength(10)]}
        sx={commonStyles.flexBoxItem}
      />
      <CustomNumberInput
        source="piping.tstPkGrp"
        label="Test Pack Grp"
        isPositive
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.lineNo"
        label="Line No"
        validate={[maxLength(75)]}
        sx={commonStyles.flexBoxLongItem}
      />
      <TextInput
        source="piping.unitNo"
        label="Unit No"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.systemCode"
        label="System Code"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.isoNumber"
        label="ISO"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.pipeSpec"
        label="Pipe Specification"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.pipeType"
        label="Pipe Type"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.pidNumber"
        label="Pid Number"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.tempDesign"
        label="Design Temp"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.pressDesign"
        label="Design Press"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.endPrep"
        label="End Prep"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.pressClass"
        label="Press Class"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.drawingGa"
        label="General Arrgmnt Dwg"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.materialType"
        label="Material Type"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.drawingPlanSpec"
        label="Dwg Plan Specification"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.pressFlowrate"
        label="Press Flow Rate"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.typeAddtl"
        label="Type-Additional"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.serviceFluid"
        label="Service Fluid"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.insPurp"
        label="Insulation Purpose"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.insThk"
        label="Insulation Thickness"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.ht"
        label="Heat Trace"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.blockNo"
        label="Block No"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.rev"
        label="Revision"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.designArea"
        label="Design Area"
        validate={[maxLength(35)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.hydroTestPressure"
        label="Hydro Test Press"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.coatingType"
        label="Coating Type"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.traceTypeCircuit"
        label="Trace Type Circuit"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.packageTovrToBmcd"
        label="Package Turnover Date"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.mastPlItem"
        label="Master Punch Item"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.designTempFH"
        label="Design Temp - FH"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.designTempFC"
        label="Design Temp - FC"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.designPressPsiH"
        label="Design Press - PSI H"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.designPressPsiC"
        label="Design Press - PSI C"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.fireproofing"
        label="Fire Proofing"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.materialConstruction"
        label="Material Construction"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.specPkg"
        label="Special Package"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.material1"
        label="Material 1"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.schedule1"
        label="Schedule 1"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.material2"
        label="Material 2"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.schedule2"
        label="Schedule 2"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="piping.fromDescription"
        label="From Description"
        validate={[maxLength(150)]}
        sx={commonStyles.flexBoxLongItem}
      />
      <TextInput
        source="piping.fromPlanDwg"
        label="From Plan Dwg"
        validate={[maxLength(100)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="piping.toDescription"
        label="To Description"
        validate={[maxLength(150)]}
        sx={commonStyles.flexBoxLongItem}
      />
      <TextInput
        source="piping.toPlanDwg"
        label="To Plan Dwg"
        validate={[maxLength(150)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
  </Fragment>
);

export default PipingForm;
