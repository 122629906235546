import React, { ReactElement } from 'react';
import { SelectField } from 'react-admin';
import { ITR_AUTOMATION_CHOICES } from '../constants';

type ItrAutomationFieldProps = {
  label?: string;
  source?: string;
};

const ItrAutomationField: React.FC<ItrAutomationFieldProps> = (
  props
): ReactElement => {
  const { label, source } = props;

  return (
    <SelectField
      label={label}
      source={source}
      choices={ITR_AUTOMATION_CHOICES}
    />
  );
};

ItrAutomationField.defaultProps = {
  label: 'ITR Automation',
  source: 'itrAutomation',
};

export default ItrAutomationField;
