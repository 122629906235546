import React, { ReactElement } from 'react';
import { RESOURCE_MATERIAL_VENDOR } from '../../../../constants';
import {
  DatagridConfigurable,
  ReferenceManyField,
  TextField,
  FunctionField,
} from 'react-admin';
import ColumnActions from '../../../../common/ColumnActions';
import VendorField from '../../../../common/VendorField';
import VendorTabEdit from './VendorTabEdit';

const renderActions = (record: Record<string, string>): ReactElement => {
  return (
    <ColumnActions showActions={{ show: false, edit: false, delete: true }}>
      <VendorTabEdit record={record} />
    </ColumnActions>
  );
};

interface VendorTabListProps {
  showActions?: boolean;
}

const VendorTabList: React.FC<VendorTabListProps> = ({
  showActions,
}): ReactElement => {
  return (
    <ReferenceManyField
      reference={RESOURCE_MATERIAL_VENDOR}
      target="materialId"
      perPage={100}
      fullWidth={true}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
        <TextField source="id" label="ID" />
        <TextField source="vendorPartNo" label="Vendor Part No" />
        <VendorField label="Vendor" />
        <TextField source="description" label="Vendor Description" />
        <TextField source="location" label="Location" />
        {showActions && (
          <FunctionField render={renderActions} label="Actions" />
        )}
      </DatagridConfigurable>
    </ReferenceManyField>
  );
};

VendorTabList.defaultProps = {
  showActions: true,
};

export default VendorTabList;
