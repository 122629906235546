import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { TextField, TopToolbar as Toolbar, useListContext } from 'react-admin';
import commonStyles from '../../../common/commonStyles';
import PriceField from '../../common/PriceField';
import RefillMaterialsButton from './RefillMaterialsButton';

interface POMaterialsFooterProps {
  isShowMode?: boolean;
}

const POMaterialsFooter: React.FC<POMaterialsFooterProps> = ({
  isShowMode,
}): ReactElement => {
  const { data } = useListContext();

  if (!data?.length) return null;

  return (
    <Box sx={commonStyles.flexBox} className="materials-footer">
      <Box className="total">Total</Box>
      <TextField label="" source="totalQtyOrdered" className="fixed selected" />
      <TextField
        label=""
        source="totalQtyReceived"
        className="fixed selected"
      />
      <Box className="fixed" />
      <PriceField label="" source="totalPrice" className="fixed selected" />
      <Box className="fixed2x" />
      <Toolbar className="action">
        {!isShowMode && <RefillMaterialsButton className="refill" />}
      </Toolbar>
    </Box>
  );
};

export default POMaterialsFooter;
