import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  CreateProps,
  Create,
  SimpleForm,
  useNotify,
} from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import Box from '@mui/material/Box';
import Add from '@mui/icons-material/Add';
import { getContext, UserContext } from '../../../provider/userContext';
import useInvalidateManyReferenceQueryCache from '../../common/hooks/useInvalidateManyReferenceQueryCache';
import SaveOnlyToolbar from '../../common/SaveOnlyToolbar';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PENDING_PUNCH } from '../../constants';
import { PendingPunch } from '../types';
import PendingPunchForm from './PendingPunchForm';

interface PendingPunchCreateInDialogProps extends CreateProps {
  record?: object;
  pendingPunchDescription?: string;
  showButton?: boolean;
  onClose?: () => void;
  disableClose?: boolean;
  originator?: string;
  targetId?: string;
}

const PendingPunchCreateInDialog: React.FC<PendingPunchCreateInDialogProps> = ({
  title = 'Add Smart Punch Item',
  record,
  showButton = true,
  pendingPunchDescription,
  onClose,
  disableClose,
  originator,
  targetId = 'componentTestId',
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const invalidateQueries = useInvalidateManyReferenceQueryCache(
    RESOURCE_PENDING_PUNCH
  );

  const transform = (data): PendingPunch => ({
    ...data,
    projectId: ctx?.projectId,
    componentId: record?.['componentId'],
    disciplineId: record?.['component']?.['disciplineId'],
    subsystemId: record?.['component']?.['subsystemId'],
    locationId: record?.['locationId'],
    originator,
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(
    (forceClose = false) => {
      if (!disableClose || forceClose === true) {
        setOpen(false);
        onClose?.();
      } else {
        notify(
          'You have selected "punchlist" in one of the radiobuttons. Please add a punch item.',
          { type: 'warning' }
        );
      }
    },
    [disableClose, notify, onClose]
  );

  const handleOnSuccess = useCallback(async () => {
    handleClose(true);
    await invalidateQueries();
  }, [handleClose, invalidateQueries]);

  useEffect(() => {
    if (pendingPunchDescription) {
      setOpen(true);
    }
  }, [pendingPunchDescription, setOpen]);

  return (
    <Fragment>
      {showButton && (
        <Button label={String(title)} onClick={handleOpen}>
          <Add />
        </Button>
      )}
      <CreateDialog
        {...rest}
        title={<span>{title}</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <Create
          resource={RESOURCE_PENDING_PUNCH}
          mutationOptions={{ onSuccess: handleOnSuccess }}
          transform={transform}
        >
          <SimpleForm
            id="pending_punch_form"
            toolbar={<SaveOnlyToolbar />}
            defaultValues={{
              disciplineId: record?.['component']?.['disciplineId'],
              subsystemId: record?.['component']?.['subsystemId'],
              locationId: record?.['locationId'],
              raisedBy: ctx?.fullName,
              raisedDate: new Date().toISOString(),
              [targetId]: record?.['id'],
              description: pendingPunchDescription,
            }}
          >
            <Box sx={commonStyles.pendingPunchCreateForm}>
              <PendingPunchForm enabled />
            </Box>
          </SimpleForm>
        </Create>
      </CreateDialog>
    </Fragment>
  );
};

export default PendingPunchCreateInDialog;
