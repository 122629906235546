import Box from '@mui/material/Box';
import React, { ReactElement, Fragment, useMemo } from 'react';
import { maxLength, SelectInput, useRecordContext } from 'react-admin';
import { required } from '../../../../utils/UtilityFunctions';
import commonStyles from '../../../common/commonStyles';
import CustomDateInput from '../../../common/CustomDateInput';
import TextInput from '../../../common/TextInput';
import { statusChoices } from '../constants';

interface PurchaseOrderTabProps {
  bulkEditMode?: boolean;
}

const PurchaseOrderRequestForm: React.FC<PurchaseOrderTabProps> = ({
  bulkEditMode,
}): ReactElement => {
  const record = useRecordContext();

  const orderNoDisabled = useMemo(() => {
    return bulkEditMode || !!record?.id;
  }, [record, bulkEditMode]);

  const requiredField = useMemo(() => {
    return bulkEditMode ? [] : [required()];
  }, [bulkEditMode]);

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="orderNo"
          label="PO Request No"
          validate={[maxLength(100)]}
          sx={commonStyles.flexBoxItem}
          disabled={orderNoDisabled}
        />
        <SelectInput
          source="status"
          label="Status"
          choices={statusChoices}
          validate={requiredField}
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          source="generateDate"
          label="Date Generated"
          sx={commonStyles.flexBoxItem}
          defaultValue={!bulkEditMode ? new Date() : null}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="clientOrderNo"
          label="Client Order No"
          validate={[maxLength(100)]}
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          source="receivedDate"
          label="Date Received"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="comment"
          label="PO Comment"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxDescriptionItem}
        />
      </Box>
    </Fragment>
  );
};

export default PurchaseOrderRequestForm;
