interface Choise<T = string> {
  id: T;
  name: string;
}

export enum SectionType {
  CERT = 'CERT',
  PUNCH = 'PUNCH',
  TEST = 'TEST',
  ENG_LIST = 'ENG_LIST',
  DRAWING = 'DRAWING',
  VENDOR_LIST = 'VENDOR_LIST',
  PRESERVATION = 'PRESERVATION',
  ATTACHMENT = 'ATTACHMENT',
  PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION',
}

export const sectionTypeChoices: Choise<SectionType>[] = [
  { id: SectionType.CERT, name: 'Certificate' },
  { id: SectionType.PUNCH, name: 'Punch' },
  { id: SectionType.TEST, name: 'Test' },
  { id: SectionType.ENG_LIST, name: 'Engineering List' },
  { id: SectionType.DRAWING, name: 'Drawing' },
  { id: SectionType.VENDOR_LIST, name: 'Contractor/Vendor List' },
  { id: SectionType.PRESERVATION, name: 'Preservation' },
  { id: SectionType.ATTACHMENT, name: 'Attachment' },
  { id: SectionType.PROJECT_DESCRIPTION, name: 'Project Description' },
];

export enum DrawingReportType {
  SUBSYSTEM = 'SUBSYSTEM',
  TAG = 'TAG',
}

export const drawingReportTypeChoices: Choise<DrawingReportType>[] = [
  { id: DrawingReportType.SUBSYSTEM, name: 'Subsystem' },
  { id: DrawingReportType.TAG, name: 'Tag' },
];

export enum EngineeringCode {
  Cable = 'Cable',
  Civil = 'Civil',
  Contractor = 'Contractor',
  CtlValve = 'CtlValve',
  ElecEquip = 'ElecEquip',
  Foundation = 'Foundation',
  Hanger = 'Hanger',
  Hydro = 'Hydro',
  Inst = 'Inst',
  IO = 'IO',
  Line = 'Line',
  ManValve = 'ManValve',
  MechEquip = 'MechEquip',
  MechSpecials = 'MechSpecials',
  MOV = 'MOV',
  Punchlist = 'Punchlist',
  Raceway = 'Raceway',
  Steel = 'Steel',
  UG_Pipe = 'UG_Pipe',
  Weld_NDE_PWHT = 'Weld_NDE_PWHT',
}

export const engineeringCodeChoices: Choise<EngineeringCode>[] = [
  { id: EngineeringCode.Cable, name: 'Cable' },
  { id: EngineeringCode.Civil, name: 'Civil' },
  { id: EngineeringCode.Contractor, name: 'Contractor' },
  { id: EngineeringCode.CtlValve, name: 'CtlValve' },
  { id: EngineeringCode.ElecEquip, name: 'ElecEquip' },
  { id: EngineeringCode.Foundation, name: 'Foundation' },
  { id: EngineeringCode.Hanger, name: 'Hanger' },
  { id: EngineeringCode.Hydro, name: 'Hydro' },
  { id: EngineeringCode.Inst, name: 'Inst' },
  { id: EngineeringCode.IO, name: 'IO' },
  { id: EngineeringCode.Line, name: 'Line' },
  { id: EngineeringCode.ManValve, name: 'ManValve' },
  { id: EngineeringCode.MechEquip, name: 'MechEquip' },
  { id: EngineeringCode.MechSpecials, name: 'MechSpecials' },
  { id: EngineeringCode.MOV, name: 'MOV' },
  { id: EngineeringCode.Punchlist, name: 'Punchlist' },
  { id: EngineeringCode.Raceway, name: 'Raceway' },
  { id: EngineeringCode.Steel, name: 'Steel' },
  { id: EngineeringCode.UG_Pipe, name: 'UG_Pipe' },
  { id: EngineeringCode.Weld_NDE_PWHT, name: 'Weld_NDE_PWHT' },
];

export const bookSectionIdStore = 'bookSectionId';
