import React, { ReactElement } from 'react';
import { FunctionField } from 'react-admin';
import { Chip } from '@mui/material';
import commonStyles from '../../common/commonStyles';

const MobilePunchField: React.FC = (): ReactElement => (
  <FunctionField
    render={(componentTest) => {
      return (
        componentTest.notProcessedPunchCount > 0 && (
          <Chip
            label={componentTest.notProcessedPunchCount}
            sx={commonStyles.customChip}
          />
        )
      );
    }}
  />
);

export default MobilePunchField;
