export const mobileTestStatuses = [
  { id: 'SUBMITTED', name: 'Submitted' },
  { id: 'APPROVED', name: 'Approved', disable: true },
  { id: 'REJECTED', name: 'Rejected' },
];

export const mobileTestStatusesFilter = [
  { id: 'SUBMITTED', name: 'Submitted' },
  { id: 'APPROVED', name: 'Approved' },
];
