import React, { useEffect, useState, ReactElement, useCallback } from 'react';
import {
  isStringControl,
  RankedTester,
  rankWith,
  optionIs,
  and,
  ControlProps,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Unwrapped } from '@jsonforms/material-renderers';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { getOptions } from '../helpers';
import StepAction from './components/StepAction';

const { MaterialOneOfRadioGroupControl } = Unwrapped;

export const RadioGroupControl: React.FC<ControlProps> = (
  props
): ReactElement => {
  const formContext = useFormContext();
  const formData = formContext?.watch('formData');
  const [stepAction, setStepAction] = useState({});
  const options = getOptions(props?.schema);

  const handleOnClick = useCallback(
    (event) => {
      // Clear the option on the second click
      if (event.target?.value === props?.data) {
        props.handleChange(props.path, undefined);
      }
    },
    [props]
  );

  useEffect(() => {
    if (formData?.stepActions?.[props.path]) {
      setStepAction(formData.stepActions[props.path]);
    }
  }, [formData, setStepAction, props]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <span onClick={handleOnClick}>
          <MaterialOneOfRadioGroupControl {...props} options={options} />
        </span>
        {stepAction && <StepAction data={stepAction} />}
      </Grid>
    </Grid>
  );
};

export const RadioGroupControlTester: RankedTester = rankWith(
  21,
  and(isStringControl, optionIs('format', 'radio'))
);

export default withJsonFormsControlProps(RadioGroupControl);
