import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import commonStyles from '../../../../common/commonStyles';
import CompletedByInput from '../../../../componentTest/components/CompletedByInput';
import CompletedDateTimeInput from '../../../../componentTest/components/CompletedDateTimeInput';
import VerifiedInput from '../../../../componentTest/components/VerifiedInput';
import { FormRowProps } from '../../../types';
import FileNameField from '../components/FileNameField';

const ItrFormRow: React.FC<FormRowProps> = ({ data }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <RowForm>
      <FileNameField data={data} />
      <TextField source="component.tag" label={labels['tagNo'] || 'Tag No'} />
      <TextField
        source="test.testFormNo"
        label={labels['testNo'] || 'Test No'}
      />
      <CompletedByInput sx={commonStyles.rowFormInput} />
      <CompletedDateTimeInput sx={commonStyles.rowFormInput} />
      <VerifiedInput sx={commonStyles.rowFormShortInput} />
    </RowForm>
  );
};

export default ItrFormRow;
