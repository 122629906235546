import React, { Fragment, ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import AssetGroupField from '../../common/AssetGroupField';
import RegionField from '../../common/RegionField';
import { TextFieldPropsWithOptionalSource } from '../../common/types';

const ProjectTextField: React.FC<
  TextFieldPropsWithOptionalSource
> = (): ReactElement => {
  const record = useRecordContext();
  const contractNo = record['projectContractNo']
    ? `(${record['projectContractNo']})`
    : '';

  return record ? (
    <Fragment>
      <div>{`${record['project']} ${contractNo}`}</div>
      {record['regionId'] && record['assetGroupId'] && (
        <div>
          <RegionField source="regionId" />
          {' / '}
          <AssetGroupField source="assetGroupId" />
        </div>
      )}
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default ProjectTextField;
