import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_DRAWING_TYPE } from '../constants';
import SmartLinkField from './SmartLinkField';

interface DrawingTypeFieldProps {
  label?: string;
  source?: string;
  sortBy?: string;
}

const DrawingTypeField: React.FC<DrawingTypeFieldProps> = ({
  label,
  source,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_DRAWING_TYPE}
  >
    <TextField source="drawingType.drawingType" />
  </SmartLinkField>
);

DrawingTypeField.defaultProps = {
  label: 'Drawing Type',
  source: 'drawingTypeId',
  sortBy: '`drawingType.drawingType`',
};

export default DrawingTypeField;
