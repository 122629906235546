import React, { ReactElement } from 'react';
import {
  ListView,
  Datagrid,
  ListContextProvider,
  ListControllerResult,
} from 'react-admin';
import { ProjectPermission } from '../../../provider/userContext';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PROJECT } from '../../constants';
import RoleField from './RoleField';
import GroupField from './GroupField';
import UserGroupField from './UserGroupField';
import SmartReferenceField from '../../common/SmartReferenceField';

interface ProjectFieldProps {
  projects: ProjectPermission[];
}

const ProjectTabField: React.FC<ProjectFieldProps> = ({
  projects,
}): ReactElement => {
  const projectList = Object.keys(projects).map((key) => {
    return {
      id: key,
      ...projects[key],
    };
  });
  const listCtx: ListControllerResult = {
    data: projectList,
    sort: { field: 'resource', order: 'ASC' },
    perPage: projectList.length,
    total: projectList.length,
    page: 0,
    displayedFilters: {},
    filterValues: {},
    selectedIds: [],
    resource: '',
    isFetching: false,
    isPending: false,
    hasNextPage: false,
    hasPreviousPage: false,
    exporter: false,
    error: null,
    onSelect: () => {},
    onToggleItem: () => {},
    onUnselectItems: () => {},
    setPerPage: () => {},
    setPage: () => {},
    setFilters: () => {},
    refetch: () => {},
    hideFilter: () => {},
    showFilter: () => {},
    setSort: () => {},
  };

  return (
    <ListContextProvider value={listCtx}>
      <ListView actions={null}>
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <SmartReferenceField
            label="Project Name"
            source="id"
            reference={RESOURCE_PROJECT}
            sx={commonStyles.flexBoxItem}
            sortable={false}
            textSource="project"
          />
          <GroupField label="Groups" source="groups" sortable={false} />
          <RoleField label="Roles" source="roles" sortable={false} />
          <UserGroupField
            label="User Groups"
            source="userGroups"
            sortable={false}
          />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

export default ProjectTabField;
