import React, { ReactElement } from 'react';
import { AblyProvider } from 'ably/react';
import NotificationsList from './NotificationsList';
import { NotificationType } from '../common/enums';
import { useNotifications } from './useNotifications';
import {
  NotificationContext,
  getNotificationContext,
} from '../../provider/notificationContext';
import { getContext, UserContext } from '../../provider/userContext';

export const ITRS_NOTIFICATION_TYPES = [
  NotificationType.ITR_CLOSEOUT,
  NotificationType.ITR_SEND_REASSIGN_EMAIL,
];

export const CERTIFICATE_NOTIFICATION_TYPES = [
  NotificationType.CERT_SEND_REASSIGN_EMAIL,
];

export const PRESERVATION_NOTIFICATION_TYPES = [
  NotificationType.PRES_SEND_REASSIGN_EMAIL,
  NotificationType.PRESERVATION_CLOSEOUT,
];

const NotificationBase: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const notificationContext: NotificationContext = getNotificationContext(
    ctx?.ablyToken,
    ctx?.company,
    ctx?.id
  );

  const { dismissAllNotifications, notifications, readNotification } =
    useNotifications(notificationContext);

  return (
    <AblyProvider client={notificationContext.ablyClient}>
      {!!notifications.length && (
        <NotificationsList
          dismissAllNotifications={dismissAllNotifications}
          notifications={notifications}
          readNotification={readNotification}
        />
      )}
    </AblyProvider>
  );
};

export default NotificationBase;
