import { useCallback, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { getErrorMessage } from '../../../utils/UtilityFunctions';

const useGeneratePdf = (
  defaultErrorMessage?: string,
  clearCache?: () => Promise<void>
) => {
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const resource = useResourceContext();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const generatePdf = useCallback(
    async (type?: string) => {
      try {
        setGenerationInProgress(true);
        return await dataProvider.generatePdf(resource, {
          id: record.id,
          type,
        });
      } catch (error) {
        let requestError = error;
        if (error?.response?.data instanceof Blob) {
          requestError = await error?.response?.data.text();
          if (error?.response?.data.type === 'application/json') {
            requestError = JSON.parse(requestError);
          }
        }

        notify(getErrorMessage(requestError, defaultErrorMessage), {
          type: 'error',
          multiLine: true,
          undoable: false,
        });
      } finally {
        setGenerationInProgress(false);
        if (clearCache) {
          await clearCache();
        }
      }
    },
    [clearCache, dataProvider, defaultErrorMessage, notify, record.id, resource]
  );

  return { generatePdf, generationInProgress };
};

export default useGeneratePdf;
