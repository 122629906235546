import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useResourceContext } from 'ra-core';
import {
  RESOURCE_CERT_DETAIL,
  RESOURCE_SMART_CERT_DETAIL,
} from '../../constants';
import useGeneratePdf from '../hooks/useGeneratePdf';

export const useGenerateCertificate = () => {
  const resource = useResourceContext();
  const queryClient = useQueryClient();

  const clearQueryCache = useCallback(async () => {
    await queryClient.removeQueries({
      queryKey: [RESOURCE_CERT_DETAIL],
      exact: false,
    });
    await queryClient.removeQueries({
      queryKey: [RESOURCE_SMART_CERT_DETAIL],
      exact: false,
    });
    await queryClient.invalidateQueries({ queryKey: [resource] });
  }, [queryClient, resource]);

  const { generatePdf, generationInProgress } = useGeneratePdf(
    'Certificate generation failed!',
    clearQueryCache
  );

  return { generateCertificate: generatePdf, generationInProgress };
};
