import React, { ReactElement } from 'react';
import { CreateProps, SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import FooterToolbar from '../common/FooterToolbar';
import SystemForm from './SystemForm';

const SystemCreate: React.FC<CreateProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data) => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle {...props} redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <SystemForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default SystemCreate;
