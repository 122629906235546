import React, { Fragment, ReactElement } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  id?: string;
}

const CustomTabPanel: React.FC<TabPanelProps> = ({
  value,
  index,
  children,
  id = `tabpanel-${index}`,
  ...rest
}): ReactElement => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={id}
    aria-labelledby={`tab-${index}`}
    {...rest}
  >
    {value === index && <Fragment>{children}</Fragment>}
  </div>
);

export default CustomTabPanel;
