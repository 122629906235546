import React, { ReactElement } from 'react';
import {
  Datagrid,
  NumberField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { Box } from '@mui/material';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_BOOK_SECTION_TEST } from '../../constants';
import { ListComponentTitle } from '../components/ListComponentTitle';

export const BookSectionTestListShow: React.FC = (): ReactElement => {
  return (
    <Box sx={commonStyles.editableDataGrid}>
      <ListComponentTitle variant="caption" label="Test" />
      <ReferenceManyField
        reference={RESOURCE_BOOK_SECTION_TEST}
        target="bookSectionId"
        perPage={MAX_RECORD_PER_PAGE}
        sort={{ field: 'sortOrder', order: 'ASC' }}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField source="testName" label="Test Form No" sortable={false} />
          <NumberField source="sortOrder" sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

export default BookSectionTestListShow;
