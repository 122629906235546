import React, { ReactElement } from 'react';
import { SelectField } from 'react-admin';
import { sectionTypeChoices } from '../constants';
import { SelectFieldPropsWithOptionalSource } from '../../common/types';

const SectionTypeField: React.FC<SelectFieldPropsWithOptionalSource> = (
  props
): ReactElement => {
  return (
    <SelectField source="sectionType" choices={sectionTypeChoices} {...props} />
  );
};

export default SectionTypeField;
