import React from 'react';
import { Box } from '@mui/material';
import { ArrayLayoutProps } from '@jsonforms/core';
import { WithDeleteDialogSupport } from '@jsonforms/material-renderers';
import ArrayToolbar from './components/ArrayToolbar';
import ArrayRows from './components/ArrayRows';

export class ArrayControl extends React.Component<
  ArrayLayoutProps & WithDeleteDialogSupport,
  any // eslint-disable-line @typescript-eslint/no-explicit-any
> {
  addItem = (path: string, value: any) => this.props.addItem(path, value); // eslint-disable-line @typescript-eslint/no-explicit-any
  render() {
    const {
      label,
      description,
      path,
      schema,
      rootSchema,
      openDeleteDialog,
      enabled,
      translations,
    } = this.props;

    return (
      <Box>
        <Box>
          <ArrayToolbar
            label={label}
            description={description}
            addItem={this.addItem}
            path={path}
            schema={schema}
            rootSchema={rootSchema}
            enabled={enabled}
            translations={translations}
          />
        </Box>
        <Box>
          <ArrayRows
            openDeleteDialog={openDeleteDialog}
            translations={translations}
            {...this.props}
          />
        </Box>
      </Box>
    );
  }
}
