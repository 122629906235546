import React, { ReactElement, Fragment } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Datagrid, TextField, useRecordContext } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_CERT_TEMPLATE } from '../../constants';
import { CertificateTemplate } from '../type';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import { ListWithPagination } from '../../common/ListWithPagination';

interface SelectCertDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (record: CertificateTemplate) => void;
}

interface SelectButtonProps {
  onSelect: (record: CertificateTemplate) => void;
}

export const SelectCertDialog: React.FC<SelectCertDialogProps> = ({
  open,
  onClose,
  onSelect,
}): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <DialogTitle>Select Certificate</DialogTitle>
      <DialogContent>
        <ListWithPagination
          title={<Fragment />}
          resource={RESOURCE_CERT_TEMPLATE}
          actions={<FreeTextSearchFilter />}
          filter={{
            divisionId: ctx?.defaultDivisionId,
          }}
          disableSyncWithLocation
        >
          <Datagrid optimized={true} bulkActionButtons={false} rowClick={false}>
            <TextField source="certName" label="Certificate" sortable={true} />
            <TextField
              source="description"
              label="Description"
              sortable={true}
            />
            <SelectButton onSelect={onSelect} />
          </Datagrid>
        </ListWithPagination>
      </DialogContent>
    </Dialog>
  );
};

const SelectButton: React.FC<SelectButtonProps> = ({
  onSelect,
}): ReactElement => {
  const record = useRecordContext<CertificateTemplate>();
  return <Button onClick={() => onSelect(record)}>Select</Button>;
};
