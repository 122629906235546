import React, { ReactElement, Fragment, useCallback } from 'react';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import BulkGeneratePdfButton from '../../common/BulkGeneratePdfButton';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_CERT_DETAIL } from '../../constants';
import BulkEditButton from '../../common/BulkEditButton';
import CertDetailsForm from '../CertDetailsForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_CERT_DETAIL,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkGeneratePdfButton onSuccess={clearCache} />
      <BulkEditButton
        resource={RESOURCE_CERT_DETAIL}
        form={<CertDetailsForm />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
