import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import ColumnActions from '../common/ColumnActions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import PunchCategoryEdit from './PunchCategoryEdit';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import ListActions from '../common/ListActions';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import PunchCategoryShow from './PunchCategoryShow';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';

const PunchCategoryList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filterDefaultValues={defaultFilter}
      filters={<FreeTextSearchFilter />}
      actions={<ListActions />}
      sort={{ field: 'category', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<PunchCategoryShow withoutActions />}
        EditComponent={<PunchCategoryEdit syncWithLocation={false} />}
        defaultVisibleColumns={['id', 'category', 'description']}
      >
        <TextField source="id" label="ID" />
        <TextField source="category" label="Punch Category" />
        <TextField source="description" label="Description" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default PunchCategoryList;
