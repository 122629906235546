import React from 'react';
import { List as RAList } from 'react-admin';
import { CustomPagination } from './CustomPagination';
import { DEFAULT_ROWS_PER_PAGE } from '../constants';

export const ListWithPagination: React.FC<
  React.ComponentProps<typeof RAList>
> = ({ children, sx, ...rest }) => {
  return (
    <RAList
      perPage={DEFAULT_ROWS_PER_PAGE}
      pagination={<CustomPagination />}
      {...rest}
      sx={
        sx ?? {
          '& .RaFilter-form': { flex: '0 1 auto' },
        }
      }
      debounce={1000}
    >
      {children}
    </RAList>
  );
};
