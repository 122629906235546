import React, { ReactElement } from 'react';
import { DateField, TextField, useStore } from 'react-admin';
import DatagridConfigurableRbac from '../../common/DatagridConfigurableRbac';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import renderMetaDataFields from '../../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../../common/helpers/storeKeys';
import useDefaultFilter from '../../common/hooks/useDefaultFilter';
import LegendButton from '../../common/LegendButton';
import ListActions from '../../common/ListActions';
import ListWithTitle from '../../common/ListWithTitle';
import ColumnActions from '../../common/ColumnActions';
import { RESOURCE_MATERIAL_RELEASED } from '../../constants';
import MaterialReleasedSideFilters from './components/MaterialReleasedSideFilters';
import StatusField from './components/StatusField';
import {
  getListRowStyle,
  getMaterialReleasedListRowBorderColor,
} from '../helpers';
import MaterialReleasedEdit from './MaterialReleasedEdit';
import MaterialReleasedShow from './MaterialReleasedShow';
import BulkGeneratePdfButton from '../../common/BulkGeneratePdfButton';
import { MATERIAL_RELEASED_EXPORT_BUTTONS, legends } from './constants';

const MaterialReleasedList: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');
  const sideFilter = getSideFiltersKey(RESOURCE_MATERIAL_RELEASED);
  const [showSideFilter] = useStore(sideFilter, false);

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{ import: false }}
          multipleExportButtonsInfo={MATERIAL_RELEASED_EXPORT_BUTTONS}
          actions={<LegendButton legends={legends} />}
        />
      }
      sort={{ field: 'orderNo', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <MaterialReleasedSideFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        defaultVisibleColumns={[
          'id',
          'orderNo',
          'generateDate',
          'releasedDate',
          'status',
          'releasedTo',
        ]}
        rowSx={getListRowStyle(getMaterialReleasedListRowBorderColor)}
        bulkActionButtons={<BulkGeneratePdfButton />}
        EditComponent={<MaterialReleasedEdit syncWithLocation={false} />}
        ShowComponent={<MaterialReleasedShow withoutActions />}
      >
        <TextField source="id" label="ID" />
        <TextField source="orderNo" label="Released Order No" />
        <DateField source="generateDate" label="Date Generated" />
        <DateField source="releasedDate" label="Date Released" />
        <TextField source="totalQty" label="Total Qty to Release" />
        <StatusField />
        <TextField source="releasedTo" label="Released To" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default MaterialReleasedList;
