import React, { ReactElement } from 'react';
import { NumberInput, NumberInputProps } from 'react-admin';
import { isNotEmpty } from '../../utils/UtilityFunctions';

export interface CustomNumberInputProps extends NumberInputProps {
  isPositive?: boolean;
  fractionDigits?: number;
  defaultNull?: boolean;
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  isPositive,
  fractionDigits,
  defaultNull,
  ...rest
}): ReactElement => (
  <NumberInput
    {...rest}
    min={isPositive ? 0 : null}
    format={(val) => {
      if (!val && !defaultNull) return 0;
      return fractionDigits !== undefined && isNotEmpty(val)
        ? Number(val).toFixed(fractionDigits)
        : val;
    }}
  />
);

CustomNumberInput.defaultProps = { defaultNull: false };

export default CustomNumberInput;
