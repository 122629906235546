import React, { ReactElement, useCallback } from 'react';
import { SimpleForm, useRedirect, useNotify } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CreateWithTitle from '../common/CreateWithTitle';
import { RESOURCE_COMPONENT_TEST } from '../constants';
import FooterToolbar from '../common/FooterToolbar';
import ComponentTestForm from './ComponentTestForm';
import { ComponentTest } from './types';

const ComponentTestCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const transform = (data: ComponentTest): ComponentTest => ({
    ...data,
    projectId: ctx?.projectId,
  });

  const onSuccess = useCallback(
    (data) => {
      notify(
        data?.status === 'NONE'
          ? 'Element created'
          : 'Element was restored from deleted'
      );
      redirect('list', RESOURCE_COMPONENT_TEST);
    },
    [redirect, notify]
  );

  return (
    <CreateWithTitle
      redirect="list"
      transform={transform}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<FooterToolbar />}>
        <ComponentTestForm />
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default ComponentTestCreate;
