import React, { ReactElement } from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { RESOURCE_SUBSYSTEM_DRAWING } from '../../../constants';

const SubsystemFormShow: React.FC = (): ReactElement => (
  <ReferenceManyField
    target="drawing_id"
    reference={RESOURCE_SUBSYSTEM_DRAWING}
  >
    <Datagrid bulkActionButtons={false} rowClick={false} sx={{ width: '50%' }}>
      <TextField label="Subsystem" source="subsystem.subsystem" />
      <TextField label="Description" source="subsystem.description" />
    </Datagrid>
  </ReferenceManyField>
);

export default SubsystemFormShow;
