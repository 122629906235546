import { usePermissions, useResourceContext } from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import { canAccessListType } from '../types';
import { actionPermissions } from '../constants';

type canAccessType = (resource?: string) => canAccessListType;

const useCanAccess: canAccessType = (outerResource) => {
  const { permissions } = usePermissions();
  const resource: string = useResourceContext({ resource: outerResource });
  const actions: string[] = Object.values(actionPermissions);
  let canAccessList: canAccessListType = {
    list: false,
    create: false,
    edit: false,
    show: false,
    delete: false,
    forceDelete: false,
    export: false,
    import: false,
    certGen: false,
    certOverride: false,
    closeout: false,
    itrAttachment: false,
    templateUpdate: false,
    pdfGen: false,
    sendMobile: false,
  };

  actions.forEach((action) => {
    canAccessList = {
      ...canAccessList,
      [action]: canAccess({
        permissions,
        action: action,
        resource: resource,
      }),
    };
  });

  return canAccessList;
};

export default useCanAccess;
