import React, { useCallback, ReactElement } from 'react';
import { GREEN, RED, YELLOW } from '../../../theme/colors';
import ColorDotField from '../../common/ColorDotField';
import { FieldPropsWithOptionalSource } from '../../common/types';

interface StatusDotColorProps extends FieldPropsWithOptionalSource {
  label?: string;
  source?: string;
}

const StatusDotColor: React.FC<StatusDotColorProps> = ({
  label,
  source,
  ...rest
}): ReactElement => {
  const getDotColor = useCallback((status: string): string => {
    switch (status) {
      case 'ACCEPTED':
        return GREEN;
      case 'CLEARED':
        return YELLOW;
      case 'RAISED':
      default:
        return RED;
    }
  }, []);

  return (
    <ColorDotField
      {...rest}
      label={label}
      source={source}
      getDotColor={getDotColor}
    />
  );
};

StatusDotColor.defaultProps = {
  label: 'Status',
  source: 'status',
};

export default StatusDotColor;
