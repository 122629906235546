import React, { ReactElement, useMemo } from 'react';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_DISCIPLINE } from '../../constants';
import sideFilterStyles from '../../common/sideFilterStyles';
import { getContext, UserContext } from '../../../provider/userContext';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';

interface MechanicalDisciplineInputProps {
  alwaysOn?: boolean;
  multiple?: boolean;
}

const MechanicalDisciplineInput: React.FC<MechanicalDisciplineInputProps> = ({
  alwaysOn,
  multiple,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'discipline', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_DISCIPLINE}
      requestParams={requestParams}
      source="mechanical.disciplineId"
      label="Discipline"
      name="discipline_inc"
      optionText="discipline"
      sx={
        alwaysOn
          ? sideFilterStyles.sideFiltersSelectInput
          : commonStyles.flexBoxItem
      }
      multiple={multiple}
      includeFields={['id', 'discipline']}
    />
  );
};

export default MechanicalDisciplineInput;
