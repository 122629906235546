import React from 'react';
import { FormControlLabel as DefaultFormControlLabel } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';

interface Props extends FormControlLabelProps {
  alwaysOn?: boolean;
  source?: string;
  helperText?: string;
}

const FormControlLabel: React.FC<Props> = (props) => {
  const innerProps = { ...props };
  delete innerProps.source;
  delete innerProps.alwaysOn;
  delete innerProps.helperText;
  return <DefaultFormControlLabel {...innerProps} />;
};

export default FormControlLabel;
