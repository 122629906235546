import { FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import { SideFilter } from '../common/types';
import { categoryChoices } from './constants';
import isEmpty from 'lodash/isEmpty';
import React, { ReactElement } from 'react';
import useSidebarFilters from '../common/hooks/useSidebarFilters';
import { RESOURCE_ACTIVITY_PHASE } from '../constants';
import commonStyles from '../common/commonStyles';

const ActivityPhaseFilterSidebar: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { data, isLoading, filterValues } = useSidebarFilters(
    RESOURCE_ACTIVITY_PHASE,
    defaultFilter
  );

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={commonStyles.filterSidebar}>
      <CardContent>
        <FilterList label="Category" icon={<CategoryIcon />}>
          {categoryChoices.map((categoryChoice) => (
            <FilterListItem
              key={categoryChoice.id}
              label={categoryChoice.name}
              value={{ category: categoryChoice.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default ActivityPhaseFilterSidebar;
