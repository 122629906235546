export enum ImportResultStatusToText {
  PARTIAL_FAIL = 'Partial Fail',
  SUCCESS = 'Success',
  FAIL = 'Fail',
}

export type ImportValidation = {
  row: number;
  cellAddress: string;
  message: string;
};

export type ImportResult = {
  status: string;
  unchangedRecords: number;
  updatedRecords: number;
  deletedRecords: number;
  insertedRecords: number;
  validations: Array<ImportValidation>;
  errorsFile?: Record<string, unknown>;
};
