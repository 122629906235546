import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import commonStyles from '../common/commonStyles';
import { DateField, Labeled, TabbedShowLayout, TextField } from 'react-admin';
import AuditListTab from '../audit/AuditListTab';

const DrawingPrefixShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => {
  return (
    <ShowComponent {...rest}>
      <TabbedShowLayout
        sx={commonStyles.bottomMargin}
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Prefix">
          <Box sx={[commonStyles.bottomMargin, { width: 800, margin: 2 }]}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="prefix" label="Prefix" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxLongItem}>
              <TextField source="description" label="Description" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <DateField source="updatedAt" label="Updated At" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="updatedBy" label="Updated By" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="updatedByName" label="Updated By Name" />
            </Labeled>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History">
          <AuditListTab tableName="tbl_drawing_prefix" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </ShowComponent>
  );
};

export default DrawingPrefixShow;
