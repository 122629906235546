import React, { ReactElement } from 'react';
import {
  CheckboxProps as MuiCheckboxProps,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormControl,
} from '@mui/material';

interface CheckboxProps extends MuiCheckboxProps {
  label: string | ReactElement;
  formControlLabelProps?: FormControlLabelProps;
  formControlProps?: FormControlProps;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  formControlLabelProps,
  formControlProps,
  ...rest
}): ReactElement => {
  return (
    <FormControl variant="standard" {...formControlProps}>
      <FormControlLabel
        {...formControlLabelProps}
        control={<MuiCheckbox {...rest} />}
        label={label}
      />
    </FormControl>
  );
};

export default Checkbox;
