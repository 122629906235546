import React, { ReactElement } from 'react';
import { ArrayField, ChipField } from 'react-admin';
import SmartSingleFieldList from '../../common/SmartSingleFieldList';

interface ComponentDrawingFieldProps {
  source?: string;
  label?: string;
  sortable?: boolean;
}

const ComponentDrawingField: React.FC<ComponentDrawingFieldProps> = ({
  label,
  sortable,
}): ReactElement => (
  <ArrayField source="componentDrawings" label={label} sortable={sortable}>
    <SmartSingleFieldList linkType={false}>
      <ChipField source="drawing.drawingName" clickable={false} />
    </SmartSingleFieldList>
  </ArrayField>
);

export default ComponentDrawingField;
