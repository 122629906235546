import React, { Fragment, ReactElement } from 'react';
import AttachmentCreateInDialog from '../attachment/AttachmentCreateInDialog';
import ImageGallery from '../imageGallery/ImageGallery';
import { ATTACHMENT_TYPE_ENUMS } from './enums';

type ImageGalleryFormTabType = {
  targetId: string;
  onlyShow?: boolean;
  outerResource?: string;
};

const ImageGalleryFormTab: React.FC<ImageGalleryFormTabType> = ({
  targetId,
  onlyShow,
  outerResource,
}): ReactElement => {
  return (
    <Fragment>
      <ImageGallery targetId={targetId} onlyShow={onlyShow} />
      {!onlyShow && (
        <AttachmentCreateInDialog
          targetId={targetId}
          title="Add Image"
          attachmentType={ATTACHMENT_TYPE_ENUMS.IMAGE}
          outerResource={outerResource}
          isImage
        />
      )}
    </Fragment>
  );
};

export default ImageGalleryFormTab;
