export const getCertDetailsSystemSource = (record) => {
  switch (true) {
    case !!record?.componentActivityId:
      return {
        source: 'component.subsystem.system.id',
        textSource: 'component.subsystem.system.systemName',
      };
    case !!record?.subsystemActivityId:
      return {
        source: 'subsystem.system.id',
        textSource: 'subsystem.system.systemName',
      };
    default:
      return { source: undefined, textSource: undefined };
  }
};

export const getCertDetailsSubsystemSource = (record) => {
  switch (true) {
    case !!record?.componentActivityId:
      return {
        source: 'component.subsystem.id',
        textSource: 'component.subsystem.subsystem',
      };
    default:
      return { source: undefined, textSource: undefined };
  }
};
