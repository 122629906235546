import React, { Fragment, ReactElement } from 'react';
import { DateField, Labeled, NumberField, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';

const PipingShowTab: React.FC = (): ReactElement => (
  <Fragment>
    <Box>
      <Labeled label="Test Pressure" sx={commonStyles.flexBoxItem}>
        <NumberField source="piping.testPressure" />
      </Labeled>
      <Labeled label="Test Units" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.testUnits" />
      </Labeled>
      <Labeled label="Test Medium" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.testMedium" />
      </Labeled>
      <Labeled label="Test Duration Minutes" sx={commonStyles.flexBoxItem}>
        <NumberField source="piping.testDurationMinutes" />
      </Labeled>
      <Labeled label="Cleaning" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.cleaning" />
      </Labeled>
      <Labeled label="Test Pack Grp" sx={commonStyles.flexBoxItem}>
        <NumberField source="piping.tstPkGrp" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Line No" sx={commonStyles.flexBoxLongItem}>
        <TextField source="piping.lineNo" />
      </Labeled>
      <Labeled label="Unit No" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.unitNo" />
      </Labeled>
      <Labeled label="System Code" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.systemCode" />
      </Labeled>
      <Labeled label="ISO" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.isoNumber" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Pipe Specification" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.pipeSpec" />
      </Labeled>
      <Labeled label="Pipe Type" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.pipeType" />
      </Labeled>
      <Labeled label="Pid Number" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.pidNumber" />
      </Labeled>
      <Labeled label="Design Temp" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.tempDesign" />
      </Labeled>
      <Labeled label="Design Press" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.pressDesign" />
      </Labeled>
      <Labeled label="End Prep" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.endPrep" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Press Class" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.pressClass" />
      </Labeled>
      <Labeled label="General Arrgmnt Dwg" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.drawingGa" />
      </Labeled>
      <Labeled label="Material Type" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.materialType" />
      </Labeled>
      <Labeled label="Dwg Plan Specification" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.drawingPlanSpec" />
      </Labeled>
      <Labeled label="Press Flow Rate" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.pressFlowrate" />
      </Labeled>
      <Labeled label="Type-Additional" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.typeAddtl" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Service Fluid" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.serviceFluid" />
      </Labeled>
      <Labeled label="Insulation Purpose" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.insPurp" />
      </Labeled>
      <Labeled label="Insulation Thickness" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.insThk" />
      </Labeled>
      <Labeled label="Heat Trace" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.ht" />
      </Labeled>
      <Labeled label="Block No" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.blockNo" />
      </Labeled>
      <Labeled label="Revision" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.rev" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Design Area" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.designArea" />
      </Labeled>
      <Labeled label="Hydro Test Press" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.hydroTestPressure" />
      </Labeled>
      <Labeled label="Coating Type" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.coatingType" />
      </Labeled>
      <Labeled label="Trace Type Circuit" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.traceTypeCircuit" />
      </Labeled>
      <Labeled label="Package Turnover Date" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.packageTovrToBmcd" />
      </Labeled>
      <Labeled label="Master Punch Item" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.mastPlItem" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Design Temp - FH" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.designTempFH" />
      </Labeled>
      <Labeled label="Design Temp - FC" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.designTempFC" />
      </Labeled>
      <Labeled label="Design Press - PSI H" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.designPressPsiH" />
      </Labeled>
      <Labeled label="Design Press - PSI C" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.designPressPsiC" />
      </Labeled>
      <Labeled label="Fire Proofing" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.fireproofing" />
      </Labeled>
      <Labeled label="Material Construction" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.materialConstruction" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Special Package" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.specPkg" />
      </Labeled>
      <Labeled label="Material 1" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.material1" />
      </Labeled>
      <Labeled label="Schedule 1" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.schedule1" />
      </Labeled>
      <Labeled label="Material 2" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.material2" />
      </Labeled>
      <Labeled label="Schedule 2" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.schedule2" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="From Description" sx={commonStyles.flexBoxLongItem}>
        <TextField source="piping.fromDescription" />
      </Labeled>
      <Labeled label="From Plan Dwg" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.fromPlanDwg" />
      </Labeled>
      <Labeled label="To Description" sx={commonStyles.flexBoxLongItem}>
        <TextField source="piping.toDescription" />
      </Labeled>
      <Labeled label="To Plan Dwg" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.toPlanDwg" />
      </Labeled>
    </Box>
    <Box>
      <Labeled label="Updated At" sx={commonStyles.flexBoxItem}>
        <DateField source="piping.updatedAt" />
      </Labeled>
      <Labeled label="Updated By" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.updatedBy" />
      </Labeled>
      <Labeled label="Updated By Name" sx={commonStyles.flexBoxItem}>
        <TextField source="piping.updatedByName" />
      </Labeled>
    </Box>
  </Fragment>
);

export default PipingShowTab;
