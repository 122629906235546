import React, { ReactElement } from 'react';
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  useRecordContext,
} from 'react-admin';
import { CustomPagination } from '../../common/CustomPagination';
import { getContext, UserContext } from '../../../provider/userContext';
import RoleField from '../../user/components/RoleField';
import GroupField from '../../user/components/GroupField';
import UserGroupField from '../../user/components/UserGroupField';
import { RESOURCE_DIVISION, RESOURCE_USER } from '../../constants';
import SmartReferenceField from '../../common/SmartReferenceField';

const GroupUsersTab: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const record = useRecordContext();
  const companySource = `companies.${ctx?.company}`;
  const projectSource = `${companySource}.projects.${record.projectId}`;

  return (
    <ReferenceManyField
      reference={RESOURCE_USER}
      target="userGroupId"
      filter={{ projectId: record.projectId }}
      sort={{ field: 'id', order: 'ASC' }}
      perPage={10}
      pagination={<CustomPagination sx={{ width: '100%' }} />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        sx={{ width: '100%' }}
      >
        <SmartReferenceField
          label="Email"
          source="id"
          reference={RESOURCE_USER}
          textSource="id"
        />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <SmartReferenceField
          label="Division"
          source={`${companySource}.defaultDivisionId`}
          reference={RESOURCE_DIVISION}
          sortable={false}
          textSource="divisionName"
        />
        <RoleField
          label="Roles"
          source={`${projectSource}.roles`}
          sortable={false}
        />
        <GroupField
          label="Groups"
          source={`${projectSource}.groups`}
          sortable={false}
        />
        <UserGroupField
          label="User Groups"
          source={`${projectSource}.userGroups`}
          sortable={false}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default GroupUsersTab;
