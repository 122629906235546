import React, { Fragment, ReactElement } from 'react';
import { useResourceContext, useStore } from 'react-admin';
import useResourceTitle from './hooks/useResourceTitle';
import { setPageTitle } from './helpers/setPageTitle';

interface ListTitleProps {
  title?: string;
}

const ListTitle: React.FC<ListTitleProps> = ({ title }): ReactElement => {
  const resource: string = useResourceContext();
  const [isExpanded] = useStore(`${resource}.datagrid.expanded`);
  const resourceTitle = useResourceTitle(true);
  const pageTitle =
    !!isExpanded?.length && resource !== 'attachment'
      ? ' '
      : title || resourceTitle;
  setPageTitle(pageTitle);

  return <Fragment>{pageTitle}</Fragment>;
};

export default ListTitle;
