import React, { Fragment, ReactElement } from 'react';
import { BooleanInput, maxLength, SelectInput } from 'react-admin';
import Box from '@mui/material/Box';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import { engineeringCodeChoices } from '../../bookSection/constants';
import VendorInput from '../../common/VendorInput/VendorInput';
import AutoCompleteWithCreateOptionReferenceInput from '../../common/AutoCompleteWithCreateOptionReferenceInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import CustomDateInput from '../../common/CustomDateInput';
import DisciplineInput from '../../common/DisciplineInput';
import EquipmentTypeInput from '../../common/EquipmentTypeInput';
import GroupInput from '../../common/GroupInput';
import LocationInput from '../../common/LocationInput';
import ModuleInput from '../../common/ModuleInput';
import SubsystemInput from '../../common/SubsystemInput';
import TextInput from '../../common/TextInput';
import WorkPackInput from '../../common/WorkPackInput';
import { validateStatusFields } from '../../common/validators';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_LOCATION } from '../../constants';
import { tagCategory } from '../constants';
import VerifiedInput from './VerifiedInput';

interface ComponentFormProps {
  bulkEditMode?: boolean;
}

const ComponentForm: React.FC<ComponentFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="hasElectrical"
          label="Has Electrical Record"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="hasPiping"
          label="Has Piping Record"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="hasMechanical"
          label="Has Mechanical Record"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="hasInstrumentation"
          label="Has Instrumentation Record"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <SubsystemInput isRequired={isRequired} sx={commonStyles.flexBoxItem} />
        <DisciplineInput isRequired={isRequired} />
        <TextInput
          source="tag"
          label="Tag"
          validate={[...requiredValidation, maxLength(255)]}
          sx={commonStyles.flexBoxItem}
          disabled={bulkEditMode}
        />
        <TextInput
          source="tagDescription"
          label="Tag Description"
          validate={[...requiredValidation, maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <GroupInput sx={commonStyles.flexBoxItem} category={['COMPONENT']} />
        <ModuleInput sx={commonStyles.flexBoxItem} />
        <WorkPackInput sx={commonStyles.flexBoxItem} />
        <LocationInput sx={commonStyles.flexBoxItem} />
        <EquipmentTypeInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput
          source="skipItrAutomation"
          label="Skip ITR Automation"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="isMaintenance"
          label="Maintenance"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tagScoped"
          label="Tag Scoped"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
        <BooleanInput
          source="tagVerified"
          label="Tag Verified"
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="parentTag"
          label="Parent Tag"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="dataSheet"
          label="Data Sheet"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="manufacturer"
          label="Manufacturer"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="model"
          label="Model"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="serial"
          label="Serial"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="batch"
          label="Batch"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="loopName"
          label="Loop Name"
          validate={[maxLength(20)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="purchaseOrder"
          label="Purchase Order"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <VendorInput />
        <CustomDateInput
          source="deliveryDate"
          label="Delivery Date"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="hardSoft"
          label="Hard Soft"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <SelectInput
          source="engList"
          choices={engineeringCodeChoices}
          label="EngList"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <AutoCompleteWithCreateOptionReferenceInput
          source="storageLocation"
          label="Storage Location"
          reference={RESOURCE_LOCATION}
          keyValue="location"
          sx={commonStyles.flexBoxLongItem}
          defaultFilter={{
            projectId: ctx?.projectId,
          }}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <SelectInput
          source="tagCategory"
          label="Tag Category"
          choices={tagCategory}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="verifiedBy"
          label="Verified By"
          validate={[maxLength(75), validateStatusFields('verified')]}
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          source="verifiedDate"
          label="Verified Date"
          validate={[validateStatusFields('verified')]}
          sx={commonStyles.flexBoxItem}
        />
        <VerifiedInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="cableFrom"
          label="Cable From"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="cableTo"
          label="Cable To"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <CustomNumberInput
          source="latitude"
          label="Latitude"
          sx={commonStyles.flexBoxItem}
        />
        <CustomNumberInput
          source="longitude"
          label="Longitude"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          source="ifcQty"
          label="IFC Qty"
          fractionDigits={2}
          isPositive
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="listSource"
          label="List Source"
          validate={[maxLength(155)]}
          sx={commonStyles.flexBoxItem}
        />
        <CustomNumberInput
          source="trackByQty"
          label="Track By Quantity"
          fractionDigits={2}
          isPositive
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="remarks"
          label="Remarks"
          multiline
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
    </Fragment>
  );
};

export default ComponentForm;
