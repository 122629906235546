import { Box, Stack, Typography, Divider, Grid, Chip } from '@mui/material';
import { ReactElement } from 'react';
import { Button, Identifier, RaRecord } from 'react-admin';
import { styles } from '../styles';

interface ViewSelectedItemsProps<T extends RaRecord = { id: Identifier }> {
  selectedItems: T[];
  getItemLabel: (record: T) => string;
  setSelectedItems: (value: ((prevState: T[]) => T[]) | T[]) => void;
  header: string;
}

export const ViewSelectedItems: React.FC<ViewSelectedItemsProps> = ({
  header,
  selectedItems,
  getItemLabel,
  setSelectedItems,
}): ReactElement => {
  const removeItem = (id: Identifier) => {
    setSelectedItems(selectedItems.filter((it) => it.id !== id));
  };

  return (
    <Box sx={{ marginTop: '30px' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant={'h6'} color={'textSecondary'} align={'left'}>
          {header}
        </Typography>
        <Button
          label="Remove All"
          variant="text"
          type="button"
          disabled={selectedItems.length === 0}
          onClick={() => setSelectedItems([])}
        />
      </Stack>

      <Divider style={styles.selectedItemsDivider} />

      <Grid container spacing={1}>
        {selectedItems.length == 0 && (
          <Typography variant={'body2'} sx={{ marginLeft: '10px' }}>
            {' '}
            No item
          </Typography>
        )}

        {selectedItems.map((item) => {
          return (
            <Grid key={item.id} item>
              <Chip
                label={getItemLabel(item)}
                variant="outlined"
                onDelete={() => removeItem(item.id)}
                sx={{ maxWidth: '200px' }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
