import { useCallback, useState } from 'react';

const useDialogState = (initialState?: boolean) => {
  const [isVisible, setIsVisible] = useState(initialState);

  const show = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return { isVisible, show, hide };
};

export default useDialogState;
