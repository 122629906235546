import React, { ReactElement } from 'react';
import { NumberInput } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import commonStyles from '../../common/commonStyles';
import { SelectTestInput } from './SelectTestInput';

export const EditForm: React.FC = (): ReactElement => (
  <RowForm submitOnEnter={false}>
    <SelectTestInput />
    <NumberInput source="sortOrder" sx={commonStyles.flexBoxItem} />
  </RowForm>
);
