import React, { ReactElement } from 'react';
import { TextField } from 'react-admin';
import { RESOURCE_CERT_TEMPLATE } from '../../../../constants';
import SmartLinkField from '../../../../common/SmartLinkField';

interface CertTemplateFieldProps {
  label?: string;
  source?: string;
  sx?: object;
  sortBy?: string;
}

const CertTemplateField: React.FC<CertTemplateFieldProps> = ({
  label,
  source,
  sx,
}): ReactElement => (
  <SmartLinkField
    label={label}
    source={source}
    resource={RESOURCE_CERT_TEMPLATE}
  >
    <TextField source="predCertTemplate.certName" sx={sx} />
  </SmartLinkField>
);

CertTemplateField.defaultProps = {
  label: 'Certificate',
  source: 'predCertTemplateId',
  sortBy: '`predCertTemplate.certName`',
};

export default CertTemplateField;
