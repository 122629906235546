import {
  BLUE,
  GREEN,
  GREY,
  ORANGE,
  WHITE,
  YELLOW,
} from '../../../theme/colors';
import { RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL } from '../../constants';

export enum PurchaseOrderRequestStatus {
  GENERATED = 'GENERATED',
  ORDERED = 'ORDERED',
  RECEIVED = 'RECEIVED',
  RECEIVED_PARTIAL = 'RECEIVED_PARTIAL',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
}

export type Status = {
  code: string;
  title: string;
  color?: string;
};

export const statuses: Status[] = [
  {
    code: PurchaseOrderRequestStatus.GENERATED,
    title: 'Generated',
    color: WHITE,
  },
  { code: PurchaseOrderRequestStatus.ORDERED, title: 'Ordered', color: ORANGE },
  { code: PurchaseOrderRequestStatus.RECEIVED, title: 'Received', color: BLUE },
  {
    code: PurchaseOrderRequestStatus.RECEIVED_PARTIAL,
    title: 'Received Partial',
    color: YELLOW,
  },
  {
    code: PurchaseOrderRequestStatus.CANCELLED,
    title: 'Cancelled',
    color: GREY,
  },
  { code: PurchaseOrderRequestStatus.CLOSED, title: 'Closed', color: GREEN },
];

const statusesByCode = statuses.reduce((acc, i) => {
  acc[i.code] = i;
  return acc;
}, {});

export const statusChoices = statuses.map(({ code, title }) => ({
  id: code,
  name: title,
}));

export const statusesLegend = statuses.map(({ title, color }) => ({
  label: `Status ${title}`,
  color,
}));

export const getRowStyle = (record, expanded = []) => {
  return {
    backgroundColor: expanded.includes(record.id)
      ? 'rgba(0, 0, 0, 0.04)'
      : 'initial',
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

export const getRowBorderColor = (record) => {
  return statusesByCode[record?.status].color || 'transparent';
};

export const MATERIAL_PURCHASE_ORDER_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'material_purchase_order',
  },
  {
    label: 'Export All',
    resource: RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
    fileName: 'material_purchase_order_all',
    columns: [
      { source: 'id', label: 'ID' },
      { source: 'poRequestNo', label: 'PO Request No' },
      { source: 'clientOrderNo', label: 'Client Order No' },
      { source: 'material', label: 'Material' },
      { source: 'qtyOrdered', label: 'Qty Ordered' },
      { source: 'price', label: 'Price' },
      { source: 'subTotal', label: 'SubTotal' },
      { source: 'totalPrice', label: 'Total Price' },
      { source: 'qtyReceived', label: 'Qty Received' },
      { source: 'status', label: 'Status' },
      { source: 'dateGenerated', label: 'Date Generated' },
      { source: 'dateReceived', label: 'Date Received' },
      { source: 'comment', label: 'Comment' },
      { source: 'description', label: 'Description' },
      { source: 'type', label: 'Type' },
    ],
    includeAllColumns: true,
  },
];
