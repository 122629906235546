import React, { ReactElement } from 'react';
import {
  TabbedForm,
  SimpleShowLayout,
  useRecordContext,
  TabbedFormTabs,
} from 'react-admin';
import LazyFormTab from '../../common/LazyFormTab';
import ActivitiesTab from '../../common/ActivitiesTab';
import DrawingTab from '../../common/DrawingTab';
import TestITRsTab from '../../common/TagITRsTab';
import PunchFormShow from '../../common/PunchFormShow';
import { activityCategory, commentType } from '../../common/constants';
import commonStyles from '../../common/commonStyles';
import CommentList from '../../comment/CommentList';
import AttachmentList from '../../attachment/AttachmentList';
import AuditListTab from '../../audit/AuditListTab';
import { RESOURCE_COMMENT, RESOURCE_COMPONENT_DRAWING } from '../../constants';
import TestAttachmentTab from './TestAttachmentTab';
import PreservationAttachmentTab from './PreservationAttachmentTab';
import ComponentShowTab from './ComponentShowTab';
import ElectricalShowTab from './ElectricalShowTab';
import PipingShowTab from './PipingShowTab';
import MechanicalShowTab from './MechanicalShowTab';
import InstrumentationShowTab from './InstrumentationShowTab';
import MaterialComponentTab from 'src/components/common/MaterialComponentTab';

const ComponentShowLayout: React.FC = (): ReactElement => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout sx={commonStyles.bottomMargin}>
      <TabbedForm
        toolbar={null}
        style={{ width: '100%' }}
        sx={commonStyles.tabbedFormBoldHeader}
        syncWithLocation={false}
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
      >
        <LazyFormTab
          label="Component"
          id="component_tab"
          sx={commonStyles.formTabHeader}
        >
          <ComponentShowTab />
        </LazyFormTab>
        {record?.hasElectrical && (
          <LazyFormTab
            label="Electrical"
            id="electrical_tab"
            sx={commonStyles.formTabHeader}
          >
            <ElectricalShowTab />
          </LazyFormTab>
        )}
        {record?.hasPiping && (
          <LazyFormTab
            label="Piping"
            id="piping_tab"
            sx={commonStyles.formTabHeader}
          >
            <PipingShowTab />
          </LazyFormTab>
        )}
        {record?.hasMechanical && (
          <LazyFormTab
            label="Mechanical"
            id="mechanical_tab"
            sx={commonStyles.formTabHeader}
          >
            <MechanicalShowTab />
          </LazyFormTab>
        )}
        {record?.hasInstrumentation && (
          <LazyFormTab
            label="Instrumentation"
            id="instrumentation_tab"
            sx={commonStyles.formTabHeader}
          >
            <InstrumentationShowTab />
          </LazyFormTab>
        )}
        <LazyFormTab
          label="Comment"
          id="comment_tab"
          sx={commonStyles.formTabHeader}
        >
          <CommentList
            resource={RESOURCE_COMMENT}
            targetId="component_id"
            commentType={commentType.component}
          />
        </LazyFormTab>
        <LazyFormTab
          label="Drawings"
          id="drawing_tab"
          sx={commonStyles.formTabHeader}
        >
          <DrawingTab
            target="component_id"
            reference={RESOURCE_COMPONENT_DRAWING}
          />
        </LazyFormTab>
        <LazyFormTab
          label="Attachment"
          id="attachment_tab"
          sx={commonStyles.formTabHeader}
        >
          <AttachmentList targetId="componentId" onlyShow />
        </LazyFormTab>
        <LazyFormTab
          label="Tag-Test/ITR Attachment"
          id="test_attachment_tab"
          sx={commonStyles.formTabHeader}
        >
          <TestAttachmentTab />
        </LazyFormTab>
        <LazyFormTab
          label="Preservation Attachment"
          id="preservation_attachment_tab"
          sx={commonStyles.formTabHeader}
        >
          <PreservationAttachmentTab />
        </LazyFormTab>
        <LazyFormTab label="Activities" id="activity_tab">
          <ActivitiesTab category={activityCategory.component} onlyShow />
        </LazyFormTab>
        <LazyFormTab
          label="Tests / ITRs"
          id="tag-itrs_tab"
          sx={commonStyles.formTabHeader}
        >
          <TestITRsTab />
        </LazyFormTab>
        <LazyFormTab
          label="Punch Items"
          id="punch_items_tab"
          sx={commonStyles.formTabHeader}
        >
          <PunchFormShow target="component_id" />
        </LazyFormTab>
        <LazyFormTab
          label="Materials"
          id="materials_tab"
          sx={commonStyles.formTabHeader}
        >
          <MaterialComponentTab />
        </LazyFormTab>
        <LazyFormTab label="History">
          <AuditListTab tableName="tbl_component" />
        </LazyFormTab>
      </TabbedForm>
    </SimpleShowLayout>
  );
};

export default ComponentShowLayout;
