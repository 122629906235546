import React, { ReactElement } from 'react';
import { ChipField, ReferenceArrayField } from 'react-admin';
import { RESOURCE_SECURITY_ROLE } from '../../constants';
import SmartSingleFieldList from '../../common/SmartSingleFieldList';

type RoleFieldProps = Omit<
  React.ComponentProps<typeof ReferenceArrayField>,
  'reference'
>;

const RoleField: React.FC<RoleFieldProps> = ({
  label,
  ...rest
}): ReactElement => (
  <ReferenceArrayField
    label={label ?? ''}
    reference={RESOURCE_SECURITY_ROLE}
    {...rest}
  >
    <SmartSingleFieldList>
      <ChipField source="role" />
    </SmartSingleFieldList>
  </ReferenceArrayField>
);

export default RoleField;
