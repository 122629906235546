import React, { Fragment, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { RED } from '../../../theme/colors';
import { IChoicesLegendItem } from './types';

const ChoicesLegendItem: React.FC<IChoicesLegendItem> = ({
  name,
  description,
  isDefault,
  isMarked,
}): ReactElement => (
  <Typography variant="body2" paragraph>
    <b>
      {isMarked ? (
        <Fragment>
          <span style={{ color: RED }}>*</span>
          <span>&nbsp;</span>
        </Fragment>
      ) : null}
      {name}
      {isDefault ? (
        <Fragment>
          <span>&nbsp;</span>
          <span>(Default)</span>
        </Fragment>
      ) : null}
      :
    </b>
    <span>&nbsp;</span>
    {description}
  </Typography>
);

export default ChoicesLegendItem;
