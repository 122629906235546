import { useMemo } from 'react';
import { getContext, UserContext } from '../../../provider/userContext';

type FilterName = 'project' | 'division';

const useDefaultFilter = (
  filterName: FilterName,
  additionalFilters = {}
): object => {
  const ctx: UserContext = getContext();

  return useMemo(() => {
    switch (filterName) {
      case 'project':
        return { ...additionalFilters, projectId: ctx.projectId };
      case 'division':
        return { ...additionalFilters, divisionId: ctx.defaultDivisionId };
      default:
        return additionalFilters;
    }
  }, [additionalFilters, ctx.defaultDivisionId, ctx.projectId, filterName]);
};

export default useDefaultFilter;
