import { useDictionaryLabelsWithResource } from '../../../../../utils/UtilityFunctions';
import { ContactCategories } from '../../../../common/contactInput/constants';
import ContactInput from '../../../../common/contactInput/ContactInput';
import CustomDateInput from '../../../../common/CustomDateInput';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement } from 'react';
import { maxLength, TextField } from 'react-admin';
import commonStyles from '../../../../common/commonStyles';
import { validateStatusFields } from '../../../../common/validators';
import { FormRowProps } from '../../../types';
import FileNameField from '../components/FileNameField';

const PunchFormRow: React.FC<FormRowProps> = ({ data }): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();

  return (
    <RowForm>
      <FileNameField data={data} />
      <TextField source="punchNo" label={labels['punchNo'] || 'Punch No'} />
      <ContactInput
        category={ContactCategories.punchSignOff}
        source="clearedBy"
        label={labels['clearedBy'] || 'Cleared By'}
        validate={[validateStatusFields('cleared'), maxLength(75)]}
        sx={commonStyles.rowFormInput}
      />
      <CustomDateInput
        source="clearedDate"
        label={labels['clearedDate'] || 'Cleared Date'}
        validate={validateStatusFields('cleared')}
        sx={commonStyles.rowFormInput}
      />
      <ContactInput
        category={ContactCategories.punchSignOff}
        source="acceptedBy"
        label={labels['acceptedBy'] || 'Accepted By'}
        validate={[validateStatusFields('accepted'), maxLength(75)]}
        sx={commonStyles.rowFormInput}
      />
      <CustomDateInput
        source="acceptedDate"
        label={labels['acceptedDate'] || 'Accepted Date'}
        validate={validateStatusFields('accepted')}
        sx={commonStyles.rowFormInput}
      />
    </RowForm>
  );
};

export default PunchFormRow;
