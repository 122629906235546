import axios from 'axios';
import { ContentType, CRUD_API_URL, getRequestConfig } from '../../api';

export const getConfigurationDataProvider = () => ({
  updateWithUpload: (resource: string, data: FormData) => {
    const id = data.get('id');

    return axios.put(`${CRUD_API_URL}/${resource}/${id}/upload`, data, {
      ...getRequestConfig(ContentType.formData),
    });
  },
});
