import React from 'react';
import { GREEN } from '../../theme/colors';

const ContactSupportLink: React.FC = () => {
  return (
    <a style={{ color: GREEN }} href="mailto:support@trackertechnologies.co">
      support@trackertechnologies.co
    </a>
  );
};

export default ContactSupportLink;
