import React, { ReactElement } from 'react';
import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import ComponentInput from '../../common/ComponentInput';
import LocationInput from '../../common/LocationInput';
import CompletedByInput from '../../componentTest/components/CompletedByInput';
import CompletedDateTimeInput from '../../componentTest/components/CompletedDateTimeInput';
import VerifiedInput from '../../componentTest/components/VerifiedInput';
import MobileTestStatusInput from '../../componentTest/components/MobileTestStatusInput';
import { mobileTestStatuses } from '../constants';
import TextInput from '../../common/TextInput';
import TestFormNoInput from '../../common/TestFormNoInput';
import { required } from '../../../utils/UtilityFunctions';

const SmartTagITRReviewForm: React.FC = (): ReactElement => {
  const record = useRecordContext();
  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput sx={commonStyles.flexBoxItem} disabled />
        <TestFormNoInput
          source="testId"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <LocationInput sx={commonStyles.flexBoxItem} disabled />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CompletedByInput sx={commonStyles.flexBoxItem} />
        <CompletedDateTimeInput sx={commonStyles.flexBoxItem} />
        <VerifiedInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <MobileTestStatusInput
          source="mobileTestStatus"
          label="Mobile Test Status"
          validate={[required()]}
          sx={commonStyles.flexBoxItem}
          choices={mobileTestStatuses}
          disableValue={record?.notProcessedPunchCount !== 0 ? 'disable' : ''}
        />
        <TextInput
          source="reviewComments"
          label="Review Comments"
          sx={{ ...commonStyles.flexBoxItem, flexGrow: 1 }}
          fullWidth
          multiline
        />
      </Box>
    </Box>
  );
};

export default SmartTagITRReviewForm;
