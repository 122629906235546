import React, { ReactElement } from 'react';
import { TextField, WrapperField } from 'react-admin';
import ColorDotField from './ColorDotField';
import { getDotColor } from './helpers/activity';
import { FieldPropsWithOptionalSource } from './types';

interface CertStatusWithColorDotField extends FieldPropsWithOptionalSource {
  label?: string;
  source?: string;
}

const CertStatusWithColorDotField: React.FC<CertStatusWithColorDotField> = ({
  label,
  source,
  ...rest
}): ReactElement => (
  <WrapperField label={label} {...rest}>
    <ColorDotField source={source} label={label} getDotColor={getDotColor} />{' '}
    <TextField source={source} />
  </WrapperField>
);

CertStatusWithColorDotField.defaultProps = {
  label: 'Cert Gating Status',
  source: 'certGatingStatus.certStatus',
  sortBy: 'certGatingStatus.cert_status',
};

export default CertStatusWithColorDotField;
