import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput, Button, Filter } from 'react-admin';
import DateRangeFilter from '../../../common/DateRangeFilter';
import useSidebarFilters from '../../../common/hooks/useSidebarFilters';
import NumberRangeFilter from '../../../common/NumberRangeFilter';
import sideFilterStyles from '../../../common/sideFilterStyles';
import TextInput from '../../../common/TextInput';
import { SideFilter } from '../../../common/types';
import { RESOURCE_MATERIAL_PURCHASE_ORDER } from '../../../constants';
import { statusChoices } from '../constants';

const PurchaseOrderRequestSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
    numberFrom,
    numberTo,
    handleNumberFromChange,
    handleNumberToChange,
  } = useSidebarFilters(RESOURCE_MATERIAL_PURCHASE_ORDER, defaultFilter);

  const dateRangeProps = useMemo(
    () => ({
      dateFrom,
      dateTo,
      handleDateFromChange,
      handleDateToChange,
    }),
    [dateFrom, dateTo, handleDateFromChange, handleDateToChange]
  );

  const numberRangeProps = useMemo(
    () => ({
      numberFrom,
      numberTo,
      handleNumberFromChange,
      handleNumberToChange,
      fullWidth: true,
      alwaysOn: true,
    }),
    [numberFrom, numberTo, handleNumberFromChange, handleNumberToChange]
  );

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="PO Request No"
            name="orderNo_inc"
            source="orderNo"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Client Order No"
            name="clientOrderNo_inc"
            source="clientOrderNo"
            fullWidth
            alwaysOn
          />
          <AutocompleteInput
            multiple
            label="Status"
            source="status"
            fullWidth
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={statusChoices}
          />
          <DateRangeFilter
            label="Date Generated"
            source="generateDate"
            {...dateRangeProps}
          />
          <DateRangeFilter
            label="Date Received"
            source="receivedDate"
            {...dateRangeProps}
          />
          <NumberRangeFilter
            label="Total Price"
            source="totalPrice"
            {...numberRangeProps}
          />
          <NumberRangeFilter
            label="Total Qty Ordered"
            source="totalQtyOrdered"
            {...numberRangeProps}
          />
          <NumberRangeFilter
            label="Total Qty Received"
            source="totalQtyReceived"
            {...numberRangeProps}
          />
        </Filter>
        <Button
          label="Clear filters"
          onClick={handleClearFilters}
          id="clearFilterButton"
        />
      </CardContent>
    </Card>
  );
};

export default PurchaseOrderRequestSidebarFilters;
