import { ReactElement } from 'react';
import { TextField, TextFieldProps, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import get from 'lodash/get';
import { isImage } from '../../utils/UtilityFunctions';

const SignatureTextField = (props: TextFieldProps): ReactElement => {
  const { source, label } = props;
  const record = useRecordContext(props);
  const value = get(record, source)?.toString();

  if (isImage(value)) {
    return (
      <Typography component="span" variant="body2">
        signed image
      </Typography>
    );
  }

  return <TextField source={source} label={label} />;
};

export default SignatureTextField;
