import React, { Fragment, ReactElement } from 'react';
import { Button } from 'react-admin';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface Legend {
  color: string;
  label: string;
}

interface LegendButtonProps {
  legends: Legend[];
}

const LegendButton: React.FC<LegendButtonProps> = ({
  legends,
}): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <Button
        label={'Legend'}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          padding: '5px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box display={'flex'} flexDirection={'column'} margin={'10px'}>
          {legends.map((legend) => (
            <Box
              key={`${legend.color}${legend.label}`}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={1}
            >
              <Box
                width={8}
                height={35}
                bgcolor={legend.color}
                marginRight={1}
              />
              <Typography variant={'body2'} color={'textPrimary'}>
                {legend.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </Fragment>
  );
};

export default LegendButton;
