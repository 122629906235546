import React, { ReactElement } from 'react';
import {
  Datagrid,
  ListContextProvider,
  useGetList,
  useList,
  useRecordContext,
  TextField,
  ResourceContextProvider,
} from 'react-admin';
import { Box } from '@mui/material';
import AttachmentRevision from '../../attachment/components/AttachmentRevision';
import AttachmentShowButton from '../../attachment/components/AttachmentShowButton';
import { RESOURCE_ATTACHMENT, RESOURCE_COMPONENT_TEST } from '../../constants';
import AttachmentEmpty from '../../common/AttachmentEmpty';
import { ATTACHMENT_TYPE_ENUMS } from '../../common/enums';
import DownloadButton from '../../common/DownloadButton';
import commonStyles from '../../common/commonStyles';

const LOOKUP_PAGE_SIZE = 100;

const PreviousAttachmentTab: React.FC = (): ReactElement => {
  const record = useRecordContext();

  const { data: itrAttachments, isLoading: itrAttachmentsIsLoading } =
    useGetList(RESOURCE_ATTACHMENT, {
      filter: {
        componentTestId: record.id,
        resourceName: RESOURCE_COMPONENT_TEST,
        isDeleted: false,
        attachmentType: ATTACHMENT_TYPE_ENUMS.GENERAL,
      },
      pagination: {
        page: 1,
        perPage: LOOKUP_PAGE_SIZE,
      },
    });

  const {
    data: componentAttachments,
    isLoading: componentAttachmentsIsLoading,
  } = useGetList(RESOURCE_ATTACHMENT, {
    filter: {
      componentId: record.componentId,
      isDeleted: false,
      attachmentType: ATTACHMENT_TYPE_ENUMS.GENERAL,
    },
    pagination: {
      page: 1,
      perPage: LOOKUP_PAGE_SIZE,
    },
  });

  const listContext = useList({
    data: [...(itrAttachments || []), ...(componentAttachments || [])],
    isLoading: itrAttachmentsIsLoading || componentAttachmentsIsLoading,
  });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ResourceContextProvider value={RESOURCE_ATTACHMENT}>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          rowClick={false}
          empty={<AttachmentEmpty />}
          expand={<AttachmentRevision onlyShow />}
          expandSingle
        >
          <TextField source="id" />
          <TextField source="fileName" />
          <TextField source="resourceName" />
          <Box sx={commonStyles.columnActionsBox}>
            <AttachmentShowButton />
            <DownloadButton />
          </Box>
        </Datagrid>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

export default PreviousAttachmentTab;
