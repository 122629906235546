import {
  CUSTOM_ROUTE_BOOK_GENERATION,
  CUSTOM_ROUTE_QR_CODE_GENERATION,
  RESOURCE_ASSIGN_ITR,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PUNCH,
} from '../constants';

export const permissionTypeChoices = [
  { id: 'allow', name: 'Allow' },
  { id: 'deny', name: 'Deny' },
];

export const actionChoices = [
  { id: '*', name: 'All' },
  { id: 'list', name: 'List' },
  { id: 'create', name: 'Create' },
  { id: 'edit', name: 'Edit' },
  { id: 'show', name: 'Show' },
  { id: 'delete', name: 'Delete' },
  { id: 'forceDelete', name: 'Force Delete' },
  { id: 'export', name: 'Export' },
  { id: 'import', name: 'Import' },
  { id: 'certGen', name: 'Certificate Generation' },
  { id: 'certOverride', name: 'Certificate Override' },
  { id: 'closeout', name: 'Closeout' },
  { id: 'itrAttachment', name: 'ITR Attachment' },
  { id: 'templateUpdate', name: 'Template Update' },
  { id: 'pdfGen', name: 'PDF Generation' },
  { id: 'sendMobile', name: 'Send Mobile' },
];

export const actionFieldChoices = [
  { id: '*', name: 'All' },
  { id: 'edit', name: 'Edit' },
  { id: 'show', name: 'Show' },
];

export const customRouteResources = [
  {
    id: `${RESOURCE_ASSIGN_ITR}`,
    name: 'Assign ITRs',
  },
  {
    id: `${CUSTOM_ROUTE_BOOK_GENERATION}`,
    name: 'Book Generate',
  },
  {
    id: `${CUSTOM_ROUTE_QR_CODE_GENERATION}`,
    name: 'QR Code Generator',
  },
];

export const fieldChoices = [
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.completed`,
    name: 'Preservation Item Activity: Completed',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.inspector`,
    name: 'Preservation Item Activity: Inspector',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.signOffDate`,
    name: 'Preservation Item Activity: Sign Off Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`,
    name: 'Preservation Item Activity: Verified By',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedDate`,
    name: 'Preservation Item Activity: Verified Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.isDeleted`,
    name: 'Preservation Item Activity: Is Deleted Side Filter',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.isClosed`,
    name: 'Preservation Item Activity: Is Closed',
  },
  {
    id: `${RESOURCE_PUNCH}.clearedBy`,
    name: 'Punch: Cleared By',
  },
  {
    id: `${RESOURCE_PUNCH}.clearedDate`,
    name: 'Punch: Cleared Date',
  },
  {
    id: `${RESOURCE_PUNCH}.acceptedBy`,
    name: 'Punch: Accepted By',
  },
  {
    id: `${RESOURCE_PUNCH}.acceptedDate`,
    name: 'Punch: Accepted Date',
  },
];
