import React, { Fragment, useCallback, useState, ReactElement } from 'react';
import { CreateDialog } from '@react-admin/ra-form-layout';
import { Button, CreateProps } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import useCanAccess from '../../../common/hooks/useCanAccess';
import { RESOURCE_ATTACHMENT } from '../../../constants';
import AdminAttachmentForm from './AdminAttachmentForm';

const AdminAttachmentAddButton: React.FC<CreateProps> = (
  props
): ReactElement => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const canAccessList = useCanAccess();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onUpload = useCallback(async () => {
    setOpen(false);
    await queryClient.invalidateQueries({
      queryKey: [RESOURCE_ATTACHMENT, 'getList'],
    });
  }, [queryClient]);

  if (!canAccessList.create) return;

  return (
    <Fragment>
      <Button label="Add attachment" onClick={handleOpen} />
      <CreateDialog
        {...props}
        title={<span>Add Attachment</span>}
        fullWidth
        maxWidth="md"
        isOpen={open}
        open={handleOpen}
        close={handleClose}
      >
        <AdminAttachmentForm onUpload={onUpload} />
      </CreateDialog>
    </Fragment>
  );
};

export default AdminAttachmentAddButton;
