import React, { Fragment, ReactElement } from 'react';
import useResourceTitle from './hooks/useResourceTitle';
import { setPageTitle } from './helpers/setPageTitle';
import usePageShortInfo from './hooks/usePageShortInfo';

interface EditTitleProps {
  title?: string;
}

const EditTitle: React.FC<EditTitleProps> = ({ title }): ReactElement => {
  const resourceTitle = useResourceTitle();
  const pageShortInfo = usePageShortInfo();

  const pageTitle = `${title || resourceTitle} ${
    pageShortInfo ? ': ' + pageShortInfo : ''
  }`;
  setPageTitle(pageTitle);

  return <Fragment>{pageTitle}</Fragment>;
};

export default EditTitle;
