import React, { ReactElement } from 'react';
import { ArrayField, ChipField, WithRecord } from 'react-admin';
import SmartSingleFieldList from '../../../common/SmartSingleFieldList';

interface GatingArrayFieldProps {
  source: string;
  resource: string;
  recordName: string;
  itemNameResource: string;
}

const GatingArrayField: React.FC<GatingArrayFieldProps> = ({
  source,
  resource,
  recordName,
  itemNameResource,
}): ReactElement => (
  <ArrayField source={source}>
    <SmartSingleFieldList resource={resource}>
      <WithRecord
        render={(record) => {
          record.id = record[recordName].id;
          return <ChipField source={itemNameResource} clickable={false} />;
        }}
      ></WithRecord>
    </SmartSingleFieldList>
  </ArrayField>
);

export default GatingArrayField;
