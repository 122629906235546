import React, { Fragment, ReactElement } from 'react';
import { BulkUpdateButton } from 'react-admin';

import useCanAccess from '../../common/hooks/useCanAccess';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_SUBSYSTEM } from '../../constants';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();
  const clearCache = useInvalidateResourceQueryCache(RESOURCE_SUBSYSTEM);

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkUpdateButton
        data={{ isAssignItr: true }}
        label="Flag All"
        icon={null}
        mutationMode="pessimistic"
        mutationOptions={{
          onSettled: clearCache,
        }}
      />
      <BulkUpdateButton
        data={{ isAssignItr: false }}
        label="Unflag All"
        icon={null}
        mutationMode="pessimistic"
        mutationOptions={{
          onSettled: clearCache,
        }}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
