import React, { Fragment, ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import commonStyles from '../../common/commonStyles';
import MechanicalDisciplineInput from './MechanicalDisciplineInput';
import TextInput from '../../common/TextInput';

const MechanicalForm: React.FC = (): ReactElement => (
  <Fragment>
    <Box sx={commonStyles.flexBox}>
      <MechanicalDisciplineInput />
      <TextInput
        source="mechanical.descriptionAddtl"
        label="Additional Description"
        validate={[maxLength(75)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.subsystemOther"
        label="Other Subsystem"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.weights"
        label="Weights"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.capacity"
        label="Capacity"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
    <Box sx={commonStyles.flexBox}>
      <TextInput
        source="mechanical.pwht"
        label="Post Weld Heat Treatment"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.insulation"
        label="Insulation"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.size"
        label="Size"
        validate={[maxLength(20)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.skidNo"
        label="Skid No"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
      <TextInput
        source="mechanical.currentPrevious"
        label="Current Previous"
        validate={[maxLength(50)]}
        sx={commonStyles.flexBoxItem}
      />
    </Box>
  </Fragment>
);

export default MechanicalForm;
