import React, { ReactElement, useState, useCallback } from 'react';
import { Edit, ReferenceManyField, useGetOne, useNotify } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import { AxiosResponse } from 'axios';
import PendingPunchCreateInDialog from '../pendingPunch/components/PendingPunchCreateInDialog';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import PendingPunchFormTab from '../pendingPunch/components/PendingPunchFormTab';
import PreviousAttachmentTab from './components/PreviousAttachmentTab';
import AttachmentFormTab from '../common/AttachmentFormTab';
import SmartTagITRForm from './components/SmartTagITRForm';
import sideFilterStyles from '../common/sideFilterStyles';
import useCanAccess from '../common/hooks/useCanAccess';
import { EditPropsWithLocation } from '../common/types';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import CustomTabPanel from '../common/CustomTabPanel';
import { Originator } from '../submitPunch/constants';
import commonStyles from '../common/commonStyles';
import EditTitle from '../common/EditTitle';
import {
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_PENDING_PUNCH,
  RESOURCE_COMPONENT_TEST,
} from '../constants';

interface SmartTagITREditProps extends EditPropsWithLocation {
  onCancel?: () => void;
}

const SmartTagITREdit: React.FC<SmartTagITREditProps> = (
  props
): ReactElement => {
  const { id } = useParams();
  const { onCancel } = props;
  const [value, setValue] = useState(0);
  const [pendingPunchTitle, setPendingPunchTitle] = useState(null);
  const [
    pendingPunchCreateDialogCloseDisabled,
    setPendingPunchCreateDialogCloseDisabled,
  ] = useState(false);
  const canAccessList = useCanAccess();
  // Use RESOURCE_COMPONENT_TEST for avoiding reloading on autosave
  const { data: record } = useGetOne(RESOURCE_COMPONENT_TEST, {
    id: props?.id ?? id,
  });
  const notify = useNotify();
  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_SMART_TAG_ITR,
    true
  );

  const clearCache = useCallback(async () => {
    await invalidateQueries();
  }, [invalidateQueries]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = useCallback(() => {
    setPendingPunchTitle(null);
  }, []);

  const handleUpload = (result: AxiosResponse) => {
    clearCache();
    if (result.status === 204) {
      notify(
        `File you uploaded is big and it will take some time to convert it to pdf. 
        Once it's converted you will be notified and the pdf file will appear in the list of attachments.`,
        { type: 'success' }
      );
    }
  };

  return (
    <Box sx={sideFilterStyles.sideEditCard} id="aside">
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={<EditTitle />}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Form"
              id="form_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Punch List"
              id="pending_punch_common_tab"
              sx={{ ...commonStyles.formTabHeader, overflowX: 'auto' }}
            />
            <Tab
              label="Attachment"
              id="attachment_tab"
              sx={commonStyles.formTabHeader}
            />
            <Tab
              label="Previous Attachment"
              id="previous_attachment_tab"
              sx={commonStyles.formTabHeader}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} id="form_tab">
          <SmartTagITRForm
            onCancel={onCancel}
            setPendingPunchTitle={setPendingPunchTitle}
            setPendingPunchCreateDialogCloseDisabled={
              setPendingPunchCreateDialogCloseDisabled
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} id="pending_punch_common_tab">
          <PendingPunchFormTab
            targetId="componentTestId"
            readonly={!canAccessList.edit}
            outerResource={RESOURCE_SMART_TAG_ITR}
            originator={Originator.ITR}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} id="attachment_tab">
          <AttachmentFormTab
            targetId="componentTestId"
            attachmentType={[ATTACHMENT_TYPE_ENUMS.SMART_ITR]}
            resourceName={RESOURCE_SMART_TAG_ITR}
            simpleView
            onUpload={handleUpload}
            onDelete={clearCache}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3} id="previous_attachment_tab">
          <PreviousAttachmentTab />
        </CustomTabPanel>
      </Edit>
      <ReferenceManyField
        reference={RESOURCE_PENDING_PUNCH}
        target="componentTestId"
        perPage={100}
        fullWidth={true}
      >
        <PendingPunchCreateInDialog
          record={record}
          showButton={false}
          pendingPunchDescription={pendingPunchTitle}
          disableClose={pendingPunchCreateDialogCloseDisabled}
          onClose={handleClose}
          originator={Originator.ITR}
        />
      </ReferenceManyField>
    </Box>
  );
};

export default SmartTagITREdit;
