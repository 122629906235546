import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField, DateField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import ActivityTextField from './components/ActivityTextField';
import ActivityPhaseField from '../common/ActivityPhaseField';
import CustomBooleanField from '../common/CustomBooleanField';
import AssignedField from '../common/Assigned/AssignedField';
import CertTemplateField from '../common/CertTemplateField';
import AttachmentList from '../attachment/AttachmentList';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import DisciplineField from '../common/DisciplineField';
import ActivityField from './components/ActivityField';
import ComponentField from '../common/ComponentField';
import { RESOURCE_ACTIVITY_TYPE } from '../constants';
import commonStyles from '../common/commonStyles';
import AuditListTab from '../audit/AuditListTab';
import CertDetailsSubsystemField from './components/CertDetailsSubsystemField';
import CertDetailsSystemField from './components/CertDetailsSystemField';

const CertDetailsShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Certificate">
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CertTemplateField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="certTemplate.description" label="Description" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="certTemplate.category" label="Category" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="status" label="Status" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityField label="Activity" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField
              resource={RESOURCE_ACTIVITY_TYPE}
              source="activityTypeId"
              label="Activity Type"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField
              source="activityConstraint"
              label="Activity Constraint"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField source="sequence" label="Activity Sequence" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField
              source="plannedDate"
              label="Activity Planned Date"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField
              source="actualDate"
              label="Activity Actual Date"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField source="duration" label="Activity Duration" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField
              source="actualDuration"
              label="Activity Actual Duration"
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ComponentField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CertDetailsSystemField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CertDetailsSubsystemField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTextField
              source="durationUnit"
              label="Activity Duration Unit"
            />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityPhaseField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DisciplineField />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CustomBooleanField source="isSmart" label="Smart" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <AssignedField label="Responsible" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CustomBooleanField source="isVerified" label="Verified" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="acceptedDate" label="Accepted Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="acceptedBy" label="Accepted By" />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Printed">
            <CustomBooleanField
              source="isPrinted"
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled label="Printed Date" sx={commonStyles.flexBoxItem}>
            <DateField source="printedDate" />
          </Labeled>
          <Labeled label="Printed By">
            <TextField source="printedBy" sx={commonStyles.flexBoxLongItem} />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certTotalSignatures"
              label="Certificate Total Signatures"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="certSigned" label="Certificate Signed" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="certSignedBy" label="Certificate Signed By" />
          </Labeled>
        </Box>
        <Box>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DateField source="generatedDate" label="Generated Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="generatedBy" label="Generated By" />
          </Labeled>
        </Box>

        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Attachment" id="attachment_tab">
        <AttachmentList
          targetId="certDetailId"
          onlyShow
          attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_cert_detail" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default CertDetailsShow;
