import React, { ReactElement } from 'react';
import { RadioButtonGroupInput } from 'react-admin';
import { ITR_AUTOMATION_CHOICES } from '../constants';

const ItrAutomationInput: React.FC = (): ReactElement => {
  return (
    <RadioButtonGroupInput
      source="itrAutomation"
      label={false}
      choices={ITR_AUTOMATION_CHOICES}
    />
  );
};

export default ItrAutomationInput;
