import {
  useDataProvider,
  useListContext,
  useNotify,
  useResourceContext,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../utils/UtilityFunctions';

const useCopyBookToProject = () => {
  const resource = useResourceContext();
  const notify = useNotify();
  const { selectedIds, onUnselectItems } = useListContext();
  const dataProvider = useDataProvider();

  const { mutate: copyBookToProject } = useMutation({
    mutationFn: async (projectIds) =>
      await dataProvider.copyBookToProject(resource, {
        bookIds: selectedIds,
        projectIds,
      }),
    onSuccess: async () => {
      onUnselectItems();
      notify(
        "Copying of books has been requested. You will receive a notification when It's completed.",
        { type: 'success' }
      );
    },
    onError: async (error: AxiosError) => {
      let errorMessage = error.message;
      if (error?.response?.data instanceof Blob) {
        errorMessage = await error?.response?.data.text();
        if (error?.response?.data.type === 'application/json') {
          errorMessage = JSON.parse(errorMessage);
        }
      }

      notify(getErrorMessage(errorMessage, 'Book(s) copying is failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  return { copyBookToProject };
};

export default useCopyBookToProject;
