import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_ACTIVITY_PHASE } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { ActivityCategory } from './types';
import { required } from '../../utils/UtilityFunctions';

interface ActivityPhaseInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  category: ActivityCategory;
  isRequired?: boolean;
}

const ActivityPhaseInput: React.FC<ActivityPhaseInputProps> = ({
  category,
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        category,
      },
      sort: { field: 'phase', order: 'ASC' as const },
    };
  }, [category, ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_ACTIVITY_PHASE}
      requestParams={requestParams}
      label="Phase"
      name="phase_inc"
      optionText="phase"
      validate={isRequired ? required() : undefined}
      includeFields={['id', 'phase']}
    />
  );
};

ActivityPhaseInput.defaultProps = {
  source: 'activityPhaseId',
};

export default ActivityPhaseInput;
