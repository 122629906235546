import {
  useRecordContext,
  TextField,
  BooleanField,
  NumberField,
} from 'react-admin';
import LogoField from '../../common/FileField';
import { CONFIGURATION_TYPE } from '../constants';
import { SvgIcon } from '@mui/material';
import { Configuration } from '../types';

const ValueField = ({ label }) => {
  const record = useRecordContext<Configuration>();

  switch (record?.cfgValueType) {
    case CONFIGURATION_TYPE.TEXT:
    case CONFIGURATION_TYPE.STRING:
      return <TextField source="cfgValue" label="Value" />;
    case CONFIGURATION_TYPE.FILE:
      return <LogoField source="file" label={label} sortable={false} />;
    case CONFIGURATION_TYPE.BOOLEAN:
      return (
        <BooleanField source="cfgValue" label="Value" FalseIcon={SvgIcon} />
      );
    case CONFIGURATION_TYPE.NUMBER:
      return <NumberField source="cfgValue" label="Value" />;
    default:
      return null;
  }
};

export default ValueField;
