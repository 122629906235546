import React, { ReactElement } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { statusChoices } from '../constants';

const StatusInput: React.FC<Omit<SelectInputProps, 'choices'>> = (
  props
): ReactElement => <SelectInput {...props} choices={statusChoices} />;

StatusInput.defaultProps = {
  source: 'status',
  label: 'Status',
};

export default StatusInput;
