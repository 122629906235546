import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { RESOURCE_COMPONENT } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';
import { required } from '../../utils/UtilityFunctions';

interface ComponentInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  sx?: object;
  disabled?: boolean;
  source?: string;
  label?: string;
}

const ComponentInput: React.FC<ComponentInputProps> = ({
  isRequired,
  onChange,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'tag', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_COMPONENT}
      requestParams={requestParams}
      name="tag_inc"
      optionText="tag"
      validate={isRequired ? [required()] : []}
      handleOnChange={onChange}
      includeFields={['id', 'tag', 'tagDescription']}
    />
  );
};

ComponentInput.defaultProps = { source: 'componentId', label: 'Tag' };

export default ComponentInput;
