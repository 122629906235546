import { Labeled, SelectField, TabbedShowLayout, TextField } from 'react-admin';
import React, { ReactElement } from 'react';
import commonStyles from '../common/commonStyles';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import { categoryChoices } from './constants';
import AuditListTab from '../audit/AuditListTab';

const ActivityPhaseShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Phase">
        <Box>
          <Labeled label="Category">
            <SelectField
              source="category"
              choices={categoryChoices}
              sx={commonStyles.flexBoxItem}
            />
          </Labeled>
          <Labeled label="Phase">
            <TextField source="phase" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled label="Sort Order">
            <TextField source="sortOrder" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Box>{renderShowMetaDataFields()}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_activity_phase" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ActivityPhaseShow;
